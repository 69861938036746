import { Box, useMediaQuery, useTheme } from "@mui/material";
import { CHBLink } from "components/CHBLink";
import { Theme } from "@mui/system";

export const FooterLinkList = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { xs: "row" },
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        gap: {
          xs: "10px",
          md: "0",
        },
      }}
    >
      <CHBLink
        to="https://homebridgeheloc.com/terms/"
        target="_blank"
        separate={!isMobile}
        isFooter
        style={{
          color: theme.palette.common.white,
          textUnderlineOffset: "2px",
          textDecorationColor: theme.palette.background.default,
          whiteSpace: "nowrap",
          margin: "0 10px",
        }}
      >
        Terms of Use
      </CHBLink>
      <CHBLink
        to="https://www.homebridge.com/privacy-policy/"
        target="_blank"
        separate={!isMobile}
        isFooter
        style={{
          color: theme.palette.common.white,
          textUnderlineOffset: "2px",
          textDecorationColor: theme.palette.background.default,
          whiteSpace: "nowrap",
          margin: "0 10px",
        }}
      >
        Privacy Policy
      </CHBLink>
      <CHBLink
        to="/licenses"
        target="_blank"
        isFooter
        style={{
          color: theme.palette.common.white,
          textUnderlineOffset: "2px",
          textDecorationColor: theme.palette.background.default,
          whiteSpace: "nowrap",
          margin: "0 10px",
        }}
      >
        Licenses
      </CHBLink>
    </Box>
  );
};
