import { useCallback, useEffect, useRef, useState } from "react";
import Occupancy from "typedef/Occupancy";
import Loan from "typedef/Loan";
import DemographicsDataChecks from "typedef/DemographicsDataChecks";
import { StepTitle } from "./StepTitle";
import { useNavigate } from "react-router-dom";
import { useUpdateLoanStatus } from "./useUpdateLoanStatus";
import { useIsLogged } from "utils/useIsLogged";
import { toCapitalize } from "utils/toCapitalize";
export interface IncomeSources {
  salaryIncome?: number;
  selfEmploymentIncome?: number;
  socialIncome?: number;
  rentalIncome?: number;
  otherIncome?: number;
}

export type LoanPurposeType =
  | "Home Improvement"
  | "Debt Consolidation"
  | "Other";

export type TypeOfOwnershipType = "Fee Simple" | "Trust" | "LLC";
export type TypeOfMaritalStatus =
  | "Select"
  | "Unmarried"
  | "Married"
  | "Separated";
export interface FormDataNumbers {
  homeValue?: number;
  loanBalance?: number;
  helocBalance?: number;
  judmentsAndLiensBalance?: number;
  creditScore?: number;
  annualIncome?: number;
  requestedLoanAmount?: number;
  initialDrawAmount?: number;
}

export interface FormData extends FormDataNumbers {
  loanId?: string;
  occupancyType?: Occupancy;
  loanPurpose?: LoanPurposeType;
  typeOfOwnershipType?: TypeOfOwnershipType;
  street_line: string | undefined;
  secondary: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zipcode: string | undefined;
  source: string;
  entries?: number;
  countyFips?: string | undefined;
  firstName: string;
  lastName: string;
  middleName?: string;
  suffix?: string;
  maritalStatus: TypeOfMaritalStatus;
  email: string;
  countryCode?: string;
  phoneNumber: string;
  phoneNumberWhitoutCountryCode?: string;
  canSendSMS: boolean;
  incomeSources: IncomeSources;
  password?: string;
  dateOfBirth?: string;
  ssn?: string;
  PrivacyPolicyCheck: boolean;
  BorrowerConsentCheck: boolean;
  telemarketingConsentCheck: boolean;
  demographicsData: DemographicsDataChecks;
  borrowerAddress?: {
    city?: string;
    state?: string;
    street_line?: string;
    secondary?: string;
    zipcode?: string;
    fullAddress?: string;
    countyFips?: string;
  };
}

export interface HELOCOffer {
  amount: number;
  cltv: number;
  estPointsAndFees: number;
  estNetProceeds: number;
  estMonthlyPayment: number;
  estMonthlyCharge?: number;
  maxAmount: number;
  minAmount: number;
  rate: number;
  interestRate: number;
}
export interface OfferResponse {
  items: HELOCOffer[];
  occupancyType: string;
  minimumPercentageInitialDraw: number;
  maximumPercentageInitialDraw: number;
  loanPurpose?: string;
}

export const useGetStarted = (loan?: Loan) => {
  const { getLoanIdByStorge } = useUpdateLoanStatus();
  const { isLogged } = useIsLogged();
  const navigate = useNavigate();
  const [loadingPage, setLoadingPage] = useState(
    Boolean(isLogged || localStorage.getItem("DTCApplication__loanId")),
  );
  const [loadingTitle, setLoadingTitle] = useState(isLogged);

  const [globalError, setGlobalError] = useState<string | null>(null);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<FormData>({
    loanId: getLoanIdByStorge() ?? undefined,
    street_line: "",
    secondary: "",
    city: "",
    state: "",
    zipcode: "",
    entries: 0,
    source: "postal",
    firstName: "",
    lastName: "",
    middleName: "",
    suffix: "",
    maritalStatus: "Select",
    email: "",
    countryCode: "+1",
    phoneNumber: "",
    phoneNumberWhitoutCountryCode: "",
    canSendSMS: false,
    incomeSources: {
      salaryIncome: undefined,
      selfEmploymentIncome: undefined,
      socialIncome: undefined,
      otherIncome: undefined,
    },
    dateOfBirth: "",
    ssn: "",
    PrivacyPolicyCheck: false,
    BorrowerConsentCheck: false,
    telemarketingConsentCheck: false,
    demographicsData: {
      ethnicity: {
        hispanicOrLatino: false,
        mexican: false,
        puertoRican: false,
        cuban: false,
        otherHispanicOrLatino: false,
        customEthnicity: "",
        notHispanicOrLatino: false,
        prefNoSay: false,
      },
      race: {
        americanIndianOrAlaskaNative: false,
        customAmericanIndianOrAlaskan: "",
        asian: false,
        asianIndian: false,
        chinese: false,
        filipino: false,
        japanese: false,
        korean: false,
        vietnamese: false,
        otherAsian: false,
        customOtherAsian: "",
        blackOrAfricanAmerican: false,
        nativeHawaiian: false,
        guamanianOrChamorro: false,
        samoan: false,
        otherPacificIslander: false,
        customOtherPacificIslander: "",
        nativeHawaiianOrOtherPacificIslander: false,
        white: false,
        prefNoSay: false,
      },
      sex: {
        male: false,
        female: false,
        prefNoSay: false,
      },
      citizenship: "",
    },
  });

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  const toStep = useCallback((statusCode: string) => {
    const steps: { [key: string]: number } = {
      DTC002: 2,
      DTC003: 3,
      DTC004: 4,
      DTC005: 5,
      DTC006: 6,
      DTC007: 7,
      DTC008: 8,
      DTC009: 9,
      DTC010: 10,
      DTC011: 11,
      DTC012: 12,
    };

    if (steps[statusCode] !== 6) {
      setLoadingTitle(false);
    } else {
      setLoadingTitle(true);
    }

    setStep(steps[statusCode] ?? 2);
  }, []);

  const handleStepTitle = useCallback(
    (step: number) => {
      const firstName = toCapitalize(formData?.firstName ?? "");
      const titles: { [key: number]: React.ReactNode } = {
        // Property
        2: (
          <StepTitle
            text="Tell us about this property"
            boldText="hidden"
            boldVisible={false}
          />
        ),
        // Loan Request
        3: (
          <StepTitle
            text="We need a few numbers"
            boldText="hidden"
            boldVisible={false}
          />
        ),
        // Credit Score
        4: (
          <StepTitle
            text="What’s your credit score?"
            boldText="hidden"
            boldVisible={false}
          />
        ),
        // Verify Email
        5: (
          <StepTitle
            boldText="Congrats"
            normalText="— you’re"
            text="pre-qualified!"
          />
        ),
        // Offer
        6: (
          <StepTitle
            boldText={`${firstName},`}
            normalText=""
            text={!globalError ? "choose your offer!" : ""}
          />
        ),
        // Loan Purpose
        7: (
          <StepTitle
            boldText={`${firstName},`}
            normalText=""
            text="how will you use your HELOC?"
          />
        ),
        // TypeOfOwnership
        8: (
          <StepTitle
            text="Almost finished…"
            boldText="hidden"
            boldVisible={false}
          />
        ),
        // BorrowerPersonalData
        9: (
          <StepTitle
            text={firstName}
            normalText="We just need a few more things,"
            boldText=""
            boldVisible={false}
          />
        ),
        // Incomes
        10: (
          <StepTitle
            text="Tell us about your income"
            boldText="hidden"
            boldVisible={false}
          />
        ),
        // BorrowerDemographicInfo
        11: (
          <StepTitle
            text="We're required to gather this information"
            boldText="hidden"
            boldVisible={false}
          />
        ),
        // BorrowerIdentityData
        12: (
          <StepTitle
            text={`One more thing, ${firstName}`}
            boldText="hidden"
            boldVisible={false}
          />
        ),
      };
      return titles[step];
    },
    [formData.firstName, globalError],
  );

  const handleStepProgressBar = useCallback((step: number) => {
    const titles: { [key: number]: number } = {
      2: 25,
      3: 55,
      4: 85,
      5: 100,
      6: 15,
      7: 30,
      8: 55,
      9: 70,
      10: 80,
      11: 90,
      12: 95,
    };
    return titles[step];
  }, []);

  const loadedRef = useRef(false);

  useEffect(() => {
    if (!loadedRef.current) {
      if (
        loan?.DTCApplication &&
        !loan?.DTCApplication?.socialSecurityNumber &&
        !loan?.DTCApplication?.dateOfBirth
      ) {
        setFormData({
          ...formData,
          loanId: loan.id,
          street_line:
            loan.DTCApplication?.address?.street_line ?? formData.street_line,
          secondary:
            loan.DTCApplication?.address?.secondary ?? formData.secondary,
          city: loan.DTCApplication?.address?.city ?? formData.city,
          state: loan.DTCApplication?.address?.state ?? formData.state,
          zipcode: loan.DTCApplication?.address?.zipcode ?? formData.zipcode,

          email: loan.DTCApplication.email ?? formData.email,
          creditScore: loan.DTCApplication.creditScore ?? formData.creditScore,
          firstName: loan.DTCApplication?.firstName ?? formData.firstName,
          lastName: loan.DTCApplication?.lastName ?? formData.lastName,
          helocBalance:
            loan.DTCApplication.helocBalance ?? formData.helocBalance,
          homeValue: loan.DTCApplication.homeValue ?? formData.homeValue,
          judmentsAndLiensBalance:
            loan.DTCApplication.judmentsAndLiensBalance ??
            formData.judmentsAndLiensBalance,
          loanBalance: loan.DTCApplication.loanBalance ?? formData.loanBalance,
          occupancyType:
            loan.DTCApplication.occupancyType ?? formData.occupancyType,
          countyFips:
            loan.DTCApplication?.address?.countyFips ?? formData.countyFips,

          countryCode: "+1",
          phoneNumber:
            loan.DTCApplication.phoneNumber ?? formData.phoneNumber.trim(),
          phoneNumberWhitoutCountryCode:
            loan.DTCApplication.phoneNumber?.replace("+1", "") ??
            formData.phoneNumberWhitoutCountryCode,
          requestedLoanAmount:
            loan.DTCApplication.requestedLoanAmount ??
            formData.requestedLoanAmount,
          initialDrawAmount:
            loan.DTCApplication.initialDrawAmount ?? formData.initialDrawAmount,
          maritalStatus:
            loan.DTCApplication.maritalStatus ?? formData.maritalStatus,
          loanPurpose: loan.DTCApplication.loanPurpose ?? formData.loanPurpose,
          typeOfOwnershipType:
            loan.DTCApplication.typeOfOwnershipType ??
            formData.typeOfOwnershipType,
          middleName: loan.DTCApplication.middleName ?? formData.middleName,
          suffix: loan.DTCApplication.suffixName ?? formData.suffix,
          incomeSources:
            loan.DTCApplication.incomeSources ?? formData.incomeSources,
          demographicsData: loan?.borrowerSteps?.demographicsVerification
            ?.citizenship
            ? (loan?.borrowerSteps
                ?.demographicsVerification as DemographicsDataChecks)
            : formData.demographicsData,
        });

        if (step === 1) {
          let loanStatus = loan.newLoanStatusCode ?? "DTC011";

          if (
            (loan.newLoanStatusCode === "DTC006" && !isLogged) ||
            (loan.newLoanStatusCode === "DTC102" && !isLogged)
          ) {
            loanStatus = "DTC005";
          }

          toStep(loanStatus);
        }

        setLoadingPage(false);
        loadedRef.current = true;
      } else if (
        loan?.id &&
        loan?.DTCApplication?.socialSecurityNumber &&
        loan?.DTCApplication?.dateOfBirth
      ) {
        navigate(`/borrower-tracker?loanId=${loan?.id}`, { replace: true });
      }
    }
  }, [step, formData, loan?.id, toStep, navigate, isLogged, loan]);

  return {
    step,
    nextStep,
    prevStep,
    formData,
    setFormData,
    loadingPage,
    setLoadingPage,
    loadingTitle,
    setLoadingTitle,
    handleStepTitle,
    handleStepProgressBar,
    setGlobalError,
  };
};
