import API from "utils/API";
import {
  useLocation,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useLoan from "./useLoan";
import InviteContextType from "typedef/InviteContextType";

export const InviteContext = React.createContext<InviteContextType | undefined>(
  {},
);

export const useInvite = () => {
  return useContext(InviteContext);
};

export const InviteCustomerContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const params = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [invite, setInvite] = useState<InviteContextType | undefined>();
  const { brokerName, loName } = params;
  let { wholesaleEntity } = params;
  const code = searchParams.get("code");
  const apiOfferCode = searchParams.get("apiOfferCode");
  const mloid = searchParams.get("mloid");
  const loan = useLoan();
  const errorStatus = 400;
  const isCMG = loan?.brokerId === "1d50068a-e574-4548-b0aa-5a04f976ded9";

  const typeUser = useMemo(
    () =>
      location.pathname.includes("coborrower") ? "coborrower" : "borrower",
    [location.pathname],
  );

  let correctEntity = useMemo(
    () =>
      ["remn", "hb", "invite"].includes(wholesaleEntity ?? "")
        ? wholesaleEntity
        : undefined,
    [wholesaleEntity],
  );

  if (brokerName === "neighborhoodloans") {
    wholesaleEntity = "invite";
    correctEntity = "invite";
  }

  const apiCallParams = useMemo(() => {
    if (!code && !brokerName && !loName && (loan?.loanOfficer || mloid)) {
      return `/get-invite/borrower?loanOfficerId=${
        loan?.loanOfficer?.id ?? mloid
      }`;
    }
    if (
      !code &&
      brokerName &&
      loName &&
      !loan?.loanOfficer &&
      wholesaleEntity
    ) {
      return `/get-invite/borrower?brokerName=${brokerName}&loName=${loName}&wholesaleEntity=${correctEntity}`;
    }
    if (code && typeUser) {
      return `/get-invite/${typeUser}?code=${code}`;
    }
    return undefined;
  }, [
    brokerName,
    code,
    correctEntity,
    loan,
    loName,
    mloid,
    typeUser,
    wholesaleEntity,
  ]);

  useEffect(() => {
    if (!apiCallParams) return;

    API.get<InviteContextType>(apiCallParams).then(async (res) => {
      if ("error" in res) {
        console.error(res);
        if (
          !loan?.coborrowerInviteCode &&
          res.status === errorStatus &&
          !isCMG
        ) {
          navigate("/lo-link-not-found");
        }
        return;
      }

      let updatedInvite = res.data;

      if (apiOfferCode) {
        try {
          const offerResponse = await API.get<InviteContextType>(
            `/get-invite/borrower?apiOfferCode=${apiOfferCode}`,
          );
          if (!("error" in offerResponse)) {
            updatedInvite = {
              ...updatedInvite,
              homeValue: offerResponse.data.homeValue,
              currentLoanBalance: offerResponse.data.currentLoanBalance,
              requestedNewLoan: offerResponse.data.requestedLoanAmount,
              useProceeds: offerResponse.data.loanPurpose,
              creditScore: offerResponse.data.creditScore,
              firstName: offerResponse.data.firstName,
              lastName: offerResponse.data.lastName,
              maritalStatus: offerResponse.data.maritalStatus,
              phoneNumber: offerResponse.data.phoneNumber,
              body: {
                incomeSourcesBorrower: {
                  salaryIncome:
                    offerResponse.data.body?.incomeSourcesBorrower.salaryIncome,
                  selfEmploymentIncome:
                    offerResponse.data.body?.incomeSourcesBorrower
                      .selfEmploymentIncome,
                  socialIncome:
                    offerResponse.data.body?.incomeSourcesBorrower.socialIncome,
                  rentalIncome:
                    offerResponse.data.body?.incomeSourcesBorrower.rentalIncome,
                  otherIncome:
                    offerResponse.data.body?.incomeSourcesBorrower.otherIncome,
                },
                confirmEmailAddressBorrower:
                  offerResponse.data.body?.confirmEmailAddressBorrower,
              },
              password: offerResponse.data.password,
              employerNameBorrower: offerResponse.data.employerNameBorrower,
              startDateBorrower: offerResponse.data.startDateBorrower,
              propertyAddress: offerResponse.data.propertyAddress,
              customAddress: offerResponse.data.customAddress,
            };
          }
        } catch (error) {
          console.error("Failed to fetch apiOfferCode invite:", error);
        }
      }

      setInvite(updatedInvite);
    });
  }, [apiCallParams, loan, navigate, errorStatus, isCMG, apiOfferCode]);

  return (
    <InviteContext.Provider value={invite}>{children}</InviteContext.Provider>
  );
};

export default useInvite;
