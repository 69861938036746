import { Fragment, useState } from "react";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import CoborrowerInvite from "../CoborrowerInvite";
import useJointAnnouncement from "./useJointAnnouncement";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import API from "utils/API";
import useLoan from "context/Invite/useLoan";

export type NPEForm = {
  email: string;
};

const JointAccountAnnouncement = () => {
  const jointAnnouncement = useJointAnnouncement();
  const [showOptions, setShowOptions] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReasonText, setOtherReasonText] = useState("");
  const [loading, setLoading] = useState(false);
  const [defaultError, setDefaultError] = useState("");
  const loan = useLoan();
  const theme = useTheme();
  const MAX_CHARACTERS = 140;
  const ownerNames = jointAnnouncement?.ownerNames?.join(", ");

  const handleButtonClick = () => {
    setShowOptions(true);
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setSelectedReason(selectedValue);
    if (selectedValue !== "other") {
      setOtherReasonText("");
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;
    if (text.length <= MAX_CHARACTERS) {
      setOtherReasonText(text);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    switch (selectedReason) {
      case "deceased": {
        await API.post({
          url: "/save-to-loan/request-tax-return",
          data: {
            loanId: loan?.id,
            isBorrower: true,
            docsList: [
              {
                category: "Income",
                documentName: "Death Certificate Income",
                isVisible: true,
                label: "Death Certificate Income Borrower",
                step: "incomeVerification",
                templateId: "",
                value: "Death Certificate Income Borrower",
              },
            ],
          },
        });
        break;
      }
      case "minor": {
        await API.post({
          url: "/save-to-loan/request-tax-return",
          data: {
            loanId: loan?.id,
            isBorrower: true,
            docsList: [
              {
                category: "Income",
                documentName: "Birth Certificate",
                isVisible: true,
                label: "Birth Certificate Borrower",
                step: "incomeVerification",
                templateId: "",
                value: "Birth Certificate Borrower",
              },
            ],
          },
        });
        break;
      }
      case "notOwner": {
        await API.post({
          url: "/save-to-loan/create-note-and-message",
          data: {
            loanId: loan?.id,
            note: "Plaid response for review - POD present",
            email: loan?.borrowerEmail,
            isIncome: true,
            optionSelected: "notOwner",
          },
        });
        break;
      }
      case "other": {
        await API.post({
          url: "/save-to-loan/create-note-and-message",
          data: {
            loanId: loan?.id,
            note: otherReasonText,
            email: loan?.borrowerEmail,
            isIncome: true,
            optionSelected: "other",
          },
        });
        break;
      }
      default: {
        setDefaultError("Not valid option");
      }
    }
    setLoading(false);
    jointAnnouncement.setOpenAnnouncement(false);
    jointAnnouncement.setIsModalOpen(true);
  };

  return (
    <Fragment>
      <CDialog
        maxWidth="xs"
        icon="warning"
        title={`We've noticed the following names as owners on your bank account: ${ownerNames}`}
        description="Please choose if you want to add them as a co-borrower."
        open={jointAnnouncement.openAnnouncement}
        data-cy="titleOwnerNames"
      >
        <CButton
          data-cy="buttonAdd"
          fullWidth
          onClick={jointAnnouncement.handleAccept}
          variant="contained"
          sx={{ marginBottom: 3, marginTop: 1 }}
          name="jointAccountAnnouncement-add"
        >
          Add {ownerNames}
        </CButton>
        <CButton
          data-cy="buttonCannotSign"
          fullWidth
          variant="outlined"
          sx={{ marginBottom: 3, marginTop: 1 }}
          name="jointAccountAnnouncement-cannotSign"
          onClick={handleButtonClick}
        >
          This person cannot sign
        </CButton>
        <div>
          {showOptions && (
            <>
              <Typography
                sx={{ marginBottom: 1, marginTop: 3, fontSize: "1rem" }}
                fontFamily={"Pro Display Bold"}
              >
                Why can't this person sign?
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.text.secondary,
                  marginBottom: 3,
                }}
              >
                Please provide more details
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-cy="checkboxDeceased"
                      checked={selectedReason === "deceased"}
                      onChange={handleOptionChange}
                      value="deceased"
                    />
                  }
                  label="Named person is deceased"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      data-cy="checkboxMinor"
                      checked={selectedReason === "minor"}
                      onChange={handleOptionChange}
                      value="minor"
                    />
                  }
                  label="Named person is a minor"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      data-cy="checkboxNotOwner"
                      checked={selectedReason === "notOwner"}
                      onChange={handleOptionChange}
                      value="notOwner"
                    />
                  }
                  label={`Named person is not owner, just "Paid On Death"`}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      data-cy="checkboxOther"
                      checked={selectedReason === "other"}
                      onChange={handleOptionChange}
                      value="other"
                    />
                  }
                  label="Other"
                />
                {selectedReason === "other" && (
                  <TextField
                    placeholder="Please provide a reason why this person can't sign."
                    multiline
                    rows={3}
                    value={otherReasonText}
                    onChange={handleTextChange}
                    variant="outlined"
                    helperText={`${
                      MAX_CHARACTERS - otherReasonText.length
                    } characters remaining`}
                    fullWidth
                    data-cy="textFieldOther"
                  />
                )}
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.text.secondary,
                    marginBottom: 3,
                  }}
                >
                  {defaultError}
                </Typography>
                <CButton
                  data-cy="buttonSubmit"
                  fullWidth
                  onClick={handleSubmit}
                  variant="contained"
                  sx={{ marginBottom: 2, marginTop: 3 }}
                  name="jointAccountAnnouncement-submit"
                  disabled={!selectedReason || loading}
                >
                  {loading ? (
                    <CircularProgress size={32} sx={{ color: "light" }} />
                  ) : (
                    "Submit"
                  )}
                </CButton>
              </FormGroup>
            </>
          )}
        </div>
      </CDialog>

      <CoborrowerInvite
        open={jointAnnouncement.coborrowerInviteIsOpen}
        onClose={() => {
          jointAnnouncement.setCoborrowerInviteIsOpen(false);
          jointAnnouncement.setIsModalOpen(false);
        }}
        onDismiss={() => {
          jointAnnouncement.setCoborrowerInviteIsOpen(false);
          jointAnnouncement.setIsModalOpen(false);
        }}
      />
    </Fragment>
  );
};

export default JointAccountAnnouncement;
