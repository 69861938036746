import API from "utils/API";
import { useForm } from "react-hook-form";
import { DialogProps } from "@mui/material";
import useTracker from "components/CTracker/useTrackerContext";
import { useEffect, useState } from "react";

const useCoborrowerInviteForm = (
  onClose?: Pick<DialogProps, "onClose">["onClose"],
  customFlags?: Record<string, string | number | boolean>,
) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const { loan, refetchLoan, setIsModalOpen } = useTracker();

  const form = useForm<{
    firstName: string;
    lastName: string;
    email: string;
  }>();
  const addedNPE = loan?.borrowerFlags?.offeredAddingNPEAsCoborrower
    ? true
    : false;
  const b2Exists = loan?.coborrowerId ? true : false;

  useEffect(() => {
    if (!addedNPE && !b2Exists && loan?.property?.dateAddressMatched) {
      setIsModalOpen(true);
    } else if (b2Exists) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(false);
    }
  }, [addedNPE, setIsModalOpen, b2Exists, loan?.property?.dateAddressMatched]);

  const onSubmit = form.handleSubmit(async (data) => {
    const response = await API.post({
      url: `/invite-coborrower`,
      data: {
        coborrowerName: data.firstName?.trim(),
        lastName: data.lastName?.trim(),
        coborrowerEmail: data.email?.toLowerCase().trim(),
        loanId: loan?.id,
      },
    });
    if ("error" in response) {
      if (response.error?.includes("Email")) {
        form.setError("email", {
          message: response.error,
        });
      } else {
        setError(response?.error);
      }
    } else {
      await API.post({
        url: `/save-to-loan/flags?entity=borrower&loanId=${loan?.id}`,
        data: {
          coborrowerInvited: true,
          ...customFlags,
        },
      });

      //set the coborrower ID so the dialog goes away until refetch is complete.
      if (loan) {
        loan.coborrowerId = "TEMP_UNTIL_REFRESH";
      }
      await refetchLoan();
      await refetchLoan();
      setIsModalOpen(false);
      onClose?.({}, "backdropClick");
    }
  });

  return {
    ...form,
    onSubmit,
    error,
  };
};

export default useCoborrowerInviteForm;
