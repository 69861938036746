import { Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

type Props = {
  children: React.ReactNode;
};

const ScrollToTop = (props: Props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const marketingCampaign: { [key: string]: string | null } = {
      ...(searchParams.get("utm_id") && {
        utm_id: searchParams.get("utm_id"),
      }),
      ...(searchParams.get("utm_source") && {
        utm_source: searchParams.get("utm_source"),
      }),
      ...(searchParams.get("utm_medium") && {
        utm_medium: searchParams.get("utm_medium"),
      }),
      ...(searchParams.get("utm_campaign") && {
        utm_campaign: searchParams.get("utm_campaign"),
      }),
      ...(searchParams.get("utm_term") && {
        utm_term: searchParams.get("utm_term"),
      }),
      ...(searchParams.get("sfmc_id") && {
        sfmc_id: searchParams.get("sfmc_id"),
      }),
      ...(searchParams.get("utm_content") && {
        utm_content: searchParams.get("utm_content"),
      }),
    };

    if (Object.keys(marketingCampaign).length > 0) {
      for (const key in marketingCampaign) {
        searchParams.delete(key);
      }
      localStorage.setItem(
        "marketingCampaign",
        JSON.stringify(marketingCampaign),
      );
      const querySearch = searchParams.toString()
        ? `?${searchParams.toString()}`
        : "";
      navigate(`${location.pathname}${querySearch}`);
    }
  }, [navigate, location.pathname, searchParams]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <Fragment>{props.children}</Fragment>;
};

export default ScrollToTop;
