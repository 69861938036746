import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { FormData } from "../../useGetStarted";
import { Errors } from "../BorrowerPersonalData/useBorrowerPersonalData";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

interface InputFieldsProps {
  formData: FormData;
  onValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
  errors: Errors[];
  animation: boolean;
  showConfirmUser: boolean;
  fourthText: string;
  confirmPassword: string;
  setConfirmPassword: (value: string) => void;
  showPassword: boolean;
  toggleShowPassword: () => void;
  code: string;
  setCode: (value: string) => void;
  resendCode: () => void;
}

export const InputFields = ({
  formData,
  onValueChange,
  error,
  errors,
  animation,
  showConfirmUser,
  fourthText,
  confirmPassword,
  setConfirmPassword,
  showPassword,
  toggleShowPassword,
  code,
  setCode,
  resendCode,
}: InputFieldsProps) => {
  const { sizes } = useHomebridgeSizes();
  return (
    <Box>
      {!showConfirmUser && (
        <>
          <Box>
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              marginBottom={"10px"}
              className="custom-typography"
            >
              First Name:
            </Typography>

            <input
              value={formData.firstName}
              id={"firstName"}
              type="text"
              onChange={onValueChange}
              style={{
                border:
                  error && errors.find((err) => err.field === "firstName")
                    ? "2px solid red"
                    : `2px solid ${fourthText}`,
                animation:
                  animation && errors.find((err) => err.field === "firstName")
                    ? "shake 0.3s ease-in-out"
                    : "none",
              }}
            />

            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={"16px"}
              color={"error"}
              textAlign={"center"}
              marginTop={"10px"}
              sx={{
                animation:
                  animation && errors.find((err) => err.field === "firstName")
                    ? "shake 0.3s ease-in-out"
                    : "none",
              }}
            >
              {error &&
                errors.find((err) => err.field === "firstName")?.message}
            </Typography>
            <p
              className="get-started__note"
              style={{
                textAlign: "center",
              }}
            >
              <strong className="strong-700">Important:</strong> Please enter
              the name you use on documents, like your home’s deed (for example,
              Thomas versus Tom).
            </p>
          </Box>
          <Box>
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              marginBottom={"10px"}
              className="custom-typography"
            >
              Last Name:
            </Typography>
            <input
              value={formData.lastName}
              id={"lastName"}
              type="text"
              onChange={onValueChange}
              style={{
                border:
                  error && errors.find((err) => err.field === "lastName")
                    ? "2px solid red"
                    : `2px solid ${fourthText}`,
                animation:
                  animation && errors.find((err) => err.field === "lastName")
                    ? "shake 0.3s ease-in-out"
                    : "none",
              }}
            />

            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={"16px"}
              color={"error"}
              textAlign={"center"}
              marginTop={"10px"}
              sx={{
                animation:
                  animation && errors.find((err) => err.field === "lastName")
                    ? "shake 0.3s ease-in-out"
                    : "none",
              }}
            >
              {error && errors.find((err) => err.field === "lastName")?.message}
            </Typography>
          </Box>
        </>
      )}

      <Box marginTop="10px">
        <Typography
          variant="body2"
          fontFamily={"NunitoSans"}
          fontSize={sizes.typography}
          fontWeight={"bold"}
          marginBottom={"10px"}
          className="custom-typography"
        >
          Email:
        </Typography>

        <input
          value={formData.email}
          disabled={showConfirmUser}
          id={"email"}
          type="text"
          onChange={onValueChange}
          style={{
            border:
              error && errors.find((err) => err.field === "email")
                ? "2px solid red"
                : `2px solid ${fourthText}`,
            animation:
              animation && errors.find((err) => err.field === "email")
                ? "shake 0.3s ease-in-out"
                : "none",
          }}
        />

        <Typography
          variant="body2"
          fontFamily={"NunitoSans"}
          fontSize={"16px"}
          color={"error"}
          textAlign={"center"}
          marginTop={"10px"}
          sx={{
            animation:
              animation && errors.find((err) => err.field === "email")
                ? "shake 0.3s ease-in-out"
                : "none",
          }}
        >
          {error && errors.find((err) => err.field === "email")?.message}
        </Typography>
      </Box>
      {!showConfirmUser && (
        <Box marginTop="20px">
          <Typography
            variant="body2"
            fontFamily={"NunitoSans"}
            fontSize={sizes.typography}
            fontWeight={"bold"}
            marginBottom={"10px"}
            className="custom-typography"
          >
            Create a Password:
          </Typography>
          <Box sx={{ position: "relative" }}>
            <input
              value={formData.password}
              id={"password"}
              type={showPassword ? "text" : "password"}
              onChange={onValueChange}
              style={{
                border:
                  error && errors.find((err) => err.field === "password")
                    ? "2px solid red"
                    : `2px solid ${fourthText}`,
                animation:
                  animation && errors.find((err) => err.field === "password")
                    ? "shake 0.3s ease-in-out"
                    : "none",
                paddingRight: "40px",
              }}
            />

            <IconButton
              onClick={toggleShowPassword}
              sx={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
              }}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Box>
          <Typography
            variant="body2"
            fontFamily={"NunitoSans"}
            fontSize={"16px"}
            color={"error"}
            textAlign={"center"}
            marginTop={"10px"}
            sx={{
              animation:
                animation && errors.find((err) => err.field === "password")
                  ? "shake 0.3s ease-in-out"
                  : "none",
            }}
          >
            {error && errors.find((err) => err.field === "password")?.message}
          </Typography>
          <p
            className="get-started__note"
            style={{
              textAlign: "center",
            }}
          >
            <strong className="strong-700">Note:</strong> Password must be at
            least 9 characters and include both upper and lower case characters,
            at least 1 symbol character, and at least 1 number
          </p>
        </Box>
      )}

      {!showConfirmUser && (
        <Box marginTop="20px">
          <Typography
            variant="body2"
            fontFamily={"NunitoSans"}
            fontSize={sizes.typography}
            fontWeight={"bold"}
            marginBottom={"10px"}
            className="custom-typography"
            sx={{
              fontFamily: "NunitoSans",
              textAlign: "start",
            }}
          >
            Confirm Password:
          </Typography>

          <Box sx={{ position: "relative" }}>
            <input
              value={confirmPassword}
              id={"confirmPassword"}
              type={showPassword ? "text" : "password"}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              style={{
                border:
                  error && confirmPassword !== formData.password
                    ? "2px solid red"
                    : `2px solid ${fourthText}`,
                animation:
                  animation && confirmPassword !== formData.password
                    ? "shake 0.3s ease-in-out"
                    : "none",
                paddingRight: "40px",
              }}
            />

            <IconButton
              onClick={toggleShowPassword}
              sx={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
              }}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Box>

          <Typography
            variant="body2"
            fontFamily={"NunitoSans"}
            fontSize={"16px"}
            color={"error"}
            textAlign={"center"}
            marginTop={"10px"}
            sx={{
              animation:
                animation && confirmPassword !== formData.password
                  ? "shake 0.3s ease-in-out"
                  : "none",
            }}
          >
            {error && confirmPassword !== formData.password
              ? "Passwords must match."
              : ""}
          </Typography>
        </Box>
      )}

      {showConfirmUser && (
        <Box marginTop="10px" width={"100%"}>
          <Typography
            variant="body2"
            fontFamily={"NunitoSans"}
            fontSize={sizes.typography}
            fontWeight={"bold"}
            marginBottom={"10px"}
            className="custom-typography"
          >
            Code
          </Typography>

          <input
            value={code}
            id={"code"}
            type="text"
            onChange={(e) => setCode(e.target.value)}
            style={{
              border:
                error && code.length !== 6
                  ? "2px solid red"
                  : `2px solid ${fourthText}`,
              animation:
                animation && error && code.length !== 6
                  ? "shake 0.3s ease-in-out"
                  : "none",
              paddingRight: "40px",
            }}
          />

          <Typography
            variant="body2"
            fontFamily={"NunitoSans"}
            fontSize={"16px"}
            color={"error"}
            textAlign={"center"}
            marginTop={"10px"}
            sx={{
              animation:
                animation && code.length !== 6
                  ? "shake 0.3s ease-in-out"
                  : "none",
            }}
          >
            {error && code.length !== 6 && "Code must be 6 characters long"}
          </Typography>
        </Box>
      )}

      {showConfirmUser &&
        errors.filter((err) => err.field === "email").length === 0 && (
          <Typography
            variant="body2"
            fontFamily={"NunitoSans"}
            fontSize={"16px"}
            marginTop={"10px"}
            textAlign={"center"}
          >
            <Box
              component={"span"}
              sx={{
                cursor: "pointer",
                borderBottom: "1px solid white",
              }}
              onClick={resendCode}
            >
              Resend Code
            </Box>
          </Typography>
        )}
    </Box>
  );
};
