import CBadge from "./CBadge";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "context/UserCustomer/useUserCustomerContext";
import {
  Menu,
  Avatar,
  MenuItem,
  IconButton,
  CircularProgress,
  useTheme,
} from "@mui/material";
import useLoan from "./useLoan";
import { useIsHomebridge } from "utils/useIsHomebridge";

const CAvatar: React.FC = () => {
  const navigate = useNavigate();
  const { user, logout, loadingUserData, selectedLoanId } = useUser();
  const loan = useLoan();
  const { isHomeBridge } = useIsHomebridge();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isBorrower = loan?.borrowerId === user?.id;
  const theme = useTheme();
  const pathname = window.location.pathname;

  if (!loadingUserData && !user) return null;

  const redirectToFinalOffer =
    (isBorrower &&
      loan?.finalOffer &&
      !loan?.borrowerFlags?.finalOfferAccepted &&
      (loan?.coborrowerId ||
        (loan?.property?.NPE &&
          (loan?.borrowerFlags?.offeredAddingNPEAsCoborrower ||
            loan?.borrowerFlags?.offeredCoborrowerInvite)) ||
        !loan?.property?.NPE) &&
      loan?.borrowerSteps?.incomeVerification?.status === "success" &&
      loan?.borrowerSteps?.creditVerification?.status === "success" &&
      loan?.borrowerSteps?.titleVerification?.status === "success" &&
      (loan?.borrowerSteps?.homeValuationVerification?.status === "success" ||
        (loan?.borrowerSteps?.homeValuationVerification?.status ===
          "analyzing" &&
          loan?.borrowerSteps?.homeValuationVerification?.reportType ===
            "BPO" &&
          loan?.borrowerSteps?.homeValuationVerification?.orderId)) &&
      (loan?.initialOffer?.useProceeds !== "Debt Consolidation" ||
        loan?.borrowerFlags?.debtsCofirmed)) ||
    loan?.borrowerFlags?.reAcceptedFinalOffer === false;

  return (
    <Fragment>
      <IconButton
        sx={{ right: -8, ml: 1 }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        data-cy="userBotton"
      >
        <CBadge
          variant="dot"
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          {!user && !loan && <CircularProgress color="primary" />}
          {user && (
            <Avatar
              sx={{
                width: { xs: 45, md: 60 },
                height: { xs: 45, md: 60 },
                bgcolor: theme.palette.primary.main,
                textTransform: "uppercase",
              }}
            >
              {user.firstName?.[0]} {user.lastName?.[0]}
            </Avatar>
          )}
        </CBadge>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {loan &&
          loan?.borrowerSteps?.fundingVerification.status === "success" && (
            <MenuItem
              onClick={() =>
                navigate(`/home-monitor?loanId=${selectedLoanId}`, {
                  replace: true,
                })
              }
            >
              Home monitor
            </MenuItem>
          )}
        {user && pathname !== "/loans" && (
          <MenuItem onClick={() => navigate("/loans", { replace: true })}>
            Your loans
          </MenuItem>
        )}
        {!isHomeBridge &&
          !loan?.borrowerFlags?.initialOfferAccepted &&
          !loan?.borrowerFlags?.archiveType &&
          isBorrower &&
          pathname !== "/loans" && (
            <MenuItem
              onClick={() =>
                navigate(`/type-of-credit-line?loanId=${selectedLoanId}`, {
                  replace: true,
                })
              }
            >
              Go to Initial Offer
            </MenuItem>
          )}
        {!isHomeBridge &&
          redirectToFinalOffer &&
          isBorrower &&
          !loan?.borrowerFlags?.archiveType && (
            <MenuItem
              onClick={() => navigate("/final-offer", { replace: true })}
            >
              Go to Final Offer
            </MenuItem>
          )}

        <MenuItem data-cy="logout" onClick={logout}>
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default CAvatar;
