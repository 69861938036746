export enum NEW_LOAN_STATUS_CODE {
  PQL,
  ARC,
  DTI,
  TTL,
  CTC,
  CLS,
}

export type LoanStatusStep = {
  [key: string]: string;
};
export interface LoanStatusStages {
  [key: string]: LoanStatusStep;
}
