import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import { useIsHomebridge } from "utils/useIsHomebridge";

const Property: FC<HELOCFormProps> = ({ loan }) => {
  const theme = useTheme();
  const property = loan?.property;
  const { isHomeBridge } = useIsHomebridge();

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      alignItems="center"
      sx={{
        backgroundColor: isHomeBridge
          ? "#D3E1EA"
          : theme.palette.background.paper,
        p: 2,
      }}
    >
      <Typography
        sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
        maxWidth="220px"
        width="100%"
      >
        Property:
      </Typography>
      {property ? (
        <Typography
          fontFamily={"Pro Display Bold"}
          sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
        >
          {property?.fullAddress}
        </Typography>
      ) : (
        <Skeleton width="75%" height={"1.5rem"} />
      )}
    </Box>
  );
};

export default Property;
