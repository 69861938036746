import { Box, Typography } from "@mui/material";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { FormData } from "screens/GetStarted/useGetStarted";
import { validateCitizenship } from "./HBCitizenship";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import { validateEthnicity } from "./HBethnicityOptions";
import { validateRace } from "./HBRaceOptions";

type HBSexOptionsProps = {
  formData: FormData;
  setFormData: (data: FormData) => void;
};

export const validateSexAndCitizenship = (formData: FormData) => {
  return (
    validateSexOptions(formData) &&
    validateCitizenship(formData) &&
    validateEthnicity(formData) &&
    validateRace(formData)
  );
};

const validateSexOptions = (formData: FormData) => {
  const sexOptions = formData.demographicsData?.sex;
  return sexOptions?.female || sexOptions?.male || sexOptions?.prefNoSay;
};

export const HBSexOptions = ({ formData, setFormData }: HBSexOptionsProps) => {
  const { primaryText } = useHomeBridgeColors();
  const { sizes } = useHomebridgeSizes();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    const sexStates = {
      male: {
        male: true,
        female: false,
        prefNoSay: false,
      },
      female: {
        male: false,
        female: true,
        prefNoSay: false,
      },
      prefNoSay: {
        male: false,
        female: false,
        prefNoSay: true,
      },
    };

    let newSexOptions;

    if (!checked) {
      newSexOptions = {
        ...formData.demographicsData.sex,
        [name]: false,
      };
    } else {
      newSexOptions = sexStates[name as keyof typeof sexStates];
    }

    setFormData({
      ...formData,
      demographicsData: {
        ...formData.demographicsData,
        sex: newSexOptions,
      },
    });
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"start"}
        justifyContent={"flex-start"}
        width={"100%"}
        textAlign={"left"}
        sx={{ gap: "10px" }}
      >
        <Typography
          variant="h6"
          color={primaryText}
          sx={{
            fontSize: sizes.typography,
          }}
        >
          What's your sex?
        </Typography>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
        >
          <input
            type="checkbox"
            id="female-checkbox"
            name="female"
            className="get-started__checkbox"
            checked={formData.demographicsData?.sex.female}
            onChange={handleCheckboxChange}
          />
          <Typography
            component="label"
            htmlFor="female-checkbox"
            sx={{ cursor: "pointer", fontSize: sizes.typography }}
          >
            Female
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
        >
          <input
            id="male-checkbox"
            type="checkbox"
            name="male"
            className="get-started__checkbox"
            checked={formData.demographicsData?.sex.male}
            onChange={handleCheckboxChange}
          />
          <Typography
            component="label"
            htmlFor="male-checkbox"
            sx={{ cursor: "pointer", fontSize: sizes.typography }}
          >
            Male
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
        >
          <input
            type="checkbox"
            id="prefNoSay-checkbox"
            name="prefNoSay"
            data-cy="prefNoSay-setpSex"
            className="get-started__checkbox"
            checked={formData.demographicsData?.sex.prefNoSay}
            onChange={handleCheckboxChange}
          />
          <Typography
            component="label"
            htmlFor="prefNoSay-checkbox"
            sx={{ cursor: "pointer", fontSize: sizes.typography }}
          >
            I do not wish to provide this information
          </Typography>
        </Box>
      </Box>
    </>
  );
};
