import Loan from "typedef/Loan";
import HELOCOffer from "./HELOCOffer";
import HELOANOffer from "./HELOANOffer";
import CLoader from "components/CLoader";
import { Fragment, useEffect, useRef, useState } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";

const FinalOffer = () => {
  const { loan: loanContext } = useUser();

  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(false);
  const loadedLoan = useRef(false);
  useEffect(() => {
    if (loanContext && !loadedLoan.current) {
      setLoading(true);
      setLoan(loanContext);
      setLoading(false);
      loadedLoan.current = true;
    }
  }, [loanContext]);

  return (
    <Fragment>
      <CLoader loading={loading} />
      {loan && loan.loanType === "HELOAN" && <HELOANOffer />}
      {loan && loan.loanType === "HELOC" && <HELOCOffer />}
    </Fragment>
  );
};

export default FinalOffer;
