import { Box, Typography, useTheme } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

interface BlogTemplateProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
  image?: string;
}

export const BlogTemplate: React.FC<BlogTemplateProps> = ({
  title,
  subtitle,
  children,
  image,
}) => {
  const theme = useTheme();
  const { sizes } = useHomebridgeSizes();

  const getCtaId = (title: string) => {
    switch (title) {
      case "How to get a HELOC":
        return "BlogHowGetHeloc";
      case "Is a HELOC a good idea?":
        return "BlogGoodIdea";
      case "What is a HELOC?":
        return "BlogWhatisHeloc";
      case "How to use home equity":
        return "BlogHowuseHomeEquity";
      default:
        return "";
    }
  };

  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.common.white,
        padding: { xs: "20px 0", md: "68px 0" },
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "80%", md: "70%", lg: "45%" },
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          height: "auto",
          margin: "0 auto",
          color: "black",
          fontSize: sizes.typography,
        }}
      >
        <Typography
          variant={"h4"}
          fontWeight={"bold"}
          textAlign={"left"}
          color={theme.palette.background.paper}
        >
          {title}
        </Typography>
        <Box
          component="img"
          src={image}
          alt="home"
          sx={{
            width: "100%",
            maxWidth: "100%",
            height: "auto",
            objectFit: "cover",
            marginTop: "32px",
          }}
        />
        <Typography
          variant={"subtitle1"}
          fontSize={sizes.typography}
          fontStyle="italic"
          color={theme.palette.background.paper}
          sx={{ marginTop: "32px" }}
        >
          {subtitle}
        </Typography>
        <Box>
          {React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) return child;
            if (child.type === "h2") {
              return (
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ marginTop: "32px", marginBottom: "10px" }}
                  color={theme.palette.background.paper}
                >
                  {child.props.children}
                  <br />
                </Typography>
              );
            }

            if (child.type === "h3") {
              return (
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ marginTop: "20px", marginBottom: "10px" }}
                  color={theme.palette.background.paper}
                >
                  {child.props.children}
                  <br />
                </Typography>
              );
            }
            if (child.type === "p") {
              return (
                <Typography
                  variant="subtitle1"
                  fontSize={sizes.typography}
                  marginTop={1}
                  color={`${theme.palette.background.paper} !important`}
                >
                  {child.props.children}
                  <br />
                </Typography>
              );
            }
            if (child.type === "small") {
              return (
                <Typography
                  variant="subtitle1"
                  fontSize={sizes.mini}
                  marginTop={1}
                  color={`${theme.palette.background.paper} !important`}
                >
                  {child.props.children}
                </Typography>
              );
            }
            if (child.type === "ul" || child.type === "ol") {
              return (
                <Box
                  component={child.type}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    marginTop: "10px",
                  }}
                  color={`${theme.palette.background.paper} !important`}
                >
                  {child.props.children}
                </Box>
              );
            }
            if (child.type === "li") {
              return (
                <Typography
                  fontSize={sizes.typography}
                  color={`${theme.palette.background.paper} !important`}
                >
                  {child.props.children}
                  <br />
                </Typography>
              );
            }
            return child;
          })}
          <Typography
            variant={"h6"}
            color={theme.palette.background.paper}
            fontWeight={"bold"}
            width={"90%"}
            fontSize={sizes.typography}
            paddingTop={"40px"}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { sx: "30px", md: 4 },
              alignItems: "center",
              justifyContent: { xs: "center", md: "space-between" },
              textAlign: "center",
              alignSelf: "center",
            }}
          >
            Check your rate and choose your amount risk-free!
            <CHBButton
              onClick={() => {
                navigate("/get-started");
              }}
              ctaId={getCtaId(title)}
            >
              Get started
            </CHBButton>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
