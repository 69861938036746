import ScrollToTop from "components/ScrollToTop";
import { BrowserRouter } from "react-router-dom";
import { MainThemeUI } from "context/MaterialUITheme/MainThemeUI";
import { UserCustomerContextProvider } from "context/UserCustomer/useUserCustomerContext";
import AppRoutes from "AppRoutes";
import { UsePrivateLabelContextProvider } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import GoogleTagManager from "components/GoogleTagManager";

import "./App.css";
import API from "./utils/API";
import { QueryClient, QueryClientProvider, QueryKey } from "react-query";
import Loan from "./typedef/Loan";
import PricingEngine from "./typedef/PricingEngine";

const queryFn = async ({ queryKey }: { queryKey: QueryKey }) => {
  let response;
  const QUERY_TYPE_KEY_OFFSET = 0;
  const LOAN_ID_KEY_OFFSET = 1;
  const QUERY_DATE_KEY_OFFSET = 2;

  switch (true) {
    case queryKey[QUERY_TYPE_KEY_OFFSET] === "Loan" ||
      queryKey[QUERY_TYPE_KEY_OFFSET] === "User": {
      response = await API.get<Loan>(
        `/get/my-loan?id=${queryKey[LOAN_ID_KEY_OFFSET]}`,
      );
      break;
    }
    case queryKey[QUERY_TYPE_KEY_OFFSET] === "AdminLoan": {
      response = await API.get<Loan>(
        `/admin-impersonate/get/loan/${queryKey[LOAN_ID_KEY_OFFSET]}`,
      );
      break;
    }
    case queryKey[QUERY_TYPE_KEY_OFFSET] === "Offer": {
      response = await API.get<Loan>(
        `/get-offer/final?loanId=${queryKey[LOAN_ID_KEY_OFFSET]}`,
      );
      break;
    }
    case queryKey[QUERY_TYPE_KEY_OFFSET] === "Heloc": {
      response = await API.get<PricingEngine>(
        `/get/heloc-pricing-engine?id=${queryKey[LOAN_ID_KEY_OFFSET]}&date=${queryKey[QUERY_DATE_KEY_OFFSET]}`,
      );
      break;
    }
    case queryKey[QUERY_TYPE_KEY_OFFSET] === "AdminHeloc": {
      response = await API.get<PricingEngine>(
        `/admin-impersonate/get/heloc-pricing-engine/heloc?id=${queryKey[LOAN_ID_KEY_OFFSET]}&date=${queryKey[QUERY_DATE_KEY_OFFSET]}`,
      );
      break;
    }
  }
  if (response && "error" in response) {
    console.log(response);
  } else {
    return response?.data;
  }
};

const loanClient = new QueryClient({
  defaultOptions: { queries: { queryFn: queryFn } },
});

const App = () => {
  const basename = process.env.REACT_APP_BASENAME ?? undefined;
  return (
    <QueryClientProvider client={loanClient}>
      <UsePrivateLabelContextProvider>
        <GoogleTagManager />
        <BrowserRouter basename={basename}>
          <MainThemeUI>
            <UserCustomerContextProvider>
              <ScrollToTop>
                <AppRoutes />
              </ScrollToTop>
            </UserCustomerContextProvider>
          </MainThemeUI>
        </BrowserRouter>
      </UsePrivateLabelContextProvider>
    </QueryClientProvider>
  );
};

export default App;
