import React, { useState } from "react";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";

interface CheckboxWithLabelProps {
  name: string;
  title?: string;
  label: string;
  state: boolean | undefined;
  sizesLabel?: string;
  onChange?: () => void;
  alignStart?: boolean;
  hasError?: boolean;
  animation?: boolean;
  isCollapse?: boolean;
  dataCy?: string;
}

const CheckboxInput: React.FC<CheckboxWithLabelProps> = ({
  name,
  title,
  label,
  state,
  sizesLabel,
  onChange,
  alignStart = false,
  hasError = false,
  animation = false,
  isCollapse = false,
  dataCy,
}) => {
  const { sizes } = useHomebridgeSizes();
  const [isExpanded, setIsExpand] = useState(true);
  const { primaryText, secondaryText, tertiaryText } = useHomeBridgeColors();

  const handleExpandClick = () => {
    setIsExpand(!isExpanded);
  };

  const handleCheckboxChange = () => {
    if (onChange) {
      onChange();
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems={alignStart ? "flex-start" : "center"}
        sx={{
          gap: "10px",
          cursor: "pointer",
          animation: animation && hasError ? "shake 0.3s ease-in-out" : "none",
        }}
        onClick={handleCheckboxChange}
      >
        <input
          type="checkbox"
          name={name}
          checked={state}
          onChange={handleCheckboxChange}
          className="get-started__checkbox"
          data-cy={dataCy}
          style={{
            border: hasError ? "2px solid red" : "1px solid #ccc",
          }}
        />
        {isCollapse ? (
          <Box
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            flexDirection={"column"}
          >
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Box
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={1}
              >
                <Typography fontSize={sizesLabel}>{title}</Typography>
              </Box>
              <Box
                display="flex"
                alignItems={alignStart ? "flex-start" : "center"}
              >
                <IconButton
                  onClick={handleExpandClick}
                  sx={{
                    padding: 0,
                    borderRadius: "50%",
                    backgroundColor: tertiaryText,
                    "&:hover": {
                      backgroundColor: primaryText,
                      color: secondaryText,
                    },
                    color: secondaryText,
                    marginLeft: 3,
                  }}
                >
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
            </Box>

            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Typography
                fontSize={sizes.typography}
                marginTop={2}
                fontWeight={400}
              >
                {label}
              </Typography>
            </Collapse>
          </Box>
        ) : (
          <Typography fontSize={sizesLabel}>{label}</Typography>
        )}
      </Box>
    </>
  );
};

export default CheckboxInput;
