import { Box, Typography, useMediaQuery, useTheme, Theme } from "@mui/material";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { CHBButton } from "components/CHBButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import googleRating from "../../assets/Images/google-rating.svg";

const LocationTestimonees = {
  HOME: {
    message: "“Excellent service and super-fast closing!”",
    author: "-Jim C",
  },
  FAQ: { message: "“Was very simple and quick.”", author: "Archie H" },
  RESOURCES: { message: "“Excellent experience.”", author: "Carl S" },
} as const;

export const HBTestimonees = () => {
  const theme = useTheme();
  const { sizes } = useHomebridgeSizes();
  const cardBackground = "#F5F5F5";
  const navigate = useNavigate();
  const location = useLocation();
  const isFaq = location.pathname.includes("/faq");
  const isHome = location.pathname === "/";
  const isResources = location.pathname.includes("/resources");
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  const getMessageForLocation = (pathname: string) => {
    if (pathname.includes("/faq")) return LocationTestimonees.FAQ;
    if (pathname === "/") return LocationTestimonees.HOME;
    if (pathname.includes("/resources")) return LocationTestimonees.RESOURCES;
    return {
      message: "“Excellent service and super-fast closing!”",
      author: "-Jim C",
    };
  };

  const getCtaIdForLocation = (pathname: string) => {
    if (pathname.includes("/faq")) return "FAQTestimony";
    if (pathname === "/") return "LandingpageTestimony";
    if (pathname.includes("/resources")) return "ResourcesTestimony";
    return "LandingpageTestimony";
  };

  const { message, author } = getMessageForLocation(location.pathname);

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "auto",
        backgroundColor: theme.palette.common.white,
      }}
    >
      <Box
        maxWidth={"880px"}
        width={{ xs: "100%", sm: "80%", md: "80%", lg: "80%", xl: "85%" }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        height={""}
        gap={isMobile ? "3em" : "4em"}
        margin={"0 auto"}
        padding={
          isHome ? { xs: "25px 0", lg: "0px" } : { xs: "40px 0", lg: "50px 0" }
        }
      >
        {!(isMobile && isResources) && (
          <>
            <Typography
              variant={"h6"}
              color={theme.palette.background.paper}
              fontStyle={"italic"}
              sx={{
                position: "relative",
                textAlign: "center",
                fontSize: sizes.typographyLarge,
                paddingX: { xs: "6px", md: "none" },
              }}
            >
              {message}
              <Typography
                variant={"subtitle1"}
                color={theme.palette.background.paper}
                fontStyle={"italic"}
                sx={{
                  top: { md: 40 },
                  textAlign: { xs: "center", md: "right" },
                  fontSize: sizes.typography,
                  paddingRight: { md: "20px" },
                }}
              >
                {author}
              </Typography>
            </Typography>

            <Box
              sx={{
                width: { xs: "90%", md: "80%", lg: "80%", xl: "85%" },
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                boxSizing: "border-box",
                justifyContent: "space-between",
                color: theme.palette.background.paper,
                height: "auto",
                gap: { xs: "20px", md: "25px" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "60%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: cardBackground,
                  borderRadius: "16px",
                  padding: {
                    xs: "36px 0",
                  },
                  fontStyle: sizes.typography,
                }}
              >
                <Typography
                  variant={"h5"}
                  color={theme.palette.background.paper}
                  sx={{
                    fontWeight: "700",
                    marginBottom: "5px",
                    textAlign: "center",
                    paddingX: "10px",
                  }}
                  letterSpacing={1}
                >
                  {isFaq && !isResources
                    ? "What is home equity?"
                    : "How much? How fast?"}
                </Typography>
                <Typography
                  variant={"h6"}
                  color={theme.palette.background.paper}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    cursor: "pointer",
                  }}
                  letterSpacing={1}
                  onClick={() => {
                    isFaq && !isResources
                      ? navigate("/resources")
                      : navigate("/faq");
                  }}
                >
                  Find out here
                  <ChevronRightOutlinedIcon
                    sx={{
                      backgroundColor: theme.palette.text.primary,
                      border: `1px solid ${theme.palette.background.paper}`,
                      borderRadius: "100%",
                    }}
                  />
                </Typography>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "35%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: cardBackground,
                  borderRadius: "16px",
                  padding: { xs: "20px 0px", md: "0px 20px" },
                }}
              >
                <img
                  src={googleRating}
                  alt="Google Rating"
                  style={{
                    backgroundColor: "transparent",
                    maxWidth: "60%",
                    height: "auto",
                  }}
                />
              </Box>
            </Box>
          </>
        )}

        <Box
          sx={{
            width: { xs: "100%", md: "75%", lg: "75%", xl: "80%" },
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            textAlign: "center",
          }}
        >
          <Typography
            variant={"h6"}
            color={theme.palette.background.paper}
            fontStyle={"bold"}
            width={isMobile ? "80%" : "100%"}
            sx={sizes.typography}
            letterSpacing={1}
            fontWeight={600}
          >
            Check your rate and choose your amount risk-free!
          </Typography>
          <CHBButton
            onClick={() => {
              navigate("/get-started");
            }}
            ctaId={getCtaIdForLocation(location.pathname)}
          >
            Get started
          </CHBButton>
        </Box>
      </Box>
    </Box>
  );
};
