import API from "utils/API";

const runHardCreditLambda = async (
  loanId: string,
  borrowerType: string,
  retryHardpull = false,
) => {
  await API.post({
    url: "/invoke",
    data: {
      FunctionName: "validateHardCredit",
      InvocationType: "RequestResponse",
      Payload: {
        loanId: loanId,
        borrowerType: borrowerType,
        retryHardpull: retryHardpull,
      },
    },
  });
};

export default runHardCreditLambda;
