import { Box, Typography } from "@mui/material";
import { useDebtToIncomeModal } from "./useDebtToIncomeModal";
import { DebtToIncomePlaid } from "./DebtToIncomePlaid";
import { DebtToIncomeTruv } from "./DebtToIncomeTruv";
import Loan from "typedef/Loan";
import { DocsToUpload } from "../useIncomeVerification";
import CDialog from "components/CDialog";
import CButton from "components/CButton";
import color from "context/MaterialUITheme/color";
import { useCallback, useEffect, useMemo, useState } from "react";
import useTracker from "components/CTracker/useTrackerContext";
import { RentalIncomeFlow } from "./RentalIncomeFlow";
import { DocumentItem } from "typedef/MissingDocuments";

interface DebtToIncomeProps {
  open: boolean;
  docsToUpload: DocsToUpload[];
  handlePlaid: () => void;
  handleTruv: () => void;
  handleOpenTruv: (manualUpload?: boolean) => void;
  needDocsUpload: boolean;
  isSelfEmployed: boolean;
  loading: boolean;
  step?: Loan["borrowerSteps"] | Loan["coborrowerSteps"];
  loan?: Loan;
  taxReturnsRequested?: boolean;
}

export const DebtToIncomeModal = ({
  open,
  docsToUpload,
  handlePlaid,
  handleTruv,
  needDocsUpload,
  isSelfEmployed,
  loading,
  step,
  loan,
  taxReturnsRequested,
  handleOpenTruv,
}: DebtToIncomeProps) => {
  const { styles } = useDebtToIncomeModal();
  const { styles: detbToIncomeStyles } = useDebtToIncomeModal();
  const processing =
    step?.incomeVerification?.errorMessagePlaid ===
      "Processing… Please give us a moment" ||
    step?.incomeVerification?.errorMessageTruv ===
      "Processing… Please give us a moment";
  const filteredDocsToUpload = docsToUpload.filter(
    (doc) => !doc.docName.includes("Death") && !doc.docName.includes("Birth"),
  );
  const [isRentalIncome, setIsRentalIncome] = useState(false);
  const [taxReturnSubmitted, setTaxReturnSubmitted] = useState(false);
  const [shouldShowRentalModal, setShouldShowRentalModal] = useState(false);
  const [expectedRentalDocsCount, setExpectedRentalDocsCount] = useState(0);
  const TAX_RETURNS_DOCUMENTS_QUANTITY = 2;

  const { type } = useTracker();
  const isBorrower = type === "borrower";

  useEffect(() => {
    const rentalIncomeStep = isBorrower
      ? loan?.borrowerFlags?.isRentalIncome
      : loan?.coborrowerFlags?.isRentalIncome;

    if (rentalIncomeStep !== undefined) {
      setIsRentalIncome(rentalIncomeStep);
    }
  }, [
    loan,
    isBorrower,
    loan?.borrowerFlags?.isRentalIncome,
    loan?.coborrowerFlags?.isRentalIncome,
  ]);

  const isShowUploadDocsTruv = step?.incomeVerification?.truvWasRun;

  const hasSubmittedTaxReturns = useCallback(() => {
    const requiredDocs = ["Tax return 1", "Tax return 2"];

    const missingDocs = isBorrower
      ? loan?.borrowerMissingDocs
      : loan?.coborrowerMissingDocs;

    const submittedDocs =
      missingDocs?.incomeVerification &&
      missingDocs?.incomeVerification.filter(
        (doc: { docName: string; submitted: boolean }) =>
          requiredDocs.includes(doc.docName) && doc.submitted,
      );

    if (submittedDocs && submittedDocs.length > 0) {
      const isSubmitted = requiredDocs.every((docName) =>
        submittedDocs.some((doc: DocumentItem) => doc.docName === docName),
      );
      setTaxReturnSubmitted(isSubmitted);
      return;
    }

    setTaxReturnSubmitted(false);
  }, [loan, isBorrower]);

  const hasSubmittedPropertyDocuments = useMemo(() => {
    const missingDocs = isBorrower
      ? loan?.borrowerMissingDocs
      : loan?.coborrowerMissingDocs;

    const propertyDocs =
      missingDocs?.incomeVerification?.filter(
        (doc) => doc.docName?.includes(" - Property"),
      ) || [];

    const rentalIncomeDocumentsSubmitted = isBorrower
      ? loan?.borrowerFlags?.rentalIncomeDocumentsSubmitted
      : loan?.coborrowerFlags?.rentalIncomeDocumentsSubmitted;

    if (rentalIncomeDocumentsSubmitted) return true;

    if (
      propertyDocs.length <
      expectedRentalDocsCount + TAX_RETURNS_DOCUMENTS_QUANTITY
    )
      return false;

    return propertyDocs.every((doc) => doc.submitted === true);
  }, [loan, isBorrower, expectedRentalDocsCount]);

  useEffect(() => {
    hasSubmittedTaxReturns();
  }, [hasSubmittedTaxReturns]);

  useEffect(() => {
    const readyToShow =
      isRentalIncome &&
      taxReturnsRequested === true &&
      taxReturnSubmitted &&
      !hasSubmittedPropertyDocuments;

    if (readyToShow && !shouldShowRentalModal) {
      setShouldShowRentalModal(true);
    }
  }, [
    isRentalIncome,
    taxReturnsRequested,
    taxReturnSubmitted,
    hasSubmittedPropertyDocuments,
    shouldShowRentalModal,
  ]);

  useEffect(() => {
    if (shouldShowRentalModal && hasSubmittedPropertyDocuments) {
      setShouldShowRentalModal(false);
    }
  }, [shouldShowRentalModal, hasSubmittedPropertyDocuments]);

  return (
    <>
      <CDialog open={open}>
        <Box sx={styles.containerStyle}>
          <Typography
            component={"h4"}
            variant="h4"
            color={"black"}
            fontWeight={700}
          >
            Debt to Income (&quot;DTI&quot;)
          </Typography>
          <Box sx={styles.contentContainerStyle}>
            <Typography sx={{ ...styles.text, mb: 2 }}>
              Please select from the following options to verify your DTI:
            </Typography>
            <DebtToIncomePlaid
              loading={loading || processing}
              selfEmployed={isSelfEmployed}
              handleButton={handlePlaid}
              step={step}
            />
            <DebtToIncomeTruv
              loading={loading || processing}
              loan={loan}
              docsToUpload={filteredDocsToUpload}
              handleButton={handleTruv}
              needDocsUpload={needDocsUpload}
              isSelfEmployed={isSelfEmployed}
              taxReturnsRequested={taxReturnsRequested}
              step={step}
            />

            {isShowUploadDocsTruv && !isSelfEmployed && (
              <>
                <Typography
                  component={"h5"}
                  variant="h5"
                  color={color.black}
                  fontWeight={700}
                  sx={{ mt: 4 }}
                >
                  Manually upload your Income docs
                </Typography>
                <Typography sx={{ ...detbToIncomeStyles.text, mt: 2 }}>
                  If you weren't able to connect through our other methods, try
                  manually uploading your most recent W2 and 2 most recent
                  paystubs.
                </Typography>
                <CButton
                  loading={loading}
                  variant="contained"
                  sx={styles.buttonStyles}
                  onClick={() => handleOpenTruv(true)}
                  name={"debtToIncomeTruv-upload-your-docs"}
                  aria-label={"debtToIncomeTruv-upload-your-docs"}
                >
                  Upload your docs here!
                </CButton>
              </>
            )}
          </Box>
        </Box>
      </CDialog>
      <RentalIncomeFlow
        open={shouldShowRentalModal}
        onClose={() => setShouldShowRentalModal(false)}
        isBorrower={isBorrower}
        showCancelButton={false}
        onExpectedDocsChange={setExpectedRentalDocsCount}
      />
    </>
  );
};
