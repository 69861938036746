import { Box, Typography } from "@mui/material";

import { CHBButton } from "components/CHBButton";
import { PropertyProps } from "../Property";
import { HBSeparate } from "components/HBSeparate";

import { useVerifyEmail } from "./useVerifyEmail";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { InputFields } from "./InputFields";
import { Link } from "react-router-dom";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import Loan from "typedef/Loan";
import { ExistantUserModal } from "./ExistantUserModal";

export interface VerifyEmailProps extends PropertyProps {
  setLoadingPage: React.Dispatch<React.SetStateAction<boolean>>;
  loan?: Loan;
}
export const VerifyEmail = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  setLoadingPage,
  loan,
}: VerifyEmailProps) => {
  const { fourthText } = useHomeBridgeColors();
  const { sizes } = useHomebridgeSizes();
  const {
    loading,
    animation,
    onValueChange,
    error,
    errors,
    showPassword,
    toggleShowPassword,
    confirmPassword,
    handleNext,
    setConfirmPassword,
    code,
    setCode,
    errorMessage,
    showConfirmUser,
    resendCode,
    toggleShowConfirmUser,
    existantUserModal,
  } = useVerifyEmail({
    formData,
    onNext,
    onPrev,
    setFormData,
    loan,
    setLoadingPage,
  });

  return (
    <Box>
      <Box className="get-started__wrapper get-started__verify-email">
        <Box width={"100%"}>
          <Typography
            variant="h4"
            fontFamily={"NunitoSans"}
            fontSize={sizes.subtitle}
            textAlign={"center"}
            fontWeight={700}
            marginTop={"30px"}
            marginBottom={"30px"}
          >
            {showConfirmUser
              ? "Please check your email"
              : "To securely review your offers,"}
            <br />
            {showConfirmUser
              ? "for the verification code."
              : "please provide the following:"}
          </Typography>

          <Box width={"90%"} margin={"0 auto"} marginTop={"20px"}>
            <InputFields
              formData={formData}
              onValueChange={onValueChange}
              animation={animation}
              error={error}
              errors={errors}
              fourthText={fourthText}
              showPassword={showPassword}
              toggleShowPassword={toggleShowPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              showConfirmUser={showConfirmUser}
              code={code}
              setCode={setCode}
              resendCode={resendCode}
            />

            {error && errorMessage && (
              <p style={{ color: "red", textAlign: "center" }}>
                {errorMessage}
              </p>
            )}

            {!showConfirmUser &&
              formData?.email !== "" &&
              formData?.email !== undefined &&
              errors?.filter((e) => e.field === "email").length === 0 && (
                <Link
                  to={`/login`}
                  style={{
                    color: "white",
                  }}
                >
                  <Typography
                    variant="body2"
                    fontFamily={"NunitoSans"}
                    fontSize={"16px"}
                    marginTop={"10px"}
                    textAlign={"center"}
                  >
                    Already have a password?
                  </Typography>
                </Link>
              )}

            <Box
              sx={{
                marginTop: "40px",
                display: "flex",
                flexDirection: {
                  xs: "column-reverse",
                  sm: "row",
                },
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <CHBButton
                onClick={showConfirmUser ? toggleShowConfirmUser : onPrev}
                disabled={loading || showConfirmUser}
                secondaryArrow={true}
              >
                Go Back
              </CHBButton>
              <CHBButton
                onClick={handleNext}
                loading={loading}
                ctaId="Step5NameEmail"
              >
                Next
              </CHBButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <HBSeparate className="step-separate" type="vertical" />

      {/* MODALS */}
      <ExistantUserModal
        openModal={existantUserModal}
        formData={formData}
        errorMessage={
          localStorage.getItem("DTCApplication__existantUser") ?? ""
        }
      />
    </Box>
  );
};
