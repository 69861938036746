import React, { useCallback, useRef, useState } from "react";
import { LoanRequestInputs } from "../LoanRequest/LoanRequestInputs";
import { Box } from "@mui/material";
import { FormData, FormDataNumbers } from "screens/GetStarted/useGetStarted";
import { verifyMaxSafeNumber } from "utils/verifyMaxSafeNumber";
import { Errors } from "../BorrowerPersonalData/usePersonalDataValidation";
import parseMoney from "utils/parseMoney";
import { CreditScoreInputs } from "../CreditScore/CreditScoreInputs";
import { CHBButton } from "components/CHBButton";
import Loan from "typedef/Loan";
import { useUpdateLoanStatus } from "screens/GetStarted/useUpdateLoanStatus";

interface ErrorSectionProps {
  errorMessage: string;
  animation: boolean;
  formData: FormData;
  setFormData: (data: FormData) => void;
  getOffer: (
    refresh?: boolean,
    e?: React.MouseEvent<HTMLButtonElement>,
  ) => Promise<void>;
  loan?: Loan;
}

export const ErrorSection = ({
  errorMessage,
  animation,
  formData,
  setFormData,
  getOffer,
  loan,
}: ErrorSectionProps) => {
  const helocBalanceInputRef = useRef<HTMLInputElement>(null);
  const judmentsAndLiensBalanceInputRef = useRef<HTMLInputElement>(null);

  const [moreBalance, setMoreBalance] = useState({
    helocBalance: false,
    judmentsAndLiensBalance: false,
  });
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState<Errors[]>([]);

  const { updateLoanStatus } = useUpdateLoanStatus();

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    const field = id as keyof FormDataNumbers;
    const numericValue = BigInt(value.replace(/[$,]/g, ""));
    const isSafeNumber = verifyMaxSafeNumber(numericValue);

    if (!isSafeNumber) {
      setError(true);
      setErrors((prev) => [
        ...prev.filter(
          (err) =>
            err.field !== field ||
            err.message !== "Values can't exceed this amount",
        ),
        {
          field,
          message: "Values can't exceed this amount",
          success: false,
        },
      ]);
      return;
    }

    const newFormData = { ...formData };
    newFormData[field] = value as unknown as number;
    setFormData(newFormData);

    if (id === "homeValue") {
      const validationResult = validateInputs(value);

      if (validationResult && validationResult.length > 0) {
        setError(true);
      }
    }
  };

  const validateInputs = (value: string) => {
    const checks: Errors[] = [
      {
        regex: /.{1,}/,
        message: "Please enter a property value",
        success: false,
        field: "homeValue",
      },
      {
        regex: /^[1-9]\d{5,}$/,
        message: "Home value cannot be less than $100,000",
        success: false,
        field: "homeValue",
      },
    ];

    const checksError = checks.map(({ regex, message, field }) => ({
      message,
      field,
      success: regex?.test(parseMoney(value ?? "").toString()),
    }));

    const inputsError = checksError.filter((error) => !error.success);

    setErrors((prev) => {
      const otherErrors = prev.filter(
        (err) =>
          err.field !== "homeValue" ||
          (err.field === "homeValue" &&
            err.message !== "Home value cannot be less than $100,000"),
      );
      return [...otherErrors, ...inputsError];
    });

    return inputsError;
  };

  const handleOpenMoreBalance = (field: keyof typeof moreBalance) => {
    setMoreBalance({
      ...moreBalance,
      [field]: !moreBalance[field],
    });

    if (field === "helocBalance") {
      setTimeout(() => {
        helocBalanceInputRef.current?.focus();
      }, 200);
    } else if (field === "judmentsAndLiensBalance") {
      setTimeout(() => {
        judmentsAndLiensBalanceInputRef.current?.focus();
      }, 200);
    }
  };

  const validateSubmit = (formData: FormData, loan?: Loan) => {
    let validate = false;

    if (
      loan?.DTCApplication?.homeValue !== undefined &&
      formData?.homeValue !== undefined
    ) {
      const homeValueString = loan.DTCApplication.homeValue?.toString();
      const oldHomeValueString = formData.homeValue?.toString();

      if (
        parseMoney(homeValueString) === parseMoney(oldHomeValueString) &&
        formData.creditScore === loan?.DTCApplication?.creditScore
      ) {
        validate = true;
      }
    }

    return validate;
  };

  const handleSubmit = useCallback(
    async (
      e: React.MouseEvent<HTMLButtonElement>,
      formDataValue: FormData,
      loanValue?: Loan,
    ) => {
      e.preventDefault();
      const homeValueString = loanValue?.DTCApplication?.homeValue?.toString();
      const oldHomeValueString = formDataValue.homeValue?.toString();

      getOffer(true, e);
      updateLoanStatus({
        loanId: formDataValue.loanId as string,
        body: {
          ...(homeValueString !== oldHomeValueString && {
            homeValue: formDataValue.homeValue,
          }),
          ...(formDataValue.creditScore !==
            loanValue?.DTCApplication?.creditScore && {
            creditScore: formDataValue.creditScore,
          }),
          loanBalance: formDataValue.loanBalance,
          helocBalance: formDataValue.helocBalance,
          judmentsAndLiensBalance: formDataValue.judmentsAndLiensBalance,
        },
      });
    },
    [getOffer, updateLoanStatus],
  );
  return (
    <Box component="form">
      <Box sx={{ marginBottom: "20px" }}>
        <p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>
      </Box>

      <LoanRequestInputs
        animation={animation}
        error={error}
        errors={errors}
        formData={formData}
        onValueChange={onValueChange}
        moreBalance={moreBalance}
        handleOpenMoreBalance={handleOpenMoreBalance}
        helocBalanceInputRef={helocBalanceInputRef}
        judmentsAndLiensBalanceInputRef={judmentsAndLiensBalanceInputRef}
      />
      <CreditScoreInputs
        animation={animation}
        formData={formData}
        setFormData={setFormData}
      />
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "40px",
          }}
        >
          <CHBButton
            type={"submit"}
            onClick={(e) => {
              handleSubmit(e, formData, loan);
            }}
            disabled={validateSubmit(formData, loan) || errors.length > 0}
          >
            Confirm
          </CHBButton>
        </Box>
      </Box>
    </Box>
  );
};
