export const incomeOptions = [
  { value: "salaryIncome", label: "Salary or hourly wages" },
  { value: "selfEmploymentIncome", label: "Self Employment" },
  { value: "socialIncome", label: "Social security, pension, disability" },
  { value: "rentalIncome", label: "Rental Income" },
  { value: "otherIncome", label: "Other" },
];

export const documentRequirements = (incomeType: string) => {
  switch (incomeType) {
    case "selfEmploymentIncome":
      return [{ name: "Tax Return #1" }, { name: "Tax Return #2" }];
    case "socialIncome":
    case "otherIncome":
      return [
        { name: "Document 1" },
        { name: "Document 2" },
        { name: "Document 3" },
        { name: "Document 4" },
        { name: "Document 5" },
      ];
    default:
      return [];
  }
};
