import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { incomeOptions } from "./incomeOptions";
import BorrowerSteps from "typedef/BorrowerSteps";
import CoborrowerSteps from "typedef/CoborrowerSteps";
import API from "utils/API";
import useLoan from "components/CTracker/useLoan";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isFilePdf } from "utils/validateFile";
import { Buffer } from "buffer";
import parseMoney from "utils/parseMoney";

const INCOME_MIN_DIGITS = 4;
const INCOME_MAX_DIGITS = 9;

export const getDigitsLenghtFromStringAmount = (value?: string) => {
  const digits = value?.replace(" ", "").replace(/[$,\s]/g, "");
  const integerPart = digits?.split(".")[0];
  return integerPart?.length;
};

type Document = {
  file: string;
  fileType: string;
  fileExt: string;
  documentName: string;
};

type AdditionalIncomeModalProps = {
  incomeType?: string;
  amount?: string;
  documents?: Document[] | null;
  fileType?: string;
  fileExt?: string;
  documentName?: string;
};

export type IncomeSources = {
  [key: string]: number | undefined;
};

type UseIncomeDocUploadProps = {
  step?: BorrowerSteps | CoborrowerSteps;
  isAdditional: boolean;
  open: boolean;
  isBorrower: boolean;
  handleClose: () => void;
  incomeSourcesOptions: { label: string; value: string }[];
  isSelfEmployed: boolean;
};

export const REQUIRED_TAX_RETURN_DOCS = 2;
export const OTHER_DOCS = 1;

const ERROR_UPLOADING_DOCUMENT = "Error uploading document. Please, try again!";

const schema = yup.object().shape({
  incomeType: yup.string().required(),
  amount: yup
    .string()
    .required("Annual income amount is required")
    .test(
      "min-digits",
      "Income amount must have at least 4 digits",
      (value) => {
        const digitsLenght = getDigitsLenghtFromStringAmount(value);
        const isValid = digitsLenght && digitsLenght >= INCOME_MIN_DIGITS;
        return isValid as boolean;
      },
    )
    .test("max-digits", "Income amount must have at most 9 digits", (value) => {
      const digitsLenght = getDigitsLenghtFromStringAmount(value);
      const isValid = digitsLenght && digitsLenght <= INCOME_MAX_DIGITS;
      return isValid as boolean;
    }),

  documents: yup
    .array()
    .of(
      yup.object().shape({
        file: yup.mixed(),
        fileType: yup.string(),
        fileExt: yup.string(),
        documentName: yup.string(),
      }),
    )
    .when("incomeType", (incomeType, schemaRef) => {
      const isSelfEmployment = incomeType === "selfEmploymentIncome";
      const isRentalIncome = incomeType === "rentalIncome";

      if (isRentalIncome) {
        return schemaRef;
      }
      return schemaRef.test(
        isSelfEmployment ? "tax-returns" : "at-least-one",

        isSelfEmployment
          ? "Two documents are required for Self Employment"
          : "At least one document is required",
        (value: Document[]) => {
          const MIN_DOCS = isSelfEmployment
            ? REQUIRED_TAX_RETURN_DOCS
            : OTHER_DOCS;
          const filteredDoc = value?.filter((doc) => doc.file);

          if (filteredDoc?.length < MIN_DOCS) return false;
          return value.some((doc) => doc.file);
        },
      );
    }),
});

const useIncomeDocUpload = ({
  step,
  isAdditional = false,
  open = false,
  isBorrower,
  handleClose,
  incomeSourcesOptions,
  isSelfEmployed,
}: UseIncomeDocUploadProps) => {
  const { loan } = useLoan();
  const [selectedIncomeType, setSelectedIncomeType] = useState("");
  const [optionAmount, setOptionAmount] = useState<number | undefined>(
    undefined,
  );
  const [availableIncomeOptions, setAvailableIncomeOptions] =
    useState(incomeSourcesOptions);
  const [moreThanOneIncomeSource, setMoreThanOneIncomeSource] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openRentalIncomeFlowModal, setOpenRentalIncomeFlowModal] =
    useState(false);

  useEffect(() => {
    const rentalIncomeDocumentsSubmitted = isBorrower
      ? loan?.borrowerFlags?.rentalIncomeDocumentsSubmitted
      : loan?.coborrowerFlags?.rentalIncomeDocumentsSubmitted;

    if (
      rentalIncomeDocumentsSubmitted !== undefined &&
      rentalIncomeDocumentsSubmitted !== null
    ) {
      setOpenRentalIncomeFlowModal(!rentalIncomeDocumentsSubmitted);
    }
  }, [
    loan?.borrowerFlags?.rentalIncomeDocumentsSubmitted,
    loan?.coborrowerFlags?.rentalIncomeDocumentsSubmitted,
    isBorrower,
  ]);

  useEffect(() => {
    if (open) {
      const incomeSources: IncomeSources =
        step?.incomeVerification?.incomeSources || {};
      const hasIncomeSources = Object.keys(incomeSources).length > 0;
      if (hasIncomeSources && selectedIncomeType === "" && !isAdditional) {
        const incomeSourceKeys = Object.keys(incomeSources);
        setMoreThanOneIncomeSource(incomeSourceKeys.length > 1);

        setAvailableIncomeOptions(incomeSourcesOptions);

        if (incomeSourcesOptions.length === 1) {
          setSelectedIncomeType(incomeSourcesOptions[0].value);
          setOptionAmount(
            incomeSources[incomeSourcesOptions[0].value] || undefined,
          );
        } else if (incomeSourcesOptions.length > 1) {
          const defaultIncomeType = incomeSourcesOptions[0].value;
          setSelectedIncomeType(defaultIncomeType);
          setOptionAmount(incomeSources[defaultIncomeType] || undefined);
        }
      } else if (isAdditional && selectedIncomeType === "") {
        setSelectedIncomeType(incomeOptions[isSelfEmployed ? 1 : 0].value);
        setOptionAmount(undefined);
      }
    }
  }, [
    step,
    isAdditional,
    selectedIncomeType,
    open,
    incomeSourcesOptions,
    isSelfEmployed,
  ]);

  const handleIncomeSourceType = (event: ChangeEvent<HTMLInputElement>) => {
    const newIncomeType = event.target.value;
    setSelectedIncomeType(newIncomeType);
    setOptionAmount(
      isAdditional
        ? undefined
        : (step?.incomeVerification?.incomeSources as IncomeSources)?.[
            newIncomeType
          ] ?? undefined,
    );
  };
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
    setError,
    watch,
  } = useForm<AdditionalIncomeModalProps>({
    resolver: yupResolver(schema),
  });

  const documents = watch("documents");

  async function convertToBase64(
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) {
    const selectedFile = event.target.files as FileList | null | undefined;

    if (selectedFile && selectedFile.length > 0) {
      const fileToLoad = selectedFile[0];
      const isPdf = await isFilePdf(fileToLoad);

      const megabyteInBytes = 1048576;
      const maxSizeInMb = 100;
      const sizeInMB = fileToLoad.size / megabyteInBytes;
      const fileType = fileToLoad.type;
      const fileExt = fileToLoad.name.split(".").pop() as string;

      if (!isPdf) {
        setError(`documents.${index}.file`, {
          message: "File type not supported",
        });
        return;
      }

      if (sizeInMB > maxSizeInMb) {
        setError(`documents.${index}.file`, {
          message: `You can't upload files larger than ${maxSizeInMb} MB`,
        });
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = function (fileLoadedEvent) {
        const document = {
          file: fileLoadedEvent?.target?.result as string,
          fileType,
          fileExt,
          documentName: fileToLoad.name,
        };

        const currentDocuments = watch("documents") || [];
        const updatedDocuments = [...currentDocuments];
        updatedDocuments[index] = document;

        setValue("documents", updatedDocuments);
      };
      fileReader.readAsDataURL(fileToLoad);
      clearErrors("documents");
    }
  }
  useEffect(() => {
    setValue("incomeType", selectedIncomeType);
    if (optionAmount !== undefined) setValue("amount", optionAmount.toString());
  }, [selectedIncomeType, optionAmount, setValue]);

  const handleRefresh = () => {
    reset();
    setSelectedIncomeType("");
    setOptionAmount(undefined);
  };

  const onSubmit = handleSubmit(async (data) => {
    const docKeys = [];

    const incomeTypeFiltered = incomeOptions.find(
      (option) => option.value === data.incomeType,
    )?.label;

    const isSelfEmployedSelected = data?.incomeType === "selfEmploymentIncome";
    const isRentalSelected = data?.incomeType === "rentalIncome";

    const listOfDocs = data?.documents?.filter((doc) => doc?.file) ?? [];

    if (!isRentalSelected) {
      for (const documentUploaded of listOfDocs) {
        setIsLoading(true);

        const index = data?.documents?.indexOf(documentUploaded) ?? 0;
        const documentNumber = index + 1;

        const baseName = isSelfEmployedSelected
          ? "taxReturn"
          : incomeTypeFiltered?.replaceAll(" ", "_").replaceAll(",", "");

        const newDocumentName = `${baseName}_${documentNumber}_${
          isAdditional ? "AdditionalIncome" : "VerifyIncome"
        }_${new Date().getTime()}`;

        const userId = isBorrower ? loan?.borrowerId : loan?.coborrowerId;

        const resultPresignedUrl = (await API.post({
          url: "/additional-income/get-presigned-url",
          data: {
            loanId: loan?.id,
            userId,
            fileType: documentUploaded?.fileType,
            extension: documentUploaded?.fileExt,
            documentName: newDocumentName,
          },
        })) as {
          error?: string;
          data: { docKey?: string; presignedUrl: string };
        };

        if ("error" in resultPresignedUrl) {
          setIsLoading(false);
          setError("documents", { message: ERROR_UPLOADING_DOCUMENT });
        } else {
          const base64Data = documentUploaded?.file?.split(",")[1];
          const buffer = Buffer.from(base64Data, "base64");
          const presignedUrl = resultPresignedUrl?.data?.presignedUrl;
          const docKey = resultPresignedUrl?.data?.docKey;

          docKeys.push(docKey);

          const response = await fetch(presignedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": documentUploaded?.fileType as string,
            },
            body: buffer,
          });

          if (response.status >= 300) {
            setIsLoading(false);
            setError("documents", { message: ERROR_UPLOADING_DOCUMENT });
          }
        }
      }
    }

    const annualAmount = isAdditional
      ? parseMoney(data?.amount ?? "0")
      : optionAmount;

    const matchingUploadedDocuments =
      docKeys?.length === listOfDocs.length || isRentalSelected;

    if (annualAmount && matchingUploadedDocuments) {
      setIsLoading(true);

      await API.post({
        url: "/additional-income/add-additional-income",
        data: {
          loanId: loan?.id,
          amount: annualAmount,
          docKeys,
          incomeType: incomeTypeFiltered,
          incomeSource: !isAdditional,
          isRentalIncome: isRentalSelected,
        },
      });

      setIsLoading(false);
      handleClose();
      handleRefresh();
    } else {
      setIsLoading(false);
      setError("documents", { message: ERROR_UPLOADING_DOCUMENT });
    }

    const rentalIncomeDocumentsSubmitted = isBorrower
      ? loan?.borrowerFlags?.rentalIncomeDocumentsSubmitted
      : loan?.coborrowerFlags?.rentalIncomeDocumentsSubmitted;

    if (
      rentalIncomeDocumentsSubmitted === undefined ||
      rentalIncomeDocumentsSubmitted === null
    ) {
      const user = isBorrower ? "borrower" : "coborrower";
      await API.post({
        url: `/save-to-loan/flags?entity=${user}&loanId=${loan?.id}`,
        data: {
          rentalIncomeDocumentsSubmitted: false,
        },
      });
    }
  });

  const handleDeleteDocument = (index: number) => {
    const currentDocuments = watch("documents") || [];
    const updatedDocuments = currentDocuments.filter(
      (_, currentIndex) => currentIndex !== index,
    );
    setValue("documents", updatedDocuments);
  };

  return {
    control,
    handleSubmit,
    selectedIncomeType,
    handleIncomeSourceType,
    onSubmit,
    availableIncomeOptions,
    moreThanOneIncomeSource,
    optionAmount,
    handleRefresh,
    convertToBase64,
    documents,
    isLoading,
    handleDeleteDocument,
    errors,
    openRentalIncomeFlowModal,
    setOpenRentalIncomeFlowModal,
  };
};

export default useIncomeDocUpload;
