import API from "utils/API";
import Loan from "typedef/Loan";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useCallback, useEffect, useRef } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { ValuesHELOC } from "screens/TypeOfCreditLine/useDefineLoanType";
import { LSLoanOfficerToken } from "CONST";
import { PrivateLabelFees } from "typedef/PrivateLabelFees";

export type ClosingCostDetails = {
  originationPoints: number;
  creditReport: number;
  complianceDocPrepeNote: number;
  AVMInspection: number;
  IDVerification: number;
  eRecordingFee: number;
  legalVestingFee: number;
  taxes: number;
  totalPayoffsAndPaydowns: number;
  totalFees: number;
};

export type ValuesSliderProps = {
  minDraw?: number;
  maxDraw?: number;
  stateCap?: string;
  showSlider?: boolean;
};

export type ErrorOffer = {
  error: string;
  errorId: string;
  status: number;
};

const useHelocOffer = (typeOfOffer = "final") => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const isAdmin = location.pathname.includes("impersonate");
  const paramsUrl = useParams();
  const currentAdminLoanId = paramsUrl?.loanId;
  const navigate = useNavigate();
  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(true);
  const [isAccepting, setIsAccepting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorOffer, setErrorOffer] = useState<ErrorOffer | undefined>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [newValuesSlider, setNewValuesSlider] = useState<ValuesHELOC>();
  const [drawLessThan100, setDrawLessThan100] = useState(false);
  const [changeByDraw, setChangeByDraw] = useState(false);
  const [fees, setFees] = useState<
    PrivateLabelFees & {
      closingCosts: number;
    }
  >();
  const [initialValuesSliderHELOC, setInitialValuesSliderHELOC] =
    useState<ValuesSliderProps>();
  const [refreshLoan, setRrefreshLoan] = useState(0);
  const [marks, setMarks] = useState<
    {
      value: number;
    }[]
  >([]);
  const { user, logout, userAdmin } = useUser();

  const loanId = search.get("loanId");
  const loadedOffer = useRef(false);
  const loadedOfferHELOC = useRef(false);

  const getAndSetLoan = useCallback(
    async (
      selectedLoanIdValue?: string,
      isAdminValue?: boolean,
      currentAdminLoanIdValue?: string,
    ) => {
      if (!selectedLoanIdValue && !currentAdminLoanIdValue) {
        return;
      }
      setLoading(true);
      const response =
        isAdminValue && currentAdminLoanIdValue
          ? await API.get<Loan>(
              `/admin-impersonate/get/loan/${currentAdminLoanIdValue}`,
            )
          : await API.get<Loan>(`/get/my-loan?id=${selectedLoanIdValue}`);

      if (!("error" in response)) {
        setLoan(response.data);
      }
      setLoading(false);
    },
    [],
  );

  const handleRrefresh = () => {
    setRrefreshLoan((prev) => prev + 1);
  };

  const getInitialSliderValues = useCallback(
    async (params: {
      loanIdValue: string;
      isAdminValue: boolean;
      currentAdminLoanIdValue: string | undefined;
    }) => {
      const { loanIdValue, isAdminValue, currentAdminLoanIdValue } = params;
      setLoading(true);
      const response =
        isAdminValue && currentAdminLoanIdValue
          ? await API.get<ValuesSliderProps>(
              `/admin-impersonate/get/get-heloc-slider-values/value?loanId=${currentAdminLoanIdValue}`,
            )
          : await API.get<ValuesSliderProps>(
              `/get/get-heloc-slider-values?loanId=${loanIdValue}`,
            );

      if ("error" in response) {
        console.error(response.error);
      } else {
        setInitialValuesSliderHELOC(response.data);
      }
      setLoading(false);
    },
    [],
  );
  const state = loan?.property.address.components.state_abbreviation;

  const getFEES = useCallback((APIUrlValue: string) => {
    API.get<PrivateLabelFees & ClosingCostDetails>(APIUrlValue).then(
      (response) => {
        if (!("error" in response)) {
          const feesRes = response.data;
          setFees({ ...feesRes, closingCosts: feesRes.totalFees });
        }
      },
    );
  }, []);

  useEffect(() => {
    if (fees === undefined && (loanId || currentAdminLoanId) && state) {
      const APIUrl = userAdmin?.id
        ? `/getServiceUnsecured/origination-fees?state=${state}&loanId=${currentAdminLoanId}`
        : `/get/origination-fees?loanId=${loanId}`;

      getFEES(APIUrl);
    }
  }, [
    fees,
    getFEES,
    userAdmin,
    loanId,
    state,
    loan?.initialOffer,
    currentAdminLoanId,
  ]);

  const totalPaydowns =
    [
      ...(loan?.borrowerSteps?.hardpullVerification?.paydownInfo ?? []),
      ...(loan?.coborrowerSteps?.hardpullVerification?.paydownInfo ?? []),
    ]?.reduce((total, item) => {
      let paydownAmount = item.paydownAmount;

      if (typeof paydownAmount === "string") {
        paydownAmount = paydownAmount.replace(/[$,]/g, "");
      }

      return total + Number(paydownAmount);
    }, 0) ?? 0;

  const totalPayoffs =
    loan?.payoff?.reduce((total, item) => total + Number(item.amount), 0) ?? 0;

  const totalPayoffsAndPaydowns = totalPaydowns + totalPayoffs;

  const getInitialCalculateHelocOffer = useCallback(
    async (params: {
      loanIdValue: string;
      maxDrawValue: number;
      isAdminValue: boolean;
      currentAdminLoanIdValue: string | undefined;
      typeOfOfferValue: string;
      closingCosts: number;
      totalPayoffsAndPaydowns: number;
      finalOffer?: Loan["finalOffer"];
    }) => {
      const {
        isAdminValue,
        currentAdminLoanIdValue,
        loanIdValue,
        maxDrawValue,
        typeOfOfferValue,
        closingCosts,
        totalPayoffsAndPaydowns: totalPayoffsValue,
        finalOffer,
      } = params;
      setLoading(true);
      let replaceString = "admin";

      const isLoanOfficer = localStorage.getItem(LSLoanOfficerToken);
      if (isLoanOfficer) {
        replaceString = "lo";
      }
      const NEGATIVE_DRAW = 0;

      let newDrawAmount = maxDrawValue;

      const loanAmount = finalOffer?.amount ?? 0;

      const drawWithClosingCosts =
        newDrawAmount + closingCosts + totalPayoffsValue;

      const isLessThan75 =
        drawWithClosingCosts < (finalOffer?.minimumInitialDraw ?? 0);

      const isDrawNegative = drawWithClosingCosts < NEGATIVE_DRAW;

      const isDrawMoreThanLoanAmount = drawWithClosingCosts > loanAmount;

      if (isDrawNegative || isLessThan75 || isDrawMoreThanLoanAmount) {
        newDrawAmount = loanAmount - (closingCosts ?? 0) - totalPayoffsValue;
      }

      const recalculatedLoanResponse =
        isAdminValue && currentAdminLoanIdValue
          ? await API.post<ValuesHELOC>({
              url: `/admin-impersonate/save-to-loan-${replaceString}/calculate-heloc-offer-${typeOfOfferValue}`,
              data: {
                initialDrawAmount: newDrawAmount,
                loanId: loanIdValue,
              },
            })
          : await API.post<ValuesHELOC>({
              url: `/calculate-heloc-offer/${typeOfOfferValue}`,
              data: {
                initialDrawAmount: newDrawAmount,
                loanId: loanIdValue,
                ignoreSaveOfferHistory: true,
              },
            });

      if ("error" in recalculatedLoanResponse) {
        if (recalculatedLoanResponse.errorId) {
          setErrorOffer(recalculatedLoanResponse as ErrorOffer);
        } else {
          navigate(`/borrower-tracker?loanId=${loanId}`, {
            replace: true,
          });
          setShowErrorMessage(true);
          setErrorMessage(recalculatedLoanResponse.error);
        }
      } else {
        setShowErrorMessage(false);
        setErrorMessage("");
        setNewValuesSlider(recalculatedLoanResponse.data);
      }
      setLoading(false);
      setChangeByDraw(false);
      setDrawLessThan100(false);
    },
    [loanId, navigate],
  );

  useEffect(() => {
    getAndSetLoan(loanId as string, isAdmin, currentAdminLoanId);
  }, [getAndSetLoan, loanId, isAdmin, currentAdminLoanId, refreshLoan]);

  useEffect(() => {
    if (!loadedOffer?.current && loan?.id) {
      getInitialSliderValues({
        loanIdValue: loan?.id,
        isAdminValue: isAdmin ?? false,
        currentAdminLoanIdValue: currentAdminLoanId,
      });
      loadedOffer.current = true;
    }
  }, [loan, isAdmin, currentAdminLoanId, getInitialSliderValues]);

  useEffect(() => {
    if (
      !loadedOfferHELOC?.current &&
      initialValuesSliderHELOC?.maxDraw &&
      newValuesSlider?.initialDrawAmount === undefined &&
      loan?.id &&
      fees &&
      totalPayoffsAndPaydowns !== undefined
    ) {
      getInitialCalculateHelocOffer({
        loanIdValue: loan?.id,
        maxDrawValue:
          initialValuesSliderHELOC?.maxDraw <
          (loan?.finalOffer?.initialDrawAmount ?? 0)
            ? initialValuesSliderHELOC?.maxDraw
            : loan?.finalOffer?.initialDrawAmount ?? 0,
        isAdminValue: isAdmin ?? false,
        currentAdminLoanIdValue: currentAdminLoanId,
        typeOfOfferValue: typeOfOffer,
        closingCosts: fees?.closingCosts,
        totalPayoffsAndPaydowns,
        finalOffer: loan?.finalOffer,
      });
      loadedOfferHELOC.current = true;
    }
  }, [
    loan,
    initialValuesSliderHELOC,
    getInitialCalculateHelocOffer,
    isAdmin,
    currentAdminLoanId,
    newValuesSlider,
    typeOfOffer,
    fees,
    totalPayoffsAndPaydowns,
    loan?.finalOffer,
  ]);

  useEffect(() => {
    if (refreshLoan !== 0 && fees && fees?.closingCosts) {
      getInitialSliderValues({
        loanIdValue: loan?.id as string,
        isAdminValue: isAdmin ?? false,
        currentAdminLoanIdValue: currentAdminLoanId,
      });
      getInitialCalculateHelocOffer({
        loanIdValue: loan?.id as string,
        maxDrawValue: initialValuesSliderHELOC?.maxDraw as number,
        isAdminValue: isAdmin ?? false,
        currentAdminLoanIdValue: currentAdminLoanId,
        typeOfOfferValue: typeOfOffer,
        closingCosts: fees?.closingCosts,
        totalPayoffsAndPaydowns,
        finalOffer: loan?.finalOffer,
      });
    }
  }, [
    initialValuesSliderHELOC?.maxDraw,
    refreshLoan,
    loan?.id,
    isAdmin,
    currentAdminLoanId,
    getInitialCalculateHelocOffer,
    getInitialSliderValues,
    typeOfOffer,
    fees,
    totalPayoffsAndPaydowns,
    loan?.finalOffer,
  ]);
  useEffect(() => {
    if (loan) {
      const minOffer = initialValuesSliderHELOC?.minDraw ?? 0;
      const maxOffer = initialValuesSliderHELOC?.maxDraw ?? 0;
      const steps = 10.0;
      const jump = (maxOffer - minOffer) / steps;
      const padding = jump * 0.1;
      const paddedMinOffer = minOffer + padding;
      const paddedMaxOffer = maxOffer - padding * 2;
      const paddedJump = (paddedMaxOffer - paddedMinOffer) / steps;
      setMarks(
        Array.from({ length: steps + 1 }).map((_, index) => ({
          value: paddedMinOffer + paddedJump * index,
        })),
      );
    }
    // eslint-disable-next-line
  }, [initialValuesSliderHELOC]);

  const recalculateOffer = async (
    incomingNewAmount: number | number[],
    closingCosts: number,
  ) => {
    if (Array.isArray(incomingNewAmount)) return undefined;
    setLoading(true);
    const recalculatedLoanResponse = await API.post<ValuesHELOC>({
      url: `/calculate-heloc-offer/${typeOfOffer}`,
      data: {
        initialDrawAmount: incomingNewAmount,
        loanId: loan?.id,
        ignoreSaveOfferHistory: true,
        closingCosts,
      },
    });
    if ("error" in recalculatedLoanResponse) {
      setShowErrorMessage(true);
      setErrorMessage(recalculatedLoanResponse.error);
    } else {
      setShowErrorMessage(false);
      setErrorMessage("");
      setNewValuesSlider(recalculatedLoanResponse?.data);
    }
    setLoading(false);
    setChangeByDraw(true);
  };

  const saveOffer = useCallback(
    async (confirmDrawAmount: number) => {
      setIsAccepting(true);
      const response = await API.post({
        url: `/accept-offer/final`,
        data: {
          initialDrawAmount: confirmDrawAmount,
          loanId: loan?.id,
          closingCosts: fees?.closingCosts,
          totalPayoffsAndPaydowns,
        },
      });
      if ("error" in response) {
        console.error(response);
        setErrorMessage(response.error);
      } else {
        navigate(`/borrower-tracker?loanId=${loanId}`, {
          replace: true,
        });
      }
      setIsAccepting(false);
    },
    [loan?.id, navigate, loanId, fees?.closingCosts, totalPayoffsAndPaydowns],
  );

  const archiveLoan = async () => {
    if (errorOffer) {
      const response = await API.post({
        url: `/save-to-loan/no-offer-archive?userId=${user?.id}&errorCode=${errorOffer.errorId}&loanId=${loan?.id}`,
      });
      logout();
      if ("error" in response) {
        console.error("Error when archiving!");
      }
    }
  };

  return {
    loan,
    marks,
    loading,
    saveOffer,
    isAccepting,
    errorMessage,
    newValuesSlider,
    showErrorMessage,
    initialValuesSliderHELOC,
    recalculateOffer: recalculateOffer,
    handleRrefresh,
    refreshLoan,
    setDrawLessThan100,
    drawLessThan100,
    archiveLoan,
    changeByDraw,
    errorOffer,
    setFees,
    fees,
    totalPayoffsAndPaydowns,
  };
};

export default useHelocOffer;
