import { useEffect } from "react";
import useUserCustomerContext from "context/UserCustomer/useUserCustomerContext";
import { Box, Typography } from "@mui/material";
import { useAdminImpersonate } from "./useAdminImpersonate";
import FinalOffer from "screens/FinalOffer";
import AdminBorrowerTracker from "screens/BorrowerTracker/AdminBorrowerTracker";
import TypeOfCreditLine from "screens/TypeOfCreditLine";
import InititalOffer from "screens/InitialOffer";
import { useParams } from "react-router-dom";
import Login from "screens/Login";
import CLoader from "components/CLoader";
import { ErrorComponent } from "./ErrorComponent";
import LoansScreen from "screens/Loans";
import HomeMonitor from "screens/HomeMonitor";
import { GetStarted } from "screens/GetStarted";

export const AdminImpersonate = () => {
  const {
    loan,
    userAdmin,
    loadingUserData,
    loadingLoan,
    setCurrentAdminLoanId,
    openedDrawer,
  } = useUserCustomerContext();
  const params = useParams();
  const isBorrower = params?.borrowerType === "borrower";

  const { stateView, messageError, messageForAdmin, onClick } =
    useAdminImpersonate(
      loan,
      isBorrower,
      userAdmin,
      loadingUserData || loadingLoan,
    );

  useEffect(() => {
    setCurrentAdminLoanId(params?.loanId);
  }, [setCurrentAdminLoanId, params?.loanId]);

  if (loadingUserData || stateView === "") {
    return <CLoader loading={true} />;
  }

  return (
    <Box position={"relative"}>
      {messageForAdmin && stateView !== "error" && (
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            zIndex: 999999,
            backgroundColor: "red",
            color: "white",
            padding: "10px",
          }}
        >
          <Typography variant="body1" sx={{ color: "white" }}>
            {messageForAdmin}
          </Typography>
        </Box>
      )}

      {stateView !== "login" && stateView !== "allLoans" && !openedDrawer && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            zIndex: 999999,
          }}
          onClick={onClick}
        ></Box>
      )}
      {stateView === "error" && <ErrorComponent messageError={messageError} />}
      {stateView === "login" && <Login isAdmin={true} />}
      {userAdmin && loan && (
        <Box>
          {stateView === "finalOffer" && <FinalOffer />}
          {stateView === "initialOffer" && <InititalOffer isAdmin={true} />}
          {stateView === "typeOfCreditLine" && (
            <TypeOfCreditLine isAdmin={true} />
          )}
          {stateView === "tracker" && (
            <AdminBorrowerTracker isBorrower={isBorrower} />
          )}
          {stateView === "homeMonitor" && <HomeMonitor isAdmin={true} />}
          {stateView === "allLoans" && <LoansScreen isAdmin={true} />}
          {stateView === "DTCApplication" && <GetStarted />}
          {/*  {stateView === "DTCInputs" && <DtcInputs />} */}
        </Box>
      )}
    </Box>
  );
};
