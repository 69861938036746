import { Checkbox, ListItem, Typography, useTheme, Link } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { Props } from "screens/BorrowerInviteRegisterForm/ConfirmValuesDialog";
import useRegisterForm, {
  RegisterFormInputs,
} from "screens/BorrowerRegisterForm/useRegisterForm";
import useRegisterFormCoborrower from "screens/CoborrowerRegisterForm/useRegisterFormCoborrower";
import { PrivateLabel } from "typedef/PrivateLabel";
import { useIsHomebridge } from "utils/useIsHomebridge";

export interface SignUpCheckbox {
  text?: string;
  isRequired?: boolean;
  key?: string;
}

type UseRegisterFormReturn =
  | ReturnType<typeof useRegisterForm>
  | ReturnType<typeof useRegisterFormCoborrower>
  | Props["form"];

interface PrivateLabelCheckboxesProps {
  privateLabel?: PrivateLabel;
  form: UseRegisterFormReturn;
}

const PrivateLabelCheckboxes: React.FC<PrivateLabelCheckboxesProps> = ({
  privateLabel,
  form,
}) => {
  const theme = useTheme();
  const { isHomeBridge } = useIsHomebridge();
  const control = form?.control as Control<RegisterFormInputs>;

  const signUpCheckboxes: SignUpCheckbox[] = Object.entries(
    privateLabel?.borrowerPortalConfiguration?.signUpCheckboxes || [],
  )?.map(([key, value]) => ({
    text: value?.text,
    isRequired: value?.isRequired,
    key: value?.key,
  }));

  const processTextWithPlaceholders = (text?: string): JSX.Element => {
    const placeholderRegex = /#([\w]+(?:\.[\w]+)*(?:\?\.[\w]+)*)/g;
    const anchorRegex = /<a\s+href="([^"]*)">([^<]+)<\/a>/g;
    const buttonRegex = /<button\s+open="([^"]*)">([\s\S]*?)<\/button>/g;

    if (!privateLabel || !text) return <></>;

    const getNestedValue = (obj?: PrivateLabel, path?: string): string => {
      if (!path || !obj) return "";
      const keys = path.replace(/\?\./g, ".").split(".");
      let current = obj;

      for (const key of keys) {
        if (
          current === null ||
          current === undefined ||
          typeof current !== "object"
        ) {
          return "";
        }
        if (!(key in current)) {
          return "";
        }
        current = (current as Record<string, unknown>)[key] as PrivateLabel;
      }

      return typeof current === "string" ? current : "";
    };

    const processedText = text?.replace(placeholderRegex, (match, path) => {
      const value = getNestedValue(privateLabel, path);
      return value !== undefined ? value : match;
    });

    const parts: (string | JSX.Element)[] = [];
    let lastIndex = 0;

    if (privateLabel?.isNFTYDoor) {
      let buttonMatch: RegExpExecArray | null;
      buttonRegex.lastIndex = 0;
      while ((buttonMatch = buttonRegex?.exec(processedText)) !== null) {
        const [fullMatch, action, buttonText] = buttonMatch;

        if (buttonMatch?.index > lastIndex) {
          parts?.push(processedText?.substring(lastIndex, buttonMatch?.index));
        }

        if (action === "terms" || action === "communications") {
          parts?.push(
            <span
              key={`custom-button-${action}-${buttonText}`}
              className="inline cursor-pointer text-inherit p-0 break-words"
              style={{
                color: isHomeBridge ? "#20A2D8" : theme?.palette?.primary?.main,
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => form?.setOpenedDialog(action)}
            >
              {buttonText?.replace(placeholderRegex, (match, path) => {
                const value = getNestedValue(privateLabel, path);
                return value !== undefined ? value : match;
              })}
            </span>,
          );
        }
        lastIndex = buttonMatch?.index + fullMatch.length;
      }
    }

    let anchorMatch: RegExpExecArray | null;
    anchorRegex.lastIndex = 0;
    while ((anchorMatch = anchorRegex?.exec(processedText)) !== null) {
      const [fullMatch, href, linkText] = anchorMatch;

      if (anchorMatch?.index > lastIndex) {
        parts?.push(processedText?.substring(lastIndex, anchorMatch?.index));
      }

      const processedHref = href?.startsWith("#")
        ? getNestedValue(privateLabel, href?.slice(1))
        : href;

      parts.push(
        <Link
          key={`${href}-${linkText}`}
          color={isHomeBridge ? "#20A2D8" : theme?.palette?.primary?.main}
          textAlign={"justify"}
          sx={{ cursor: "pointer", textDecoration: "none" }}
          href={processedHref || "#"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkText}
        </Link>,
      );

      lastIndex = anchorMatch?.index + fullMatch.length;
    }

    if (lastIndex < processedText?.length) {
      parts?.push(processedText?.substring(lastIndex));
    }

    return <>{parts}</>;
  };

  return (
    <>
      {signUpCheckboxes
        ?.filter((checkbox) => checkbox?.text)
        ?.map((checkbox) => (
          <ListItem
            key={checkbox?.key}
            disableGutters
            alignItems="flex-start"
            sx={{ mt: 1 }}
          >
            <Controller
              control={control}
              name={`signUpCheckbox_${checkbox?.key}`}
              render={({ field }) => (
                <Checkbox
                  sx={{ pl: 0 }}
                  {...field}
                  onClick={() => field.onChange(!field?.value)}
                  checked={field?.value}
                  required={checkbox?.isRequired}
                />
              )}
            />
            <Typography
              mt={0.8}
              variant="subtitle1"
              color={theme?.palette?.text?.primary}
              textAlign="justify"
              sx={{ fontSize: "0.94rem" }}
            >
              {processTextWithPlaceholders(checkbox?.text)}
            </Typography>
          </ListItem>
        ))}
    </>
  );
};

export default PrivateLabelCheckboxes;
