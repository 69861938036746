import React, { useCallback, useRef, useState } from "react";
import { HellaFast } from "screens/HBHome/HellaFast";
import { Box } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { FormData } from "../../useGetStarted";
import AddressType from "typedef/Address";
import useAddressAutoComplete from "components/CAddressAutocomplete/useAddressAutocomplete";
import API from "utils/API";
import { useLocation, useNavigate } from "react-router-dom";
import Loan from "typedef/Loan";
import { InputAddress } from "./InputAddress";

type BodyRegisterLoan = {
  city: string | undefined;
  state: string | undefined;
  street_line: string | undefined;
  secondary: string | undefined;
  zipcode: string | undefined;
  marketingCampaign?: Loan["marketingCampaign"];
};

interface AddressProps {
  onNext: () => void;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  loan?: Loan;
}

export const Address = ({
  onNext,
  formData,
  setFormData,
  loan,
}: AddressProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const marketingCampaignString: string | null =
    localStorage.getItem("marketingCampaign");
  const marketingCampaign = marketingCampaignString
    ? JSON.parse(marketingCampaignString)
    : undefined;

  const [loadingRegisterLoan, setLoadingRegisterLoan] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [hasSelectedAddress, setHasSelectedAddress] = useState(false);
  const addressType: AddressType | undefined = undefined;

  const isAddress = {
    city: formData.city,
    state: formData.state,
    street_line: formData.street_line,
    secondary: formData.secondary,
    zipcode: formData.zipcode,
    entries: formData.entries ?? 0,
  };
  const [isAlreadyAddress, setIsAlreadyAddress] = useState(
    Boolean(isAddress.street_line),
  );
  const [currentAddress, setCurrentAddress] = useState<AddressType | undefined>(
    {
      city: "",
      state: "",
      street_line: "",
      secondary: "",
      zipcode: "",
      entries: 0,
    },
  );

  const [animation, setAnimation] = useState(false);

  const valueInputRef = useRef<HTMLInputElement>(null);

  const { text } = useAddressAutoComplete({
    address: addressType,
    setAddress: setCurrentAddress as React.Dispatch<AddressType | null>,
  });

  const handleNext = async () => {
    if (
      !currentAddress?.street_line &&
      !isAlreadyAddress &&
      !hasSelectedAddress
    ) {
      setAnimation(true);
      setError(true);
      setErrorMessage("Please enter a property");
      if (!text.length && error) {
        valueInputRef.current?.focus();
      } else if (text.length && error) {
        setErrorMessage("Please select a property");
        valueInputRef.current?.focus();
      }
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else if (isAlreadyAddress) {
      onNext();
    } else {
      setError(false);
      setErrorMessage("");
      setFormData({
        ...formData,
        city: currentAddress?.city,
        state: currentAddress?.state,
        street_line: currentAddress?.street_line,
        secondary: currentAddress?.secondary ?? "",
        zipcode: currentAddress?.zipcode,
      });
      const data = await registerLoan({
        body: {
          city: currentAddress?.city,
          state: currentAddress?.state,
          street_line: currentAddress?.street_line,
          secondary: currentAddress?.secondary ?? "",
          zipcode: currentAddress?.zipcode,
          marketingCampaign: marketingCampaign?.utm_source
            ? marketingCampaign
            : loan?.marketingCampaign,
        },
      });

      if (data?.loanId) {
        localStorage.setItem("DTCApplication__loanId", data.loanId);
        localStorage.removeItem("marketingCampaign");
        navigate(location.pathname);
        onNext();
      }
    }
  };

  const registerLoan = useCallback(
    async (params: { body: BodyRegisterLoan }) => {
      try {
        const loanIdByStorge = localStorage.getItem("DTCApplication__loanId");
        setLoadingRegisterLoan(true);
        const { body } = params;
        const response = await API.post<{ body: string }>({
          url: `/register-user/loan${
            loanIdByStorge ? `?loanId=${loanIdByStorge}` : ""
          }`,
          data: {
            ...body,
          },
        });
        if ("error" in response) {
          throw new Error(response.error);
        } else {
          setLoadingRegisterLoan(false);
          return JSON.parse(response.data.body);
        }
      } catch (error) {
        const err = error as Error;
        setError(true);
        setErrorMessage(
          err.message ? err.message : "An error occurred, please try again.",
        );
        setLoadingRegisterLoan(false);
      }
    },
    [],
  );
  const showUsePropertyMessage = isAlreadyAddress ? (
    <>
      <strong className="strong-900">
        {addressToString(isAddress as AddressType)}
      </strong>{" "}
      will be used to secure your HELOC. If this is correct, simply click Next
    </>
  ) : undefined;
  return (
    <Box>
      <Box>
        <HellaFast
          title={
            <>
              The Homebridge HELOC <br />
              Get started in minutes!
            </>
          }
        />
      </Box>
      <Box
        className="get-started__wrapper get-started__address"
        style={{
          fontFamily: "NunitoSans",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <InputAddress
          inputLabel="What’s the address of the property?"
          setCurrentAddress={setCurrentAddress}
          setIsAlreadyAddress={setIsAlreadyAddress}
          valueInputRef={valueInputRef}
          error={error}
          setError={setError}
          animation={animation}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          addressToString={addressToString}
          setHasSelectedAddress={setHasSelectedAddress}
        />
        <p className="get-started__note" style={{ marginBottom: "40px" }}>
          {isAlreadyAddress
            ? showUsePropertyMessage
            : "This is the property you’ll use to secure your HELOC."}
        </p>
        <CHBButton
          onClick={handleNext}
          loading={loadingRegisterLoan}
          ctaId="Step1Address"
        >
          Next
        </CHBButton>
      </Box>
      <HBSeparate className="step-separate" type="vertical" />
    </Box>
  );
};

export function addressToString(incomingAddress: {
  city: string;
  state: string;
  street_line: string;
  secondary?: string;
  zipcode: string;
  entries?: number;
}): string {
  const address = { ...incomingAddress };
  if (!address) return "";
  let whiteSpace = "";
  if (address.secondary) {
    if ((address.entries ?? 0) > 1) {
      address.secondary += ` (${address.entries})`;
    }
    whiteSpace = " ";
  }
  return `${address.street_line}${whiteSpace}${address.secondary}, ${address.city}, ${address.state}, ${address.zipcode}`;
}
