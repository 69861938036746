interface CustomInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  refInput?: React.Ref<HTMLInputElement>;
}
export const CustomInput = ({ refInput, ...props }: CustomInputProps) => {
  return <input ref={refInput} {...props} />;
};
