import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { useEffect } from "react";

interface GoogleTagManagerProps {
  gtmId?: string;
}

const GoogleTagManager: React.FC<GoogleTagManagerProps> = () => {
  const location = window.location.pathname;
  const { privateLabel } = usePrivateLabel();
  const gtmId = privateLabel?.google.hasBorrowerPortalTagId;

  useEffect(() => {
    if (typeof window !== "undefined") {
      const signupPathRegex = /\/[^/]+\/[^/]+\/[^/]+\/signup$/;
      if (!signupPathRegex.test(location) && gtmId) {
        (function (w, d, s, l, i) {
          //@ts-ignore
          w[l] = w[l] || [];
          //@ts-ignore
          w[l].push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js",
          });
          const f = d.createElement(s);
          const j = d.getElementsByTagName(s)[0];
          const dl = l !== "dataLayer" ? "&l=" + l : "";
          //@ts-ignore
          f.style.display = "none";
          //@ts-ignore
          f.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          if (j && j.parentNode) {
            j.parentNode.insertBefore(f, j);
          }
        })(window, document, "script", "dataLayer", gtmId);

        const existingIframe = document.querySelector(
          "iframe[src='https://www.googletagmanager.com/ns.html?id=" +
            gtmId +
            "']",
        );
        if (!existingIframe) {
          const noscript = document.createElement("noscript");
          const iframe = document.createElement("iframe");
          iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
          iframe.height = "0";
          iframe.width = "0";
          iframe.style.display = "none";
          iframe.style.visibility = "hidden";
          noscript.appendChild(iframe);
          document.body.appendChild(noscript);
        }
      }
    }
  }, [location, gtmId]);

  return null;
};

export default GoogleTagManager;
