import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import API from "utils/API";
import * as yup from "yup";

const useValidateAccount = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const email = params.email;
  const schema = yup.object().shape({
    email: yup.string().email("invalid format").required("email is required"),
    code: yup
      .string()
      .min(6, "invalid format")
      .max(6, "invalid format")
      .required("code is required"),
  });

  const form = useForm({
    defaultValues: {
      email: email,
      code: "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = form.handleSubmit((data) => {
    setLoading(true);
    setError("");
    API.post<{ message: string }>({
      url: "/confirm-user",
      data: {
        code: data.code,
        type: "email",
        username: email?.trim(),
        phoneNumber: "",
      },
    })
      .then((result) => {
        if ("error" in result) {
          setError(result.error);
        } else {
          navigate(`/login?email=${data.email}`, { replace: true });
          localStorage.removeItem("DTCApplication__loanId");
        }
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return { ...form, handleSubmit, loading, error };
};

export default useValidateAccount;
