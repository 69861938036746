import moment from "moment";
import { Fragment } from "react";
import useTracker from "../useTrackerContext";
import { Box, Typography, useTheme } from "@mui/material";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import NewBadge from "../Badge";
import checkB2pendingAction from "utils/checkB2pendingAction";

const LoanInformation: React.FC = () => {
  const { privateLabel } = usePrivateLabel();
  const { property, loan } = useTracker();
  const theme = useTheme();
  const hasNewStatus = loan?.newLoanStatusCode;
  const isPQL = hasNewStatus?.includes("PQL");
  const hasLO = loan?.loanOfficerId;
  const coborrowerFullName = loan?.coborrowerFullName;
  const isArchived = loan?.borrowerFlags?.archiveType;
  const isManualArchive = hasNewStatus === "ARC200";
  const submittedByLO = loan?.submittedByLO;

  const b2PendingAction = checkB2pendingAction(
    loan?.newBorrowerStatusCode ?? "",
    loan?.newCoborrowerStatusCode ?? "",
  );

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={{ xs: "start", md: "center" }}
      flexDirection={{ xs: "column", md: "row" }}
    >
      <Box>
        {property && (
          <Fragment>
            <Typography
              variant="h5"
              sx={{ fontFamily: "Pro Text Semibold" }}
              color={theme.palette.text.primary}
            >
              {property?.address?.delivery_line_1}.
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontFamily: "Pro Text Light" }}
              color={theme.palette.text.primary}
            >
              {property?.address?.components?.city_name},{" "}
              {property?.address?.components?.state_abbreviation}.{" "}
              {property?.address?.components?.zipcode}
            </Typography>
            {b2PendingAction && (
              <NewBadge
                color={"255,159,67"}
                message={`Pending Action from ${coborrowerFullName}`}
                isPQL={isPQL}
                hasLO={hasLO}
                isArchived={isArchived}
                isManualArchive={isManualArchive}
                hasSubmittedByLO={submittedByLO}
              />
            )}
          </Fragment>
        )}
      </Box>
      <Box textAlign={{ xs: "left", md: "right" }} mt={{ xs: 2, md: 0 }}>
        <Typography
          variant="subtitle1"
          color={theme.palette.text.secondary}
          sx={{ opacity: 0.9 }}
        >
          Last update:{" "}
          {loan?.initialOffer.createdAt
            ? moment(loan?.initialOffer.createdAt).format("lll")
            : "-"}
          <br />
          {loan?.loanOfficer && (
            <Fragment>
              {loan.broker?.lenderName ?? privateLabel?.lenderName} | NMLS ID #
              {loan.broker?.NMLScompany ?? privateLabel?.NMLScompany}
              <br />
              {loan.loanOfficer?.firstName} {loan.loanOfficer.lastName} | NMLS
              ID #{loan.loanOfficer?.NMLSNumber}
              <br />
            </Fragment>
          )}
          Application #:{" "}
          <span
            style={{
              color: theme.palette.primary.main,
              fontWeight: privateLabel ? "bold" : "none",
            }}
            data-cy="loanId"
          >
            {loan?.id}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default LoanInformation;
