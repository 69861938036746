import React, {
  useState,
  ReactNode,
  useContext,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import API from "utils/API";
import Loan from "typedef/Loan";
import CDialog from "components/CDialog";
import CButton from "components/CButton";
import { LoginType } from "typedef/login";
import {
  LSisborrower,
  LStoken,
  LStokenExp,
  LSAdminToken,
  LSAdminTokenExp,
  LSLoanOfficerToken,
  LSLoanOfficerTokenExp,
} from "CONST";
import { Search, useLocation, useNavigate } from "react-router-dom";
import useFHConnectUser from "./useFHConnectUser";
import { UserType } from "typedef/ApiUserResponses";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { useIsHomebridge } from "utils/useIsHomebridge";
import { QueryObserverResult, useQuery } from "react-query";

export type UserContextType = {
  loan?: Loan;
  loadingLoan: boolean;
  logout(): void;
  user?: UserType;
  userAdmin?: UserType;
  loadingUserData: boolean;
  isGettingLoggedIn: boolean;
  login(email: string, password: string): Promise<void | string>;
  adminAuth(search: Search): Promise<void | string>;
  setUser: React.Dispatch<React.SetStateAction<UserType | undefined>>;
  inviteError?: string;
  setCurrentAdminLoanId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  getAdminLoan(loanId?: string): Promise<void | string>;
  openedDrawer: boolean;
  setOpenedDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  isReady: boolean;
  selectedLoanId: string;
  setSelectedLoanId: React.Dispatch<React.SetStateAction<string>>;
  handleSelectLoan: (loanId: string, isAdmin?: boolean) => Promise<void>;
  getLoan: (loanId: string) => Promise<string | undefined>;
  refetchLoan: () => Promise<QueryObserverResult<Loan, unknown>>;
};

const useUser = () => {
  return useContext(UserContext);
};

export const UserContext = React.createContext<UserContextType>({
  user: {},
  loan: undefined,
  loadingLoan: false,
  logout: () => undefined,
  loadingUserData: false,
  isGettingLoggedIn: false,
  setUser: (user) => user,
  login: async (_email, _password) => undefined,
  adminAuth: async (_search) => undefined,
  userAdmin: {},
  setCurrentAdminLoanId: () => undefined,
  getAdminLoan: async () => undefined,
  openedDrawer: false,
  setOpenedDrawer: () => undefined,
  isReady: false,
  selectedLoanId: "",
  setSelectedLoanId: () => undefined,
  handleSelectLoan: async () => undefined,
  getLoan: async (loanId: string) => undefined,
  refetchLoan: async () =>
    undefined as unknown as Promise<QueryObserverResult<Loan, unknown>>,
});

/**
 * @author Giuliano Antonanzas
 */
export const UserCustomerContextProvider: React.FC<{
  children: ReactNode;
  loanId?: string;
}> = ({ children }) => {
  const { isHomeBridge } = useIsHomebridge();
  const navigate = useNavigate();
  const location = useLocation();
  const [openedDrawer, setOpenedDrawer] = useState(false);
  const loadedLoanRef = useRef(false);
  const [currentAdminLoanId, setCurrentAdminLoanId] = useState<
    string | undefined
  >();
  const FHConnectUser = useFHConnectUser();
  const [loan, setLoan] = useState<Loan>();
  const [loadingLoan, setLoadingLoan] = useState<boolean>(false);
  const [selectedLoanId, setSelectedLoanId] = useState("");
  const token = localStorage.getItem(LStoken);
  const tokenExp = localStorage.getItem(LStokenExp);
  const [isReady, setIsReady] = useState(false);
  const [getAdminLoanError, setGetAdminLoanError] = useState<
    string | undefined
  >();
  const adminToken = localStorage.getItem(LSAdminToken);
  const adminTokenExp = localStorage.getItem(LSAdminTokenExp);
  const loanOfficerToken = localStorage.getItem(LSLoanOfficerToken);
  const loanOfficerTokenExp = localStorage.getItem(LSLoanOfficerTokenExp);
  const [wholesaleEntityValue, setWholesaleEntityValue] = useState<
    string | undefined
  >(undefined);
  const [user, setUser] = useState<UserType>();
  const [userAdmin, setUserAdmin] = useState<UserType>();
  const [modalIsShown, setModalIsShown] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [isGettingLoggedIn, setIsGettingLoggedIn] = useState(false);
  const [inviteError, setInviteError] = useState("");
  const [modalInviteErrorShown, setModalInviteErrorIsShown] = useState(false);
  const { privateLabel } = usePrivateLabel();
  const isAdmin = location.pathname.includes("impersonate");
  const search = new URLSearchParams(location.search);
  const loandIsFromQueryURL = search.get("loanId");

  const logout = useCallback(
    (toNavigate = true) => {
      localStorage.removeItem(LStoken);
      localStorage.removeItem(LStokenExp);
      localStorage.removeItem(LSisborrower);
      setUser(undefined);
      setSelectedLoanId("");
      setLoan(undefined);
      if (toNavigate) {
        navigate("/login", { replace: true });
      }
    },
    [navigate],
  );

  const adminLogout = useCallback(() => {
    localStorage.removeItem(LSAdminToken);
    localStorage.removeItem(LSAdminTokenExp);
  }, []);

  const loanOfficerLogout = useCallback(() => {
    localStorage.removeItem(LSLoanOfficerToken);
    localStorage.removeItem(LSLoanOfficerTokenExp);
  }, []);

  const { refetch: adminRefetch } = useQuery<Loan>({
    queryKey: ["AdminLoan", currentAdminLoanId, userAdmin],
    onSuccess: (data) => {
      setLoan(data);
      setLoadingLoan(false);
    },
    enabled: !!currentAdminLoanId,
    onError: (error) => {
      setGetAdminLoanError(error as string);
    },
  });

  const getAdminLoan = useCallback(async () => {
    if (!userAdmin?.id || !currentAdminLoanId) return undefined;
    if (getAdminLoanError) return getAdminLoanError;
    await adminRefetch();
    return undefined;
  }, [adminRefetch, currentAdminLoanId, getAdminLoanError, userAdmin?.id]);

  const login = useCallback(
    async (email: string, password: string) => {
      setIsGettingLoggedIn(true);
      const loginResponse = await API.post<LoginType>({
        url: "/login",
        data: {
          email: email.trim(),
          password,
        },
      });
      if ("error" in loginResponse) {
        setIsGettingLoggedIn(false);
        return loginResponse.error;
      }
      localStorage.setItem(
        LStokenExp,
        String(loginResponse.data.idToken.payload.exp * 1000),
      );
      localStorage.setItem(LStoken, loginResponse.data.idToken.jwtToken);
      const userIdLogged = loginResponse.data.accessToken.payload.sub;
      setUser((prev) => ({
        ...prev,
        exp: loginResponse.data.idToken.payload.exp * 1000,
        token: loginResponse.data.idToken.jwtToken,
        id: userIdLogged,
      }));
      localStorage.removeItem("DTCApplication__loanId");
      localStorage.removeItem("DTCApplication__existantUser");

      const multipleLoanResponse = await API.get<{
        isUserWithMultipleLoans: boolean;
        uniqueLoanId?: string;
      }>("/get/multiple-loans");
      let isUserWithMultipleLoans = false;
      let uniqueLoanId;
      if ("data" in multipleLoanResponse) {
        isUserWithMultipleLoans =
          multipleLoanResponse?.data?.isUserWithMultipleLoans;

        uniqueLoanId = multipleLoanResponse?.data?.uniqueLoanId;
      }

      //** If is unique loan of the user, go to the tracker */
      if (uniqueLoanId && !isUserWithMultipleLoans) {
        /** Check where the user should go to next */
        const getMyLoanResponse =
          isAdmin && currentAdminLoanId
            ? await API.get<Loan>(
                `/admin-impersonate/get/loan/${currentAdminLoanId}`,
              )
            : await API.get<Loan>(`/get/my-loan?id=${uniqueLoanId}`);
        if ("error" in getMyLoanResponse) {
          alert(getMyLoanResponse.error);
        } else {
          setSelectedLoanId(uniqueLoanId);
          if ("body" in getMyLoanResponse.data) {
            setInviteError(
              JSON.parse(getMyLoanResponse?.data?.body as unknown as string),
            );
            setModalInviteErrorIsShown(true);

            logout();
          } else {
            const loan = getMyLoanResponse?.data;
            const isBorrower = userIdLogged === loan?.borrowerId;

            if (
              loan?.inviteCode &&
              loan?.borrowerFlags?.completedByBorrower &&
              loan?.submittedByLO
            ) {
              await API.post({
                url: "/delete-invite",
                data: loan,
              });
            }
            if (
              loan &&
              loan.borrowerFlags &&
              loan.borrowerFlags.archiveType &&
              loan.borrowerFlags.archiveType === "Paid Off"
            ) {
              setModalIsShown(true);
            }

            const borrowerTracker =
              loan.borrowerSteps !== undefined &&
              loan.borrowerFlags?.initialOfferAccepted;

            const homeMonitorValidations =
              loan.borrowerSteps?.fundingVerification.status === "success" ||
              loan?.loanStatusCode === "ARCHIVED";
            const toGetStarted =
              loan?.newLoanStatusCode?.includes("DTC") && isHomeBridge;
            setIsReady(true);

            if (toGetStarted) {
              navigate(`/get-started?loanId=${uniqueLoanId}`, {
                replace: true,
              });
            } else if (homeMonitorValidations) {
              navigate(`/home-monitor?loanId=${uniqueLoanId}`, {
                replace: true,
              });
            } else if (borrowerTracker) {
              navigate(
                isBorrower
                  ? `/borrower-tracker?loanId=${uniqueLoanId}`
                  : `/coborrower-tracker?loanId=${uniqueLoanId}`,
                { replace: true },
              );
            } else if (!borrowerTracker && isBorrower) {
              navigate(`/type-of-credit-line?loanId=${uniqueLoanId}`, {
                replace: true,
              });
            } else {
              navigate(`/loans`, {
                replace: true,
              });
            }
          }
        }
      } else if (isUserWithMultipleLoans) {
        navigate("/loans", { replace: true });
      }
      setIsGettingLoggedIn(false);
    },
    // eslint-disable-next-line
    [navigate],
  );

  //load loan using reactQuery. Doesn't trigger until selectedLoanId is set
  //useQuery returns some constants we can use - in this case we're storing the refetch hook
  //so we can refetch the data when we know it will be modified
  const { refetch } = useQuery<Loan>({
    queryKey: ["Loan", selectedLoanId],
    onSuccess: (data) => {
      if (data && "body" in data) {
        setInviteError(JSON.parse(data as unknown as string));
        if (user && token && !isHomeBridge) {
          setSelectedLoanId("");
          navigate("/loans", { replace: true });
        } else {
          setModalInviteErrorIsShown(true);
          logout();
        }
      } else {
        setLoan(data);
        setLoadingLoan(false);
      }
    },
    enabled: !!selectedLoanId,
    onError: alert,
    refetchInterval: 5000, //refresh every 5s
  });
  const refetchLoan = refetch;

  const getLoan = useCallback(
    async (loanId: string) => {
      if (!user?.id || !loanId) return undefined;

      if (loanId !== selectedLoanId) {
        setSelectedLoanId(loanId); //this will change the loan ID the useQuery hook above uses
        await refetch();
      }

      return undefined;
    },
    [refetch, selectedLoanId, user?.id],
  );

  //refactored to use react query (useQuery above)
  //if no loanId we do nothing, otherwise call refetch
  const getLoanData = useCallback(
    async (loanId: string | undefined) => {
      if (!loanId) {
        setLoadingLoan(false);
        return;
      }

      if (loanId !== selectedLoanId) {
        setSelectedLoanId(loanId);
        await refetch();
      }

      setLoadingLoan(false);
    },
    [refetch, selectedLoanId],
  );

  const adminAuth = useCallback(
    async (adminToken: string) => {
      if (adminToken) {
        localStorage.setItem(
          LSAdminTokenExp,
          String(new Date().getTime() + 86400000),
        );
        localStorage.setItem(LSAdminToken, adminToken);
      }
      if (adminToken && currentAdminLoanId) {
        const userResponse = await API.get<UserType>(
          "/admin-impersonate/get/get-admin-user/users",
        );
        if ("error" in userResponse) {
          adminLogout();
          setUserAdmin(undefined);
          setLoadingLoan(false);
          setLoadingUserData(false);
          return userResponse.error;
        }
        const admin = userResponse.data;
        setLoadingLoan(true);

        const result = await API.get<Loan>(
          `/admin-impersonate/get/loan/${currentAdminLoanId}`,
        );
        if ("error" in result) {
          setLoadingLoan(false);
          return result.error;
        } else {
          const loan = result?.data;
          setLoan(loan);
          setUserAdmin((prev) => ({
            ...prev,
            email: admin.email,
            exp: new Date().getTime() + 86400000,
            token: adminToken,
          }));
          logout(false);
          loanOfficerLogout();
          setLoadingLoan(false);
        }
      }
    },

    [currentAdminLoanId, adminLogout, loanOfficerLogout, logout],
  );

  const loanOfficerAuth = useCallback(
    async (loanOfficerToken: string, wholesaleEntity?: string) => {
      if (loanOfficerToken) {
        localStorage.setItem(
          LSLoanOfficerTokenExp,
          String(new Date().getTime() + 86400000),
        );
        localStorage.setItem(LSLoanOfficerToken, loanOfficerToken);
      }
      if (loanOfficerToken && currentAdminLoanId) {
        const userResponse = await API.get<UserType>(
          `/admin-impersonate/user${
            wholesaleEntity ? `?wholesaleEntity=${wholesaleEntity}` : ""
          }`,
        );
        if ("error" in userResponse) {
          loanOfficerLogout();
          setUserAdmin(undefined);
          setLoadingLoan(false);
          setLoadingUserData(false);
          return userResponse.error;
        }
        const admin = userResponse.data;
        setLoadingLoan(true);

        const result = await API.get<Loan>(
          `/admin-impersonate/get/loan/${currentAdminLoanId}`,
        );
        if ("error" in result) {
          setLoadingLoan(false);
          setLoadingUserData(false);
          return result.error;
        } else {
          const loan = result?.data;
          setLoan(loan);
          setUserAdmin((prev) => ({
            ...prev,
            email: admin.email,
            exp: new Date().getTime() + 86400000,
            token: loanOfficerToken,
          }));
          logout(false);
          loanOfficerLogout();
          setLoadingLoan(false);
          setLoadingUserData(false);
        }
      }
    },

    [currentAdminLoanId, loanOfficerLogout, logout],
  );

  useEffect(() => {
    (async () => {
      if (token) {
        const dateNow = new Date().getTime();
        const authDate = Number(tokenExp ?? 0);

        if (dateNow > authDate) {
          logout();
        } else {
          try {
            setLoadingUserData(true);
            const userResponse = await API.get<UserType>("/get/user-profile");
            if ("error" in userResponse) {
              throw Error(userResponse.error);
            } else {
              setUser(userResponse.data);
              const user = userResponse.data;
              if (user.id && user.email)
                FHConnectUser(
                  user.id,
                  `${user.firstName} ${user.lastName}`,
                  user.email,
                );
            }
          } catch (e) {
            console.error(e);
          } finally {
            setLoadingUserData(false);
          }
        }
      }
    })();
    // eslint-disable-next-line
  }, [logout, token]);

  useEffect(() => {
    (async () => {
      if (adminToken && currentAdminLoanId) {
        setLoadingUserData(true);
        const dateNow = new Date().getTime();
        const authDate = Number(adminTokenExp ?? 0);

        if (dateNow > authDate) {
          adminLogout();
          setUserAdmin(undefined);
          setLoadingLoan(false);
          setLoadingUserData(false);
        } else {
          setLoadingUserData(true);

          const userResponse = await API.get<UserType>(
            "/admin-impersonate/get/get-admin-user/users",
          );

          if ("error" in userResponse) {
            adminLogout();
            setUserAdmin(undefined);
            setLoadingLoan(false);
            setLoadingUserData(false);
            return userResponse.error;
          } else {
            if (currentAdminLoanId) {
              setUserAdmin(userResponse.data);
              setLoadingLoan(true);
              const result = await API.get<Loan>(
                `/admin-impersonate/get/loan/${currentAdminLoanId}`,
              );
              if ("error" in result) {
                setLoadingLoan(false);
                setLoadingUserData(false);
                return result.error;
              }
              logout(false);
              loanOfficerLogout();
              setLoan(result?.data);
              setLoadingLoan(false);
              setLoadingUserData(false);
            } else {
              setLoadingUserData(false);
              setLoadingLoan(false);
            }
          }
        }
      }
    })();
  }, [
    adminLogout,
    adminToken,
    adminTokenExp,
    currentAdminLoanId,
    loanOfficerLogout,
    logout,
  ]);

  const loadedLoan = useCallback(async () => {
    const multipleLoanResponse = await API.get<{
      isUserWithMultipleLoans: boolean;
      uniqueLoanId?: string;
    }>("/get/multiple-loans");
    let uniqueLoanId;
    if ("data" in multipleLoanResponse) {
      uniqueLoanId = multipleLoanResponse?.data?.uniqueLoanId;
      if (multipleLoanResponse?.data.isUserWithMultipleLoans) {
        navigate("/loans", { replace: true });
      }
    }
    if (!uniqueLoanId) return;
    setSelectedLoanId(uniqueLoanId);
  }, [navigate]);

  useEffect(() => {
    (async () => {
      if (loanOfficerToken && currentAdminLoanId) {
        setLoadingUserData(true);
        const dateNow = new Date().getTime();
        const authDate = Number(loanOfficerTokenExp ?? 0);

        if (dateNow > authDate) {
          loanOfficerLogout();
          setUserAdmin(undefined);
          setLoadingLoan(false);
          setLoadingUserData(false);
        } else {
          setLoadingUserData(true);

          const userResponse = await API.get<UserType>(
            `/admin-impersonate/user${
              wholesaleEntityValue
                ? `?wholesaleEntity=${wholesaleEntityValue}`
                : ""
            }`,
          );
          if ("error" in userResponse) {
            loanOfficerLogout();
            setUserAdmin(undefined);
            setLoadingLoan(false);
            setLoadingUserData(false);
            return userResponse.error;
          } else {
            if (currentAdminLoanId) {
              setUserAdmin(userResponse.data);
              setLoadingLoan(true);
              const result = await API.get<Loan>(
                `/admin-impersonate/get/loan/${currentAdminLoanId}`,
              );
              if ("error" in result) {
                setLoadingLoan(false);
                setLoadingUserData(false);
                return result.error;
              }
              logout(false);
              adminLogout();
              setLoan(result?.data);
              setLoadingLoan(false);
              setLoadingUserData(false);
            } else {
              setLoadingUserData(false);
              setLoadingLoan(false);
            }
          }
        }
      }
    })();
  }, [
    currentAdminLoanId,
    loanOfficerLogout,
    logout,
    loanOfficerToken,
    loanOfficerTokenExp,
    adminLogout,
    wholesaleEntityValue,
  ]);

  useEffect(() => {
    if (user) {
      setLoadingLoan(true);
      let loanIdValue = undefined;
      if (selectedLoanId !== "") {
        loanIdValue = selectedLoanId;
      } else if (!isAdmin && loandIsFromQueryURL) {
        loanIdValue = loandIsFromQueryURL;
      }
      getLoanData(loanIdValue);
    }
    // eslint-disable-next-line
  }, [user, location, selectedLoanId]);

  useEffect(() => {
    if (user && isHomeBridge && !loan?.id && !loadedLoanRef.current) {
      loadedLoan();
      loadedLoanRef.current = true;
    }
  });

  useEffect(() => {
    setLoadingLoan(true);
    const searchParams = location.search;
    const loanOfficerToken = new URLSearchParams(searchParams).get(
      LSLoanOfficerToken,
    );

    const adminToken = new URLSearchParams(searchParams).get(LSAdminToken);
    const userToken = new URLSearchParams(searchParams).get(LStoken);
    const userTokenExp = new URLSearchParams(searchParams).get(LStokenExp);

    if (loanOfficerToken) {
      const wholesaleEntity = new URLSearchParams(searchParams).get(
        "wholesaleEntity",
      );
      if (wholesaleEntity) {
        setWholesaleEntityValue(wholesaleEntity);
      }
      adminLogout();
      logout(false);
      navigate(`${location.pathname}`, { replace: true });
      loanOfficerAuth(
        loanOfficerToken,
        wholesaleEntity ? wholesaleEntity : undefined,
      );
    } else if (adminToken) {
      loanOfficerLogout();
      logout(false);
      navigate(`${location.pathname}`, { replace: true });
      adminAuth(adminToken);
    } else if (userToken && userTokenExp) {
      const URLSearchParamsFrom = new URLSearchParams(searchParams);
      URLSearchParamsFrom.delete(LStoken);
      URLSearchParamsFrom.delete(LStokenExp);
      const loanId = URLSearchParamsFrom.get("loanId");
      navigate(
        `${location.pathname}${loanId ? "?" : ""}${URLSearchParamsFrom}`,
        {
          replace: true,
        },
      );
      localStorage.setItem(LStoken, userToken);
      localStorage.setItem(LStokenExp, userTokenExp);
    }
  }, [
    location.search,
    adminAuth,
    loanOfficerAuth,
    navigate,
    location.pathname,
    adminLogout,
    loanOfficerLogout,
    logout,
  ]);

  const handleSelectLoan = useCallback(
    async (loanId: string, isAdmin = false) => {
      setLoadingLoan(true);
      setSelectedLoanId(loanId);
      if (isAdmin) {
        setCurrentAdminLoanId(loanId);
        await getAdminLoan();
      } else {
        await getLoanData(loanId);
      }
    },
    [getAdminLoan, getLoanData],
  );

  useEffect(() => {
    const isValidNYSite =
      privateLabel?.newYorkApplicationsDomain &&
      privateLabel?.newYorkApplicationsDomain?.includes(
        window.location.hostname,
      );
    if (!isValidNYSite && privateLabel?.newYorkApplicationsDomain) {
      window.location.href = `${privateLabel.newYorkApplicationsDomain}${location.pathname}${location.search}`;
    }
  }, [
    privateLabel?.newYorkApplicationsDomain,
    location.pathname,
    location.search,
  ]);

  const contextValue = useMemo(
    () => ({
      user,
      login,
      loan,
      loadingLoan,
      logout,
      setUser,
      loadingUserData,
      isGettingLoggedIn,
      inviteError,
      adminAuth,
      userAdmin,
      setCurrentAdminLoanId,
      getAdminLoan,
      openedDrawer,
      setOpenedDrawer,
      isReady,
      selectedLoanId,
      setSelectedLoanId,
      handleSelectLoan,
      getLoan,
      refetchLoan,
    }),
    [
      user,
      login,
      loan,
      loadingLoan,
      logout,
      setUser,
      loadingUserData,
      isGettingLoggedIn,
      inviteError,
      adminAuth,
      userAdmin,
      setCurrentAdminLoanId,
      getAdminLoan,
      openedDrawer,
      setOpenedDrawer,
      isReady,
      selectedLoanId,
      setSelectedLoanId,
      handleSelectLoan,
      getLoan,
      refetchLoan,
    ],
  );

  return (
    <UserContext.Provider value={contextValue}>
      <CDialog
        open={modalIsShown}
        title={"Loan Paid Off"}
        description={`Thank you for your application with ${privateLabel?.lenderName}. Loan ${selectedLoanId} is now Paid in full.`}
        icon="check"
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={() => setModalIsShown(!modalIsShown)}
          name="paidOffDialog-close"
        >
          Close
        </CButton>
      </CDialog>
      <CDialog
        open={modalInviteErrorShown}
        title={""}
        description={inviteError}
        icon="check"
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={() => {
            setModalInviteErrorIsShown(!modalInviteErrorShown);
            logout();
          }}
          name="paidOffDialog-close2"
        >
          Close
        </CButton>
      </CDialog>
      {children}
    </UserContext.Provider>
  );
};

export default useUser;
