import API from "utils/API";
import Loan from "typedef/Loan";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useCallback, useEffect, useRef } from "react";
import { LSLoanOfficerToken } from "CONST";
import useUser from "context/UserCustomer/useUserCustomerContext";
import useTypeOfCreditLine from "./useTypeOfCreditLine";

export type ValuesSliderProps = {
  minDraw?: number;
  maxDraw?: number;
};
export type ValuesHELOC = {
  APR?: number;
  initialDrawAmount?: number;
  interestRate?: number;
  maximumInitialDraw?: number;
  minimumInitialDraw?: number;
  monthlyInterestCharge?: number;
  amount?: number;
};
export type UseTypeOfCreditLineReturnType = ReturnType<
  typeof useTypeOfCreditLine
>;

const useInitialHelocOffer = (
  isAdmin = false,
  typeOfCreditLine?: UseTypeOfCreditLineReturnType,
) => {
  const navigate = useNavigate();
  const paramsUrl = useParams();
  const currentAdminLoanId = paramsUrl?.loanId;
  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(true);
  const [isAccepting, setIsAccepting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [newValuesSlider, setNewValuesSlider] = useState<ValuesHELOC>();
  const [initialValuesSliderHELOC, setInitialValuesSliderHELOC] =
    useState<ValuesSliderProps>();
  const [refreshLoan, setRrefreshLoan] = useState(0);
  const [marks, setMarks] = useState<
    {
      value: number;
    }[]
  >([]);

  const loadedInitialOffer = useRef(false);
  const loadedInitialOfferHELOC = useRef(false);

  const { selectedLoanId } = useUser();
  const handleRrefresh = () => {
    setRrefreshLoan((prev) => prev + 1);
  };

  const getAndSetLoan = useCallback(
    async (
      selectedLoanIdValue: string,
      isAdminValue?: boolean,
      currentAdminLoanIdValue?: string,
    ) => {
      const response =
        isAdminValue && currentAdminLoanIdValue
          ? await API.get<Loan>(
              `/admin-impersonate/get/loan/${currentAdminLoanIdValue}`,
            )
          : await API.get<Loan>(`/get/my-loan?id=${selectedLoanIdValue}`);

      if (!("error" in response)) {
        setLoan(response.data);
      }
    },
    [],
  );

  useEffect(() => {
    getAndSetLoan(selectedLoanId, isAdmin, currentAdminLoanId);
  }, [getAndSetLoan, selectedLoanId, isAdmin, currentAdminLoanId, refreshLoan]);

  const getInitialCalculateHelocOffer = useCallback(
    async (params: {
      loanIdValue: string;
      maxDrawValue: number;
      isAdminValue: boolean;
      currentAdminLoanIdValue: string | undefined;
    }) => {
      const {
        isAdminValue,
        currentAdminLoanIdValue,
        maxDrawValue,
        loanIdValue,
      } = params;
      setLoading(true);
      let replaceString = "admin";

      const isLoanOfficer = localStorage.getItem(LSLoanOfficerToken);
      if (isLoanOfficer) {
        replaceString = "lo";
      }
      const recalculatedLoanResponse =
        isAdminValue && currentAdminLoanIdValue
          ? await API.post<ValuesHELOC>({
              url: `/admin-impersonate/save-to-loan-${replaceString}/calculate-heloc-offer-initial?ignoreMaxDti=true`,
              data: {
                initialDrawAmount: maxDrawValue,
                loanId: currentAdminLoanIdValue,
              },
            })
          : await API.post<ValuesHELOC>({
              url: `/calculate-heloc-offer/initial?ignoreMaxDti=true`,
              data: {
                initialDrawAmount: maxDrawValue,
                loanId: loanIdValue,
                ignoreSaveOfferHistory: true,
              },
            });

      if ("error" in recalculatedLoanResponse) {
        setShowErrorMessage(true);
        setErrorMessage(recalculatedLoanResponse.error);
      } else {
        setShowErrorMessage(false);
        setErrorMessage("");
        setNewValuesSlider(recalculatedLoanResponse.data);
      }
      setLoading(false);
    },
    [],
  );

  const getInitialSliderValues = useCallback(
    async (params: {
      loanIdValue: string;
      isAdminValue: boolean;
      currentAdminLoanIdValue: string | undefined;
      isRefresh?: boolean;
    }) => {
      const { loanIdValue, isAdminValue, currentAdminLoanIdValue } = params;
      setLoading(true);
      const response =
        isAdminValue && currentAdminLoanIdValue
          ? await API.get<ValuesSliderProps>(
              `/admin-impersonate/get/get-heloc-slider-values/value?loanId=${currentAdminLoanIdValue}`,
            )
          : await API.get<ValuesSliderProps>(
              `/get/get-heloc-slider-values?loanId=${loanIdValue}`,
            );

      if ("error" in response) {
        console.error(response.error);
      } else {
        setInitialValuesSliderHELOC(response.data);
      }
      setLoading(false);
    },
    [],
  );

  useEffect(() => {
    if (!loadedInitialOffer?.current && loan?.id) {
      getInitialSliderValues({
        loanIdValue: loan?.id,
        isAdminValue: isAdmin ?? false,
        currentAdminLoanIdValue: currentAdminLoanId,
      });
      loadedInitialOffer.current = true;
    }
  }, [loan, isAdmin, currentAdminLoanId, getInitialSliderValues]);

  useEffect(() => {
    if (
      initialValuesSliderHELOC?.maxDraw &&
      !loadedInitialOfferHELOC.current &&
      loan?.id
    ) {
      getInitialCalculateHelocOffer({
        loanIdValue: loan?.id,
        maxDrawValue: initialValuesSliderHELOC?.maxDraw,
        isAdminValue: isAdmin ?? false,
        currentAdminLoanIdValue: currentAdminLoanId,
      });
      loadedInitialOfferHELOC.current = true;
    }
  }, [
    loan,
    initialValuesSliderHELOC,
    isAdmin,
    currentAdminLoanId,
    getInitialCalculateHelocOffer,
  ]);

  useEffect(() => {
    if (refreshLoan !== 0) {
      getInitialSliderValues({
        loanIdValue: loan?.id as string,
        isAdminValue: isAdmin ?? false,
        currentAdminLoanIdValue: currentAdminLoanId,
      });

      getInitialCalculateHelocOffer({
        loanIdValue: loan?.id as string,
        maxDrawValue: initialValuesSliderHELOC?.maxDraw as number,
        isAdminValue: isAdmin ?? false,
        currentAdminLoanIdValue: currentAdminLoanId,
      });
    }
  }, [
    initialValuesSliderHELOC?.maxDraw,
    refreshLoan,
    loan?.id,
    isAdmin,
    currentAdminLoanId,
    getInitialCalculateHelocOffer,
    getInitialSliderValues,
  ]);

  useEffect(() => {
    if (loan) {
      const minOffer = initialValuesSliderHELOC?.minDraw ?? 0;
      const maxOffer = initialValuesSliderHELOC?.maxDraw ?? 0;
      const steps = 10.0;
      const jump = (maxOffer - minOffer) / steps;
      const padding = jump * 0.1;
      const paddedMinOffer = minOffer + padding;
      const paddedMaxOffer = maxOffer - padding * 2;
      const paddedJump = (paddedMaxOffer - paddedMinOffer) / steps;
      setMarks(
        Array.from({ length: steps + 1 }).map((_, index) => ({
          value: paddedMinOffer + paddedJump * index,
        })),
      );
    }
    // eslint-disable-next-line
  }, [initialValuesSliderHELOC]);

  const recalculateOffer = async (
    _: unknown,
    incomingNewAmount: number | number[],
  ) => {
    if (Array.isArray(incomingNewAmount)) return undefined;
    setLoading(true);
    let newAmount = incomingNewAmount;
    if (incomingNewAmount > (initialValuesSliderHELOC?.maxDraw ?? 0))
      newAmount = initialValuesSliderHELOC?.maxDraw ?? 0;
    if (incomingNewAmount < (initialValuesSliderHELOC?.minDraw ?? 0))
      newAmount = initialValuesSliderHELOC?.minDraw ?? 0;

    if (newAmount !== loan?.initialOffer?.amount) {
      newAmount = newAmount - (typeOfCreditLine?.fees?.totalFees ?? 0);
    }

    const recalculatedLoanResponse = await API.post<ValuesHELOC>({
      url: `/calculate-heloc-offer/initial?ignoreMaxDti=true`,
      data: {
        initialDrawAmount: newAmount,
        loanId: loan?.id,
        ignoreSaveOfferHistory: true,
      },
    });
    if ("error" in recalculatedLoanResponse) {
      setShowErrorMessage(true);
      setErrorMessage(recalculatedLoanResponse.error);
    } else {
      setShowErrorMessage(false);
      setErrorMessage("");
      setNewValuesSlider(recalculatedLoanResponse?.data);
    }
    setLoading(false);
  };

  const saveOffer = useCallback(async () => {
    setIsAccepting(true);
    const response = await API.post({
      url: `/accept-offer/initial`,
      data: {
        initialDrawAmount: newValuesSlider?.initialDrawAmount,
        loanId: loan?.id,
      },
    });
    if ("error" in response) {
      setErrorMessage(response.error);
    } else {
      navigate(`/borrower-tracker?loanId=${selectedLoanId}`, { replace: true });
    }
    setIsAccepting(false);
    // eslint-disable-next-line
  }, [navigate, loan, initialValuesSliderHELOC, newValuesSlider]);

  return {
    loan,
    marks,
    loading,
    saveOffer,
    isAccepting,
    errorMessage,
    newValuesSlider,
    showErrorMessage,
    initialValuesSliderHELOC,
    recalculateOffer: recalculateOffer,
    handleRrefresh,
    refreshLoan,
  };
};

export default useInitialHelocOffer;

export type InitialHELOCOffer = ReturnType<typeof useInitialHelocOffer>;
