import Loan from "typedef/Loan";

interface GetErrorMessagesParams {
  maxdti?: number | undefined;
  dti: number;
  isLoading?: boolean;
  step?: Loan["borrowerSteps"] | Loan["coborrowerSteps"];
  awaitB2Register?: boolean;
  loan?: Loan;
  adminNeedToReviewIncomes?: boolean | undefined;
  hardpullIsMissing?: boolean;
}

export const getErrorMessages = ({
  maxdti,
  dti,
  isLoading,
  step,
  awaitB2Register,
  loan,
  adminNeedToReviewIncomes,
  hardpullIsMissing,
}: GetErrorMessagesParams) => {
  let errorMessage = "";
  let errorMessageImpersonate = "";
  if (maxdti === undefined) {
    errorMessage =
      "DTI exceeds our threshold for your credit score. We cannot offer you a loan today.";
    errorMessageImpersonate =
      "An acceptable DTI value for the Credit Score could not be found.";
  } else if (dti > maxdti) {
    errorMessage =
      "DTI is above the maximum allowed, please restructure with the options presented below.";
    errorMessageImpersonate =
      "The borrower DTI exceeds the maximum allowed. They should add more income, select tradelines for payoff, or add a Coborrower to proceed.";
  } else if (isLoading) {
    errorMessage = "Please wait, we're processing your request.";
    errorMessageImpersonate = "Please wait, we're processing borrower request.";
  } else if (step?.incomeVerification?.annualIncome === undefined) {
    errorMessage =
      "We can't find a valid Annual Income amount. Please review, verify your income or contact support.";
    errorMessageImpersonate =
      "We can't find a valid Annual Income amount. Please review, verify borrower income.";
  } else if (awaitB2Register) {
    errorMessage =
      "Your Co-Borrower is not yet registered. Please ask them to complete their steps to continue.";
    errorMessageImpersonate =
      "The Co-Borrower is not registered yet. Please ask them to register and verify their income to continue.";
  } else if (adminNeedToReviewIncomes) {
    errorMessage =
      "Income is pending approval, please expect an update within the next 24 hours.";
    errorMessageImpersonate =
      "Borrower income is under review, please review income information.";
  } else if (hardpullIsMissing) {
    errorMessage =
      "We have not received a response from Experian . Please click retry. (If frozen, please unfreeze first).";
    errorMessageImpersonate =
      "We have not received borrower Hard Credit report yet.";
  } else if (loan?.borrowerSteps?.incomeVerification?.needsReview) {
    errorMessage =
      "Income is under review, please expect outreach within 24 hours.";
    errorMessageImpersonate =
      "Borrower income is under review, please review income information.";
  } else if (loan?.coborrowerSteps?.incomeVerification?.needsReview) {
    errorMessage =
      "Income is under review, please expect outreach within 24 hours.";
    errorMessageImpersonate =
      "Coborrower incomeis under review, please review income information.";
  }

  return { errorMessage, errorMessageImpersonate };
};
