import Loan from "typedef/Loan";
import { useCallback, useEffect, useState } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";

const useLoan = () => {
  const [loan, setLoan] = useState<Loan>();
  const { loan: loanContext } = useUser();

  const getAndSetLoan = useCallback(async () => {
    if (loanContext) {
      setLoan(loanContext);
    }
  }, [loanContext]);

  useEffect(() => {
    getAndSetLoan();
  }, [getAndSetLoan]);

  return loan;
};

export default useLoan;
