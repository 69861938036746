import { Box } from "@mui/material";
import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import AccordionItem from "components/HBFaqAccordion/accordionItem";

type AccordionData = {
  title: string;
  content: JSX.Element;
};

const createAccordionData = (
  navigate: (path: string) => void,
): AccordionData[] => [
  {
    title: "How much?",
    content: (
      <>
        Our HELOC lets you access up to 80% of your home's available equity
        ($25k to $400k) without refinancing your current mortgage.{" "}
        <div id="container--FAQHowMuch" style={{ all: "unset" }}>
          <button id="button--FAQHowMuch" style={{ all: "unset" }}>
            <div
              style={{
                textDecoration: "underline",
                fontStyle: "bold",
                cursor: "pointer",
              }}
              onClick={() => navigate("/get-started")}
              id="FAQHowMuch"
            >
              Start now
            </div>
          </button>
        </div>{" "}
        to get an exact dollar amount.
      </>
    ),
  },
  {
    title: "How fast?",
    content: (
      <>
        Your online closing can be scheduled in as few as three days, with
        funding to follow soon after. (Compared to a cash-out refinance, that's
        a month or more faster!)
      </>
    ),
  },
  {
    title: "How easy?",
    content: (
      <>
        Unlike a cash-out refinance, our HELOC requires no paperwork or long
        delays.{" "}
        <div id="container--FAQHowEasy" style={{ all: "unset" }}>
          <button id="button--FAQHowEasy" style={{ all: "unset" }}>
            <div
              style={{
                textDecoration: "underline",
                fontStyle: "bold",
                cursor: "pointer",
              }}
              onClick={() => navigate("/get-started")}
              id="FAQHowEasy"
            >
              Apply now
            </div>
          </button>
        </div>{" "}
        in minutes.
      </>
    ),
  },
  {
    title: "How smart?",
    content: (
      <>
        Compared to credit cards and personal loans, our HELOC is the smart and
        affordable way to get cash fast.{" "}
        <div id="container--FAQHowSmart" style={{ all: "unset" }}>
          <button id="button--FAQHowSmart" style={{ all: "unset" }}>
            <div
              style={{
                textDecoration: "underline",
                fontStyle: "bold",
                cursor: "pointer",
              }}
              onClick={() => navigate("/get-started")}
              id="FAQHowSmart"
            >
              Check your rate
            </div>
          </button>
        </div>{" "}
        now.
      </>
    ),
  },
  {
    title: "What’s next?",
    content: (
      <>
        Take two minutes to check your rate and how much home equity you can
        access now — with no impact on your credit score.
      </>
    ),
  },
];

export const InfoAccordion = () => {
  const [expandedPanels, setExpandedPanels] = useState<Record<string, boolean>>(
    {},
  );

  const navigate = useNavigate();
  const accordionData = createAccordionData(navigate);

  const toggleAccordion = useCallback((panel: string) => {
    setExpandedPanels((prev) => {
      const newState = { ...prev };
      if (newState[panel]) {
        delete newState[panel];
      } else {
        newState[panel] = true;
      }
      return newState;
    });
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      {accordionData.map((accordion, index) => {
        const panelKey = `panel${index + 1}`;
        const isExpanded = expandedPanels[panelKey] || false;

        return (
          <AccordionItem
            key={panelKey}
            panelKey={panelKey}
            isExpanded={isExpanded}
            onToggle={() => toggleAccordion(panelKey)}
            accordion={accordion}
          />
        );
      })}
    </Box>
  );
};
