import AddressType from "typedef/Address";
import { useEffect, useState } from "react";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { Box, Typography } from "@mui/material";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import useAddressAutoComplete from "components/CAddressAutocomplete/useAddressAutocomplete";

interface InputAddressProps {
  inputLabel: string;
  setCurrentAddress: (address: AddressType | undefined) => void;
  setIsAlreadyAddress?: (isAlreadyAddress: boolean) => void;
  valueInputRef: React.RefObject<HTMLInputElement>;
  error: boolean;
  setError: (error: boolean) => void;
  animation: boolean;
  errorMessage?: string;
  setErrorMessage?: (error: string) => void;
  addressToString: (address: AddressType) => string;
  setHasSelectedAddress: (hasSelectedAddress: boolean) => void;
}

export const InputAddress = ({
  inputLabel,
  setCurrentAddress,
  setIsAlreadyAddress,
  valueInputRef,
  error,
  setError,
  animation,
  errorMessage,
  setErrorMessage,
  addressToString,
  setHasSelectedAddress,
}: InputAddressProps) => {
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const addressType: AddressType | undefined = undefined;
  const { fourthText } = useHomeBridgeColors();
  const { sizes } = useHomebridgeSizes();

  const {
    text,
    setText,
    handleOnInputChange,
    handleOnChange,
    options,
    setOptions,
    loading,
  } = useAddressAutoComplete({
    address: addressType,
    setAddress: setCurrentAddress as React.Dispatch<AddressType | null>,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    handleOnInputChange(e, e.target.value, "input");
    setOpenSuggestions(true);
    setHasSelectedAddress(false);
    if (inputValue.trim().length === 0) {
      setOptions([]);
      setOpenSuggestions(false);
    }
  };

  useEffect(() => {
    if (text.trim().length === 0) {
      setCurrentAddress(undefined);
    }
  }, [text, setCurrentAddress]);

  return (
    <>
      <Typography
        variant="body2"
        fontFamily={"NunitoSans"}
        fontSize={sizes.typography}
        marginBottom={"20px"}
        fontWeight={400}
      >
        {inputLabel}
      </Typography>

      <Box
        sx={{
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <input
          ref={valueInputRef}
          type="text"
          value={text}
          onChange={handleChange}
          style={{
            border: error ? "2px solid red" : `2px solid ${fourthText}`,
            animation: animation ? "shake 0.3s ease-in-out" : "none",
          }}
        />
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading && (
            <p
              className="spinner"
              style={{
                padding: "10px",
                margin: 0,
              }}
            ></p>
          )}
        </Box>

        {options.length > 0 && openSuggestions && (
          <Box
            sx={{
              marginTop: "10px",
              backgroundColor: "#102939",
              border: "1px solid #20A2D8",
              borderTop: "none",
            }}
          >
            {options.map((option, index) => (
              <Box
                className="get-started__address__option"
                style={{
                  borderBottom: "1px solid #20A2D8",
                  padding: "10px",
                }}
                key={`${option.street_line}-${option.city}`}
                onClick={(e) => {
                  handleOnChange(e, option, "selectOption");
                  setOpenSuggestions(false);
                  setText(addressToString(option));
                  setErrorMessage?.("");
                  setError(false);
                  setIsAlreadyAddress?.(false);
                  setHasSelectedAddress(true);
                }}
              >
                {addressToString(option)}
              </Box>
            ))}
          </Box>
        )}
      </Box>
      {text.length >= 0 && (
        <Typography
          variant="body2"
          fontFamily={"NunitoSans"}
          fontSize={"16px"}
          color={"error"}
          marginTop={"10px"}
          sx={{
            animation: animation ? "shake 0.3s ease-in-out" : "none",
          }}
        >
          {errorMessage !== "" ? errorMessage : ""}
        </Typography>
      )}
    </>
  );
};
