import CButton from "components/CButton";
import CDialog from "components/CDialog";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useTracker from "components/CTracker/useTrackerContext";
import { ClickAwayListener } from "@mui/material";
import useUserCustomerContext from "context/UserCustomer/useUserCustomerContext";
import { finalOfferValidations } from "utils/finalOfferValidations";

const FinalOfferVerification: React.FC = () => {
  const { loan } = useTracker();
  const navigate = useNavigate();
  const { user } = useUserCustomerContext();
  const [showDifferentOfferWarning, setShowDifferentOfferWarning] =
    useState(false);
  const checkValidation = loan ? finalOfferValidations(loan, user) : false;
  const isBorrower = user && user.id === loan?.borrowerId;
  const valuationReviewPending = loan?.borrowerFlags?.valuationReviewPending;
  useEffect(() => {
    if (!checkValidation) return;
    navigate(`/final-offer?loanId=${loan?.id}`, { replace: true });
  }, [
    checkValidation,
    loan,
    user,
    isBorrower,
    navigate,
    valuationReviewPending,
    loan?.borrowerFlags?.tradelinesProposedAndDTIConditionallyApproved,
  ]);

  return (
    <div>
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <ClickAwayListener onClickAway={() => {}}>
        <CDialog
          fullWidth
          maxWidth="xs"
          icon="warning"
          scroll={"paper"}
          sx={{ padding: "2rem" }}
          title="Your offer has changed."
          open={showDifferentOfferWarning}
          onClose={() => setShowDifferentOfferWarning(false)}
          disableEscapeKeyDown
        >
          <CButton
            fullWidth
            onClick={() => navigate("/final-offer", { replace: true })}
            variant="contained"
            name="finalOfferDialog-viewNewOffer"
          >
            View new offer
          </CButton>
        </CDialog>
      </ClickAwayListener>
    </div>
  );
};

export default FinalOfferVerification;
