import { Box, Skeleton, Slider, Typography, useTheme } from "@mui/material";
import CDialog from "components/CDialog";
import { FC, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CButton from "components/CButton";
import WarningIcon from "@mui/icons-material/Warning";
import { InitialHELOCOffer } from "../useInitialHelocOffer";
import dollarFormatter from "utils/FormatterDollar";
import SliderValueDisplay from "./SliderValueDisplay";

interface Props extends InitialHELOCOffer {
  isOpen: boolean;
  handleCloseModal: () => void;
  handleChangeDrawAmount: (value: number) => void;
  closingCost?: number;
}

const DrawRequestModal: FC<Props> = ({
  isOpen,
  handleCloseModal,
  handleChangeDrawAmount,
  closingCost,
  ...offer
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (offer?.loan?.initialOffer?.amount) {
      offer.recalculateOffer(null, offer.loan.initialOffer.amount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer?.loan?.initialOffer?.amount]);

  const isComponentLoading =
    offer.loading ||
    !offer?.newValuesSlider?.initialDrawAmount ||
    !offer.initialValuesSliderHELOC?.maxDraw ||
    !offer.initialValuesSliderHELOC?.minDraw;

  const initialDrawAmount = offer?.newValuesSlider?.initialDrawAmount ?? 0;
  const initialOfferAmount = offer?.loan?.initialOffer?.amount;
  const closingCostValue = closingCost ?? 0;

  const finalInitialDrawAmount =
    initialDrawAmount === initialOfferAmount
      ? initialDrawAmount
      : initialDrawAmount + closingCostValue;

  const cashToClose = finalInitialDrawAmount - closingCostValue;

  return (
    <CDialog
      style={{ margin: 0 }}
      open={isOpen}
      onClose={handleCloseModal}
      fullWidth
      maxWidth="xs"
    >
      <Box sx={{ margin: { xs: "0px" } }} display="flex" flexDirection="column">
        <Box
          sx={{ margin: { xs: "0px" } }}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography
            fontSize="22px"
            fontWeight={600}
            maxWidth={"200px"}
            textAlign={"left"}
          >
            Draw Request
          </Typography>
          <CloseIcon
            fontSize="medium"
            onClick={handleCloseModal}
            sx={{ cursor: "pointer" }}
          />
        </Box>
        <Typography mt="15px" textAlign="left" fontSize={18}>
          Available Draw:{" "}
          {isComponentLoading ? (
            <span style={{ display: "inline-block" }}>
              <Skeleton
                width="7rem"
                sx={{
                  display: "inline-block",
                }}
              />
            </span>
          ) : (
            <span style={{ fontSize: "18px" }}>
              {dollarFormatter.format(
                offer?.loan?.initialOffer?.initialDrawAmount ?? 0,
              )}
            </span>
          )}
        </Typography>
        <Typography mt="15px" textAlign="left" fontSize={18}>
          Draw:{" "}
          {isComponentLoading ? (
            <span style={{ display: "inline-block" }}>
              <Skeleton
                width="7rem"
                sx={{
                  display: "inline-block",
                }}
              />
            </span>
          ) : (
            <span style={{ fontSize: "18px" }}>
              {dollarFormatter.format(finalInitialDrawAmount)}
            </span>
          )}
        </Typography>
        <Typography mt="15px" textAlign="left" fontSize={18} color={"red"}>
          - Closing cost:{" "}
          <span style={{ fontSize: "18px" }}>
            {dollarFormatter.format(closingCost ?? 0)}
          </span>
        </Typography>
        <Typography mt="15px" mb={2} textAlign="left" fontSize={18}>
          - Cash to Borrower:{" "}
          {isComponentLoading ? (
            <span style={{ display: "inline-block" }}>
              <Skeleton
                width="7rem"
                sx={{
                  display: "inline-block",
                }}
              />
            </span>
          ) : (
            <Typography
              style={{ fontSize: "18px", display: "inline" }}
              color="green"
            >
              {dollarFormatter.format(cashToClose)}
            </Typography>
          )}
        </Typography>
        {isComponentLoading ? (
          <Skeleton
            width={"100%"}
            height={"2.8rem"}
            sx={{ marginTop: "30px" }}
          />
        ) : (
          offer.initialValuesSliderHELOC?.minDraw !==
            offer.initialValuesSliderHELOC?.maxDraw && (
            <Box
              py={1}
              sx={{
                borderTop: { xs: "0.5px solid lightGrey", md: "none" },
                borderBottom: {
                  xs: "0.5px solid lightGrey",
                  md: "none",
                },
                marginTop: "30px",
              }}
            >
              <SliderValueDisplay
                valueSlider={finalInitialDrawAmount}
                min={offer.initialValuesSliderHELOC?.minDraw ?? 0}
                max={offer.initialValuesSliderHELOC?.maxDraw ?? 0}
                valueToDisplay={cashToClose}
              />
              <Slider
                aria-label="Price"
                marks={offer.marks}
                disabled={isComponentLoading}
                onChangeCommitted={offer.recalculateOffer}
                value={finalInitialDrawAmount}
                getAriaValueText={(value) => `${value}`}
                min={offer.initialValuesSliderHELOC?.minDraw ?? 0}
                max={offer.initialValuesSliderHELOC?.maxDraw ?? 0}
                defaultValue={
                  offer?.newValuesSlider?.initialDrawAmount ??
                  offer.initialValuesSliderHELOC?.maxDraw
                }
                step={1000}
                sx={{
                  "& .MuiSlider-thumb": {
                    height: 25,
                    borderRadius: "4px",
                    clipPath: "polygon(0 0, 100% 0, 100% 50%, 50% 100%, 0 50%)",
                  },
                  "& .MuiSlider-rail": {
                    height: 15,
                    color: theme.palette.text.secondary,
                  },
                  "& .MuiSlider-mark": {
                    width: 6,
                    height: 6,
                    borderRadius: 5,
                    color: theme.palette.background.paper,
                  },
                  "& .MuiSlider-track": {
                    height: 15,
                    opacity: "0%",
                    color: theme.palette.text.secondary,
                  },
                  "& .MuiSlider-markLabel": {
                    color: theme.palette.text.secondary,
                    fontFamily: "Pro Display Regular",
                  },
                }}
              />
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography
                  color={theme.palette.text.secondary}
                  variant="subtitle2"
                >
                  75%
                </Typography>
                <Typography
                  color={theme.palette.text.secondary}
                  variant="subtitle2"
                >
                  100%
                </Typography>
              </Box>
            </Box>
          )
        )}
        {(offer?.newValuesSlider?.initialDrawAmount ?? 0) <
        (offer?.newValuesSlider?.amount ?? 0) ? (
          <Box display="flex" flexDirection="row" gap="12px" marginTop="26px">
            <WarningIcon style={{ color: "#EAB308" }} />
            <Typography fontSize="16px">Draw is less than 100%</Typography>
          </Box>
        ) : null}
        <CButton
          type="button"
          disabled={isComponentLoading}
          loading={offer.isAccepting || offer.loading}
          onClick={() => {
            handleChangeDrawAmount(
              offer?.newValuesSlider?.initialDrawAmount ?? 0,
            );
            handleCloseModal();
          }}
          name="changeLoanAmountSubmitModal"
          fullWidth
          variant="contained"
          sx={{ marginTop: "40px", paddingY: "19px" }}
        >
          <Typography>Confirm Draw</Typography>
        </CButton>
        <CButton
          type="button"
          onClick={handleCloseModal}
          name="cancelChangeLoanAmountSubmitModal"
          fullWidth
          variant="outlined"
          sx={{ marginTop: "24px", paddingY: "19px" }}
        >
          <Typography>Cancel</Typography>
        </CButton>
      </Box>
    </CDialog>
  );
};

export default DrawRequestModal;
