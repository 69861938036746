import API from "utils/API";
import Loan from "typedef/Loan";
import { useState, useEffect, useCallback } from "react";
import { PrivateLabelFees } from "typedef/PrivateLabelFees";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { useParams } from "react-router-dom";
import { ClosingCostDetails } from "screens/FinalOffer/useHelocOffer";

export type Marks = Loan["initialOffer"][];

const useTypeOfCreditLine = (isAdmin?: boolean) => {
  const params = useParams();
  const currentAdminLoanId = params?.loanId;
  const [loan, setLoan] = useState<Loan>();
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(true);
  const [markSelectIndex, setMarkSelectIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { selectedLoanId } = useUser();

  const handleRefresh = () => {
    setRefresh((prev) => prev + 1);
  };

  const handleChangeDrawAmount = (value: number) => {
    setLoan((prev) => {
      const previousLoan = prev as Loan;

      return {
        ...previousLoan,
        initialOffer: {
          ...previousLoan?.initialOffer,
          initialDrawAmount: value,
        },
      };
    });
  };

  const getAndSetLoan = useCallback(
    async (
      selectedLoanIdValue: string,
      isAdminValue?: boolean,
      currentAdminLoanIdValue?: string,
    ) => {
      setLoading(true);
      const response =
        isAdminValue && currentAdminLoanIdValue
          ? await API.get<Loan>(
              `/admin-impersonate/get/loan/${currentAdminLoanIdValue}`,
            )
          : await API.get<Loan>(`/get/my-loan?id=${selectedLoanIdValue}`);

      if (!("error" in response)) {
        setLoan(response.data);
      }
      setLoading(false);
    },
    [],
  );

  useEffect(() => {
    getAndSetLoan(selectedLoanId, isAdmin, currentAdminLoanId);
  }, [getAndSetLoan, selectedLoanId, isAdmin, currentAdminLoanId, refresh]);

  const [fees, setFees] = useState<PrivateLabelFees & ClosingCostDetails>();
  const state = loan?.property.address.components.state_abbreviation;
  useEffect(() => {
    setLoading(true);
    if (loan?.id) {
      API.get<PrivateLabelFees & ClosingCostDetails>(
        `/getServiceUnsecured/origination-fees?state=${state}&loanId=${loan?.id}`,
      )
        .then((response) => {
          if (!("error" in response)) {
            setFees(response.data);
            setLoading(false);
          }
        })
        // eslint-disable-next-line
        .catch((_error) => {
          setLoading(false);
        });
    }
  }, [state, loan?.id, loan?.initialOffer?.amount]);

  const originationFee =
    fees?.totalFee && loan?.initialOffer?.amount
      ? fees?.totalFee * loan?.initialOffer?.amount
      : null;

  const originalTotalFee =
    fees?.totalFee !== undefined ? fees?.totalFee : 0.0299;

  return {
    handleRefresh,
    refresh,
    loan,
    loading,
    errorMessage,
    showErrorMessage,
    markSelectIndex,
    setMarkSelectIndex,
    handleChangeDrawAmount,
    fees,
    originationFee,
    originalTotalFee,
    setErrorMessage,
    setShowErrorMessage,
  };
};

export default useTypeOfCreditLine;
