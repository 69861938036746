import { Box, Typography, Collapse, IconButton } from "@mui/material";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { FormData } from "screens/GetStarted/useGetStarted";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

type HBethnicityOptionsProps = {
  formData: FormData;
  setFormData: (data: FormData) => void;
};

type ExpandedState = {
  asian: boolean;
  hawaiian: boolean;
};

export const validateRace = (formData: FormData) => {
  const race = formData.demographicsData?.race;
  return (
    race?.americanIndianOrAlaskaNative ||
    race?.asian ||
    race?.blackOrAfricanAmerican ||
    race?.nativeHawaiianOrOtherPacificIslander ||
    race?.white ||
    race?.prefNoSay
  );
};

export const HBRaceOptions = ({
  formData,
  setFormData,
}: HBethnicityOptionsProps) => {
  const [expandedSections, setExpandedSections] = useState<ExpandedState>({
    asian: false,
    hawaiian: false,
  });
  const { primaryText, secondaryText, tertiaryText } = useHomeBridgeColors();
  const { sizes } = useHomebridgeSizes();

  const handleExpand = (section: keyof ExpandedState) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const currentRace = formData.demographicsData.race;

    let newRace;

    if (!checked) {
      newRace = {
        ...currentRace,
        [name]: false,
      };

      if (name === "americanIndianOrAlaskaNative")
        newRace.customAmericanIndianOrAlaskan = "";

      if (name === "asian") {
        newRace = {
          ...newRace,
          asianIndian: false,
          chinese: false,
          filipino: false,
          japanese: false,
          korean: false,
          vietnamese: false,
          otherAsian: false,
          customOtherAsian: "",
        };
      }

      if (name === "nativeHawaiianOrOtherPacificIslander") {
        newRace = {
          ...newRace,
          nativeHawaiian: false,
          guamanianOrChamorro: false,
          samoan: false,
          otherPacificIslander: false,
          customOtherPacificIslander: "",
        };
      }

      if (
        [
          "asianIndian",
          "chinese",
          "filipino",
          "japanese",
          "korean",
          "vietnamese",
          "otherAsian",
        ].includes(name)
      ) {
        const allAsianChildrenUnchecked = ![
          "asianIndian",
          "chinese",
          "filipino",
          "japanese",
          "korean",
          "vietnamese",
          "otherAsian",
        ]
          .filter((child) => child !== name)
          .some((child) => currentRace[child as keyof typeof currentRace]);

        if (allAsianChildrenUnchecked) {
          newRace.asian = false;
        }

        newRace.customOtherAsian = "";
      }

      if (
        [
          "nativeHawaiian",
          "guamanianOrChamorro",
          "samoan",
          "otherPacificIslander",
        ].includes(name)
      ) {
        const allHawaiianChildrenUnchecked = ![
          "nativeHawaiian",
          "guamanianOrChamorro",
          "samoan",
          "otherPacificIslander",
        ]
          .filter((child) => child !== name)
          .some((child) => currentRace[child as keyof typeof currentRace]);

        if (allHawaiianChildrenUnchecked) {
          newRace.nativeHawaiianOrOtherPacificIslander = false;
        }

        newRace.customOtherPacificIslander = "";
      }
    } else if (name === "prefNoSay") {
      newRace = {
        americanIndianOrAlaskaNative: false,
        customAmericanIndianOrAlaskan: "",
        asian: false,
        asianIndian: false,
        chinese: false,
        filipino: false,
        japanese: false,
        korean: false,
        vietnamese: false,
        otherAsian: false,
        customOtherAsian: "",
        blackOrAfricanAmerican: false,
        nativeHawaiianOrOtherPacificIslander: false,
        nativeHawaiian: false,
        guamanianOrChamorro: false,
        samoan: false,
        otherPacificIslander: false,
        customOtherPacificIslander: "",
        white: false,
        prefNoSay: true,
      };
    } else if (
      [
        "asianIndian",
        "chinese",
        "filipino",
        "japanese",
        "korean",
        "vietnamese",
        "otherAsian",
      ].includes(name)
    ) {
      const hasOtherAsianChildrenChecked = [
        "asianIndian",
        "chinese",
        "filipino",
        "japanese",
        "korean",
        "vietnamese",
        "otherAsian",
      ]
        .filter((child) => child !== name)
        .some((child) => currentRace[child as keyof typeof currentRace]);

      newRace = {
        ...currentRace,
        [name]: checked,
        asian: checked || hasOtherAsianChildrenChecked,
        prefNoSay: false,
      };
    } else if (
      [
        "nativeHawaiian",
        "guamanianOrChamorro",
        "samoan",
        "otherPacificIslander",
      ].includes(name)
    ) {
      const hasOtherHawaiianChildrenChecked = [
        "nativeHawaiian",
        "guamanianOrChamorro",
        "samoan",
        "otherPacificIslander",
      ]
        .filter((child) => child !== name)
        .some((child) => currentRace[child as keyof typeof currentRace]);

      newRace = {
        ...currentRace,
        [name]: checked,
        nativeHawaiianOrOtherPacificIslander:
          checked || hasOtherHawaiianChildrenChecked,
        prefNoSay: false,
      };
    } else {
      newRace = {
        ...currentRace,
        [name]: checked,
        prefNoSay: false,
      };
    }

    setFormData({
      ...formData,
      demographicsData: {
        ...formData.demographicsData,
        race: newRace,
      },
    });
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"start"}
        justifyContent={"flex-start"}
        width={"100%"}
        textAlign={"left"}
        sx={{ gap: "10px" }}
      >
        <Typography
          variant="h6"
          color={primaryText}
          sx={{
            fontSize: sizes.typography,
          }}
        >
          What's your race? (Check one or more.)
        </Typography>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
        >
          <input
            type="checkbox"
            name="americanIndianOrAlaskaNative"
            id="americanIndianOrAlaskaNative"
            className="get-started__checkbox"
            checked={
              formData.demographicsData?.race?.americanIndianOrAlaskaNative
            }
            onChange={handleCheckboxChange}
          />
          <Typography
            component="label"
            htmlFor="americanIndianOrAlaskaNative"
            sx={{ cursor: "pointer", fontSize: sizes.typography }}
          >
            American Indian or Alaska Native
          </Typography>
        </Box>
        <Collapse
          in={formData.demographicsData?.race.americanIndianOrAlaskaNative}
        >
          <Box pl={4}>
            <input
              type="text"
              placeholder="Please specify"
              value={
                formData.demographicsData?.race.customAmericanIndianOrAlaskan ||
                ""
              }
              onChange={(e) => {
                setFormData({
                  ...formData,
                  demographicsData: {
                    ...formData.demographicsData,
                    race: {
                      ...formData.demographicsData.race,
                      customAmericanIndianOrAlaskan: e.target.value,
                    },
                  },
                });
              }}
              style={{ marginLeft: "8px" }}
            />
          </Box>
        </Collapse>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
        >
          <input
            type="checkbox"
            name="asian"
            id="asian"
            className="get-started__checkbox"
            checked={formData.demographicsData?.race?.asian}
            onChange={handleCheckboxChange}
          />
          <Typography
            component="label"
            htmlFor="asian"
            sx={{ cursor: "pointer", fontSize: sizes.typography }}
          >
            Asian
          </Typography>
          <IconButton
            onClick={() => handleExpand("asian")}
            sx={{
              padding: 0,
              borderRadius: "50%",
              backgroundColor: tertiaryText,
              "&:hover": {
                backgroundColor: primaryText,
                color: secondaryText,
              },
              color: secondaryText,
              marginLeft: 3,
              alignSelf: "center",
            }}
          >
            {expandedSections.asian ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        <Collapse in={expandedSections.asian} timeout="auto" unmountOnExit>
          <Box
            pl={3}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={"flex-start"}
            textAlign={"left"}
            sx={{ gap: "10px" }}
          >
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <input
                type="checkbox"
                name="asianIndian"
                id="asianIndian"
                className="get-started__checkbox"
                checked={formData.demographicsData?.race?.asianIndian}
                onChange={handleCheckboxChange}
              />
              <Typography
                component="label"
                htmlFor="asianIndian"
                sx={{ cursor: "pointer", fontSize: sizes.typography }}
              >
                Asian Indian
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <input
                type="checkbox"
                name="chinese"
                id="chinese"
                className="get-started__checkbox"
                checked={formData.demographicsData?.race?.chinese}
                onChange={handleCheckboxChange}
              />
              <Typography
                component="label"
                htmlFor="chinese"
                sx={{ cursor: "pointer", fontSize: sizes.typography }}
              >
                Chinese
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <input
                type="checkbox"
                id="filipino"
                name="filipino"
                className="get-started__checkbox"
                checked={formData.demographicsData?.race?.filipino}
                onChange={handleCheckboxChange}
              />
              <Typography
                component="label"
                htmlFor="filipino"
                sx={{ cursor: "pointer", fontSize: sizes.typography }}
              >
                Filipino
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <input
                type="checkbox"
                name="japanese"
                id="japanese"
                className="get-started__checkbox"
                checked={formData.demographicsData?.race?.japanese}
                onChange={handleCheckboxChange}
              />
              <Typography
                component="label"
                htmlFor="japanese"
                sx={{ cursor: "pointer", fontSize: sizes.typography }}
              >
                Japanese
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <input
                type="checkbox"
                name="korean"
                id="korean"
                className="get-started__checkbox"
                checked={formData.demographicsData?.race?.korean}
                onChange={handleCheckboxChange}
              />
              <Typography
                component="label"
                htmlFor="korean"
                sx={{ cursor: "pointer", fontSize: sizes.typography }}
              >
                Korean
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <input
                type="checkbox"
                name="vietnamese"
                id="vietnamese"
                className="get-started__checkbox"
                checked={formData.demographicsData?.race?.vietnamese}
                onChange={handleCheckboxChange}
              />
              <Typography
                component="label"
                htmlFor="vietnamese"
                sx={{ cursor: "pointer", fontSize: sizes.typography }}
              >
                Vietnamese
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <input
                type="checkbox"
                name="otherAsian"
                id="otherAsian"
                className="get-started__checkbox"
                checked={formData.demographicsData?.race?.otherAsian}
                onChange={handleCheckboxChange}
              />
              <Typography
                component="label"
                htmlFor="other-asian"
                sx={{ cursor: "pointer", fontSize: sizes.typography }}
              >
                Other Asian
              </Typography>
              <Typography fontSize={sizes.typography}>Other Asian</Typography>
            </Box>
            <Collapse in={formData.demographicsData?.race.otherAsian}>
              <Box pl={4}>
                <input
                  type="text"
                  placeholder="Please specify"
                  value={formData.demographicsData?.race.customOtherAsian || ""}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      demographicsData: {
                        ...formData.demographicsData,
                        race: {
                          ...formData.demographicsData.race,
                          customOtherAsian: e.target.value,
                        },
                      },
                    });
                  }}
                  style={{ marginLeft: "8px" }}
                  required
                />
              </Box>
            </Collapse>
          </Box>
        </Collapse>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
        >
          <input
            type="checkbox"
            name="blackOrAfricanAmerican"
            id="blackOrAfricanAmerican"
            className="get-started__checkbox"
            checked={formData.demographicsData?.race?.blackOrAfricanAmerican}
            onChange={handleCheckboxChange}
          />
          <Typography
            component="label"
            htmlFor="black"
            sx={{ cursor: "pointer", fontSize: sizes.typography }}
          >
            Black or African American
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
        >
          <input
            type="checkbox"
            name="nativeHawaiianOrOtherPacificIslander"
            id="nativeHawaiianOrOtherPacificIslander"
            className="get-started__checkbox"
            checked={
              formData.demographicsData?.race
                ?.nativeHawaiianOrOtherPacificIslander
            }
            onChange={handleCheckboxChange}
          />
          <Typography
            component="label"
            htmlFor="nativeHawaiianOrOtherPacificIslander"
            sx={{ cursor: "pointer", fontSize: sizes.typography }}
          >
            Native Hawaiian or Other Pacific Islander
          </Typography>
          <IconButton
            onClick={() => handleExpand("hawaiian")}
            sx={{
              padding: 0,
              borderRadius: "50%",
              backgroundColor: tertiaryText,
              "&:hover": {
                backgroundColor: primaryText,
                color: secondaryText,
              },
              color: secondaryText,
              marginLeft: 3,
              alignSelf: "center",
            }}
          >
            {expandedSections.hawaiian ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
          </IconButton>
        </Box>
        <Collapse in={expandedSections.hawaiian} timeout="auto" unmountOnExit>
          <Box
            pl={3}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={"flex-start"}
            textAlign={"left"}
            sx={{ gap: "10px" }}
          >
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <input
                type="checkbox"
                name="nativeHawaiian"
                id="nativeHawaiian"
                className="get-started__checkbox"
                checked={formData.demographicsData?.race?.nativeHawaiian}
                onChange={handleCheckboxChange}
              />
              <Typography
                component="label"
                htmlFor="guamanian"
                sx={{ cursor: "pointer", fontSize: sizes.typography }}
              >
                Native Hawaiian
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <input
                type="checkbox"
                name="guamanianOrChamorro"
                id="guamanianOrChamorro"
                className="get-started__checkbox"
                checked={formData.demographicsData?.race?.guamanianOrChamorro}
                onChange={handleCheckboxChange}
              />
              <Typography
                component="label"
                htmlFor="guamanian"
                sx={{ cursor: "pointer", fontSize: sizes.typography }}
              >
                Guamanian or Chamorro
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <input
                type="checkbox"
                name="samoan"
                id="samoan"
                className="get-started__checkbox"
                checked={formData.demographicsData?.race?.samoan}
                onChange={handleCheckboxChange}
              />
              <Typography
                component="label"
                htmlFor="samoan"
                sx={{ cursor: "pointer", fontSize: sizes.typography }}
              >
                Samoan
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems={{ xs: "start", md: "center" }}
              sx={{ gap: "10px" }}
            >
              <input
                type="checkbox"
                name="otherPacificIslander"
                id="otherPacificIslander"
                className="get-started__checkbox"
                checked={formData.demographicsData?.race?.otherPacificIslander}
                onChange={handleCheckboxChange}
              />
              <Typography
                component="label"
                htmlFor="otherPacificIslander"
                sx={{ cursor: "pointer", fontSize: sizes.typography }}
              >
                Other Pacific Islander
              </Typography>
            </Box>
            <Collapse in={formData.demographicsData?.race.otherPacificIslander}>
              <Box pl={4}>
                <input
                  type="text"
                  placeholder="Please specify"
                  value={
                    formData.demographicsData?.race
                      .customOtherPacificIslander || ""
                  }
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      demographicsData: {
                        ...formData.demographicsData,
                        race: {
                          ...formData.demographicsData.race,
                          customOtherPacificIslander: e.target.value,
                        },
                      },
                    });
                  }}
                  style={{ marginLeft: "8px" }}
                  required
                />
              </Box>
            </Collapse>
          </Box>
        </Collapse>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
        >
          <input
            type="checkbox"
            name="white"
            id="white"
            className="get-started__checkbox"
            checked={formData.demographicsData?.race?.white}
            onChange={handleCheckboxChange}
          />
          <Typography
            component="label"
            htmlFor="white"
            sx={{ cursor: "pointer", fontSize: sizes.typography }}
          >
            White
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems={{ xs: "start", md: "center" }}
          sx={{ gap: "10px" }}
        >
          <input
            type="checkbox"
            name="prefNoSay"
            data-cy="prefNoSay-setpRace"
            id="prefNoSay"
            className="get-started__checkbox"
            checked={formData.demographicsData?.race?.prefNoSay}
            onChange={handleCheckboxChange}
          />
          <Typography
            component="label"
            htmlFor="prefNoSay"
            sx={{ cursor: "pointer", fontSize: sizes.typography }}
          >
            I do not wish to provide this information
          </Typography>
        </Box>
      </Box>
    </>
  );
};
