import { Box, Typography } from "@mui/material";
import { LoanFlag } from "typedef/Loan";

type NewBadgeProps = {
  message: string | undefined;
  color: string;
  isPQL: boolean | undefined;
  hasLO: string | undefined;
  isArchived: LoanFlag | undefined;
  isManualArchive: boolean;
  hasSubmittedByLO: boolean | undefined;
};

const NewBadge = ({
  message,
  color,
  isPQL,
  hasLO,
  isArchived,
  isManualArchive,
  hasSubmittedByLO,
}: NewBadgeProps) => {
  let prefix = "";
  if (isPQL) {
    if (hasLO && hasSubmittedByLO === undefined) {
      prefix = "B1: ";
    } else if (hasLO) {
      prefix = "MLO: ";
    } else {
      prefix = "DTC: ";
    }
  }

  const archiveType = isManualArchive ? isArchived : "";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="body2"
          data-cy="statusLoan"
          sx={{
            margin: 0,
            padding: "5px 12px",
            borderRadius: "15px",
            color: `rgb(${color})`,
            backgroundColor: `rgba(${color}, 0.1)`,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {prefix}
          {message}
          {archiveType}
        </Typography>
      </Box>
    </Box>
  );
};

export default NewBadge;
