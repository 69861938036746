import usePlaid from "./usePlaid";
import Typography from "@mui/material/Typography";
import CTrackerTimelineItem from "components/CTimelineItem";
import { Fragment, useState } from "react";
import useTracker from "components/CTracker/useTrackerContext";
import TruvBridge from "@truv/react";
import useTruvIncome from "./useTruvIncome";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";
import UploadEachDocument from "components/CTracker/StepsDialogs/UploadEachDocument";
import API from "utils/API";
import { useIncomeVerification } from "./useIncomeVerification";
import { DebtToIncomeModal } from "./DebtToIncomeModal";
import useDebts from "./DebtsTable/useDebts";
import SelfGuidedDTI from "./SelfGuidedDTI/SelfGuidedDTI";
import { Box } from "@mui/material";
import color from "context/MaterialUITheme/color";
import { useIsHomebridge } from "utils/useIsHomebridge";
import runHardCreditLambda from "utils/runHardCreditLambda";
import CButton from "components/CButton";
import dollarFormatter from "utils/FormatterDollar";
import { useGetDtiByCreditScore } from "utils/useGetDtiByCreditScore";

export const messageCardStyles = (isHomeBridge: boolean) => ({
  backgroundColor: isHomeBridge ? "#D3E1EA" : color.background,
  color: color.black,
  paddingY: "10px",
  paddingX: 2,
  marginTop: 2,
  borderRadius: 2,
});

const IncomeVerification = ({
  trackerStatusColor,
}: {
  trackerStatusColor: TrackerStatusColor;
}) => {
  const [incomeStepComplete, setIncomeStepComplete] = useState(false);
  const plaid = usePlaid(setIncomeStepComplete);
  const truv = useTruvIncome();
  const { isHomeBridge } = useIsHomebridge();
  const { loan, refetchLoan, type, isModalOpen } = useTracker();
  const {
    processingStatus,
    loading,
    hardpullMissing,
    prevStep,
    isBorrower,
    isSelfEmployed,
    taxReturnsRequested,
    setLoading,
    step,
    setOpenTruv,
    promptManualTruv,
    isB1Docs,
    isB2Docs,
    openTruv,
    docsToUpload,
    loanBalanceCredit,
    incomeUpdate,
    message,
    showDebtToIncomeModal,
    needDocsUpload,
    requestedAdminMessage,
  } = useIncomeVerification(loan, type, isModalOpen, incomeStepComplete);

  const { defaultDTI } = useDebts({ loan, step, isBorrower });

  const handleOpenTruv = async (manualUpload?: boolean) => {
    setLoading(true);
    await truv.getBridgeToken(manualUpload);
    setOpenTruv(true);
    setLoading(false);
  };
  const borrowerType = isBorrower ? "borrower" : "coborrower";
  const handleButtonClick = async () => {
    setLoading(true);
    if (loan?.id && borrowerType) {
      await runHardCreditLambda(loan.id, borrowerType);
      setLoading(false);
    }
  };

  const creditBlockedOrFrozen = isBorrower
    ? loan?.borrowerSteps?.hardpullVerification?.hardCreditScore === -1 &&
      (Boolean(
        loan?.borrowerSteps?.hardpullVerification?.creditProfile?.statement?.[0]
          .type,
      ) ||
        loan?.borrowerSteps?.hardpullVerification?.creditProfile
          ?.informationalMessage?.[0]?.messageNumber === "07")
    : loan?.coborrowerSteps?.hardpullVerification?.hardCreditScore === -1 &&
      (Boolean(
        loan?.coborrowerSteps?.hardpullVerification?.creditProfile
          ?.statement?.[0].type,
      ) ||
        loan?.coborrowerSteps?.hardpullVerification?.creditProfile
          ?.informationalMessage?.[0]?.messageNumber === "07");

  const handleOpenPlaid = async () => {
    setLoading(true);

    const accountWithoutName =
      step?.incomeVerification.baseReport?.bankAccounts.find(
        (account) =>
          !account?.owners ||
          !account?.owners?.length ||
          (account?.owners?.[0] &&
            (account?.owners?.[0].names?.[0] === "" ||
              account?.owners?.[0].names?.[0] === undefined)),
      );
    const accountWithoutMask =
      step?.incomeVerification.baseReport?.bankAccounts.find(
        (account) => account.mask === undefined || account.mask === "",
      );
    const accountsValidation =
      accountWithoutName || accountWithoutMask ? true : false;
    const updateMode =
      step?.incomeVerification.status === "analyzing" &&
      step?.incomeVerification.plaidWasRun &&
      accountsValidation;
    plaid.loadEmployment(
      updateMode,
      accountWithoutMask?.itemId ?? accountWithoutName?.itemId,
    );
    setLoading(false);
  };

  const handleUploadTaxReturns = async () => {
    setLoading(true);
    await API.post({
      url: "/save-to-loan/request-tax-return",
      data: {
        loanId: loan?.id,
        isBorrower,
      },
    });

    //refetch the loan so the workflow progresses
    //HACK - it would be nice to refresh just once
    await refetchLoan();
    await refetchLoan();
    setLoading(false);
  };

  const isDebtConsolidation =
    loan?.initialOffer?.useProceeds === "Debt Consolidation";

  const steps = isBorrower ? "borrowerSteps" : "coborrowerSteps";

  const incomeSources = loan?.[steps]?.incomeVerification?.incomeSources;
  const isOnlySalary =
    incomeSources?.salaryIncome &&
    !incomeSources?.otherIncome &&
    !incomeSources?.selfEmploymentIncome &&
    !incomeSources?.rentalIncome &&
    !incomeSources?.socialIncome;

  const showDebtToIncome =
    incomeSources &&
    isOnlySalary &&
    !isDebtConsolidation &&
    loan?.[steps]?.incomeVerification?.incomeSelection === "W2 (TWN)" &&
    loan?.[steps]?.incomeVerification?.theWorkNumber?.projectedAnnualIncome !==
      0;

  const annualIncome =
    loan?.borrowerSteps?.incomeVerification?.annualIncome ??
    loan?.initialOffer?.annualIncome ??
    0;

  const { dti } = useDebts({ loan });
  const dtiValues = useGetDtiByCreditScore();

  const isDTIValid = dtiValues?.dti !== undefined && dti <= dtiValues?.dti;

  return (
    <CTrackerTimelineItem
      processingStatus={processingStatus ?? loading}
      status={hardpullMissing}
      title="Income"
      loading={loading}
      tooltip={`For W2 applicants, we use a payroll verification system.  For self-employed applicants, we connect with your bank account to verify income.`}
      {...(promptManualTruv &&
        hardpullMissing === "analyzing" &&
        prevStep &&
        !(isBorrower ? isB1Docs : isB2Docs) &&
        !docsToUpload.some((doc) => doc.docName === "Truv W2 and paystubs") &&
        !isSelfEmployed && {
          action: {
            message: "Upload your docs here!",
            onClick: async () => {
              await handleOpenTruv(true);
            },
          },
        })}
      badges={
        Boolean(showDebtToIncome) &&
        loan?.[steps]?.incomeVerification?.fastPass &&
        isDTIValid && (
          <Box
            color="success"
            sx={{
              paddingInlineEnd: 2,
              paddingInlineStart: 2,
              color: "rgb(5, 150, 105)",
              backgroundColor: "rgba(209, 250, 229, 0.5)",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <svg
              width={12}
              height={12}
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="fastpass_svg"
            >
              <g clipPath="url(#clip0_1636_291)">
                <path
                  d="M2 8C2 8 2.5 7.5 4 7.5C5.5 7.5 6.5 8.5 8 8.5C9.5 8.5 10 8 10 8V2.5C10 2.5 9.5 3 8 3C6.5 3 5.5 2 4 2C2.5 2 2 2.5 2 2.5M2 11.5L2 1.5"
                  stroke="#28C76F"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                />
              </g>
            </svg>
            <Typography
              variant="subtitle1"
              component="span"
              sx={{ paddingLeft: "5px" }}
            >
              Fast Pass
            </Typography>
          </Box>
        )
      }
    >
      <TruvBridge
        isOpened={openTruv}
        onClose={() => setOpenTruv(false)}
        bridgeToken={truv.bridgeToken as string}
        onSuccess={async (public_token: string) => {
          setOpenTruv(false);
          await truv.sendPublicToken(public_token);
        }}
      />

      <Typography variant="h6" color={[trackerStatusColor[hardpullMissing]]}>
        {hardpullMissing === "analyzing" &&
          !isBorrower &&
          step?.incomeVerification.message}

        {hardpullMissing === "failed" && message}
        {hardpullMissing === "success" && !isBorrower && message}
        {isBorrower &&
          hardpullMissing === "success" &&
          loan?.coborrowerInvite &&
          loan.coborrowerSteps?.incomeVerification.status !== "success" && (
            <Fragment>
              <br />
              Waiting on the borrower to complete their steps
            </Fragment>
          )}

        {isBorrower &&
          (hardpullMissing === "analyzing" ||
            hardpullMissing === "success") && (
            <Fragment>
              {hardpullMissing === "analyzing"
                ? `You reported $${(incomeUpdate
                    ? Number(incomeUpdate)
                    : 0
                  ).toLocaleString("en-US")} of annual household income.`
                : `We verified $${(incomeUpdate
                    ? Number(incomeUpdate)
                    : 0
                  ).toLocaleString("en-US")} of annual income.`}
              <br />
              We detected ${loanBalanceCredit} of annual debts from your credit
              report.
              <br />
              {hardpullMissing === "analyzing" && (
                <Box sx={messageCardStyles(isHomeBridge)}>
                  <Typography
                    fontSize={{ xs: "0.8rem", sm: "1rem" }}
                    fontWeight={{ xs: 500, sm: 400 }}
                  >
                    {message.replace(requestedAdminMessage, "")}
                  </Typography>
                </Box>
              )}
              {hardpullMissing === "analyzing" &&
                message.includes(requestedAdminMessage) && (
                  <p
                    style={{
                      color: "#FA9D4F",
                      fontWeight: 500,
                      margin: 0,
                    }}
                  >
                    {requestedAdminMessage}
                  </p>
                )}
              {hardpullMissing === "success" && message}
              {creditBlockedOrFrozen &&
                !isSelfEmployed &&
                loan?.borrowerSteps?.incomeVerification.status ===
                  "success" && (
                  <p style={{ margin: 0, fontSize: "1.3rem" }}>
                    {
                      loan?.borrowerSteps?.incomeVerification
                        ?.hardpullMissingMessage
                    }
                  </p>
                )}
            </Fragment>
          )}
        {hardpullMissing === "analyzing" &&
          needDocsUpload &&
          !showDebtToIncomeModal && (
            <UploadEachDocument
              docsToUpload={docsToUpload}
              loan={loan}
              handleOpenTruv={handleOpenTruv}
            />
          )}
      </Typography>
      {creditBlockedOrFrozen && (
        <>
          <span
            className="text-red text-xl"
            style={{ fontSize: "1.2rem", color: "red" }}
          >
            {isBorrower
              ? loan?.borrowerSteps?.hardpullVerification
                  ?.hardpullMissingMessageBorrower
              : loan?.coborrowerSteps?.hardpullVerification
                  ?.hardpullMissingMessageBorrower}
          </span>
          <CButton
            variant="contained"
            onClick={handleButtonClick}
            disabled={loading}
            sx={{
              textTransform: "inherit",
              fontSize: 18,
              fontWeight: 500,
              marginBottom: 3,
              marginTop: 3,
            }}
            name="creditVerification-retryNowHardCredit"
            loading={loading}
          >
            Retry hard credit
          </CButton>
        </>
      )}
      {Boolean(showDebtToIncome) &&
        loan?.[steps]?.incomeVerification?.fastPass &&
        isDTIValid && (
          <Box
            sx={{
              p: 2,
              backgroundColor: "rgba(209, 250, 229, 0.5)",
              color: "rgb(5, 150, 105)",
              width: "90%",
              marginTop: "1rem",
            }}
          >
            <p
              style={{
                fontWeight: 600,
                marginBottom: "0.25rem",
                marginTop: "0px",
              }}
            >
              Good news
            </p>
            We already verified {""}
            {dollarFormatter?.format(annualIncome)} of W2 income with The Work
            Number! This application is eligible for{" "}
            <p style={{ fontWeight: 600, display: "inline" }}>FastPass HELOC</p>{" "}
            {""}
            which means it will likely close in the next business day.
          </Box>
        )}

      {Boolean(showDebtToIncome) &&
        loan?.[steps]?.incomeVerification?.fastPass === false &&
        !isDTIValid && (
          <Box
            sx={{
              p: 2,
              backgroundColor: "rgba(254, 243, 199, 0.5)",
              color: "rgb(202, 138, 4)",
              width: "90%",
              marginTop: "1rem",
            }}
          >
            We have been able to verify {""}
            {dollarFormatter?.format(annualIncome)} of W2 income with The Work
            Number, however we need you to pay down some debts to qualify or add
            another income source.
          </Box>
        )}

      {(hardpullMissing !== "pending" &&
        step?.incomeVerification?.annualIncome) ||
      step?.incomeVerification.annualIncome === 0 ? (
        <SelfGuidedDTI isBorrower={isBorrower} loan={loan} step={step} />
      ) : null}
      {hardpullMissing !== "pending" && (
        <DebtToIncomeModal
          open={
            (defaultDTI === Infinity &&
              step?.incomeVerification?.message !==
                "We received your response and our CX team is now reviewing it." &&
              step?.incomeVerification?.needsReview === false) ||
            showDebtToIncomeModal
          }
          docsToUpload={docsToUpload}
          handlePlaid={handleOpenPlaid}
          handleTruv={isSelfEmployed ? handleUploadTaxReturns : handleOpenTruv}
          handleOpenTruv={handleOpenTruv}
          needDocsUpload={needDocsUpload}
          isSelfEmployed={isSelfEmployed}
          loading={loading || plaid.loadingPlaid}
          loan={loan}
          taxReturnsRequested={taxReturnsRequested}
          step={step}
        />
      )}
    </CTrackerTimelineItem>
  );
};

export default IncomeVerification;
