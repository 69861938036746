import useUser from "context/UserCustomer/useUserCustomerContext";
import { useEffect, useState } from "react";
import Loan from "typedef/Loan";

const useLoan = () => {
  const [loan, setLoan] = useState<Loan>();

  const { loan: loanContext } = useUser();

  useEffect(() => {
    if (loanContext) {
      setLoan(loanContext);
    }
  }, [loanContext]);

  return loan;
};

export default useLoan;
