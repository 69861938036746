import * as yup from "yup";
import API from "utils/API";
import { useForm } from "react-hook-form";
import parseMoney from "utils/parseMoney";
import { DialogProps } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { handleOcuppancy } from "screens/BorrowerRegisterForm/useRegisterForm";
import PricingEngine from "typedef/PricingEngine";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import useLoan from "context/Invite/useLoan";
import dollarFormatter from "utils/FormatterDollar";

const useEditLoanAmount = (
  endpoint: string,
  onClose: Pick<DialogProps, "onClose">["onClose"],
  setEditLoanAmountOpen: (value: React.SetStateAction<boolean>) => void,
  handleSetPopAppraisalModal: (value: boolean) => void,
  setAvmsData: (value: string[] | undefined) => void,
  isFirstLien?: boolean,
  tnMaxOffer?: number, //temporary TN validation
  state?: string,
  loanId?: string,
  handleRefreshLoan?: () => void,
  handleRefreshOffer?: () => void,
) => {
  const { privateLabel } = usePrivateLabel();
  const [pricingEngine, setPricingEngine] = useState<PricingEngine>();
  const loan = useLoan();
  const [loading, setLoading] = useState(false);
  const MIN_LOAN_AMOUNT_AK = 25001;
  const MIN_LOAN_AMOUNT = 25000;
  const MAX_LOAN_AMOUNT = 400000;
  useEffect(() => {
    const privateLabelId = privateLabel?.id;
    const helocType = handleOcuppancy(loan?.occupancy ?? "Primary Residence");
    const pricingEngineId = `${privateLabelId}#${helocType}`;
    const encodePricingEngineId = encodeURIComponent(pricingEngineId);

    API.get<PricingEngine>(
      `/get-heloc-pricing-engine?id=${encodePricingEngineId}`,
    ).then((result) => {
      if ("error" in result) {
        return;
      } else {
        setPricingEngine(result.data);
      }
    });
  }, [loan?.occupancy, privateLabel]);
  const schema = yup.object().shape({
    newLoanAmount: yup
      .string()
      .required("Request loan amount is required.")
      .test(
        "betweenAmount",
        isFirstLien
          ? `Loan Amount should be between ${
              state === "AK" ? "$25,001" : "$25,000"
            }
            - ${
              tnMaxOffer
                ? `$${tnMaxOffer?.toLocaleString("en-US")}`
                : dollarFormatter?.format?.(
                    pricingEngine?.loanMaxFirstLien ?? MAX_LOAN_AMOUNT,
                  ) //temporary TN validation
            }`
          : `Loan Amount should be between ${
              state === "AK" ? "$25,001" : "$25,000"
            }
             - ${
               tnMaxOffer
                 ? `$${tnMaxOffer?.toLocaleString("en-US")}`
                 : dollarFormatter?.format?.(
                     pricingEngine?.loanMax ?? MIN_LOAN_AMOUNT,
                   ) //temporary TN validation
             }`,
        (value) => {
          if (!value) return true;
          const amount = parseMoney(value);
          if (
            isFirstLien &&
            (amount < (state === "AK" ? MIN_LOAN_AMOUNT_AK : MIN_LOAN_AMOUNT) ||
              amount >
                (tnMaxOffer
                  ? tnMaxOffer
                  : pricingEngine?.loanMaxFirstLien ?? MAX_LOAN_AMOUNT)) //temporary TN validation
          ) {
            return false;
          }
          if (
            !isFirstLien &&
            (amount < (state === "AK" ? MIN_LOAN_AMOUNT_AK : MIN_LOAN_AMOUNT) ||
              amount >
                (tnMaxOffer
                  ? tnMaxOffer
                  : pricingEngine?.loanMax ?? MAX_LOAN_AMOUNT)) //temporary TN validation
          ) {
            return false;
          }
          return true;
        },
      ),
  });

  const form = useForm<{ newLoanAmount: string }>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = form.handleSubmit(async (data) => {
    setLoading(true);
    const response = (await API.post({
      url: endpoint,
      method: "PUT",
      data: { amount: parseMoney(data.newLoanAmount), loanId },
    })) as {
      data: { needsAppraisal: boolean; avmsData: string[] };
      error?: string;
    };
    setAvmsData(response?.data?.avmsData);
    if (response?.data?.needsAppraisal === true) {
      handleSetPopAppraisalModal(true);
    } else {
      if ("error" in response) {
        form.setError("newLoanAmount", {
          message: response.error,
        });
      } else {
        onClose?.({}, "backdropClick");
        form.resetField("newLoanAmount");
        handleRefreshLoan?.();
        handleRefreshOffer?.();
        setEditLoanAmountOpen(false);
      }
    }
    setLoading(false);
  });

  return {
    ...form,
    onSubmit,
    loading,
  };
};

export default useEditLoanAmount;
