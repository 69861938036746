import { useCallback, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { FormData } from "../../useGetStarted";
import API, { ResponseError } from "utils/API";
import { useNavigate } from "react-router-dom";

type Errors = {
  field: keyof FormData;
  message: string;
  success?: boolean;
  regex?: RegExp;
  validate?: (value: string) => boolean;
};

const useIdentityValidation = (
  formData: FormData,
  setFormData: React.Dispatch<React.SetStateAction<FormData>>,
) => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState<Errors[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [animation, setAnimation] = useState(false);
  const [loading, setLoading] = useState(false);

  const [checkboxState, setCheckboxState] = useState({
    PrivacyPolicyCheck: false,
    BorrowerConsentCheck: false,
  });

  const submitLoan = useCallback(
    async (formDataValue: FormData) => {
      setLoading(true);
      setErrorMessage("");
      setError(false);
      const { loanId } = formDataValue;
      const response = await API.post({
        url: `/save-to-loan/submit-loan?loanId=${loanId}`,
        data: {
          ...formDataValue,
        },
      });
      if ("error" in response) {
        const responseError = response?.error as unknown as ResponseError;
        if (responseError?.errorId?.message) {
          setErrorMessage(responseError?.errorId?.message);
          setError(true);
          setLoading(false);
          return;
        } else {
          setErrorMessage(response.error);
          setError(true);
          setLoading(false);
          return;
        }
      } else {
        setLoading(false);
        navigate(`/borrower-tracker?loanId=${loanId}`, { replace: true });
      }
    },
    [navigate],
  );

  const handleNext = () => {
    const inputsToValidate: { field: keyof FormData; value: string }[] = [
      { field: "dateOfBirth", value: formData.dateOfBirth ?? "" },
      { field: "ssn", value: formData.ssn ?? "" },
    ];
    const inputErrors = validateInputs(inputsToValidate, checkboxState);
    if (
      inputErrors.length > 0 ||
      !checkboxState.PrivacyPolicyCheck ||
      !checkboxState.BorrowerConsentCheck
    ) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setError(false);
      submitLoan(formData);
    }
  };

  const onValueChange = (event: unknown) => {
    let id: string;
    let value: string;

    if (event && typeof event === "object" && "target" in event) {
      const targetEvent = event as React.ChangeEvent<HTMLInputElement>;
      id = targetEvent.target.id;
      value = targetEvent.target.value;
    } else {
      const dateEvent = event as Dayjs | null;
      id = "dateOfBirth";
      value = dateEvent ? dateEvent.format("MM-DD-YYYY") : "";
    }

    const field = id as keyof FormData;
    const newFormData = { ...formData };
    (newFormData[field as unknown as keyof FormData] as unknown) = value;
    setFormData(newFormData);
    validateInputs({ field, value }, checkboxState);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCheckboxState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const validateInputs = (
    inputs:
      | { field: keyof FormData; value: string }
      | { field: keyof FormData; value: string }[],
    checkbox: { [key: string]: boolean },
  ) => {
    const checks: Errors[] = [
      {
        regex: /^\d{3}-\d{2}-\d{4}$/,
        validate: (value: string) => {
          if (!value) return false;
          return true;
        },
        message: "Invalid SSN format",
        success: false,
        field: "ssn",
      },
      {
        regex: /^(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])-(19|20)\d{2}$/,
        validate: (value: string) => {
          if (!value) return false;

          const date = dayjs(value, "MM-DD-YYYY");
          const minDate = dayjs("1908-02-05");
          const maxDate = dayjs().subtract(18, "years");

          const DOBCheck = checks.find(
            (check) => check.field === "dateOfBirth",
          );
          if (!date.isValid()) {
            if (DOBCheck) {
              DOBCheck.message = "Invalid Date format";
            }
            return false;
          }

          if (date.isBefore(minDate) || date.isAfter(maxDate)) {
            if (DOBCheck) {
              DOBCheck.message = "Must be 18+ years old";
            }
            return false;
          }

          return true;
        },
        message: "Invalid Date",
        success: false,
        field: "dateOfBirth",
      },
    ];

    const validationErrors: Errors[] = [];

    const isArray = Array.isArray(inputs);

    if (isArray) {
      inputs.forEach(({ field, value }) => {
        const check = checks.find((check) => check.field === field);
        if (check && !check.regex?.test(value)) {
          validationErrors.push({
            field,
            message: check.message,
            success: false,
          });
        } else if (check && check.validate && !check.validate(value)) {
          validationErrors.push({
            field,
            message: check.message,
            success: false,
          });
        }
      });
    } else {
      const currentErrors = [...errors];

      const filteredCurrentErrors = currentErrors.filter(
        (error) => error.field !== inputs.field,
      );

      const check = checks.filter((check) => check.field === inputs.field);
      check.forEach((check) => {
        if (!check.regex?.test(inputs.value)) {
          filteredCurrentErrors.push({
            field: inputs.field,
            message: check.message,
            success: false,
          });
        } else if (check && check.validate && !check.validate(inputs.value)) {
          filteredCurrentErrors.push({
            field: inputs.field,
            message: check.message,
            success: false,
          });
        }
      });
      validationErrors.push(...filteredCurrentErrors);
    }

    if (!checkbox.PrivacyPolicyCheck) {
      validationErrors.push({
        field: "PrivacyPolicyCheck",
        message: "Privacy Policy must be accepted",
        success: false,
      });
    }

    if (!checkbox.BorrowerConsentCheck) {
      validationErrors.push({
        field: "BorrowerConsentCheck",
        message: "Borrower Consent must be accepted",
        success: false,
      });
    }

    setErrors(validationErrors);
    return validationErrors;
  };

  return {
    errors,
    animation,
    error,
    errorMessage,
    handleNext,
    onValueChange,
    handleCheckboxChange,
    checkboxState,
    loading,
  };
};

export default useIdentityValidation;
