import { NEW_LOAN_STATUS_CODE } from "typedef/NewLoanStatusCode";

const checkB2pendingAction = (b1Status: string, b2Status: string) => {
  if (b1Status === b2Status || !b2Status) return false;
  const stageB1 = b1Status.substring(0, 3);
  const stageB2 = b2Status.substring(0, 3);
  const stageNumericValueB1 =
    NEW_LOAN_STATUS_CODE[stageB1 as keyof typeof NEW_LOAN_STATUS_CODE];
  const stageNumericValueB2 =
    NEW_LOAN_STATUS_CODE[stageB2 as keyof typeof NEW_LOAN_STATUS_CODE];
  if (stageNumericValueB1 !== stageNumericValueB2) {
    return stageNumericValueB1 >= stageNumericValueB2;
  }
  const stepB1 = Number(b1Status.substring(3));
  const stepB2 = Number(b2Status.substring(3));

  if (stageB1 === "PQL" && stageB2 === "PQL") return stepB1 <= stepB2;
  else return stepB1 >= stepB2;
};

export default checkB2pendingAction;
