import { Box } from "@mui/material";
import { useRef, useState } from "react";
import { verifyMaxSafeNumber } from "utils/verifyMaxSafeNumber";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { PropertyProps } from "../Property";
import { FormDataNumbers } from "../../useGetStarted";

import parseMoney from "utils/parseMoney";

import { useUpdateLoanStatus } from "screens/GetStarted/useUpdateLoanStatus";
import { LoanRequestInputs } from "./LoanRequestInputs";

type Errors = {
  field: keyof FormDataNumbers;
  message: string;
  success?: boolean;
  regex?: RegExp;
};

export const LoanRequest = ({
  onNext,
  onPrev,
  formData,
  setFormData,
}: PropertyProps) => {
  const { puUpdateLoanStatus } = useUpdateLoanStatus();
  const [error, setError] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [errors, setErrors] = useState<Errors[]>([]);

  const helocBalanceInputRef = useRef<HTMLInputElement>(null);
  const judmentsAndLiensBalanceInputRef = useRef<HTMLInputElement>(null);

  const [moreBalance, setMoreBalance] = useState({
    helocBalance: false,
    judmentsAndLiensBalance: false,
  });

  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const validationErrors = validateInputs(
      formData?.homeValue?.toString() ?? "",
    );

    const hasErrors = validationErrors?.length > 0 || errors.length > 0;

    if (hasErrors) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
      return;
    }

    puUpdateLoanStatus({
      body: {
        homeValue: formData.homeValue,
        loanBalance: formData.loanBalance,
        helocBalance: formData.helocBalance,
        judmentsAndLiensBalance: formData.judmentsAndLiensBalance,
      },
    });
    onNext();
  };

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    const field = id as keyof FormDataNumbers;
    const numericValue = BigInt(value.replace(/[$,]/g, ""));
    const isSafeNumber = verifyMaxSafeNumber(numericValue);

    if (!isSafeNumber) {
      setError(true);
      setErrors((prev) => [
        ...prev.filter(
          (err) =>
            err.field !== field ||
            err.message !== "Values can't exceed this amount",
        ),
        {
          field,
          message: "Values can't exceed this amount",
          success: false,
        },
      ]);
      return;
    }

    const newFormData = { ...formData };
    newFormData[field] = value as unknown as number;
    setFormData(newFormData);

    if (id === "homeValue") {
      const validationResult = validateInputs(value);

      if (validationResult && validationResult.length > 0) {
        setError(true);
      }
    }
  };

  const handleOpenMoreBalance = (field: keyof typeof moreBalance) => {
    setMoreBalance({
      ...moreBalance,
      [field]: !moreBalance[field],
    });

    if (field === "helocBalance") {
      setTimeout(() => {
        helocBalanceInputRef.current?.focus();
      }, 200);
    } else if (field === "judmentsAndLiensBalance") {
      setTimeout(() => {
        judmentsAndLiensBalanceInputRef.current?.focus();
      }, 200);
    }
  };

  const validateInputs = (value: string) => {
    const checks: Errors[] = [
      {
        regex: /.{1,}/,
        message: "Please enter a property value",
        success: false,
        field: "homeValue",
      },
      {
        regex: /^[1-9]\d{5,}$/,
        message: "Home value cannot be less than $100,000",
        success: false,
        field: "homeValue",
      },
    ];

    const checksError = checks.map(({ regex, message, field }) => ({
      message,
      field,
      success: regex?.test(parseMoney(value ?? "").toString()),
    }));

    const inputsError = checksError.filter((error) => !error.success);

    setErrors((prev) => {
      const otherErrors = prev.filter(
        (err) =>
          err.field !== "homeValue" ||
          (err.field === "homeValue" &&
            err.message !== "Home value cannot be less than $100,000"),
      );
      return [...otherErrors, ...inputsError];
    });

    return inputsError;
  };

  return (
    <div>
      <div className="get-started__wrapper get-started__loan-request">
        <Box width={"100%"}>
          <Box
            component="form"
            width={"90%"}
            margin={"0 auto"}
            marginTop={"20px"}
          >
            <LoanRequestInputs
              animation={animation}
              error={error}
              errors={errors}
              formData={formData}
              onValueChange={onValueChange}
              moreBalance={moreBalance}
              handleOpenMoreBalance={handleOpenMoreBalance}
              helocBalanceInputRef={helocBalanceInputRef}
              judmentsAndLiensBalanceInputRef={judmentsAndLiensBalanceInputRef}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column-reverse",
                  sm: "row",
                },
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                marginTop: "40px",
              }}
            >
              <CHBButton onClick={onPrev} secondaryArrow={true} type="button">
                Go Back
              </CHBButton>
              <CHBButton
                onClick={handleNext}
                type="submit"
                ctaId="Step3ValueandMTG"
              >
                Next
              </CHBButton>
            </Box>
          </Box>
        </Box>
      </div>
      <HBSeparate className="step-separate" type="vertical" />
    </div>
  );
};
