import { Box, useMediaQuery } from "@mui/material";
import { Logo } from "./Logo";
import { LinkList } from "./LinkList";
import { Turn as Hamburger } from "hamburger-react";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { Profile } from "../Profile";

interface NavProps {
  openedDrawer: boolean;
  handleOpen?: Dispatch<SetStateAction<boolean>>;
}

export const Nav = ({ openedDrawer, handleOpen }: NavProps) => {
  const { primaryText, secondaryText } = useHomeBridgeColors();
  const location = useLocation();
  const pathName = location.pathname;
  const isGetStarted = pathName.includes("/get-started");
  const isFaqOrResources =
    pathName.includes("/faq") || pathName.includes("/resources");
  const isMobile = useMediaQuery("(max-width: 991px)");

  const { user, logout } = useUser();

  const isLogged = !!user;

  return (
    <Box
      className="hb-header__nav"
      justifyContent="space-between"
      sx={{
        padding: "10px 0",
      }}
      component={"nav"}
    >
      {isMobile ? (
        <Box>
          <Box
            display={"flex"}
            justifyContent={!isGetStarted ? "space-between" : "center"}
          >
            <Logo />
            {!isGetStarted && (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isLogged && !openedDrawer && (
                  <Profile user={user} logout={logout} />
                )}
                <Hamburger
                  size={30}
                  toggled={openedDrawer}
                  toggle={handleOpen}
                  color={isFaqOrResources ? secondaryText : primaryText}
                />
              </Box>
            )}
          </Box>
          {!isGetStarted && <LinkList handleOpen={handleOpen} />}
        </Box>
      ) : (
        <Box
          display={"flex"}
          justifyContent={!isGetStarted ? "space-between" : "center"}
        >
          <Logo />
          {!isGetStarted && <LinkList />}
        </Box>
      )}
    </Box>
  );
};
