import { useState } from "react";
import { FormData } from "../../useGetStarted";
import { useUpdateLoanStatus } from "screens/GetStarted/useUpdateLoanStatus";

import qs from "qs";
import API from "utils/API";
import { CheckPhone } from "screens/BorrowerInviteRegisterForm/useRegisterForm";
export type Errors = {
  field: keyof FormData;
  message: string;
  success?: boolean;
  regex?: RegExp;
};

const usePersonalDataValidation = (
  formData: FormData,
  setFormData: React.Dispatch<React.SetStateAction<FormData>>,
  onNext: () => void,
) => {
  const { updateLoanStatus } = useUpdateLoanStatus();
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState<Errors[]>([]);
  const [animation, setAnimation] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputsToValidate: { field: keyof FormData; value: string }[] = [
    { field: "firstName", value: formData.firstName ?? "" },
    { field: "lastName", value: formData.lastName ?? "" },
    { field: "middleName", value: formData.middleName ?? "" },
    { field: "suffix", value: formData.suffix ?? "" },
    { field: "maritalStatus", value: formData.maritalStatus ?? "" },
    { field: "email", value: formData.email ?? "" },
    { field: "phoneNumber", value: formData.phoneNumber ?? "" },
  ];
  const [checkboxState, setCheckboxState] = useState({
    telemarketingConsentCheck: formData.telemarketingConsentCheck,
  });

  const handleNext = async () => {
    setLoading(true);
    const inputErrors = validateInputs(inputsToValidate, checkboxState);
    if (inputErrors.length > 0 || !checkboxState.telemarketingConsentCheck) {
      setAnimation(true);
      setError(true);
      setLoading(false);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setError(false);

      const phoneNumbers = {
        phoneBorrower: formData.phoneNumber,
      };

      const queryString = qs.stringify(phoneNumbers);
      const { data } = (await API.get(
        `/getServiceUnsecured/validate-phone-number?${queryString}`,
      )) as CheckPhone;

      if (!data?.isValid) {
        setAnimation(true);
        setError(true);
        setErrors([
          {
            field: "phoneNumber",
            message: `Please provide a valid phone number.`,
          },
        ]);
        setLoading(false);
        setTimeout(() => {
          setAnimation(false);
        }, 300);

        return;
      }

      updateLoanStatus({
        loanId: formData.loanId as string,
        body: {
          middleName: formData.middleName,
          suffix: formData.suffix,
          maritalStatus: formData.maritalStatus,
          phoneNumber: formData.phoneNumber,
          isB1Mobile:
            data?.phoneTypeBorrower === "MOBILE" ||
            data?.phoneTypeBorrower === "VOIP",
          telemarketingConsentCheck: checkboxState.telemarketingConsentCheck,
        },
      });
      onNext();
      setLoading(false);
    }
  };

  const onValueChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { id, value } = event.target;
    const field = id as keyof FormData;
    const newFormData = { ...formData };
    const trimmedValue = field === "phoneNumber" ? value.trim() : value;
    (newFormData[field as unknown as keyof FormData] as unknown) =
      trimmedValue as string;
    setFormData(newFormData);
    validateInputs({ field, value }, checkboxState);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCheckboxState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    if (checked) {
      formData.telemarketingConsentCheck = true;
      setError(false);
    }
  };

  const validateInputs = (
    inputs:
      | { field: keyof FormData; value: string }[]
      | { field: keyof FormData; value: string },
    checkbox: { [key: string]: boolean },
  ) => {
    const checks: Errors[] = [
      {
        regex: /^[a-zA-Z]+$/,
        message: "Must contain only letters.",
        success: false,
        field: "firstName",
      },
      {
        regex: /^[a-zA-Z]+$/,
        message: "Must contain only letters.",
        success: false,
        field: "lastName",
      },
      {
        regex: /^$|^[a-zA-Z]+$/,
        message: "Must contain only letters.",
        success: false,
        field: "middleName",
      },
      {
        regex: /^$|^[a-zA-Z]+$/,
        message: "Must contain only letters.",
        success: false,
        field: "suffix",
      },
      {
        regex: /^(Unmarried|Married|Separated)$/,
        message: "Invalid marital status.",
        success: false,
        field: "maritalStatus",
      },
      {
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Email address must be valid.",
        success: false,
        field: "email",
      },
      {
        regex: /^[+\d]{1}[\d\s]{11,15}$/,
        message:
          "A valid mobile phone number counts with 10 to 14 digits and no special characters.",
        success: false,
        field: "phoneNumber",
      },
      {
        regex: /.{9,}/,
        message: "Password must be at least 9 characters.",
        success: false,
        field: "password",
      },
      {
        regex: /\d/,
        message: "Password must contain at least one number.",
        success: false,
        field: "password",
      },
      {
        regex: /[^a-zA-Z0-9]/,
        message: "Password must contain a special character.",
        success: false,
        field: "password",
      },
      {
        regex: /[A-Z]/,
        message: "Password must contain an uppercase letter.",
        success: false,
        field: "password",
      },
      {
        regex: /[a-z]/,
        message: "Password must contain a lowercase letter.",
        success: false,
        field: "password",
      },
    ];

    const validationErrors: Errors[] = [];

    const isArray = Array.isArray(inputs);

    if (isArray) {
      inputs.forEach(({ field, value }) => {
        const check = checks.find((check) => check.field === field);
        const trimmedValue = field === "phoneNumber" ? value.trim() : value;
        if (check && !check.regex?.test(trimmedValue)) {
          validationErrors.push({
            field,
            message: check.message,
            success: false,
          });
        }
      });

      if (!checkbox.telemarketingConsentCheck) {
        validationErrors.push({
          field: "telemarketingConsentCheck",
          message: "You must accept telemarketing consent to continue",
          success: false,
        });
      }
    } else {
      const currentErrors = [...errors];

      const filteredCurrentErrors = currentErrors.filter(
        (error) => error.field !== inputs.field,
      );

      const check = checks.filter((check) => check.field === inputs.field);
      check.forEach((check) => {
        const trimmedValue =
          check.field === "phoneNumber" ? inputs.value.trim() : inputs.value;
        if (!check.regex?.test(trimmedValue)) {
          filteredCurrentErrors.push({
            field: inputs.field,
            message: check.message,
            success: false,
          });
        }
      });

      if (!checkbox.telemarketingConsentCheck) {
        validationErrors.push({
          field: "telemarketingConsentCheck",
          message: "You must accept telemarketing consent to continue",
          success: false,
        });
      }

      validationErrors.push(...filteredCurrentErrors);
    }

    setErrors(validationErrors);
    return validationErrors;
  };

  return {
    error,
    errors,
    animation,
    handleNext,
    onValueChange,
    handleCheckboxChange,
    checkboxState,
    validateInputs,
    loading,
  };
};

export default usePersonalDataValidation;
