import { Box, Typography, Divider } from "@mui/material";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { HBFaqAccordion } from "components/HBFaqAccordion";
import { useLocation } from "react-router-dom";
import { HBTestimonees } from "components/HBTestimonees";
import { HBResources } from "components/HBResources";

export const HBFaqAndResources = () => {
  const location = useLocation();
  const isFaq = location.pathname.includes("faq");
  const isResources = location.pathname.includes("resources");

  const { secondaryText, fourthText } = useHomeBridgeColors();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "auto",
          backgroundColor: fourthText,
          padding: "68px 0",
        }}
      >
        <Box
          width={{ xs: "90%", sm: "80%", md: "80%", lg: "80%" }}
          maxWidth={"880px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          height={"auto"}
          gap={"32px"}
          margin={"0 auto"}
        >
          <Typography
            variant={"body1"}
            color={secondaryText}
            fontSize={{
              xs: "32px",
              sm: "32px",
              xl: "42px",
            }}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            {isFaq && !isResources
              ? "How a Homebridge HELOC works"
              : "Handy HELOC Resources"}
          </Typography>
          <Divider
            sx={{
              width: { xs: "75%", lg: "80%", xl: "85%" },
              height: "2px",
              backgroundColor: secondaryText,
              display: { xs: "none", sm: "block" },
            }}
          ></Divider>
          {isFaq && !isResources ? <HBFaqAccordion /> : <HBResources />}
        </Box>
      </Box>
      <HBTestimonees />
    </>
  );
};
