import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { FC, useState } from "react";
import EditLoanAmount from "screens/TypeOfCreditLine/EditLoanAmount";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import dollarFormatter from "utils/FormatterDollar";
import { useIsHomebridge } from "utils/useIsHomebridge";

const LoanRequest: FC<HELOCFormProps> = ({ handleRrefresh, loan }) => {
  const theme = useTheme();
  const { isHomeBridge } = useIsHomebridge();
  const [editLoanAmountOpen, setEditLoanAmountOpen] = useState(false);
  const property = loan?.property;
  let bpoPending = false;

  if (
    loan?.borrowerSteps?.appraisalVerification?.appraisalType === "BPO" &&
    loan?.borrowerSteps?.appraisalVerification?.status === "analyzing"
  ) {
    bpoPending = true;
  }
  const parsedLoanAmount = dollarFormatter.format(
    loan?.finalOffer?.amount ?? 0,
  );

  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        alignItems="center"
        sx={{
          backgroundColor: isHomeBridge
            ? "#D3E1EA"
            : theme.palette.background.paper,
          p: 2,
        }}
      >
        <Typography
          sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
          maxWidth="220px"
          width="100%"
        >
          Loan Request:
        </Typography>
        {loan?.finalOffer?.amount ? (
          <Box
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-start", md: "center" },
            }}
            display="flex"
            alignItems="center"
            gap={{ xs: 0, md: 1 }}
          >
            <Typography
              sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
              fontFamily="Pro Display Bold"
            >
              {parsedLoanAmount}
            </Typography>
            {!bpoPending && (
              <Typography
                onClick={() => setEditLoanAmountOpen(true)}
                sx={{
                  cursor: "pointer",
                  fontSize: { xs: "1rem", md: "1.2rem" },
                }}
                color="#6767AA"
              >
                Edit
              </Typography>
            )}
          </Box>
        ) : (
          <Skeleton width="75%" height={"1.5rem"} />
        )}
      </Box>

      <EditLoanAmount
        endpoint="/change-offer-amount/final"
        open={editLoanAmountOpen}
        tnMaxOffer={
          // temporary TN validation
          loan?.initialOffer.tnMaxOffer
            ? loan?.initialOffer.tnMaxOffer
            : undefined
        }
        loanId={loan?.id}
        onSubmit={() => {
          if (handleRrefresh) {
            handleRrefresh();
          }
        }}
        onClose={() => {
          setEditLoanAmountOpen(false);
        }}
        state={property?.address?.components?.state_abbreviation}
        setEditLoanAmountOpen={setEditLoanAmountOpen}
      />
    </>
  );
};

export default LoanRequest;
