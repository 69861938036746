import { Box, useTheme } from "@mui/material";
import { FC, Fragment, useState } from "react";
import TermsOfTheLoan from "screens/TypeOfCreditLine/TermsOfTheLoanHELOC";
import LoanRequest from "./LoanRequest";
import InterestRate from "./InterestRate";
import Draw from "./Draw";
import Property from "./Property";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import EstPointsAndFees from "./EstPointsAndFees";
import EstNetProceeds from "./EstNetProceeds";
import { styled } from "@mui/system";
import { useIsHomebridge } from "utils/useIsHomebridge";

const HELOCForm: FC<HELOCFormProps> = (props) => {
  const offer = props;
  const loan = props.loan;
  const theme = useTheme();
  const { isHomeBridge } = useIsHomebridge();

  const [termsOfTheLoan, setTermsOfTheLoan] = useState(false);

  const StyledGridItem = styled(Box)(({ theme }) => ({
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
  }));

  return (
    <Fragment>
      <Box
        bgcolor={isHomeBridge ? "#D3E1EA" : theme.palette.background.paper}
        boxShadow="rgba(0,0,0,0.16) 0px 1px 4px"
        sx={{ borderRadius: "24px" }}
        px={{ xs: 1, md: 3 }}
        py={{ xs: 1, md: 3 }}
        display="grid"
      >
        <StyledGridItem>
          <Property {...offer} />
        </StyledGridItem>
        <StyledGridItem>
          <LoanRequest {...offer} />
        </StyledGridItem>
        <StyledGridItem>
          <InterestRate {...offer} />
        </StyledGridItem>
        <StyledGridItem>
          <Draw {...offer} />
        </StyledGridItem>
        <Box sx={{ width: { xs: "100%", sm: "90%" }, margin: "0 auto" }}>
          <EstPointsAndFees {...offer} />
        </Box>
        <StyledGridItem>
          <EstNetProceeds {...offer} />
        </StyledGridItem>
      </Box>
      <TermsOfTheLoan
        loan={loan}
        open={termsOfTheLoan}
        handleClose={() => setTermsOfTheLoan(false)}
      />
    </Fragment>
  );
};

export default HELOCForm;
