import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import dollarFormatter from "utils/FormatterDollar";
import toPercentage from "utils/toPercentage";
import { useIsHomebridge } from "utils/useIsHomebridge";

const InterestRate: FC<HELOCFormProps> = ({ newValuesSlider }) => {
  const theme = useTheme();
  const { isHomeBridge } = useIsHomebridge();
  const monthlyPaymentInitialDraw = newValuesSlider?.monthlyPaymentInitialDraw;
  const parsedInterestRate = toPercentage(newValuesSlider?.interestRate ?? 0);
  const parsedMonthlyPayment = dollarFormatter.format(
    monthlyPaymentInitialDraw ?? 0,
  );

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      alignItems="center"
      sx={{
        backgroundColor: isHomeBridge
          ? "#D3E1EA"
          : theme.palette.background.paper,
        p: 2,
      }}
    >
      <Typography
        sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
        maxWidth="220px"
        width="100%"
      >
        Interest Rate:
      </Typography>

      {newValuesSlider ? (
        <>
          <Typography
            fontFamily={"Pro Display Bold"}
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              alignItems: { xs: "flex-start", md: "center" },
              fontSize: { xs: "1rem", md: "1.3rem" },
            }}
            gap={{ xs: 0, md: 1 }}
          >
            {parsedInterestRate}{" "}
            <Typography
              sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
              color={
                isHomeBridge
                  ? theme.palette.primary.main
                  : theme.palette.text.disabled
              }
            >
              ({parsedMonthlyPayment} Est. Monthly Payment)
            </Typography>
          </Typography>
        </>
      ) : (
        <Skeleton width="75%" height={"1.5rem"} />
      )}
    </Box>
  );
};

export default InterestRate;
