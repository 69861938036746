import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import dollarFormatter from "utils/FormatterDollar";
import { FormData, HELOCOffer } from "../../useGetStarted";
import { DrawAmount } from "./DrawAmount";
import { CustomText } from "./CustomText";
import { Edit } from "@mui/icons-material";
import { EditAmount } from "./EditAmount";

interface OfferDetailProps {
  loading?: boolean;
  item: HELOCOffer;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  edit: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  getOffer: (refresh?: boolean) => Promise<void>;
}

export const OfferDetail = ({
  loading,
  item,
  formData,
  setFormData,
  edit,
  setEdit,
  getOffer,
}: OfferDetailProps) => {
  const { sizes } = useHomebridgeSizes();
  const minAmountForInitialDraw = 35000;
  const toggleEdit = () => {
    setEdit(!edit);
  };

  return (
    <Box textAlign={"center"}>
      <Box>
        <Typography
          variant="h6"
          fontFamily={"NunitoSans"}
          fontSize={sizes.subtitle}
          fontWeight={500}
        >
          Total HELOC Amount:
        </Typography>

        <Typography
          variant="h4"
          fontFamily="NunitoSans"
          fontSize={sizes.typographyLarge}
          sx={{
            textAlign: "center",
          }}
          fontWeight={900}
        >
          {dollarFormatter.format(
            edit ? item.amount : formData.requestedLoanAmount ?? item.amount,
          )}
          <IconButton
            onClick={toggleEdit}
            sx={{
              padding: 0,
            }}
          >
            <Edit
              htmlColor={"black"}
              sx={{
                fontSize: "1.5rem",
                marginLeft: "5px",
                marginBottom: "5px",
              }}
            />
          </IconButton>
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="h6"
          fontFamily={"NunitoSans"}
          fontSize={sizes.subtitle}
          fontWeight={500}
        >
          Draw Amount:
        </Typography>
        <Typography
          variant="h4"
          fontFamily="NunitoSans"
          fontSize={sizes.typographyLarge}
          fontWeight={900}
          sx={{
            textAlign: "center",
          }}
        >
          {dollarFormatter.format(
            edit ? item.amount : formData.initialDrawAmount ?? item.amount,
          )}
        </Typography>
        {formData?.requestedLoanAmount &&
          formData.requestedLoanAmount > minAmountForInitialDraw && (
            <DrawAmount
              formData={formData}
              setFormData={setFormData}
              disabled={false}
            />
          )}
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          marginLeft: "0",
          marginTop: "0",
        }}
      >
        <Grid container item xs={12} spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <CustomText
              label={"Est. Monthly Payment:"}
              value={dollarFormatter.format(item?.estMonthlyPayment ?? 0) ?? ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomText
              label={"Est. Points and Fees:"}
              value={dollarFormatter.format(item?.estPointsAndFees ?? 0) ?? ""}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <CustomText
              label={"Interest Rate:"}
              value={`${item?.interestRate ?? 0}%`}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomText
              label={"Est. Net Proceeds:"}
              value={
                dollarFormatter.format(
                  formData?.initialDrawAmount
                    ? formData.initialDrawAmount - item?.estPointsAndFees
                    : item.estNetProceeds ?? 0,
                ) ?? ""
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {/* MODALS */}
      <EditAmount
        openModal={edit}
        setOpenModal={setEdit}
        formData={formData}
        setFormData={setFormData}
        item={item}
        loading={loading}
        getOffer={getOffer}
        requestedLoanAmount={formData.requestedLoanAmount}
      />
    </Box>
  );
};
