import Loan from "typedef/Loan";
export type UserType = {
  id?: string;
  email?: string;
  token?: string;
  lastName?: string;
  firstName?: string;
};
const isBorrower = (
  loan: Loan | undefined,
  user: UserType | undefined,
): boolean => Boolean(loan && ((user && user.id === loan.borrowerId) || !user));

export const finalOfferValidations = (
  loan: Loan,
  user: UserType | undefined,
): boolean => {
  const paydownInfo = loan.borrowerSteps?.hardpullVerification?.paydownInfo
    ?.length
    ? loan.borrowerSteps.hardpullVerification.paydownInfo
    : loan.coborrowerSteps?.hardpullVerification?.paydownInfo?.length
      ? loan.coborrowerSteps.hardpullVerification.paydownInfo
      : [];
  let isPaydownAccepted = paydownInfo.length === 0;
  if (
    paydownInfo?.length &&
    loan?.initialOffer?.useProceeds !== "Debt Consolidation"
  ) {
    const confirmedDebts =
      !loan?.coborrowerId ||
      !loan?.coborrowerFlags?.tradelinesEdited ||
      loan?.coborrowerFlags?.debtsCofirmed;
    const payoffs = loan.payoff?.every((payoff) => {
      return payoff?.payoffStatus === "Accepted";
    });
    const tradelines =
      loan.borrowerSteps?.hardpullVerification?.paydownInfo?.concat(
        loan.coborrowerSteps?.hardpullVerification?.paydownInfo ?? [],
      ) ?? [];
    const tradelinesWithCounterpartyOrExternal =
      tradelines.length >= 1
        ? tradelines?.every((tradeline) =>
            Boolean(tradeline?.counterpartyId && tradeline?.receivingAccountId),
          )
        : payoffs;
    isPaydownAccepted =
      (tradelinesWithCounterpartyOrExternal && confirmedDebts) ?? false;
  }
  const debtsValidationB1 = loan?.borrowerFlags?.debtsCofirmed;
  const debtsValidationB2 =
    loan && (loan?.coborrowerId ? loan?.coborrowerFlags?.debtsCofirmed : true);

  const confirmedDebts =
    (debtsValidationB1 &&
      debtsValidationB2 &&
      loan?.initialOffer?.useProceeds === "Debt Consolidation") ||
    (loan?.initialOffer?.useProceeds !== "Debt Consolidation" &&
      isPaydownAccepted);
  const b1Validations = Boolean(
    loan &&
      isBorrower(loan, user) &&
      (!loan?.loanOfficerId ||
        !loan?.brokerId ||
        loan?.borrowerSteps?.brokerDisclosuresVerification?.status ===
          "success") &&
      ((!loan?.borrowerFlags?.finalOfferAccepted &&
        loan?.borrowerSteps?.demographicsVerification?.status === "success" &&
        loan?.borrowerSteps?.insuranceVerification?.status === "success" &&
        loan?.borrowerSteps?.disclosuresVerification?.status === "success" &&
        loan?.borrowerSteps?.incomeVerification?.status === "success" &&
        loan?.borrowerSteps?.creditVerification?.status === "success" &&
        loan?.borrowerSteps?.titleVerification?.status === "success" &&
        loan?.borrowerSteps?.idVerification?.status === "success" &&
        (loan.borrowerSteps.homeValuationVerification.status === "success" ||
          (loan.borrowerSteps.homeValuationVerification.status ===
            "analyzing" &&
            loan.borrowerSteps?.homeValuationVerification?.reportType ===
              "BPO" &&
            loan.borrowerSteps?.homeValuationVerification?.orderId))) ||
        loan?.borrowerFlags?.reAcceptedFinalOffer === false) &&
      confirmedDebts,
  );
  if (loan?.coborrowerId) {
    return (
      b1Validations &&
      Boolean(
        loan &&
          (!loan?.loanOfficerId ||
            !loan?.brokerId ||
            loan?.coborrowerSteps?.brokerDisclosuresVerification?.status ===
              "success") &&
          loan?.coborrowerSteps?.disclosuresVerification?.status ===
            "success" &&
          loan?.coborrowerSteps?.incomeVerification?.status === "success" &&
          loan?.coborrowerSteps?.demographicsVerification?.status ===
            "success" &&
          loan?.coborrowerSteps?.creditVerification?.status === "success" &&
          loan?.coborrowerSteps?.idVerification?.status === "success",
      )
    );
  } else {
    return b1Validations;
  }
};
