import API from "utils/API";
import Loan from "typedef/Loan";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useCallback, useEffect, useRef } from "react";
import Address from "typedef/Address";
import { FieldErrors } from "react-hook-form";
import { DemographicsVerification } from "typedef/BorrowerSteps";
import { LSLoanOfficerToken } from "CONST";
import useUser from "context/UserCustomer/useUserCustomerContext";

export type ValuesSliderProps = {
  minDraw?: number;
  maxDraw?: number;
  showSlider?: boolean;
};

export type ValuesHELOC = {
  APR?: number;
  initialDrawAmount?: number;
  interestRate?: number;
  maximumInitialDraw?: number;
  minimumInitialDraw?: number;
  monthlyInterestCharge?: number;
  monthlyPayment?: number;
  monthlyPaymentInitialDraw?: number;
  netFundingWiredToBank?: number;
  amount?: number;
};

const useDefineLoanType = (loan: Loan, isAdmin?: boolean) => {
  const navigate = useNavigate();
  const params = useParams();
  const currentAdminLoanId = params?.loanId;
  const [loading, setLoading] = useState(true);
  const [isAccepting, setIsAccepting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [primaryHomeAddress, setPrimaryHomeAddress] = useState<Address>();
  const [primaryHomeAddressError, setPrimaryHomeAddressError] =
    useState<FieldErrors>();
  const [equalAddress, setEqualAddress] = useState(false);
  const { refetchLoan } = useUser();

  const loadedInitialOffer = useRef(false);
  const loadedInitialOfferHELOC = useRef(false);

  const [newValuesSlider, setNewValuesSlider] = useState<
    ValuesHELOC | undefined
  >();
  const [initialValuesSliderHELOC, setInitialValuesSliderHELOC] = useState<
    ValuesSliderProps | undefined
  >();

  const [marks, setMarks] = useState<
    {
      value: number;
    }[]
  >([]);

  const getInitialSliderValues = useCallback(
    async (params: {
      loanIdValue: string;
      isAdminValue: boolean;
      currentAdminLoanIdValue: string | undefined;
    }) => {
      setNewValuesSlider(undefined);
      setMarks([]);
      setInitialValuesSliderHELOC(undefined);
      const { loanIdValue, isAdminValue, currentAdminLoanIdValue } = params;
      setLoading(true);
      const response =
        isAdminValue && currentAdminLoanIdValue
          ? await API.get<ValuesSliderProps>(
              `/admin-impersonate/get/get-heloc-slider-values/value?loanId=${currentAdminLoanIdValue}`,
            )
          : await API.get<ValuesSliderProps>(
              `/get/get-heloc-slider-values?loanId=${loanIdValue}`,
            );

      if ("error" in response) {
        console.error(response.error);
      } else {
        setInitialValuesSliderHELOC(response.data);
      }
      setLoading(false);
    },
    [],
  );

  const getInitialCalculateHelocOffer = useCallback(
    async (params: {
      loanIdValue: string;
      maxDrawValue: number;
      isAdminValue: boolean;
      currentAdminLoanIdValue: string | undefined;
    }) => {
      const {
        isAdminValue,
        currentAdminLoanIdValue,
        maxDrawValue,
        loanIdValue,
      } = params;
      setLoading(true);
      let replaceString = "admin";

      const isLoanOfficer = localStorage.getItem(LSLoanOfficerToken);
      if (isLoanOfficer) {
        replaceString = "lo";
      }
      const recalculatedLoanResponse =
        isAdminValue && currentAdminLoanIdValue
          ? await API.post<ValuesHELOC>({
              url: `/admin-impersonate/save-to-loan-${replaceString}/calculate-heloc-offer-initial?ignoreMaxDti=true`,
              data: {
                initialDrawAmount: maxDrawValue,
                loanId: currentAdminLoanIdValue,
              },
            })
          : await API.post<ValuesHELOC>({
              url: `/calculate-heloc-offer/initial?ignoreMaxDti=true`,
              data: {
                initialDrawAmount: maxDrawValue,
                loanId: loanIdValue,
                ignoreSaveOfferHistory: true,
              },
            });

      if ("error" in recalculatedLoanResponse) {
        console.error(recalculatedLoanResponse);
        setShowErrorMessage(true);
        setErrorMessage(recalculatedLoanResponse.error);
      } else {
        setShowErrorMessage(false);
        setErrorMessage("");
        setNewValuesSlider(recalculatedLoanResponse.data);
      }
      setLoading(false);
    },
    [],
  );

  useEffect(() => {
    if (!loadedInitialOffer.current && loan?.id) {
      getInitialSliderValues({
        loanIdValue: loan.id,
        isAdminValue: isAdmin ?? false,
        currentAdminLoanIdValue: currentAdminLoanId,
      });
      loadedInitialOffer.current = true;
    }
  }, [loan, isAdmin, currentAdminLoanId, getInitialSliderValues]);

  useEffect(() => {
    if (
      initialValuesSliderHELOC?.maxDraw &&
      loan?.id &&
      !loadedInitialOfferHELOC.current
    ) {
      getInitialCalculateHelocOffer({
        loanIdValue: loan.id,
        maxDrawValue: initialValuesSliderHELOC?.maxDraw,
        isAdminValue: isAdmin ?? false,
        currentAdminLoanIdValue: currentAdminLoanId,
      });
      loadedInitialOfferHELOC.current = true;
    }
  }, [
    initialValuesSliderHELOC,
    loan?.id,
    isAdmin,
    currentAdminLoanId,
    getInitialCalculateHelocOffer,
  ]);

  useEffect(() => {
    if (loan) {
      const minOffer = initialValuesSliderHELOC?.minDraw ?? 0;
      const maxOffer = initialValuesSliderHELOC?.maxDraw ?? 0;
      const steps = 10.0;
      const jump = (maxOffer - minOffer) / steps;
      const padding = jump * 0.1;
      const paddedMinOffer = minOffer + padding;
      const paddedMaxOffer = maxOffer - padding * 2;
      const paddedJump = (paddedMaxOffer - paddedMinOffer) / steps;

      setMarks(
        Array.from({ length: steps + 1 }).map((_, index) => ({
          value: paddedMinOffer + paddedJump * index,
        })),
      );
    }
    // eslint-disable-next-line
  }, [initialValuesSliderHELOC]);

  const recalculateOffer = async (incomingNewAmount: number | number[]) => {
    if (Array.isArray(incomingNewAmount)) return undefined;
    setLoading(true);
    let initialDrawAmount = incomingNewAmount;
    if (incomingNewAmount > (initialValuesSliderHELOC?.maxDraw ?? 0))
      initialDrawAmount = initialValuesSliderHELOC?.maxDraw ?? 0;
    if (incomingNewAmount < (initialValuesSliderHELOC?.minDraw ?? 0))
      initialDrawAmount = initialValuesSliderHELOC?.minDraw ?? 0;

    const recalculatedLoanResponse = await API.post<ValuesHELOC>({
      url: `/calculate-heloc-offer/initial?ignoreMaxDti=true`,
      data: {
        initialDrawAmount,
        loanId: loan?.id,
        ignoreSaveOfferHistory: true,
      },
    });
    if ("error" in recalculatedLoanResponse) {
      console.error(recalculatedLoanResponse);
      setShowErrorMessage(true);
      setErrorMessage(recalculatedLoanResponse.error);
    } else {
      setShowErrorMessage(false);
      setErrorMessage("");
      setNewValuesSlider(recalculatedLoanResponse?.data);
    }
    setLoading(false);
  };
  const savePrimaryHomeAddress = async (primaryHomeAddress: Address) => {
    await API.post({
      url: `/save-to-loan/save-primary-home-address`,
      data: { address: primaryHomeAddress, loanId: loan?.id },
    });
  };

  const saveOffer = useCallback(
    async (
      loanType: string,
      initialDrawAmount = 0,
      demographics?: DemographicsVerification,
      boConsentedHardpull = false,
    ) => {
      setIsAccepting(true);
      if (primaryHomeAddress) {
        await savePrimaryHomeAddress(primaryHomeAddress);
      }
      const response = await API.post({
        url: `/define-loan-type?ignoreMaxDti=true`,
        data: {
          loanId: loan?.id,
          loanType: loanType,
          ...(loanType === "HELOC" && {
            initialDrawAndProceed:
              initialDrawAmount ??
              newValuesSlider?.initialDrawAmount ??
              initialValuesSliderHELOC?.maxDraw,
          }),
          demographics: demographics,
        },
      });
      if ("error" in response) {
        console.error(response);
        setErrorMessage(response.error);
      } else {
        await API.post({
          url: `/save-to-loan/flags?entity=borrower&loanId=${loan?.id}`,
          data: {
            initialOfferChanged: true,
            boConsentedHardpull: boConsentedHardpull ?? undefined,
          },
        });
        // Refresh loan to get the new values and prevent redirect on tracker
        await refetchLoan();
        navigate(`/borrower-tracker?loanId=${loan?.id}`, {
          replace: true,
        });
      }
      setIsAccepting(false);
    },
    // eslint-disable-next-line
    [
      navigate,
      initialValuesSliderHELOC,
      newValuesSlider,
      loan,
      primaryHomeAddress,
    ],
  );

  return {
    loan,
    marks,
    loading,
    saveOffer,
    isAccepting,
    errorMessage,
    newValuesSlider,
    showErrorMessage,
    initialValuesSliderHELOC,
    recalculateOffer,
    setPrimaryHomeAddres: setPrimaryHomeAddress,
    primaryHomeAddress,
    setPrimaryHomeAddresError: setPrimaryHomeAddressError,
    primaryHomeAddressError,
    setEqualAddress: setEqualAddress,
    equalAddress,
  };
};

export default useDefineLoanType;
