import React, { useEffect, useMemo, useState } from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { documentRequirements, incomeOptions } from "./incomeOptions";
import CDialog from "components/CDialog";
import CTextField from "components/CTextField";
import CButton from "components/CButton";
import UploadDocumentInput from "./UploadDocInput/UploadDocInput";
import BorrowerSteps from "typedef/BorrowerSteps";
import CoborrowerSteps from "typedef/CoborrowerSteps";
import dollarFormatter from "utils/FormatterDollar";
import useIncomeDocUpload, {
  REQUIRED_TAX_RETURN_DOCS,
  OTHER_DOCS,
} from "./useIncomeDocUpload";
import CNumberField from "components/CNumberField";
import useTruvIncome from "../../useTruvIncome";
import TruvBridge from "@truv/react";
import Loan from "typedef/Loan";
import { RentalIncomeFlow } from "../../DebtToIncomeModal/RentalIncomeFlow";

type IncomeDocUploadProps = {
  isAdditional: boolean;
  open: boolean;
  handleClose: () => void;
  isBorrower: boolean;
  step?: BorrowerSteps | CoborrowerSteps;
  incomeSourcesOptions: { label: string; value: string }[];
  loan?: Loan;
};

const IncomeDocUpload: React.FC<IncomeDocUploadProps> = ({
  isAdditional,
  open,
  handleClose,
  isBorrower,
  step,
  incomeSourcesOptions,
  loan,
}) => {
  const incomeTypeLoan = isBorrower
    ? loan?.borrowerEmploymentType
    : loan?.coborrowerEmploymentType;

  const isSelfEmployed = incomeTypeLoan === "Self Employed";

  const {
    control,
    selectedIncomeType,
    handleIncomeSourceType,
    convertToBase64,
    onSubmit,
    availableIncomeOptions,
    moreThanOneIncomeSource,
    optionAmount,
    handleRefresh,
    documents,
    isLoading,
    handleDeleteDocument,
    errors,
    openRentalIncomeFlowModal,
    setOpenRentalIncomeFlowModal,
  } = useIncomeDocUpload({
    step,
    isAdditional,
    open,
    isBorrower,
    handleClose,
    incomeSourcesOptions,
    isSelfEmployed,
  });

  const truv = useTruvIncome();
  const isSalarySelected = selectedIncomeType === "salaryIncome";
  const [openTruv, setOpenTruv] = useState<boolean>(false);
  const [isLoadingTruv, setisLoadingTruv] = useState(false);
  const modalTitle = isAdditional ? "Add Additional Income" : "Verify Income";
  const [uploadDocumentText, setUploadDocumentText] = useState<string>();
  const [isRentalIncome, setIsRentalIncome] = useState(false);
  const [shouldShowRentalModal, setShouldShowRentalModal] = useState(false);
  const [showUploadDocumentsText, setShowUploadDocumentsText] = useState(true);
  const [expectedRentalDocsCount, setExpectedRentalDocsCount] = useState(0);

  useEffect(() => {
    const rentalIncomeStep = isBorrower
      ? loan?.borrowerFlags?.isRentalIncome
      : loan?.coborrowerFlags?.isRentalIncome;

    if (rentalIncomeStep !== undefined) {
      setIsRentalIncome(rentalIncomeStep);
    }
  }, [
    loan,
    isBorrower,
    loan?.borrowerFlags?.isRentalIncome,
    loan?.coborrowerFlags?.isRentalIncome,
  ]);

  const disabledButton = documents?.some(() => {
    const minDocToUpload =
      selectedIncomeType === "selfEmploymentIncome"
        ? REQUIRED_TAX_RETURN_DOCS
        : OTHER_DOCS;

    if (selectedIncomeType === "rentalIncome") {
      return false;
    }

    return (
      documents?.filter((taxReturn) => taxReturn?.file?.length).length <
      minDocToUpload
    );
  });

  const handleRefreshAndClose = () => {
    handleRefresh();
    handleClose();
  };

  const handleOpenTruv = async () => {
    setisLoadingTruv(true);
    const manualUpload = true;
    await truv.getBridgeToken(manualUpload);
    setOpenTruv(true);
    setisLoadingTruv(false);
    handleRefreshAndClose();
  };

  useEffect(() => {
    const rentalIncomeFlag = isBorrower
      ? loan?.borrowerFlags?.rentalIncomeDocumentsSubmitted
      : loan?.coborrowerFlags?.rentalIncomeDocumentsSubmitted;
    if (
      (!rentalIncomeFlag || rentalIncomeFlag === undefined) &&
      isRentalIncome
    ) {
      setShowUploadDocumentsText(true);
    } else {
      setShowUploadDocumentsText(false);
    }

    if (selectedIncomeType === "selfEmploymentIncome") {
      setUploadDocumentText(
        "Please provide 2 years of tax returns (business & personal).",
      );
    } else if (selectedIncomeType === "socialIncome") {
      setUploadDocumentText(
        "Please provide a copy of your award letter, distribution schedule, or other retirement documentation.",
      );
    } else if (selectedIncomeType === "otherIncome") {
      setUploadDocumentText(
        "Common documents include offer letter, contract, benefits statement, EOB, etc.",
      );
    } else if (selectedIncomeType === "rentalIncome") {
      setUploadDocumentText(
        "Another modal will pop-up to upload corresponding files",
      );
    } else {
      setUploadDocumentText(" ");
    }
  }, [
    selectedIncomeType,
    isBorrower,
    loan?.borrowerFlags?.rentalIncomeDocumentsSubmitted,
    loan?.coborrowerFlags?.rentalIncomeDocumentsSubmitted,
    isRentalIncome,
  ]);

  const requiredDocuments = documentRequirements(selectedIncomeType);

  const filteredIncomeOptions = incomeOptions.filter(
    (option) =>
      !(isSelfEmployed && isAdditional && option.value === "salaryIncome"),
  );

  const hasSubmittedPropertyDocuments = useMemo(() => {
    const missingDocs = isBorrower
      ? loan?.borrowerMissingDocs
      : loan?.coborrowerMissingDocs;

    const propertyDocs =
      missingDocs?.incomeVerification?.filter(
        (doc) => doc.docName?.includes(" - Property"),
      ) || [];

    const rentalIncomeDocumentsSubmitted = isBorrower
      ? loan?.borrowerFlags?.rentalIncomeDocumentsSubmitted
      : loan?.coborrowerFlags?.rentalIncomeDocumentsSubmitted;

    if (rentalIncomeDocumentsSubmitted) return true;

    if (expectedRentalDocsCount === 0) return false;

    if (propertyDocs.length < expectedRentalDocsCount) return false;

    return propertyDocs.every((doc) => doc.submitted === true);
  }, [loan, isBorrower, expectedRentalDocsCount]);

  useEffect(() => {
    const readyToShow =
      isRentalIncome &&
      openRentalIncomeFlowModal &&
      !hasSubmittedPropertyDocuments;

    if (readyToShow && !shouldShowRentalModal) {
      setShouldShowRentalModal(true);
    }
  }, [
    isRentalIncome,
    openRentalIncomeFlowModal,
    hasSubmittedPropertyDocuments,
    shouldShowRentalModal,
  ]);

  useEffect(() => {
    if (shouldShowRentalModal && hasSubmittedPropertyDocuments) {
      setShouldShowRentalModal(false);
    }
  }, [shouldShowRentalModal, hasSubmittedPropertyDocuments]);

  return (
    <>
      <CDialog
        title={modalTitle}
        open={open}
        onClose={handleRefreshAndClose}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box
          component="form"
          sx={{ p: { xs: 0, md: 3 }, width: { xs: "100%", md: 480 } }}
        >
          <Controller
            name="incomeType"
            control={control}
            rules={{ required: "Income type is required" }}
            render={({ field, fieldState: { error } }) => (
              <Box mb={1}>
                {isAdditional ? (
                  <CTextField
                    {...field}
                    select
                    fullWidth
                    required
                    sx={{
                      mb: 2,
                      textAlign: "left",
                    }}
                    label="Type of Income"
                    value={selectedIncomeType}
                    onChange={handleIncomeSourceType}
                  >
                    {filteredIncomeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </CTextField>
                ) : (
                  <CTextField
                    {...field}
                    select={moreThanOneIncomeSource}
                    fullWidth
                    required
                    sx={{
                      mb: 2,
                      textAlign: "left",
                    }}
                    label="Type of Income"
                    value={selectedIncomeType}
                    onChange={handleIncomeSourceType}
                    InputProps={{
                      readOnly: !moreThanOneIncomeSource,
                    }}
                  >
                    {availableIncomeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </CTextField>
                )}
                {error && (
                  <Box color="error.main" fontSize="0.75rem" mt={0.5}>
                    {error.message}
                  </Box>
                )}
              </Box>
            )}
          />

          <>
            {!(isAdditional && isSalarySelected) && (
              <Controller
                name="amount"
                control={control}
                rules={{
                  required: "Amount is required",
                  pattern: {
                    value: /^\d{1,9}$/,
                    message: "Please enter a valid number (max 9 digits)",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <Box>
                    {isAdditional ? (
                      <CNumberField
                        {...field}
                        fullWidth
                        required
                        label={`$ Total Annual Compensation - ${
                          isBorrower ? "Primary Borrower" : "Co-Borrower"
                        }`}
                        helperText="This will be verified by uploading documents."
                        placeholder="$200,000"
                        isNumericString
                        thousandSeparator
                        allowNegative={false}
                        allowEmptyFormatting={false}
                        prefix="$ "
                        sx={{ mb: 2, textAlign: "left" }}
                        value={optionAmount}
                      />
                    ) : (
                      <CTextField
                        {...field}
                        fullWidth
                        required
                        sx={{
                          mb: 2,
                          textAlign: "left",
                        }}
                        label={`$ Total Annual Compensation - ${
                          isBorrower ? "Primary Borrower" : "Co-Borrower"
                        }`}
                        value={
                          optionAmount !== undefined
                            ? dollarFormatter.format(optionAmount)
                            : undefined
                        }
                        helperText="This will be verified by uploading documents."
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                    {error && (
                      <Typography color="error.main" fontSize="1rem" mb={2}>
                        {error?.message}
                      </Typography>
                    )}
                  </Box>
                )}
              />
            )}
            <Box mt={2}>
              <>
                {showUploadDocumentsText && (
                  <>
                    <Typography
                      color="text.primary"
                      fontSize="1rem"
                      mb={1}
                      textAlign={"left"}
                    >
                      Upload Files
                    </Typography>

                    <Typography
                      color="text.secondary"
                      fontSize="1rem"
                      mb={1}
                      textAlign={"left"}
                    >
                      {uploadDocumentText}
                    </Typography>
                  </>
                )}
              </>
              {requiredDocuments?.map((requestedDoc, index) => {
                return (
                  <UploadDocumentInput
                    key={`${requestedDoc.name}-${selectedIncomeType}`}
                    documentRequestName={requestedDoc.name}
                    onChange={convertToBase64}
                    handleDelete={handleDeleteDocument}
                    index={index}
                    control={control}
                    selectedDocumentName={documents?.[index]?.documentName}
                    name={`documents.${index}.file`}
                  />
                );
              })}
            </Box>
          </>
          {isSalarySelected && (
            <>
              <Typography
                color="text.secondary"
                fontSize="1rem"
                mb={1}
                textAlign={"left"}
              >
                Please provide your W2 and two recent paystubs.
              </Typography>
              <CButton
                fullWidth
                onClick={handleOpenTruv}
                variant="contained"
                loading={isLoadingTruv}
                name="missingDocsDialog-upload"
              >
                Upload Files
              </CButton>
            </>
          )}
          {errors?.documents ? (
            <Typography color="error.main" fontSize="1rem" mt={2}>
              {errors?.documents?.message}
            </Typography>
          ) : null}
          <Box
            flexDirection={"column"}
            display={"flex"}
            gap={2}
            marginTop={2}
            alignItems={"center"}
          >
            {!isSalarySelected && (
              <CButton
                fullWidth
                onClick={onSubmit}
                variant="contained"
                loading={isLoading}
                name="missingDocsDialog-upload"
                disabled={disabledButton}
              >
                {modalTitle}
              </CButton>
            )}
            <CButton
              type="reset"
              fullWidth
              variant="outlined"
              disabled={isLoading || isLoadingTruv}
              onClick={handleRefreshAndClose}
            >
              Cancel
            </CButton>
          </Box>
        </Box>
      </CDialog>
      <TruvBridge
        isOpened={openTruv}
        onClose={() => setOpenTruv(false)}
        bridgeToken={truv.bridgeToken as string}
        onSuccess={async (public_token: string) => {
          setOpenTruv(false);
          await truv.sendPublicToken(public_token);
        }}
      />
      <RentalIncomeFlow
        open={shouldShowRentalModal}
        onClose={() => {
          setOpenRentalIncomeFlowModal(false);
          setShouldShowRentalModal(false);
        }}
        isBorrower={isBorrower}
        showCancelButton={true}
        onExpectedDocsChange={setExpectedRentalDocsCount}
      />
    </>
  );
};

export default IncomeDocUpload;
