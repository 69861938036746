import { useTheme } from "@mui/material";
import { StyledContainer, StyledTypography } from "./useStyles";

export default function LoanCompleted() {
  const theme = useTheme();

  return (
    <StyledContainer maxWidth="lg">
      <StyledTypography variant="h1">
        <span style={{ color: theme.palette.primary.main }}>404 :(</span>
        <br />
        Page not found!
      </StyledTypography>
    </StyledContainer>
  );
}

export function ErrorLoanOfficerLinkNotFound() {
  const theme = useTheme();

  return (
    <StyledContainer maxWidth="lg">
      <StyledTypography variant="h1" sx={{ padding: 2 }}>
        <span style={{ color: theme.palette.primary.main }}>404 :(</span>
        <br />
        <StyledTypography variant="h3" sx={{ margin: 0 }}>
          This Loan Officer link is invalid.
          <br /> Please, try again with another link or <br />
          contact the Loan Officer.
        </StyledTypography>
      </StyledTypography>
    </StyledContainer>
  );
}
