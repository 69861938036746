import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { FC, useState } from "react";
import ShowDetailsModal from "./showDetailsModal";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import dollarFormatter from "utils/FormatterDollar";
import { useIsHomebridge } from "utils/useIsHomebridge";
import { getLegalVestingfee } from "utils/getLegalAndVesting";

const AMOUNT_OF_DECIMALS = 2;

const EstPointsAndFees: FC<HELOCFormProps> = ({
  loan,
  newValuesSlider,
  fees,
  loading,
}) => {
  const theme = useTheme();
  const { isHomeBridge } = useIsHomebridge();
  const [showDetails, setShowDetails] = useState(false);
  const state = loan?.property.address.components.state_abbreviation;
  const loanFees = loan?.loanFees;

  const originationFee =
    fees?.totalFee && newValuesSlider?.amount
      ? fees?.totalFee * newValuesSlider?.amount
      : undefined;
  const taxes =
    loan?.borrowerSteps?.titleVerification?.countyFees ??
    loan?.borrowerSteps?.titleVerification?.taxFeesAmount;
  const creditReport = Number(loanFees?.feesByLoanStateType?.creditReport ?? 0);
  const complianceDocPrepeNote = Number(
    loanFees?.feesByLoanStateType?.complianceDocPrepeNote ?? 0,
  );
  const AVMInspection = Number(
    loanFees?.feesByLoanStateType?.AVMInspection ?? 0,
  );

  const idVerificationFee = Number(
    loanFees?.feesByLoanStateType?.IDVerification ?? 0,
  );
  const eRecordingFee = Number(
    loanFees?.feesByLoanStateType?.valuesInNotary?.recordingFeeServicelink !==
      undefined
      ? loanFees?.feesByLoanStateType?.valuesInNotary?.recordingFeeServicelink
      : loanFees?.feesByLoanStateType?.valuesInNotary?.recordingFeeSimplifile,
  );
  const legalVestingFee = Number(
    loanFees?.feesByLoanStateType?.valuesInNotary
      ?.propertyReportFeeServicelink ?? getLegalVestingfee(state),
  );

  const taxesValue = Number(taxes ?? 0);

  const details = [
    ["Credit Report", creditReport],
    ["Compliance / Doc Prep / Enote", complianceDocPrepeNote],
    ["Property AVM / Inspection", AVMInspection],
    ["ID Verification", idVerificationFee],
    ["eRecording", eRecordingFee],
    ["Legal and Vesting", legalVestingFee],
    ["Taxes", taxesValue],
  ];

  const estPointsAndFees = Number(
    fees?.closingCosts?.toFixed(AMOUNT_OF_DECIMALS),
  );
  const parsedEstPointsAndFeesAmount = dollarFormatter.format(estPointsAndFees);
  const totalFee = Number(((fees?.totalFee as number) * 100).toFixed(2));

  return (
    <>
      <Box
        display="grid"
        alignItems="center"
        sx={{
          backgroundColor: isHomeBridge
            ? "#D3E1EA"
            : theme.palette.background.paper,
          p: 2,
        }}
        gridTemplateColumns="1fr 1fr"
      >
        <Typography
          sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
          maxWidth="220px"
          width="100%"
        >
          Est. Points and Fees:
        </Typography>
        <Box
          sx={{
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "flex-start", md: "center" },
          }}
          display="flex"
          alignItems="center"
          fontFamily={"Pro Display Bold"}
          gap={{ xs: 0, md: 1 }}
        >
          {(estPointsAndFees || estPointsAndFees === 0) &&
          newValuesSlider?.amount ? (
            <>
              <Typography
                sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
                fontFamily={"Pro Display Bold"}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {Number(originationFee) < 0 ? (
                    <Skeleton
                      variant="text"
                      width="4.5rem"
                      height="1.5rem"
                      sx={{ display: "inline-block" }}
                    />
                  ) : (
                    <span>{parsedEstPointsAndFeesAmount}</span>
                  )}
                </div>
              </Typography>
              <Typography
                onClick={() => setShowDetails(true)}
                sx={{
                  cursor: "pointer",
                  fontSize: { xs: "1rem", md: "1.2rem" },
                  textDecoration: isHomeBridge ? "underline" : "none",
                }}
                color="#6767AA"
                data-cy="showDetailsFees"
              >
                Show details
              </Typography>
            </>
          ) : (
            <Skeleton width="75%" height={"1.5rem"} />
          )}
        </Box>
      </Box>

      {showDetails && (
        <ShowDetailsModal
          openModal={showDetails}
          onClose={() => {
            setShowDetails(false);
          }}
          totalCost={fees?.closingCosts}
          totalFee={totalFee}
          details={details}
          loading={loading}
          originationFee={originationFee}
          state={state}
        />
      )}
    </>
  );
};

export default EstPointsAndFees;
