import React from "react";
import { Box, Typography, useTheme, Skeleton } from "@mui/material";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import dollarFormatter from "utils/FormatterDollar";

interface ShowDetailsModalProps {
  openModal: boolean;
  onClose?: (event?: React.SyntheticEvent, reason?: string) => void;
  totalFee: number | undefined;
  details: (string | number)[][];
  loading: boolean;
  originationFee: number | undefined;
  state?: string;
  totalCost?: number;
}

const ShowDetailsModal: React.FC<ShowDetailsModalProps> = ({
  openModal,
  onClose,
  totalFee,
  details,
  loading,
  originationFee,
  state,
  totalCost,
}) => {
  const theme = useTheme();
  const totalFeePercentaje = totalFee ? totalFee : "2.99";

  return (
    <CDialog open={openModal} onClick={onClose} maxWidth="md" fullWidth>
      <Box display="flex" flexDirection="column">
        <Typography fontSize="25px" fontWeight={600} textAlign={"left"}>
          Estimated Fees
        </Typography>
        <Typography
          fontSize="22px"
          fontWeight={600}
          textAlign={"left"}
          marginTop="25px"
          data-cy="originationFee"
        >
          {`${state !== "MI" ? "Origination Fee" : "Processing Fee"} (${
            totalFeePercentaje ?? 0
          }%)`}
          : {dollarFormatter.format(Number(originationFee ?? 0))}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          marginTop="20px"
          marginBottom="20px"
        >
          <Typography fontSize="20px" fontWeight={600} textAlign="left">
            Fees:
          </Typography>
          {details.map(([title, value]) => (
            <Box
              key={title}
              display="flex"
              justifyContent="space-between"
              alignItems={"flex-start"}
              sx={{ flex: "1 1 0" }}
            >
              <Typography
                fontSize={18}
                color={theme.palette.text.primary}
                sx={{ flex: "1 1 0", textAlign: "left", textWrap: "nowrap" }}
                data-cy="originationFee"
              >
                {title}:
              </Typography>
              <Typography
                fontSize={14}
                color={theme.palette.text.primary}
                fontFamily="Pro Display Regular"
                ml={2}
                sx={{ flex: "1 1 0", textAlign: "right" }}
                data-cy="originationFee"
              >
                {!loading ? (
                  typeof value === "number" ? (
                    dollarFormatter.format(Number(value))
                  ) : (
                    String(value)
                  )
                ) : (
                  <Skeleton
                    width="75%"
                    height={"1.5rem"}
                    sx={{ flex: "1 1 0", textAlign: "right" }}
                  />
                )}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          style={{
            justifyContent: "justify-between",
            display: "flex",
            flexDirection: "column",
            flex: "1 1 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              textAlign: "left",
              textWrap: "nowrap",
              marginTop: "20px",
            }}
          >
            <Typography
              fontSize={18}
              color={theme.palette.text.primary}
              fontFamily="Pro Display Bold"
              sx={{ flex: "1 1 0", textAlign: "left", textWrap: "nowrap" }}
            >
              Total:
            </Typography>
            <Typography
              fontSize={14}
              color={theme.palette.text.primary}
              fontFamily="Pro Display Bold"
              sx={{ flex: "1 1 0", textAlign: "right" }}
            >
              {!loading ? (
                dollarFormatter.format(Number(totalCost))
              ) : (
                <Skeleton
                  width="75%"
                  height={"1.5rem"}
                  sx={{ flex: "1 1 0", textAlign: "right" }}
                />
              )}
            </Typography>
          </Box>
        </Box>
        <CButton
          type="reset"
          variant="outlined"
          fullWidth
          sx={{
            marginTop: 2,
            backgroundColor: theme.palette.primary.main,
            color: "white",
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
          onClick={onClose}
          data-cy="closeDetails"
        >
          Close Details
        </CButton>
      </Box>
    </CDialog>
  );
};

export default ShowDetailsModal;
