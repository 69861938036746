type MetaTags = { [key: string]: { title: string; description: string } };

export const useIsHomebridge = () => {
  const isHomeBridge =
    window.location.hostname.includes("test.hb.nftydoor.com") ||
    window.location.hostname.includes("test.homebridge.com") ||
    window.location.hostname.includes("homebridgecloud.com") ||
    window.location.hostname.includes("homebridge.com");

  //const isHomeBridge = true;

  const metaTags: MetaTags = {
    "/": {
      title: "Home Equity Line of Credit (HELOC) - Homebridge",
      description:
        "Learn how to get a HELOC fast with Homebridge. No paperwork, no delays, no kidding!",
    },
    "/faq": {
      title: "HELOC FAQs - Homebridge",
      description:
        "Learn how Homebridge works, how much equity you can access, and how fast you can close on a HELOC.",
    },
    "/resources": {
      title: "HELOC Resources - Homebridge",
      description:
        "Learn more about the benefits of getting a HELOC with Homebridge. Apply today.",
    },
    "/resources/what-is-home-equity": {
      title: "What Is Home Equity, How Does It Work?",
      description:
        "Learn what home equity is, how to use it, and explore home equity loans along with refinancing options.",
    },
    "/resources/what-is-a-heloc": {
      title: "What is a HELOC and How Does It Work?",
      description:
        "Learn what a HELOC is and how you can use it for expenses for your home.",
    },
    "/resources/is-a-heloc-a-good-idea": {
      title: "Is a HELOC a Good Idea?",
      description: "How to Get a HELOC (Home Equity Line of Credit)",
    },
    "/resources/how-to-get-a-heloc": {
      title: "How to Get a HELOC (Home Equity Line of Credit)",
      description:
        "Learn what home equity is, how to use it, and explore home equity loans along with refinancing options.",
    },
    "/borrower-tracker": {
      title: "HELOC Application - Homebridge",
      description:
        "Apply for a HELOC online with Homebridge today! Get started in just two minutes.",
    },
    "/get-started": {
      title: "HELOC Application - Homebridge",
      description:
        "Apply for a HELOC online with Homebridge today! Get started in just two minutes.",
    },
    "/resources/how-to-use-home-equity": {
      title: "HELOC Application - Homebridge",
      description:
        "Apply for a HELOC online with Homebridge today! Get started in just two minutes.",
    },
  };

  return {
    isHomeBridge,
    metaTags,
  };
};
