import API from "utils/API";
import Loan from "typedef/Loan";
import { useNavigate } from "react-router-dom";
import { useState, useCallback } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { useQuery } from "react-query";

export type Marks = Loan["initialOffer"][];

const useHeloanOffer = () => {
  const navigate = useNavigate();
  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(true);
  const [marks, setMarks] = useState<Marks>([]);
  const [isAccepting, setIsAccepting] = useState(false);
  const [markSelectIndex, setMarkSelectIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { selectedLoanId } = useUser();

  const saveOffer = useCallback(async () => {
    setIsAccepting(true);
    const response = await API.post({
      url: `/accept-offer/final`,
      data: { amount: marks[markSelectIndex].amount, loanId: loan?.id },
    });
    if ("error" in response) {
      console.error(response);
      setErrorMessage(response.error);
      setShowErrorMessage(true);
    } else {
      navigate(`/borrower-tracker?loanId=${selectedLoanId}`, { replace: true });
    }
    setIsAccepting(false);
  }, [navigate, marks, markSelectIndex, loan?.id, selectedLoanId]);

  setShowErrorMessage(false);
  const {
    data: loanData,
    error: loanError,
    isError: isLoanError,
    isLoading: isLoanLoading,
    isFetched: isLoanFetched,
  } = useQuery<Loan>({
    queryKey: ["Loan", selectedLoanId],
    enabled: !!selectedLoanId,
  });
  setLoading(isLoanLoading);
  const {
    data: offerData,
    error: offerError,
    isError: isOfferError,
    isFetched: isOfferFetched,
  } = useQuery<Marks>({
    queryKey: ["Loan", loan?.id],
    enabled: !!loan?.id,
  });

  if (isLoanFetched && isOfferFetched) {
    setShowErrorMessage(isLoanError);
    if (isLoanError) {
      setShowErrorMessage(true);
      setErrorMessage(loanError as string);
    } else {
      setLoan(loanData);
    }
    if (isOfferError) {
      setShowErrorMessage(true);
      setErrorMessage(offerError as string);
    } else {
      setMarks(offerData || []);
    }
  }

  return {
    loan,
    loading,
    saveOffer,
    isAccepting,
    errorMessage,
    showErrorMessage,
    marks,
    markSelectIndex,
    setMarkSelectIndex,
  };
};

export default useHeloanOffer;
