import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import CDialog from "components/CDialog";
import CButton from "components/CButton";
import API from "utils/API";
import useLoan from "components/CTracker/useLoan";
import { Buffer } from "buffer";
import "./FilesInput.scss";

export const RentalIncomeFlow = ({
  open,
  onClose,
  isBorrower,
  showCancelButton = true,
  onExpectedDocsChange,
}: {
  open: boolean;
  onClose: () => void;
  isBorrower: boolean;
  showCancelButton?: boolean;
  onExpectedDocsChange: (count: number) => void;
}) => {
  const { loan, refetch } = useLoan();
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string;
  }>({});
  const [currentStep, setCurrentStep] = useState("initial");
  const [uploadedDocuments, setUploadedDocuments] = useState<
    {
      docName: string;
      propertyIndex: number;
      file: File;
      fileType: string;
      fileExt: string;
    }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState<string>();
  const [errorDocuments, setErrorDocuments] = useState<string[]>();
  const [selectedFileNames, setSelectedFileNames] = useState<{
    [key: string]: string;
  }>({});
  const user = isBorrower ? "borrower" : "coborrower";

  const loanId = loan?.id;

  let rentalProperties = 0;
  const borrowerRental =
    loan?.borrowerSteps?.incomeVerification?.rentalProperties;
  const coborrowerRental =
    loan?.coborrowerSteps?.incomeVerification?.rentalProperties;

  if (borrowerRental || coborrowerRental) {
    rentalProperties = isBorrower
      ? parseInt(borrowerRental ?? "0", 10)
      : parseInt(coborrowerRental ?? "0", 10);
  }

  const steps = [
    {
      id: "initial",
      question:
        "Did you report your rental income on your most recent tax return?",
      nextYes: "mortgage",
      nextNo: "leaseAgreements",
    },
    {
      id: "mortgage",
      question: "Is there a current mortgage on all rental properties?",
      nextYes: "escrowed",
      nextNo: "noMortgageDocs",
    },
    {
      id: "escrowed",
      question: "Are taxes and insurance escrowed?",
      nextYes: "uploadMortgage",
      nextNo: "uploadMortgageAndTaxes",
    },
    {
      id: "leaseAgreements",
      question: "Do you have lease agreements to support rental income?",
      nextYes: "leaseEscrowed",
      nextNo: "noLeaseDocs",
    },
    {
      id: "leaseEscrowed",
      question: "Are taxes and insurance escrowed?",
      nextYes: "uploadLeaseAndMortgage",
      nextNo: "uploadAllDocs",
    },
  ];

  const handleSelection = (stepId: string, value: string) => {
    setErrorSubmit(undefined);
    setErrorDocuments(undefined);
    const newSelectedOptions: { [key: string]: string } = {
      ...selectedOptions,
    };

    let reset = false;
    for (const step of steps) {
      if (reset) {
        delete newSelectedOptions[step.id];
      }
      if (step.id === stepId) {
        newSelectedOptions[step.id] = value;
        reset = true;
      }
    }

    setSelectedOptions(newSelectedOptions);

    const nextStep = steps.find((step) => step.id === stepId);
    if (nextStep) {
      setCurrentStep(value === "yes" ? nextStep.nextYes : nextStep.nextNo);
    }
  };

  const getRequiredDocuments = useCallback(() => {
    if (currentStep === "uploadMortgage") return ["Current Mortgage Statement"];
    if (currentStep === "uploadMortgageAndTaxes")
      return [
        "Current Mortgage Statement",
        "Current Tax Bill",
        "Current HOA Statement",
        "Current Insurance",
      ];
    if (currentStep === "uploadLeaseAndMortgage")
      return ["Current Mortgage Statement", "Current Lease Agreements"];
    if (currentStep === "uploadAllDocs")
      return [
        "Current Mortgage Statement",
        "Current Tax Bill",
        "Current HOA Statement",
        "Current Insurance",
        "Current Lease Agreements",
      ];
    if (currentStep === "noLeaseDocs") return [];
    if (currentStep === "noMortgageDocs")
      return ["Current Tax Bill", "Current Insurance", "Current HOA Statement"];
    return [];
  }, [currentStep]);

  const handleFileChange = (
    docName: string,
    propertyIndex: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      setUploadedDocuments((prev) => [
        ...prev.filter(
          (doc) =>
            !(doc.docName === docName && doc.propertyIndex === propertyIndex),
        ),
        {
          docName,
          propertyIndex,
          file,
          fileType: file.type,
          fileExt: file.name.split(".").pop() || "",
        },
      ]);
    }
  };

  const validateUploadedDocuments = () => {
    const requiredDocs = getRequiredDocuments().filter(
      (doc) => doc !== "Current HOA Statement",
    );

    const missingDocs: string[] = [];

    for (let i = 0; i < rentalProperties; i++) {
      for (const doc of requiredDocs) {
        if (
          !uploadedDocuments.some(
            (file) => file.docName === doc && file.propertyIndex === i,
          )
        ) {
          missingDocs.push(`${doc} for Property ${i + 1}`);
        }
      }
    }

    if (missingDocs.length > 0) {
      setErrorDocuments(missingDocs);
      setLoading(false);
      return false;
    }

    return true;
  };

  const uploadDocuments = async () => {
    setLoading(true);
    setErrorDocuments(undefined);
    if (currentStep !== "noLeaseDocs") {
      if (!validateUploadedDocuments()) return;

      for (const doc of uploadedDocuments) {
        const docNameWithProperty = `${doc.docName} - Property ${
          doc.propertyIndex + 1
        }`;
        const isCurrentBorrower = isBorrower
          ? loan?.borrowerId
          : loan?.coborrowerId;

        const document = {
          loanId: loanId,
          fileType: doc.fileType,
          userId: isCurrentBorrower,
          extension: doc.fileExt,
          docOption: docNameWithProperty,
          step: "incomeVerification",
          privateLabelId: loan?.privateLabelId,
        };

        await API.post({
          url: "/save-to-loan/request-tax-return",
          data: {
            loanId: loanId,
            isBorrower,
            docsList: [
              {
                documentName: docNameWithProperty,
                step: "incomeVerification",
                category: "Income",
                isVisible: true,
                templateId: "",
                label: `${doc.docName} ${
                  isBorrower ? "Borrower" : "Co-borrower"
                }`,
                value: `${doc.docName} ${
                  isBorrower ? "Borrower" : "Co-borrower"
                }`,
              },
            ],
          },
        });

        const result = (await API.post({
          url: "/upload-docs-borrower/get-presignedurl",
          data: document,
        })) as {
          error?: string;
          data?: { presignedUrl: string; docKey: string; documentName: string };
        };

        if ("error" in result) {
          setErrorSubmit(result.error);
          setLoading(false);
          return;
        }

        const presignedUrl = result?.data?.presignedUrl as string;
        const docKey = result?.data?.docKey;

        await new Promise<void>((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(doc.file);

          fileReader.onloadend = async () => {
            try {
              const base64Data = (fileReader.result as string).split(",")[1];
              const buffer = Buffer.from(base64Data, "base64");

              const response = await fetch(presignedUrl, {
                method: "PUT",
                headers: {
                  "Content-Type": doc.fileType,
                },
                body: buffer,
              });

              if (response.status >= 300) {
                setErrorSubmit(`Error uploading file`);
                reject();
                return;
              }

              await API.post({
                url: "/upload-docs-borrower/confirm-document-save",
                data: {
                  loanId: loanId,
                  userId: isCurrentBorrower,
                  docKey,
                  docOption: {
                    docName: docNameWithProperty,
                  },
                  step: "incomeVerification",
                  uploaderId: isCurrentBorrower,
                  privateLabelId: loan?.privateLabelId,
                },
              });

              resolve();
            } catch (err) {
              setErrorSubmit("Unexpected error");
              reject(err);
            }
          };

          fileReader.onerror = () => {
            setErrorSubmit("File reading failed");
            reject();
          };
        });
      }
    }
    await API.post({
      url: `/save-to-loan/flags?entity=${user}&loanId=${loanId}`,
      data: {
        rentalIncomeDocumentsSubmitted: true,
      },
    });

    await refetch();
    await refetch();
    setLoading(false);
    onClose();
  };

  const handleCancel = async () => {
    await API.post({
      url: `/save-to-loan/flags?entity=${user}&loanId=${loanId}`,
      data: {
        rentalIncomeDocumentsSubmitted: null,
      },
    });

    onClose();
  };

  useEffect(() => {
    const expectedDocsCount =
      getRequiredDocuments().filter((doc) => doc !== "Current HOA Statement")
        .length * rentalProperties;

    onExpectedDocsChange(expectedDocsCount);
  }, [
    currentStep,
    rentalProperties,
    getRequiredDocuments,
    onExpectedDocsChange,
  ]);

  return (
    <CDialog open={open} title="Rental Income">
      <Box sx={{ p: 2, maxWidth: "400px", textAlign: "left" }}>
        {steps.map((step) =>
          selectedOptions[step.id] !== undefined || step.id === currentStep ? (
            <Box key={step.id} sx={{ mb: 3 }}>
              <Typography sx={{ fontSize: "18px", fontWeight: 400, mb: 1 }}>
                {step.question}
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  value={selectedOptions[step.id] || ""}
                  onChange={(e) => handleSelection(step.id, e.target.value)}
                >
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          ) : null,
        )}

        {getRequiredDocuments().length > 0 && (
          <Box sx={{ mt: 3 }}>
            {Array.from({ length: rentalProperties }, (_, i) => (
              <Box key={i} sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: "18px", fontWeight: 600, mb: 1 }}>
                  Property {i + 1}
                </Typography>
                {getRequiredDocuments().map((doc, index) => (
                  <Box key={index}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 200,
                        color: "#808080",
                        marginBottom: "16px",
                      }}
                    >
                      Please provide {doc}
                    </Typography>
                    <div className="custom-file-upload">
                      <span className="file-name">
                        {selectedFileNames[`${doc}_${i}`] || "Select file"}
                      </span>
                      <button className="browse-btn">Browse</button>
                      <input
                        type="file"
                        className="file-input"
                        onChange={(e) => {
                          const fileName = e.target.files?.[0]?.name || "";
                          const key = `${doc}_${i}`;
                          setSelectedFileNames((prev) => ({
                            ...prev,
                            [key]: fileName,
                          }));
                          handleFileChange(doc, i, e);
                        }}
                      />
                    </div>
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        )}

        {errorDocuments && (
          <>
            <Typography sx={{ color: "#D92D20", mt: 2 }}>
              Missing required documents:
            </Typography>
            {errorDocuments.map((doc, index) => (
              <Typography key={index} sx={{ color: "#D92D20", mt: 2 }}>
                - {doc}
              </Typography>
            ))}
          </>
        )}
        {errorSubmit && (
          <Typography color="error.main" sx={{ mt: 2 }}>
            There was an error: {errorSubmit}
          </Typography>
        )}

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <CButton
            variant="outlined"
            onClick={handleCancel}
            style={{ visibility: showCancelButton ? "visible" : "hidden" }}
          >
            Cancel
          </CButton>
          <CButton
            variant="contained"
            onClick={() => uploadDocuments()}
            disabled={
              loading ||
              (getRequiredDocuments().length === 0 &&
                currentStep !== "noLeaseDocs")
            }
          >
            Confirm
          </CButton>
        </Box>
      </Box>
    </CDialog>
  );
};
