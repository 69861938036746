import React from "react";
import { Typography, Box } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { DeleteOutlineRounded } from "@mui/icons-material";

const UploadDocumentInput = ({
  documentRequestName,
  onChange,
  selectedDocumentName,
  name,
  control,
  index,
  handleDelete,
}: {
  documentRequestName: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handleDelete: (index: number) => void;
  selectedDocumentName?: string;
  name: string;
  control: Control;
  index: number;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return selectedDocumentName ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "primary.main",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Typography>{selectedDocumentName}</Typography>
            <DeleteOutlineRounded
              onClick={() => handleDelete(index)}
              sx={{ fontSize: "1.1rem", cursor: "pointer" }}
            />
          </Box>
        ) : (
          <Box mb={1}>
            <input
              {...field}
              type="file"
              id={`upload-document`}
              style={{ display: "none" }}
              onChange={(e) => onChange(e, index)}
              value={""}
            />
            <label
              htmlFor="upload-document"
              style={{
                border: "1px solid #d8d6de",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "6px",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
                gap={2}
              >
                <Typography fontSize={"1rem"} color={"text.secondary"}>
                  {documentRequestName}
                </Typography>
              </Box>
              <Box
                style={{
                  background: "white",
                  padding: "8px 35px",
                  border: "0px",
                  borderLeft: "1px solid #d8d6de",
                  color: "#5E5873",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <Typography fontSize="1rem">Browse</Typography>
              </Box>
            </label>
            {error ? (
              <Typography color="error.main" fontSize="1rem" mt={2}>
                {error?.message}
              </Typography>
            ) : null}
          </Box>
        );
      }}
    />
  );
};

export default UploadDocumentInput;
