import { useState } from "react";
import Address from "typedef/Address";
import CButton from "components/CButton";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import CAddressAutocomplete from "components/CAddressAutocomplete";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";

export default function CHow() {
  const navigate = useNavigate();
  const location = useLocation();
  const [address, setAddress] = useState<Address | null>(null);
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ backgroundColor: theme.palette.primary.main }}
    >
      <Container maxWidth="lg">
        <Box
          p={{ md: 8, xs: 0 }}
          py={{ md: 8, xs: 4 }}
          color={theme.palette.background.paper}
        >
          <Typography
            variant="h3"
            mb={{ md: 3, xs: 0 }}
            fontSize={{ xs: "2rem", md: "3rem" }}
            role="heading"
            aria-level={4}
          >
            How does it work?
          </Typography>
          <List sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}>
            <ListItem disableGutters alignItems="flex-start">
              <Box
                mr={1}
                mt={0.4}
                display="inline"
                fontFamily="Pro Display Semibold"
              >
                1.
              </Box>
              <Typography
                role="heading"
                aria-level={2}
                data-acsb-fake-title={true}
                sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}
              >
                <span style={{ fontFamily: "Pro Display Semibold" }}>
                  {privateLabel?.borrowerPortalConfiguration
                    ?.customHomePageContent?.howDoesItWorkSteps?.step1?.title ??
                    "Apply"}
                </span>{" "}
                -{" "}
                {privateLabel?.borrowerPortalConfiguration
                  ?.customHomePageContent?.howDoesItWorkSteps?.step1
                  ?.description ??
                  "Complete a 1-minute application to get an instant pre-qualification."}
              </Typography>
            </ListItem>
            <ListItem disableGutters alignItems="flex-start">
              <Box
                mr={1}
                mt={0.4}
                display="inline"
                fontFamily="Pro Display Semibold"
              >
                2.
              </Box>
              <Typography sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}>
                <span style={{ fontFamily: "Pro Display Semibold" }}>
                  {privateLabel?.borrowerPortalConfiguration
                    ?.customHomePageContent?.howDoesItWorkSteps?.step2?.title ??
                    "Choose"}
                </span>{" "}
                -{" "}
                {privateLabel?.borrowerPortalConfiguration
                  ?.customHomePageContent?.howDoesItWorkSteps?.step2
                  ?.description ??
                  "Choose how much you want as your initial draw."}
              </Typography>
            </ListItem>
            <ListItem disableGutters alignItems="flex-start">
              <Box
                mr={1}
                mt={0.4}
                display="inline"
                fontFamily="Pro Display Semibold"
              >
                3.
              </Box>
              <Typography sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}>
                <span style={{ fontFamily: "Pro Display Semibold" }}>
                  {privateLabel?.borrowerPortalConfiguration
                    ?.customHomePageContent?.howDoesItWorkSteps?.step3?.title ??
                    "Close"}
                </span>{" "}
                -{" "}
                {privateLabel?.borrowerPortalConfiguration
                  ?.customHomePageContent?.howDoesItWorkSteps?.step3
                  ?.description ??
                  "Schedule your online notary closing, and then we fund 3 days later.*"}
              </Typography>
            </ListItem>
          </List>
          {privateLabel?.hasOrganicFlow && (
            <>
              <CAddressAutocomplete
                sx={{ paddingBottom: { sm: "1rem" }, mt: 1 }}
                address={address}
                placeholder="Start typing your home address and please select from the dropdown."
                setAddress={setAddress}
                noLabel
                InputProps={{
                  endAdornment: (
                    <CButton
                      variant="contained"
                      onClick={() =>
                        navigate(`/signup${location.search}`, {
                          state: address,
                          replace: true,
                        })
                      }
                      sx={{
                        p: 1,
                        px: 3,
                        width: "15rem",
                        fontSize: "1rem",
                        display: { xs: "none", sm: "block", md: "block" },
                      }}
                    >
                      Check My Rate
                    </CButton>
                  ),
                }}
              />
              <Box mt={2} display={{ xs: "block", sm: "none", md: "none" }}>
                <CButton
                  fullWidth
                  variant="outlined"
                  aria-hidden="true"
                  data-acsb-hidden="true"
                  onClick={() =>
                    navigate(`/signup${location.search}`, {
                      state: address,
                      replace: true,
                    })
                  }
                >
                  Check My Rate
                </CButton>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Container>
  );
}
