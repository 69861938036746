import { Box } from "@mui/material";
import { useState } from "react";

import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { PropertyProps } from "../Property";
import { useUpdateLoanStatus } from "screens/GetStarted/useUpdateLoanStatus";
import { CreditScoreInputs } from "./CreditScoreInputs";

export const CreditScore = ({
  onNext,
  onPrev,
  formData,
  setFormData,
}: PropertyProps) => {
  const { puUpdateLoanStatus } = useUpdateLoanStatus();

  const [errorMessage, setErrorMessage] = useState(false);
  const [animation, setAnimation] = useState(false);

  const handleNext = () => {
    if (!formData.creditScore) {
      setAnimation(true);
      setErrorMessage(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      setErrorMessage(false);
      puUpdateLoanStatus({
        body: {
          creditScore: formData.creditScore,
        },
      });
      onNext();
    }
  };

  return (
    <div>
      <div className="get-started__wrapper get-started__credit-score">
        <Box width={"100%"}>
          <CreditScoreInputs
            animation={animation}
            errorMessage={errorMessage}
            formData={formData}
            setErrorMessage={setErrorMessage}
            setFormData={setFormData}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                sm: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              marginTop: "40px",
            }}
          >
            <CHBButton onClick={onPrev} secondaryArrow={true}>
              Go Back
            </CHBButton>
            <CHBButton
              onClick={handleNext}
              className="animated-arrow-button"
              ctaId="Step4CreditScore"
            >
              Next
            </CHBButton>
          </Box>
        </Box>
      </div>
      <HBSeparate className="step-separate" type="vertical" />
    </div>
  );
};
