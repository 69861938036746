export function getLegalVestingfee(stateCode: string | undefined) {
  if (!stateCode) return 95;
  if (stateCode === "MD") {
    return 70;
  } else if (stateCode === "DC") {
    return 95;
  } else if (stateCode === "VA") {
    return 75;
  } else if (stateCode === "FL") {
    return 65;
  } else if (stateCode === "TX") {
    return 0;
  } else {
    return 70;
  }
}
