import { Fragment, useState } from "react";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import { useForm } from "react-hook-form";
import CTextField from "components/CTextField";
import CoborrowerInvite from "../CoborrowerInvite";
import useNPEAnnouncement from "./useNPEAnnouncement";
import useTracker from "components/CTracker/useTrackerContext";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import useLoan from "context/Invite/useLoan";
import API from "utils/API";

export type NPEForm = {
  email: string;
};

type ErrorBody = {
  isNFTYError: boolean;
  message: string;
  status: number;
};

type NPEResponse = {
  data: {
    body: ErrorBody;
    headers: {
      "Access-Control-Allow-Headers": string;
      "Access-Control-Allow-Origin": string;
      "Access-Control-Allow-Methods": string;
    };
    statusCode: number;
  };
  status: number;
};

const NPEAnnouncement = () => {
  const form = useForm<NPEForm>();
  const { property } = useTracker();
  const npeAnnouncement = useNPEAnnouncement();
  const theme = useTheme();
  const isUniqueExtensions = (value: string) => {
    const extensions = value?.match(/\.[a-zA-Z]+/g) || [];
    const uniqueExtensions = Array.from(new Set(extensions));
    return extensions.length === uniqueExtensions.length;
  };

  const [showOptions, setShowOptions] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReasonText, setOtherReasonText] = useState("");
  const [loading, setLoading] = useState(false);
  const [defaultError, setDefaultError] = useState("");
  const loan = useLoan();
  const MAX_CHARACTERS = 140;

  const handlePersonCannotSign = () => {
    setShowOptions(true);
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setSelectedReason(selectedValue);
    if (selectedValue !== "other") {
      setOtherReasonText("");
    }
  };
  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;
    if (text.length <= MAX_CHARACTERS) {
      setOtherReasonText(text);
    }
  };
  const handleSubmitPersonCannotSign = async () => {
    setLoading(true);
    let response;
    switch (selectedReason) {
      case "deceased": {
        response = (await API.post({
          url: "/save-to-loan/request-tax-return",
          data: {
            loanId: loan?.id,
            isBorrower: true,
            docsList: [
              {
                category: "Title",
                documentName: "Death Certificate",
                isVisible: true,
                label: "Death Certificate",
                step: "titleVerification",
                templateId: "d-4a0995b946874fa19efdb9fe77fcf31b",
                value: "Death Certificate",
              },
            ],
          },
        })) as NPEResponse;
        break;
      }
      case "minor": {
        response = (await API.post({
          url: "/save-to-loan/request-tax-return",
          data: {
            loanId: loan?.id,
            isBorrower: true,
            docsList: [
              {
                category: "Income",
                documentName: "Birth Certificate",
                isVisible: true,
                label: "Birth Certificate Borrower",
                step: "incomeVerification",
                templateId: "",
                value: "Birth Certificate Borrower",
              },
            ],
          },
        })) as NPEResponse;
        break;
      }
      case "other": {
        response = (await API.post({
          url: "/save-to-loan/create-note-and-message",
          data: {
            loanId: loan?.id,
            note: otherReasonText,
            email: loan?.borrowerEmail,
            optionSelected: "other",
            isFromNPE: true,
          },
        })) as NPEResponse;
        break;
      }
      default: {
        setDefaultError("Not valid option");
      }
    }

    if (
      response?.data?.statusCode !== 400 &&
      response?.data?.statusCode !== 401
    ) {
      npeAnnouncement.setOpenNPEForm(false);
      npeAnnouncement.setIsModalOpen(false);
      await API.post({
        url: `/save-to-loan/flags?entity=borrower&loanId=${loan?.id}`,
        data: {
          offeredAddingNPEAsCoborrower: true,
        },
      });
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <CDialog
        maxWidth="xs"
        icon="warning"
        title={`We've noticed ${property?.NPE} as another owner on your property`}
        description="Will you be adding this person as a co-borrower?"
        open={npeAnnouncement.openAnnouncement}
      >
        <CButton
          fullWidth
          data-cy="opcionYes"
          onClick={npeAnnouncement.handleAccept}
          variant="contained"
          sx={{ marginBottom: 3, marginTop: 1 }}
          name="npeAnnouncementDialog-yes"
        >
          Yes
        </CButton>
        <CButton
          fullWidth
          data-cy="opcionNo"
          onClick={npeAnnouncement.handleSelectNo}
          variant="outlined"
          name="npeAnnouncementDialog-no"
        >
          No
        </CButton>
      </CDialog>

      <CDialog
        maxWidth="xs"
        icon="warning"
        title={`Should you qualify for a loan, we will need to add this person as a non borrowing spouse and would need them to sign`}
        description={!showOptions ? "Please enter their email below" : ""}
        open={npeAnnouncement.openNPEForm}
      >
        <form
          onSubmit={form.handleSubmit((data) => npeAnnouncement.addNPE(data))}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {!showOptions && (
            <>
              <CTextField
                error={
                  form.formState.errors.email?.message ||
                  npeAnnouncement.emailError
                }
                helperText={
                  form.formState.errors.email?.message ||
                  npeAnnouncement.emailError
                }
                {...form.register("email", {
                  required: {
                    value: true,
                    message: "Email is required.",
                  },
                  minLength: {
                    value: 2,
                    message: "The Email must be at least 2 characters long.",
                  },
                  maxLength: {
                    value: 100,
                    message: "The Email cannot be longer than 100 characters.",
                  },
                  pattern: {
                    value:
                      /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,40})+(@([a-zA-Z0-9-]{2,20}))+(?:\.[a-zA-Z0]{2,3})*$/i,
                    message: "The email format is incorrect.",
                  },
                  validate: (value) =>
                    isUniqueExtensions(value) ||
                    "The email format is incorrect.",
                })}
                sx={{ marginTop: 1 }}
                label={"Second owner's email"}
                onChange={() => npeAnnouncement.setEmailError(undefined)}
              />

              <CButton
                sx={{ marginBottom: 3, marginTop: 3 }}
                fullWidth
                type="submit"
                loading={form.formState.isSubmitting}
                disabled={loading}
                variant="contained"
                name="npeAnnouncementDialog-add"
              >
                Add
              </CButton>
            </>
          )}

          <CButton
            fullWidth
            variant="contained"
            sx={{ marginBottom: 3, marginTop: 1 }}
            name="jointAccountAnnouncement-cannotSign"
            disabled={loading}
            onClick={handlePersonCannotSign}
          >
            This person cannot sign
          </CButton>
          <div>
            {showOptions && (
              <>
                <Typography
                  sx={{ marginBottom: 1, marginTop: 3, fontSize: "1rem" }}
                  fontFamily={"Pro Display Bold"}
                >
                  Why can't this person sign?
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.text.secondary,
                    marginBottom: 3,
                  }}
                >
                  Please provide more details
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedReason === "deceased"}
                        onChange={handleOptionChange}
                        value="deceased"
                      />
                    }
                    label="Named person is deceased"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedReason === "minor"}
                        onChange={handleOptionChange}
                        value="minor"
                      />
                    }
                    label="Named person is a minor"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedReason === "other"}
                        onChange={handleOptionChange}
                        value="other"
                      />
                    }
                    label="Other"
                  />
                  {selectedReason === "other" && (
                    <TextField
                      placeholder="Please provide a reason why this person can't sign."
                      multiline
                      rows={3}
                      value={otherReasonText}
                      onChange={handleTextChange}
                      variant="outlined"
                      helperText={`${
                        MAX_CHARACTERS - otherReasonText.length
                      } characters remaining`}
                      fullWidth
                    />
                  )}
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.text.secondary,
                      marginBottom: 3,
                    }}
                  >
                    {defaultError}
                  </Typography>
                  <CButton
                    fullWidth
                    onClick={handleSubmitPersonCannotSign}
                    variant="contained"
                    sx={{ marginBottom: 2, marginTop: 3 }}
                    name="jointAccountAnnouncement-submit"
                    disabled={!selectedReason || loading}
                  >
                    {loading ? (
                      <CircularProgress size={32} sx={{ color: "light" }} />
                    ) : (
                      "Submit"
                    )}
                  </CButton>
                </FormGroup>
              </>
            )}
          </div>
          <CButton
            fullWidth
            onClick={
              showOptions
                ? () => setShowOptions(false)
                : npeAnnouncement.handleCloseNPEForm
            }
            variant="outlined"
            name="npeAnnouncementDialog-cancel"
            disabled={loading}
          >
            Cancel
          </CButton>
        </form>
      </CDialog>

      <CoborrowerInvite
        open={npeAnnouncement.coborrowerInviteIsOpen}
        onClose={() => npeAnnouncement.setCoborrowerInviteIsOpen(false)}
        onDismiss={npeAnnouncement.handleInviteDismiss}
        customFlags={{ offeredAddingNPEAsCoborrower: true }}
      />
    </Fragment>
  );
};

export default NPEAnnouncement;
