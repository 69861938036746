import { Box } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { CHBLink } from "components/CHBLink";
import { HBSeparate } from "components/HBSeparate";
import { useLocation, useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { Profile } from "components/HBHeader/Profile";

interface LinkListProps {
  className?: string;
  isFooter?: boolean;
  handleOpen?: Dispatch<SetStateAction<boolean>>;
}

export const LinkList = ({
  className = "",
  handleOpen,
  isFooter = false,
}: LinkListProps) => {
  const { user, logout, loadingUserData } = useUser();

  const isLogged = !!user;
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const isGetStarted = pathName.includes("/get-started");

  const handleCloseMenu = () => {
    handleOpen && handleOpen(false);
  };

  return (
    <Box className={`link-list ${className}`}>
      <Box className="link-list__desktop">
        {/* <CHBLink
          to="/"
          isFooter={isFooter}
          separate
        >
          Home
        </CHBLink> */}
        <CHBLink to="/faq" isFooter={isFooter} separate>
          FAQ
        </CHBLink>
        <CHBLink
          to="/resources"
          isFooter={isFooter}
          separate={(!isFooter && isLogged) || !isLogged}
        >
          RESOURCES
        </CHBLink>
        {!isLogged && !loadingUserData && (
          <CHBLink to="/login" isFooter={isFooter}>
            LOG IN
          </CHBLink>
        )}
        {isLogged && !isFooter && <Profile user={user} logout={logout} />}
        {!isGetStarted && (
          <CHBButton
            onClick={() => {
              navigate("/get-started");
            }}
            styleContainer={{
              maxWidth: "130px",
            }}
            style={{
              padding: "5px 30px",
              width: "130px",
            }}
            ctaId={isFooter ? "LandingpageFooter" : "LandingpageHeader"}
          >
            APPLY
          </CHBButton>
        )}
      </Box>
      <Box className="link-list__mobile">
        {/* <CHBLink
          to="/"
          className="link-list__mobile__link"
          onClick={handleCloseMenu}
          isFooter={isFooter}
        >
          Home
        </CHBLink> */}
        {/* <HBSeparate type="vertical" /> */}
        <CHBLink
          to="/faq"
          className="link-list__mobile__link"
          onClick={handleCloseMenu}
          isFooter={isFooter}
        >
          FAQ
        </CHBLink>
        <HBSeparate type="vertical" />
        <CHBLink
          to="/resources"
          className="link-list__mobile__link"
          onClick={handleCloseMenu}
          isFooter={isFooter}
        >
          RESOURCES
        </CHBLink>
        <HBSeparate type="vertical" />
        {!isLogged && !loadingUserData && (
          <CHBLink
            to="/login"
            className="link-list__mobile__link"
            onClick={handleCloseMenu}
            isFooter={isFooter}
          >
            LOGIN
          </CHBLink>
        )}
        {/* {isLogged && !isFooter && <Profile user={user} logout={logout} />} */}
        {!isGetStarted && (
          <CHBButton
            onClick={() => {
              navigate("/get-started");
              handleCloseMenu();
            }}
            styleContainer={{
              maxWidth: "130px",
            }}
            style={{
              padding: "5px 30px",
              width: "130px",
            }}
            ctaId={isFooter ? "LandingpageFooter" : "LandingpageHeader"}
          >
            APPLY
          </CHBButton>
        )}
      </Box>
    </Box>
  );
};
