import {
  Box,
  Typography,
  Divider,
  useMediaQuery,
  Grid,
  useTheme,
} from "@mui/material";
import { Container, Theme } from "@mui/system";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import { HellaFast } from "./HellaFast";
import { useNavigate } from "react-router-dom";
import { HBTestimonees } from "components/HBTestimonees";
import { CircleNumberOne } from "components/icons/CircleNumberOne";
import { CircleNumberTwo } from "components/icons/CircleNumberTwo";
import { CircleNumberThree } from "components/icons/CircleNumberThree";
import UseYourHelocFor from "./UseYourHelocFor/UseYourHelocFor";
import HBFeatureItem from "./HBFeatureItem";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

export const HBHome = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const { sizes } = useHomebridgeSizes();

  return (
    <Box sx={{ padding: "0px", margin: "0px", width: "100%" }}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        marginTop={"30px"}
        flexDirection={"column"}
        sx={{
          width: "100%",
        }}
      >
        <HellaFast
          title={
            <>
              The Homebridge HELOC <br /> has arrived!
            </>
          }
        />
        <CHBButton
          onClick={() => {
            navigate("/get-started");
          }}
          variant="contained"
          color="primary"
          size="large"
          styleContainer={{
            maxWidth: "200px",
          }}
          ctaId="LandingpageMainGetStarted"
        >
          GET STARTED
        </CHBButton>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
          marginTop="20px"
        >
          <Typography
            variant="body1"
            color={theme.palette.common.white}
            fontSize={sizes.typography}
          >
            Get approved in minutes
          </Typography>
          <HBSeparate />
          <Typography
            variant="body1"
            color={theme.palette.common.white}
            fontSize={sizes.typography}
          >
            Get cash in days
          </Typography>
        </Box>
      </Box>
      <UseYourHelocFor isMobile={isMobile} />
      <Box
        bgcolor={"#D3E1EA"}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        marginTop={"30px"}
        flexDirection={"column"}
        sx={{ paddingY: "60px" }}
      >
        <Typography
          variant="h4"
          sx={{
            fontSize: sizes.typographyLarge,
            color: "#1a2b3b",
            mb: 3,
            width: {
              xs: "66%",
            },
            textAlign: "center",
          }}
          fontWeight={900}
          fontFamily={"NunitoSans"}
        >
          No, this is not an ordinary HELOC…
        </Typography>

        <Divider
          orientation="horizontal"
          sx={{
            border: `1px solid ${theme.palette.text.primary}`,
            width: { xs: "85%", md: "60%", lg: "50%", xl: "50%" },
            mb: 5,
          }}
        />

        <Container
          maxWidth="lg"
          sx={{
            textAlign: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={2} width={"70%"} alignSelf={"center"}>
            <HBFeatureItem
              title="NO"
              subtitle="paperwork"
              description={`Just complete our simple online application and we'll take it from there.`}
            />
            <HBFeatureItem
              title="NO"
              subtitle="delays"
              description={`Get your cash in days, not weeks or months like other lenders.`}
            />
            <HBFeatureItem
              title="NO"
              subtitle="kidding"
              description={`Apply now and you could get the cash you need, right now when you need
it!`}
            />
          </Grid>
        </Container>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        color={theme.palette.background.paper}
        sx={{
          backgroundColor: theme.palette.common.white,
          paddingY: {
            xs: "40px",
            md: "60px",
          },
          gap: {
            xs: "20px",
            md: "40px",
          },
          color: theme.palette.background.paper,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: {
              xs: "20px",
              md: "40px",
            },
            width: "100%",
          }}
        >
          <Typography
            variant="body1"
            color={theme.palette.background.paper}
            fontSize={sizes.typographyLarge}
            fontWeight={900}
            fontFamily={"NunitoSans"}
            textAlign={"center"}
          >
            It&#8217;s as easy as 1-2-3!
          </Typography>
          <Divider
            orientation="horizontal"
            sx={{
              border: `1px solid ${theme.palette.text.primary}`,
              width: { xs: "85%", md: "60%", lg: "50%", xl: "50%" },
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              gap: {
                xs: "40px",
                md: "50px",
              },
              width: {
                sm: "90%",
                md: "45%",
              },
              paddingY: "20px",
            }}
          >
            <Typography
              variant="body1"
              color={theme.palette.background.paper}
              fontSize={sizes.subtitle}
              fontWeight={"700"}
              textAlign={"center"}
              width={isMobile ? "85%" : "100%"}
            >
              A Homebridge HELOC is the fast, easy, and smart way to turn your
              home's equity into cash.{" "}
              <div id="container--LandingpageEasy123" style={{ all: "unset" }}>
                <button
                  id="button--LandingpageEasy123"
                  style={{ all: "unset" }}
                >
                  <div
                    onClick={() => navigate("/get-started")}
                    style={{
                      textDecoration: "underline",
                      color: theme.palette.background.paper,
                      cursor: "pointer",
                    }}
                    id="LandingpageEasy123"
                  >
                    Get started now
                  </div>
                </button>
              </div>
              :
            </Typography>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "20px",
                alignSelf: isMobile ? "center" : "start",
                paddingLeft: isMobile ? "0px" : "20px",
                margin: "0px",
                width: isMobile ? "90%" : "100%",
              }}
            >
              <li
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <CircleNumberOne />
                <Typography
                  variant="body1"
                  color={theme.palette.background.paper}
                  fontSize={sizes.subtitle}
                  fontWeight={"700"}
                  textAlign={"left"}
                >
                  Check your rate with no impact on your credit score.
                </Typography>
              </li>
              <li
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <CircleNumberTwo />
                <Typography
                  variant="body1"
                  color={theme.palette.background.paper}
                  fontSize={sizes.subtitle}
                  fontWeight={"700"}
                  textAlign={"left"}
                >
                  Choose how much you want (up to $400,000).
                </Typography>
              </li>
              <li
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <CircleNumberThree />
                <Typography
                  variant="body1"
                  color={theme.palette.background.paper}
                  fontSize={sizes.subtitle}
                  fontWeight={"700"}
                  textAlign={"left"}
                >
                  Close in as few as three days.
                </Typography>
              </li>
            </ul>
            {!isMobile && (
              <Typography
                variant="body1"
                color={theme.palette.background.paper}
                fontSize={sizes.subtitle}
                fontWeight={"650"}
                textAlign={"center"}
                width={"100%"}
              >
                Questions?{" "}
                <span
                  onClick={() => navigate("/faq")}
                  style={{
                    textDecoration: "underline",
                    color: theme.palette.background.paper,
                    cursor: "pointer",
                  }}
                >
                  Look here
                </span>{" "}
                for fast answers or{" "}
                <a
                  href="https://www.homebridge.com/contact-us/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "underline",
                    color: theme.palette.background.paper,
                    cursor: "pointer",
                  }}
                >
                  contact us
                </a>
                .
              </Typography>
            )}
          </Box>
        </Box>
        <Divider
          orientation="horizontal"
          sx={{
            border: `1px solid ${theme.palette.text.primary}`,
            width: { xs: "85%", md: "60%", lg: "50%", xl: "50%" },
          }}
        />
        <HBTestimonees />
      </Box>
    </Box>
  );
};
