import { Box, Container, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { Nav } from "./Nav";
import { useEffect, useState } from "react";

export const HBHeader = () => {
  const location = useLocation();
  const pathName = location.pathname;

  const isFaqOrResources =
    pathName.includes("/faq") || pathName.includes("/resources");
  const { primaryText, secondaryText } = useHomeBridgeColors();
  const [openedDrawer, setOpenedDrawer] = useState(false);
  const [scrollDirection, setScrollDirection] = useState<"up" | "down">("up");
  const isMobile = useMediaQuery("(max-width: 991px)");

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleMenuVisibilityScroll = () => {
      if (!isMobile || openedDrawer) return undefined;
      const currentScrollY = window.scrollY;
      if (currentScrollY < 100) {
        setScrollDirection("up");
      } else if (currentScrollY < lastScrollY) {
        setScrollDirection("up");
      } else if (currentScrollY > lastScrollY) {
        setScrollDirection("down");
      }

      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleMenuVisibilityScroll);

    return () => {
      window.removeEventListener("scroll", handleMenuVisibilityScroll);
    };
  }, [openedDrawer, isMobile]);

  const handleClassNameHeader = () => {
    let className = "hb-header";

    if (openedDrawer) {
      className += " menu-opened";
    }

    if (scrollDirection === "up") {
      className += " show";
    } else {
      className += " hide";
    }
    return className;
  };
  const classNameHeader = handleClassNameHeader();

  return (
    <Box
      className={`${classNameHeader}`}
      id={"hb-header"}
      sx={{
        backgroundColor: isFaqOrResources ? primaryText : secondaryText,
        paddingTop: "1.5vh",
      }}
    >
      <Box>
        {/* {!isGetStarted && (
          <Box
            className="hb-header__top"
            sx={{
              backgroundColor: secondaryBackground,
            }}
          >
            <Container className="hb-header__top__container">
              <Box className="hb-header__top">
                <Typography
                  variant="body1"
                  textAlign="end"
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  sx={{
                    padding: "10px 0",
                  }}
                >
                  <Link
                    to="https://myloan.homebridge.com/?_gl=1%2A1nx2yfy%2A_ga%2ANzcwODgyMTIxLjE3MzI1Mzg5ODk.%2A_ga_0Q94YM4N7L%2AMTczNjM1OTE0OC40LjEuMTczNjM1OTM5NC4wLjAuMA.."
                    target="_blank"
                    style={{
                      color: theme.palette.background.paper,
                      textDecoration: "underline",
                    }}
                  >
                    Make a payment
                  </Link>
                </Typography>
              </Box>
            </Container>
          </Box>
        )} */}

        <Box
          className="hb-header__bottom"
          sx={{
            backgroundColor: isFaqOrResources ? primaryText : secondaryText,
          }}
        >
          <Container className="hb-header__bottom__container">
            <Nav openedDrawer={openedDrawer} handleOpen={setOpenedDrawer} />
          </Container>
        </Box>
      </Box>
    </Box>
  );
};
