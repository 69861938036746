import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import ShowNetDetailsModal from "./showNetDetailsModal";
import dollarFormatter from "utils/FormatterDollar";
import { useIsHomebridge } from "utils/useIsHomebridge";

const EstNetProceeds: FC<HELOCFormProps> = ({
  loan,
  fees,
  newValuesSlider,
}) => {
  const theme = useTheme();
  const { isHomeBridge } = useIsHomebridge();
  const [showDetails, setShowDetails] = useState(false);
  const [totalPayoffs, setTotalPayoffs] = useState(0);
  const [totalPaydowns, setTotalPaydowns] = useState(0);
  const [paydowns, setPaydowns] = useState<
    {
      subscriberName: string;
      updatedAt: number;
      counterpartyId: string;
      receivingAccountId: string;
      paydownAmount: string;
      accountNumber: string;
    }[]
  >([]);

  useEffect(() => {
    let payoffs = 0;
    let paydowns = 0;
    if (loan?.payoff) {
      loan.payoff.forEach((element) => {
        payoffs += element?.amount ?? 0;
      });
    }
    if (
      loan?.borrowerSteps?.hardpullVerification?.paydownInfo &&
      loan?.coborrowerSteps?.hardpullVerification?.paydownInfo
    ) {
      loan?.borrowerSteps?.hardpullVerification?.paydownInfo?.forEach(
        (element) => {
          paydowns += element.paydownAmount
            ? parseInt(element?.paydownAmount, 10)
            : 0;
        },
      );
      loan?.coborrowerSteps?.hardpullVerification?.paydownInfo?.forEach(
        (element) => {
          paydowns += element.paydownAmount
            ? parseInt(element?.paydownAmount, 10)
            : 0;
        },
      );
    } else if (loan?.borrowerSteps?.hardpullVerification?.paydownInfo) {
      loan?.borrowerSteps?.hardpullVerification?.paydownInfo?.forEach(
        (element) => {
          paydowns += element.paydownAmount
            ? parseInt(element?.paydownAmount, 10)
            : 0;
        },
      );
    } else if (loan?.coborrowerSteps?.hardpullVerification?.paydownInfo) {
      loan?.coborrowerSteps?.hardpullVerification?.paydownInfo?.forEach(
        (element) => {
          paydowns += element.paydownAmount
            ? parseInt(element?.paydownAmount, 10)
            : 0;
        },
      );
    } else {
      paydowns = 0;
    }
    setTotalPayoffs(payoffs);
    setTotalPaydowns(paydowns);
  }, [loan]);

  useEffect(() => {
    if (
      loan?.borrowerSteps?.hardpullVerification?.paydownInfo &&
      loan?.coborrowerSteps?.hardpullVerification?.paydownInfo
    ) {
      setPaydowns([
        ...(loan?.borrowerSteps?.hardpullVerification?.paydownInfo ?? []),
        ...(loan?.coborrowerSteps?.hardpullVerification?.paydownInfo ?? []),
      ]);
    } else if (loan?.borrowerSteps?.hardpullVerification?.paydownInfo) {
      setPaydowns(loan?.borrowerSteps?.hardpullVerification?.paydownInfo);
    } else if (loan?.coborrowerSteps?.hardpullVerification?.paydownInfo) {
      setPaydowns(loan?.coborrowerSteps?.hardpullVerification?.paydownInfo);
    } else {
      setPaydowns([]);
    }
  }, [loan]);

  const currDrawAmount = newValuesSlider?.initialDrawAmount ?? 0;

  const isDrawAmountAtMax =
    newValuesSlider?.initialDrawAmount === loan?.finalOffer?.amount;

  const netFund = isDrawAmountAtMax
    ? currDrawAmount - (fees?.totalFee ?? 0) - totalPaydowns - totalPayoffs
    : currDrawAmount;

  const parsedEsNeetsAmount = dollarFormatter.format(netFund);

  return (
    <Box
      display="grid"
      alignItems="center"
      sx={{
        backgroundColor: isHomeBridge
          ? "#D3E1EA"
          : theme.palette.background.paper,
        p: 2,
      }}
      gridTemplateColumns="1fr 1fr"
    >
      <Typography
        sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
        maxWidth="220px"
        width="100%"
      >
        Est. Net Proceeds:{" "}
      </Typography>
      <Box
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
        display="flex"
        alignItems="center"
        fontFamily={"Pro Display Bold"}
        gap={{ xs: 0, md: 1 }}
      >
        {parsedEsNeetsAmount ? (
          <>
            <Typography
              sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
              fontFamily={"Pro Display Bold"}
            >
              {parsedEsNeetsAmount}
            </Typography>
            <Typography
              onClick={() => setShowDetails(true)}
              sx={{
                cursor: "pointer",
                textDecoration: isHomeBridge ? "underline" : "none",
                fontSize: { xs: "1rem", md: "1.2rem" },
              }}
              color="#6767AA"
            >
              Show details
            </Typography>
          </>
        ) : (
          <Skeleton width="75%" height={"1.5rem"} />
        )}
      </Box>
      {showDetails && (
        <ShowNetDetailsModal
          openModal={showDetails}
          onClose={() => {
            setShowDetails(false);
          }}
          paydowns={paydowns}
          payoffs={loan?.payoff}
          loan={loan}
        />
      )}
    </Box>
  );
};

export default EstNetProceeds;
