import Address from "typedef/Address";
import parseMoney from "utils/parseMoney";
import { useParams } from "react-router-dom";
import { RegisterFormInputs } from "./useRegisterForm";
import { useLocation } from "react-router-dom";
const parseIncomes = (income: string) => {
  if (!income) {
    return 0;
  }
  const numericValue = income.replace(/[$,\s]/g, "");
  const numberValue = parseFloat(numericValue);
  return numberValue;
};
const useParseToSubmit = () => {
  const params = useParams();
  const sourceId = params["sourceId"];

  const location = useLocation();
  const locationSearch = new URLSearchParams(location.search);
  const paramsCode = locationSearch.get("code");
  const paramsApiOfferCode = locationSearch.get("apiOfferCode");

  return (data: RegisterFormInputs) => {
    const annualIncome = data?.annualIncome
      ? parseMoney(data?.annualIncome)
      : data.annualIncomeToString;
    const homeValue = parseMoney(data.homeValue);
    const requestedNewLoan = parseMoney(data.requestedNewLoan);
    const currentLoanBalance = parseMoney(data.currentLoanBalance);
    const phoneNumber = data.phoneNumber.replace(/ /g, "");
    const creditScore = parseInt(data.creditScore?.split(" ")[0]);
    const { street_line, ...restOfAddress } = data.propertyAddress;
    const address = {
      street: street_line,
      ...restOfAddress,
    };
    let borrowerAddress: (Address & { street?: string }) | undefined;
    if (data.borrowerAddress) {
      const { ...propsBorrowerAddress } = data.borrowerAddress;

      borrowerAddress = {
        street: propsBorrowerAddress?.street_line,
        ...propsBorrowerAddress,
      };
    }
    const addCoborrower = data.addCoborrower === "Yes";

    const isOnlySalaryB1 =
      data?.salaryIncome &&
      !data?.selfEmploymentIncome &&
      !data?.socialIncome &&
      !data?.rentalIncome &&
      !data?.otherIncome;

    const isOnlySalaryB2 =
      data?.salaryIncomeCoborrower &&
      !data?.selfEmploymentIncomeCoborrower &&
      !data?.socialIncomeCoborrower &&
      !data?.rentalIncomeCoborrower &&
      !data?.otherIncomeCoborrower;

    const employmentType = isOnlySalaryB1
      ? "Full Time Employed/ W2"
      : "Self Employed";

    const employmentTypeCoborrower = isOnlySalaryB2
      ? "Full Time Employed/ W2"
      : "Self Employed";

    return {
      ...data,
      address,
      agreeNotifications: true,
      annualIncome: annualIncome,
      borrowerAddress,
      creditScore,
      currentLoanBalance: currentLoanBalance,
      customAddress:
        data.propertyAddress?.street_line === "My address is not listed"
          ? {
              city: data?.manual_city,
              secondary: data?.manual_apt,
              state: data?.manual_state?.toLocaleUpperCase(),
              street_name: data?.manual_street_name,
              street_number: data?.manual_street_number,
              zipcode: data?.manual_zipcode,
              street_suffix: data?.manual_street_suffix,
            }
          : undefined,
      customBorrowerAddress:
        data.borrowerAddress?.street_line === "My address is not listed"
          ? {
              city: data?.manual_borrower_city,
              secondary: data?.manual_borrower_apt,
              state: data?.manual_borrower_state?.toLocaleUpperCase(),
              street_name: data?.manual_borrower_street_name,
              street_number: data?.manual_borrower_street_number,
              zipcode: data?.manual_borrower_zipcode,
              street_suffix: data?.manual_borrower_street_suffix,
            }
          : undefined,
      email: data.email?.trim(),
      employerNameBorrower: data.employerNameBorrower ?? undefined,
      employmentType: employmentType,
      maritalStatus: data.maritalStatus,
      firstName: data.firstName?.trim(),
      hasAcceptTerms: true,
      homeValue: homeValue,
      inviteCode: paramsCode,
      apiOfferCode: paramsApiOfferCode,
      lastName: data.lastName?.trim(),
      middleName: data.middleName?.trim(),
      suffix: data.suffix,
      manualPropertyAddress:
        data.propertyAddress?.street_line === "My address is not listed",
      manualBorrowerAddress:
        data.borrowerAddress?.street_line === "My address is not listed",
      occupancy: data.occupancy,
      password: data.password,
      phoneNumber,
      requestedNewLoan: requestedNewLoan,
      sourceId: sourceId,
      startDateBorrower: data.startDateBorrower ?? undefined,
      useProceeds: data.useProceeds,
      salaryIncome: parseIncomes(data.salaryIncome ?? ""),
      otherIncome: parseIncomes(data.otherIncome ?? ""),
      rentalIncome: parseIncomes(data.rentalIncome ?? ""),
      selfEmploymentIncome: parseIncomes(data.selfEmploymentIncome ?? ""),
      socialIncome: parseIncomes(data.socialIncome ?? ""),
      ...(addCoborrower && {
        legalFirstNameCoborrower: data?.legalFirstNameCoborrower?.trim(),
        legalLastNameCoborrower: data?.legalLastNameCoborrower?.trim(),
        legalMiddleNameCoborrower: data?.legalMiddleNameCoborrower?.trim(),
        legalSuffixCoborrower: data?.legalSuffixCoborrower?.trim(),
        maritalStatusCoborrower: data?.maritalStatusCoborrower,
        confirmEmailAddressCoborrower:
          data?.confirmEmailAddressCoborrower?.trim(),
        phoneCoborrower: data?.phoneCoborrower?.replace(/ /g, ""),
        salaryIncomeCoborrower: parseIncomes(data.salaryIncomeCoborrower ?? ""),
        otherIncomeCoborrower: parseIncomes(data.otherIncomeCoborrower ?? ""),
        rentalIncomeCoborrower: parseIncomes(data.rentalIncomeCoborrower ?? ""),
        selfEmploymentIncomeCoborrower: parseIncomes(
          data.selfEmploymentIncomeCoborrower ?? "",
        ),
        socialIncomeCoborrower: parseIncomes(data.socialIncomeCoborrower ?? ""),
        employerNameCoborrower:
          data.employerNameCoborrower === ""
            ? undefined
            : data.employerNameCoborrower?.trim(),
        startDateCoborrower:
          data.startDateCoborrower === ""
            ? undefined
            : data.startDateCoborrower?.trim(),
        employmentTypeCoborrower: employmentTypeCoborrower,
      }),
    };
  };
};

export default useParseToSubmit;
