import { Fragment } from "react";
import CHeader from "components/CHeader";
import CFooter from "components/CFooter";
import { Outlet, useLocation } from "react-router-dom";
import { HBHeader } from "components/HBHeader";
import { HBFooter } from "components/HBFooter";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import "./styles.scss";
import { useIsHomebridge } from "utils/useIsHomebridge";
import { Box } from "@mui/material";
import useUser from "context/UserCustomer/useUserCustomerContext";

const Layout = () => {
  const { loan } = useUser();
  const isDTCFlow = loan?.newLoanStatusCode?.startsWith("DTC");

  const { isHomeBridge } = useIsHomebridge();
  const location = useLocation();
  const pathName = location.pathname;
  const isGetStarted = pathName.includes("/get-started");
  const isImpersonate = pathName.includes("/impersonate");
  const isFaqOrResources =
    pathName.includes("/faq") || pathName.includes("/resources");
  const { primaryBackground, primaryText, secondaryText } =
    useHomeBridgeColors();
  return (
    <Fragment>
      {isHomeBridge ? (
        <Box
          className={`homebridge-layout ${
            pathName !== "/"
              ? `homebridge-layout__${pathName
                  .split("/")
                  .filter((segment) => segment && !segment.match(/^\d/))
                  .join("_")}`
              : ""
          }`}
          style={{
            display: "grid",
            minHeight: "100dvh",
            gridTemplateRows: "auto 1fr auto",
            fontFamily: "NunitoSans",
            backgroundColor: isDTCFlow
              ? secondaryText
              : isFaqOrResources
                ? primaryText
                : secondaryText,
          }}
        >
          {isImpersonate ? <CHeader /> : <HBHeader />}
          <Box
            className="homebridge-layout__content"
            style={{
              backgroundColor: isDTCFlow
                ? secondaryText
                : isImpersonate
                  ? primaryText
                  : primaryBackground,
              color: primaryText,
              width: "100%",
            }}
          >
            <Outlet />
          </Box>
          {!isGetStarted && <HBFooter />}
        </Box>
      ) : (
        <>
          <CHeader />
          <Outlet />
          <CFooter />
        </>
      )}
    </Fragment>
  );
};

export default Layout;
