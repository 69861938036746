import { ButtonProps } from "@mui/material";
import "./styles.scss";
export interface CHBButtonProps extends ButtonProps {
  children: React.ReactNode;
  styles?: React.CSSProperties;
  styleContainer?: React.CSSProperties;
  classNameContainer?: string;
  loading?: boolean;
  secondaryArrow?: boolean;
  ctaId?: string;
}

export const CHBButton = ({
  children,
  styles,
  classNameContainer,
  styleContainer,
  loading,
  secondaryArrow,
  ctaId,
  ...props
}: CHBButtonProps) => {
  function getButtonClassName(params: {
    loadingValue: boolean;
    disabledValue: boolean;
    secondaryArrowValue: boolean;
  }) {
    const { loadingValue, disabledValue, secondaryArrowValue } = params;
    let buttonClassName = "";
    if (loadingValue) {
      buttonClassName = "animated-button animated-button--loading";
    } else if (disabledValue) {
      buttonClassName = "animated-button animated-button--disabled";
    } else if (secondaryArrowValue) {
      buttonClassName = "secondary-animated-arrow-button";
    } else {
      buttonClassName = "animated-arrow-button";
    }

    return buttonClassName;
  }

  return (
    <div
      className={`animated-arrow-button-container ${classNameContainer ?? ""}`}
      style={styleContainer}
      id={ctaId ? `container--${ctaId}` : undefined}
    >
      <button
        className={`${getButtonClassName({
          disabledValue: props.disabled ?? false,
          loadingValue: loading ?? false,
          secondaryArrowValue: secondaryArrow ?? false,
        })}`}
        {...(!loading && { ...props })}
        id={ctaId ? `button--${ctaId}` : undefined}
      >
        {loading ? (
          <div className="spinner"></div>
        ) : (
          <>
            <span></span>
            <div className="animated-button-description text-black" id={ctaId}>
              {children}
            </div>
          </>
        )}
      </button>
    </div>
  );
};
