import {
  Box,
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  CircularProgress,
} from "@mui/material";
import { FC } from "react";
import Loan from "typedef/Loan";
import dollarFormatter from "utils/FormatterDollar";
import CButton from "components/CButton";
import ChangeB2ToNPEModal from "../ChangeB2ToNPEModal";
import { UseDebtsReturnProps } from "./useDebts";
import HelpDialog from "../HelpDialog";
import BorrowerSteps from "typedef/BorrowerSteps";
import CoborrowerSteps from "typedef/CoborrowerSteps";
import CDialog from "components/CDialog";
import checkTenMonthsMaturity from "./checkTenMonthsMaturity";
import DebtItem from "./DebtItem";
import ChangeDebtsInformationModal from "./ChangeDebtsInformationModal";
import { getErrorMessages } from "./errorMessages";

type DebtsTableProps = {
  loan?: Loan;
  isBorrower: boolean;
  step?: BorrowerSteps | CoborrowerSteps;
  showDebts?: () => void;
  dtiItems: { dti: number | undefined; dtiHurdle: number; dtiAdjustor: number };
  useDebts: UseDebtsReturnProps;
};

const MIN_BALANCE_TRADELINE = 0;

const DebtsTable: FC<DebtsTableProps> = ({
  loan,
  isBorrower,
  step,
  showDebts,
  dtiItems,
  useDebts,
}) => {
  const {
    isProposedPaydown,
    theme,
    tradelines,
    isLoading,
    handleSelectTradeLine,
    handleConfirmDebts,
    setIsLastTradeline,
    dti,
    openModalB2toNPE,
    needsHelp,
    openHelpDialog,
    setOpenHelpDialog,
    totalBalanceSelected,
    netFunding,
    checkedItems,
    showDTI,
    handleCloseModalB2ToNPE,
    requestedAdmin,
    errorDataMessage,
    setErrorDataMessage,
    handleRefreshData,
    checkedDebts,
    openSlider,
    setOpenSlider,
    currentId,
    handleNext,
    isLastTradeline,
    skTradelines,
  } = useDebts;

  let awaitB2Register = false;

  const maxdti = dtiItems?.dti;

  if (
    loan?.borrowerSteps?.incomeVerification?.isJointAccount &&
    loan?.coborrowerId?.includes("temp")
  ) {
    awaitB2Register = true;
  }

  if (!tradelines) {
    return <CircularProgress size={32} sx={{ padding: "6px" }} />;
  }

  const balance = Number(netFunding) - Number(totalBalanceSelected);
  const colorBalance =
    Number(balance) < Number(netFunding) / 4
      ? "#FF7A7A"
      : Number(balance) < Number(netFunding) / 2
        ? "#FFC857"
        : "#34C155";

  const adminNeedToReviewIncomes =
    step?.incomeVerification?.selfEmployed?.selfEmployedData.some(
      (item) =>
        item?.uploadedByBorrower && !item?.debtIncome && !item?.isIgnored,
    );
  const hardpullIsMissing =
    step?.hardpullVerification?.hardCreditScore === undefined;

  const { errorMessage, errorMessageImpersonate } = getErrorMessages({
    maxdti,
    dti,
    isLoading,
    step,
    awaitB2Register,
    loan,
    adminNeedToReviewIncomes,
    hardpullIsMissing,
  });

  const isImpersonate = window.location.pathname?.includes("impersonate");

  return (
    <>
      <Box sx={{ width: "100%" }}>
        {showDTI && (
          <Box>
            <ChangeB2ToNPEModal
              openModalB2toNPE={openModalB2toNPE}
              handleCloseModalB2ToNPE={handleCloseModalB2ToNPE}
            />
            {!loan?.[isBorrower ? "borrowerFlags" : "coborrowerFlags"]
              ?.debtsCofirmed ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    marginTop: 4,
                  }}
                >
                  <Typography
                    fontSize={{ xs: "18px", sm: "20px" }}
                    color={"black"}
                  >
                    {isProposedPaydown
                      ? "Our team has reviewed your Income and Debts and we need to make the following payment(s) to get your DTI within our guidelines, please review and then click “Confirm and Proceed to Next Step”."
                      : "You may be able to lower your DTI by paying off debt(s) from the list below"}
                  </Typography>
                  {!isProposedPaydown && (
                    <Typography
                      fontSize={{ xs: "18px", sm: "20px" }}
                      color={"red"}
                    >
                      {
                        "Please click 'Confirm and Proceed to next step' below to continue"
                      }
                    </Typography>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      fontSize={{ xs: "16px", sm: "20px" }}
                      color={"black"}
                    >
                      Pay off your debts
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginLeft: 5,
                      }}
                    >
                      <Typography
                        fontSize={{ xs: "16px", sm: "20px" }}
                        color={colorBalance}
                        textAlign={{ xs: "end", sm: "inherit" }}
                      >
                        Available Balance: {dollarFormatter.format(balance)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    overflowX: "auto",
                    whiteSpace: { xs: "nowrap", sm: "inherit" },
                    width: "100%",
                    paddingBottom: 1,
                  }}
                >
                  <TableContainer sx={{ width: "100%", minWidth: 650 }}>
                    <Table sx={{ width: "100%" }} aria-label="simple table">
                      <TableHead sx={{ borderBottom: "2px solid whitesmoke" }}>
                        <TableRow
                          sx={{
                            "& td, & th": {
                              border: 0,
                              margin: 0,
                              paddingLeft: "0px",
                              paddingBottom: "1px",
                              textWrap: "nowrap",
                            },
                          }}
                        >
                          <TableCell key={"TradelineName"}>
                            <Typography
                              sx={{
                                fontSize: { xs: "16px", sm: "18px" },
                                color: theme.palette.grey["600"],
                              }}
                            >
                              Tradeline name
                            </Typography>
                          </TableCell>
                          <TableCell align="inherit" key={"Type"}>
                            <Typography
                              sx={{
                                fontSize: { xs: "16px", sm: "18px" },
                                color: theme.palette.grey["600"],
                              }}
                            >
                              Type
                            </Typography>
                          </TableCell>
                          <TableCell align="inherit" key={"Monthly"}>
                            <Typography
                              sx={{
                                fontSize: { xs: "16px", sm: "18px" },
                                color: theme.palette.grey["600"],
                              }}
                            >
                              Monthly
                            </Typography>
                          </TableCell>
                          <TableCell align="inherit" key={"Balance"}>
                            <Typography
                              sx={{
                                fontSize: { xs: "16px", sm: "18px" },
                                color: theme.palette.grey["600"],
                              }}
                            >
                              Balance
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tradelines?.map((row, index) => {
                          const isChecked =
                            checkedItems.find((item) => item.index === index)
                              ?.checked || false;
                          const disabled =
                            isLoading ||
                            (!isChecked &&
                              totalBalanceSelected + Number(row.balance) >
                                netFunding);

                          const isTenMonthsMaturity = checkTenMonthsMaturity({
                            isInstallment: row?.revolvingOrInstallment === "I",
                            isOpen: row?.accountStatus === "Open",
                            terms: row?.terms,
                            openDate: row?.openDate,
                            isSubjectProperty: false, // Hardcoded to false to evaluate the 10-month maturity even if it is a subject property.
                            sk: skTradelines,
                          });

                          const isSelfReported =
                            row?.terms === "001" && !row?.openDate;

                          if (
                            //** Display only these tradelines  */
                            (row?.responsibility === "Individual" ||
                              row?.responsibility === "Joint Account") &&
                            row?.accountStatus === "Open" &&
                            row?.source === (isBorrower ? "B1" : "B2") &&
                            !row?.acceptedBefore &&
                            !row?.ignored &&
                            Number(row?.balance ?? 0) > MIN_BALANCE_TRADELINE &&
                            !isTenMonthsMaturity &&
                            !isSelfReported
                          ) {
                            const STUDENT_MULTIPLIER_TRADELINE = 0.01;
                            const isStudentLoans =
                              row?.revolvingOrInstallment === "I" &&
                              row?.accountTypeOriginal === "12" &&
                              row?.monthlyPayment === "00000000";
                            const monthlyAmount =
                              Number(row.amount1) *
                              STUDENT_MULTIPLIER_TRADELINE;

                            const monthlyPayment =
                              row.monthlyPayment === "UNKNOWN"
                                ? "UNKNOWN"
                                : dollarFormatter.format(
                                    Number(
                                      isStudentLoans
                                        ? monthlyAmount
                                        : row.monthlyPayment ?? 0,
                                    ),
                                  );
                            const balanceAmount = dollarFormatter?.format(
                              Number(row?.balance ?? 0),
                            );
                            if (
                              !requestedAdmin ||
                              (requestedAdmin && Boolean(row?.proposedAdmin))
                            ) {
                              return (
                                <DebtItem
                                  isStudentLoans={isStudentLoans}
                                  key={index}
                                  balanceAmount={balanceAmount}
                                  monthlyPayment={monthlyPayment}
                                  disabled={disabled}
                                  row={row}
                                  index={index}
                                  handleSelectTradeLine={handleSelectTradeLine}
                                  theme={theme}
                                  isBorrower={isBorrower}
                                  loanId={loan?.id as string}
                                  handleRefreshData={handleRefreshData}
                                  currentIndex={index}
                                  isLastTradeline={isLastTradeline}
                                />
                              );
                            } else {
                              return null;
                            }
                          } else return null;
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { sm: "row", xs: "column" },
                    gap: { sm: "12px", xs: "0px" },
                    justifyContent: "center",
                    marginTop: { sm: 2, xs: 3 },
                  }}
                >
                  <CButton
                    type="button"
                    variant="contained"
                    onClick={() => {
                      if (checkedDebts && checkedDebts?.length > 0) {
                        setOpenSlider(true);
                      } else {
                        setIsLastTradeline(true);
                        handleConfirmDebts();
                      }
                    }}
                    sx={{
                      whiteSpace: { sm: "wrap", md: "nowrap" },
                      fontSize: { sm: "16px", xs: "14px" },
                      width: { sm: "auto", xs: "100%" },
                    }}
                    disabled={
                      maxdti === undefined ||
                      dti > maxdti ||
                      isLoading ||
                      step?.incomeVerification?.annualIncome === undefined ||
                      awaitB2Register ||
                      loan?.borrowerSteps?.incomeVerification?.needsReview ||
                      loan?.coborrowerSteps?.incomeVerification?.needsReview ||
                      adminNeedToReviewIncomes ||
                      hardpullIsMissing
                    }
                    name="debtsTable-confirmDebts"
                    data-cy="debtsTable-confirmDebts"
                    tooltipText={errorMessage}
                  >
                    {isLoading ? (
                      <CircularProgress size={32} sx={{ color: "white" }} />
                    ) : (
                      "Confirm and proceed to next step"
                    )}
                  </CButton>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "12px",
                      marginTop: { sm: 0, xs: 2 },
                    }}
                  >
                    <CButton
                      type="button"
                      variant="outlined"
                      onClick={showDebts}
                      sx={{ whiteSpace: "nowrap" }}
                      fullWidth
                      disabled={isProposedPaydown}
                    >
                      Cancel
                    </CButton>
                    <CButton
                      type="button"
                      disabled={
                        needsHelp ||
                        step?.incomeVerification?.status === "success"
                      }
                      variant="outlined"
                      onClick={() => setOpenHelpDialog(true)}
                      sx={{ whiteSpace: "nowrap" }}
                      name="debtsTable-needHelp"
                      aria-label="debtsTable-needHelp"
                      fullWidth
                    >
                      I need help
                    </CButton>
                  </Box>
                </Box>
                {errorMessageImpersonate && isImpersonate && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { sm: "row", xs: "column" },
                      gap: { sm: "12px", xs: "0px" },
                      justifyContent: "center",
                      marginTop: { sm: 2, xs: 3 },
                      color: theme.palette.error.main,
                    }}
                  >
                    {errorMessageImpersonate}
                  </Box>
                )}
                {awaitB2Register && (
                  <Typography fontSize={15} color={"red"}>
                    To complete final approval, your coborrower must register.
                  </Typography>
                )}
              </>
            ) : null}
          </Box>
        )}
      </Box>

      <HelpDialog
        isOpen={openHelpDialog}
        onClose={() => setOpenHelpDialog(false)}
        isBorrower={isBorrower}
        loanId={loan?.id}
      />
      <CDialog
        error={errorDataMessage}
        open={errorDataMessage ? Boolean(errorDataMessage) : false}
        icon="warning"
        description={errorDataMessage}
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={() => {
            setErrorDataMessage(undefined);
          }}
          name="debtsTable-continue"
          aria-label="debtsTable-continue"
        >
          Continue
        </CButton>
      </CDialog>

      {openSlider &&
        currentId &&
        checkedDebts?.map((item, index) => {
          const currentIndex = tradelines?.findIndex((el) => el.id === item.id);
          return (
            <ChangeDebtsInformationModal
              key={index}
              counter={{
                current: currentId,
                total: checkedDebts?.length ?? 0,
              }}
              open={currentId - 1 === index}
              onClose={() => setOpenSlider(false)}
              tradeline={{
                accountNumber: item?.accountNumber,
                payto: item?.creditorName,
                paytoAddress: item?.address,
                id: item?.id,
                balance: item?.balance,
                monthlyPayment: item?.monthlyPayment,
              }}
              isBorrower={isBorrower}
              loanId={loan?.id as string}
              handleRefreshData={handleRefreshData}
              handleNextSlider={() => {
                handleNext(currentId + 1, checkedDebts?.length ?? 0);
              }}
              currentIndex={currentIndex}
              isLastTradeline={isLastTradeline}
            />
          );
        })}
    </>
  );
};

export default DebtsTable;
