import { Typography, Box } from "@mui/material";

import HELLAFAST from "../../../assets/Icons/hella_fast.svg";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";

interface HellaFastProps {
  title: React.ReactNode;
}

export const HellaFast = ({ title }: HellaFastProps) => {
  const { tertiaryText, fourthText } = useHomeBridgeColors();
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      marginTop={"30px"}
      marginBottom={"30px"}
      flexDirection={"column"}
    >
      <Box>
        <img
          src={HELLAFAST}
          alt="Homebridge LOGO"
          width={"100%"}
          height={"100%"}
        />
      </Box>
      <Typography
        fontFamily={"NunitoSans"}
        variant="h2"
        fontSize={{
          xs: "32px",
          sm: "42px",
          md: "48px",
          lg: "58px",
          xl: "68px",
        }}
        textAlign={"center"}
        color={fourthText}
        fontWeight={700}
      >
        {title}
      </Typography>
      <Typography
        variant="h3"
        fontSize={{
          xs: "22px",
          sm: "30px",
          md: "34px",
          lg: "40px",
          xl: "46px",
        }}
        fontWeight={400}
        color={tertiaryText}
      >
        Access your home equity now.
      </Typography>
    </Box>
  );
};
