import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBorrowerPersonalData } from "../BorrowerPersonalData/useBorrowerPersonalData";
import { FormData } from "../../useGetStarted";
import API, { ResponseError } from "utils/API";
import { LStoken, LStokenExp } from "CONST";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { useIsLogged } from "utils/useIsLogged";
import { useNavigate } from "react-router-dom";
import { VerifyEmailProps } from ".";

export const useVerifyEmail = ({
  formData,
  onNext,
  setFormData,
  loan,
  setLoadingPage,
}: VerifyEmailProps) => {
  const { isLogged } = useIsLogged();
  const navigate = useNavigate();
  const { setUser, setSelectedLoanId } = useUser();
  const [loading, setLoading] = useState(false);
  const { errors, validateInputs } = useBorrowerPersonalData();
  const [isRegister, setIsRegister] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [animation, setAnimation] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmUser, setShowConfirmUser] = useState(
    Boolean(!isLogged && loan?.DTCApplication?.email),
  );
  const [existantUserModal, setExistantUserModal] = useState(false);

  const inputsToValidate: { field: keyof FormData; value: string }[] =
    useMemo(() => {
      return [
        { field: "firstName", value: formData.firstName ?? "" },
        { field: "email", value: formData.email ?? "" },
        { field: "password", value: formData.password ?? "" },
        { field: "lastName", value: formData.lastName ?? "" },
      ];
    }, [formData]);

  const onValueChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { id, value } = event.target;
    const field = id as keyof FormData;
    const newFormData = { ...formData };
    (newFormData[field as unknown as keyof FormData] as unknown) = value;
    setFormData(newFormData);
    validateInputs({ field, value });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmUser = useCallback(() => {
    setError(false);
    setErrorMessage("");
    setShowConfirmUser(!showConfirmUser);
  }, [showConfirmUser]);

  const registerUser = useCallback(async (params: { formData: FormData }) => {
    setError(false);
    setLoading(true);
    setErrorMessage("");
    try {
      const response = await API.post<{ body: string }>({
        url: `/register-user/user?loanId=${localStorage.getItem(
          "DTCApplication__loanId",
        )}`,
        data: {
          ...params.formData,
        },
      });

      if ("error" in response) {
        const responseError = response?.error as unknown as ResponseError;
        if (responseError?.errorId?.message) {
          setErrorMessage(responseError?.errorId?.message);
          setError(true);
          setLoading(false);
          return;
        } else {
          setErrorMessage(response.error);
          setError(true);
          setLoading(false);
          return;
        }
      } else {
        const data = response.data;

        const body: { existantUser?: { message: string } } = JSON.parse(
          data.body,
        );

        const existantUser = body?.existantUser;
        if (existantUser) {
          localStorage.setItem(
            "DTCApplication__existantUser",
            existantUser.message,
          );
          setExistantUserModal(true);
          setLoading(false);
        } else {
          setLoading(false);
          setShowConfirmUser(true);
          setIsRegister(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const resendCode = useCallback(async () => {
    setError(false);
    setLoading(true);
    setErrorMessage("");
    try {
      const response = await API.post({
        url: "/resend-code",
        data: {
          username: formData.email?.trim(),
          type: "email",
        },
      });
      if ("error" in response) {
        const responseError = response?.error as unknown as ResponseError;
        if (responseError?.errorId?.message) {
          setErrorMessage(responseError.errorId.message);
          setError(true);
          setLoading(false);
          return;
        } else {
          setErrorMessage(response.error);
          setError(true);
          setLoading(false);
          return;
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [formData.email, setError]);

  const signIn = useCallback(async () => {
    setError(false);
    setLoading(true);
    setErrorMessage("");
    try {
      const data = {
        email: formData.email,
        password: formData.password,
        DTCApplication: true,
      };

      if (formData.password) {
        const response = await API.post<{
          idToken: {
            jwtToken: string;
            payload: {
              exp: number;
            };
          };
          accessToken: {
            payload: {
              sub: string;
            };
          };
        }>({
          url: `/login`,
          data,
        });
        if ("error" in response) {
          const responseError = response?.error as unknown as ResponseError;
          if (responseError?.errorId?.message) {
            setErrorMessage(responseError.errorId.message);
            setError(true);
            setLoading(false);
            return;
          } else {
            setErrorMessage(response.error);
            setError(true);
            setLoading(false);
            return;
          }
        } else {
          localStorage.setItem(
            LStokenExp,
            String(response.data.idToken.payload.exp * 1000),
          );
          localStorage.setItem(LStoken, response.data.idToken.jwtToken);
          const userIdLogged = response.data.accessToken.payload.sub;
          setUser((prev) => ({
            ...prev,
            exp: response.data.idToken.payload.exp * 1000,
            token: response.data.idToken.jwtToken,
            id: userIdLogged,
          }));

          const multipleLoanResponse = await API.get<{
            isUserWithMultipleLoans: boolean;
            uniqueLoanId?: string;
          }>("/get/multiple-loans");
          let uniqueLoanId;
          if ("data" in multipleLoanResponse) {
            uniqueLoanId = multipleLoanResponse?.data?.uniqueLoanId;
          }

          if (uniqueLoanId) {
            setSelectedLoanId(uniqueLoanId);
            setLoading(false);
            setLoadingPage(true);
            onNext();
          }
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    formData.email,
    formData.password,
    setUser,
    setSelectedLoanId,
    onNext,
    navigate,
    setLoadingPage,
  ]);

  const confirmUser = useCallback(
    async (email: string, code: string) => {
      setError(false);
      setLoading(true);
      setErrorMessage("");
      const minLen = 6;
      if (!code || code.length !== minLen) {
        setError(true);
        setAnimation(true);
        setTimeout(() => {
          setAnimation(false);
        }, 300);
        setLoading(false);
        return;
      }
      try {
        const data = {
          username: email,
          code: code,
        };

        const response = await API.post({
          url: `/confirm-user`,
          data,
        });
        if ("error" in response) {
          const responseError = response?.error as unknown as ResponseError;
          if (responseError?.errorId?.message) {
            setErrorMessage(responseError.errorId.message);
            setError(true);
            setLoading(false);
            return;
          } else {
            setErrorMessage(response.error);
            setError(true);
            setLoading(false);
            return;
          }
        } else {
          localStorage.removeItem("DTCApplication__loanId");
          signIn();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [signIn],
  );

  const handleNext = useCallback(() => {
    setError(false);
    setErrorMessage("");
    const inputErrors = validateInputs(inputsToValidate);
    if (
      !showConfirmUser &&
      (inputErrors?.length > 0 || formData.password !== confirmPassword)
    ) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else if (isRegister && !showConfirmUser) {
      toggleShowConfirmUser();
    } else if (showConfirmUser) {
      confirmUser(formData.email, code);
    } else {
      registerUser({ formData });
    }
  }, [
    confirmPassword,
    formData,
    inputsToValidate,
    showConfirmUser,
    validateInputs,
    registerUser,
    code,
    confirmUser,
    isRegister,
    toggleShowConfirmUser,
  ]);

  useEffect(() => {
    const existantUserFromLocalStorage = localStorage.getItem(
      "DTCApplication__existantUser",
    );

    if (existantUserFromLocalStorage) {
      setExistantUserModal(true);
    }
  }, []);

  return {
    animation,
    onValueChange,
    error,
    errors,
    errorMessage,
    showPassword,
    toggleShowPassword,
    confirmPassword,
    handleNext,
    setConfirmPassword,
    loading,
    showConfirmUser,
    setShowConfirmUser,
    isRegister,
    setIsRegister,
    registerUser,
    confirmUser,
    code,
    setCode,
    validateInputs,
    setError,
    resendCode,
    toggleShowConfirmUser,
    signIn,
    existantUserModal,
  };
};
