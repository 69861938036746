import "./styles.scss";
import { Address } from "./Steps/Address";
import { Property } from "./Steps/Property";
import { LoanRequest } from "./Steps/LoanRequest";
import { CreditScore } from "./Steps/CreditScore";
import { Offer } from "./Steps/Offer";
import { Box, Typography } from "@mui/material";
import { LoanPurpose } from "./Steps/LoanPurpose";
import { TypeOfOwnership } from "./Steps/TypeOfOwnership";
import { BorrowerPersonalData } from "./Steps/BorrowerPersonalData";
import { Incomes } from "./Steps/Incomes";
import { VerifyEmail } from "./Steps/VerifyEmail";
import { useGetStarted } from "./useGetStarted";
import useUser from "context/UserCustomer/useUserCustomerContext";
import CLoading from "components/CLoading";
import { BorrowerIdentityData } from "./Steps/BorrowerIdentity";
import { ProgressBar } from "./progressBar";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import { BorrowerDemographicInfo } from "./Steps/BorrowerDemographicInfo";
import { useCallback, useEffect, useRef, useState } from "react";
import Loan from "typedef/Loan";
import API from "utils/API";

export const GetStarted = () => {
  const { loan } = useUser();
  const [isLoan, setIsLoan] = useState<Loan | undefined>(loan);
  const loanId = localStorage.getItem("DTCApplication__loanId");
  const loadedIsLoan = useRef(Boolean(isLoan));
  const loadedLoan = useRef(false);

  const getLoanByIdStorge = useCallback(async (params: { loanId?: string }) => {
    try {
      const { loanId } = params;
      if (!loanId) {
        return;
      }
      const response = await API.get<Loan>({
        url: `/getServiceUnsecured/DTC-application?loanId=${loanId}`,
      });
      if ("error" in response) {
        throw new Error(response.error);
      } else {
        return setIsLoan(response.data);
      }
    } catch (error) {
      const err = error as Error;
      console.log("error en get dtc", err);
    }
  }, []);

  useEffect(() => {
    if (!loadedIsLoan.current && loanId) {
      getLoanByIdStorge({ loanId: loanId });
      loadedIsLoan.current = true;
    } else if (loan) {
      setIsLoan(loan);
      loadedIsLoan.current = true;
    }
  }, [getLoanByIdStorge, loanId, loan]);

  const {
    formData,
    setFormData,
    nextStep,
    prevStep,
    step,
    loadingPage,
    handleStepProgressBar,
    handleStepTitle,
    setGlobalError,
    loadingTitle,
    setLoadingTitle,
  } = useGetStarted(isLoan);
  const { sizes } = useHomebridgeSizes();

  useEffect(() => {
    if (!loadedLoan.current && loan?.id) {
      setFormData((prev) => {
        return {
          ...prev,
          loanId: loan.id,
        };
      });
      loadedLoan.current = true;
    }
  }, [loan, setFormData]);

  const stepHandler: { [key: number]: JSX.Element } = {
    1: (
      <Address
        formData={formData}
        onNext={nextStep}
        setFormData={setFormData}
        loan={isLoan}
      />
    ),
    2: (
      <Property
        formData={formData}
        onNext={nextStep}
        onPrev={prevStep}
        setFormData={setFormData}
      />
    ),
    3: (
      <LoanRequest
        formData={formData}
        onNext={nextStep}
        onPrev={prevStep}
        setFormData={setFormData}
      />
    ),
    4: (
      <CreditScore
        formData={formData}
        onNext={nextStep}
        onPrev={prevStep}
        setFormData={setFormData}
      />
    ),
    5: (
      <VerifyEmail
        formData={formData}
        onNext={nextStep}
        onPrev={prevStep}
        setFormData={setFormData}
        loan={isLoan}
        setLoadingPage={setLoadingTitle}
      />
    ),
    6: (
      <Offer
        formData={formData}
        onNext={nextStep}
        onPrev={prevStep}
        setFormData={setFormData}
        setGlobalError={setGlobalError}
        setLoadingPage={setLoadingTitle}
        loan={isLoan}
      />
    ),
    7: (
      <LoanPurpose
        formData={formData}
        onNext={nextStep}
        onPrev={prevStep}
        setFormData={setFormData}
      />
    ),
    8: (
      <TypeOfOwnership
        formData={formData}
        onNext={nextStep}
        onPrev={prevStep}
        setFormData={setFormData}
      />
    ),
    9: (
      <BorrowerPersonalData
        formData={formData}
        onNext={nextStep}
        onPrev={prevStep}
        setFormData={setFormData}
      />
    ),
    10: (
      <Incomes
        formData={formData}
        onNext={nextStep}
        onPrev={prevStep}
        setFormData={setFormData}
      />
    ),
    11: (
      <BorrowerDemographicInfo
        formData={formData}
        onNext={nextStep}
        onPrev={prevStep}
        setFormData={setFormData}
      />
    ),
    12: (
      <BorrowerIdentityData
        formData={formData}
        onPrev={prevStep}
        setFormData={setFormData}
      />
    ),
  };

  const currentStep = stepHandler[step];
  const currentStepTitle = handleStepTitle(step);
  const currentStepProgressBar = handleStepProgressBar(step);
  const show = step < 6;

  return (
    <Box className="get-started">
      {!loadingTitle && step !== 1 && (
        <div className="get-started__wrapper">
          <Box width={"100%"}>
            <Typography
              variant="h2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.title}
              textAlign={"center"}
              marginTop={"20px"}
              marginBottom={"30px"}
              fontWeight={900}
            >
              {currentStepTitle}
            </Typography>

            <ProgressBar progress={currentStepProgressBar} show={show} />
          </Box>
        </div>
      )}
      {loadingPage ? <CLoading /> : currentStep}
    </Box>
  );
};
