function sanitizeString(value?: string): string | null {
  if (!value) return null;

  const sanitized = value?.replace(/[^a-zA-Z0-9 #]/g, "")?.trim();

  if (!sanitized) return null;
  return sanitized;
}

export default sanitizeString;
