import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  Box,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Address from "typedef/Address";
import CAddressAutocomplete from "components/CAddressAutocomplete";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import fondoImagen from "assets/Images/fondohome1.png";
import fondoImagen2 from "assets/Images/fondohome2.png";
import { Theme } from "@mui/system";

import "../home.modules.scss";

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 },
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 0 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "easeInOut",
      duration: 1,
    },
  },
};

interface HeightConfig {
  vh: number;
  heightRatio?: number;
}

// Height configurations for different device categories
const DEVICE_HEIGHT_CONFIGS: Record<string, HeightConfig> = {
  smallPhone: { vh: 125, heightRatio: 1.3 }, // For smaller phones like iPhone SE
  mediumPhone: { vh: 100, heightRatio: 1.2 }, // For medium phones like iPhone XR
  largePhone: { vh: 105 }, // For larger phones
  smallTablet: { vh: 135 }, // For iPad Mini
  largeTablet: { vh: 145 }, // For iPad Pro
  default: { vh: 120 }, // Default fallback
};

const getDeviceCategory = (width: number, height: number): string => {
  if (width < 600) {
    if (height < 700) return "smallPhone";
    if (height < 850) return "mediumPhone";
    return "largePhone";
  }

  if (width < 900) return "smallTablet";
  if (width < 1200) return "largeTablet";

  return "default";
};

const calculateResponsiveHeight = (
  deviceCategory: string,
  windowHeight: number,
): string => {
  const config = DEVICE_HEIGHT_CONFIGS[deviceCategory];

  if (!config) return `${DEVICE_HEIGHT_CONFIGS.default.vh}vh`;

  if (config.heightRatio) {
    const adjustedVh = config.vh * config.heightRatio * (windowHeight / 800);
    return `${Math.min(Math.max(adjustedVh, 90), 150)}vh`;
  }

  return `${config.vh}vh`;
};

export default function CBanner() {
  const navigate = useNavigate();
  const location = useLocation();
  const [address, setAddress] = useState<Address | null>(null);
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();
  const [isExiting, setIsExiting] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  const mainTitle = privateLabel?.borrowerPortalConfiguration
    ?.customHomePageContent?.mainTitle || (
    <>
      Tap your home <br /> equity in 1 minute
    </>
  );
  const isCornerstonePL =
    privateLabel?.id === "PRIVATE#LABEL#test.heloc.houseloan.com" ||
    privateLabel?.id === "PRIVATE#LABEL#heloc.houseloan.com";

  const isNeighborhoodloansPL =
    privateLabel?.id === "PRIVATE#LABEL#test.heloc.neighborhoodloans.com" ||
    privateLabel?.id === "PRIVATE#LABEL#heloc.neighborhoodloans.com";
  const setTimeoutNumber = 500;
  const handleNavigate = () => {
    setIsExiting(true);
    setTimeout(() => {
      navigate(`/signup${location.search}`, {
        state: address,
        replace: true,
      });
    }, setTimeoutNumber);
  };

  const getHeaderXsHeight = () => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    if (privateLabel?.isNFTYDoor) {
      return "70vh";
    }

    if (!privateLabel?.hasOrganicFlow) {
      return privateLabel?.mediaResources?.mobileBanner ? "70vh" : "110vh";
    }

    if (windowWidth <= 900) {
      const deviceCategory = getDeviceCategory(windowWidth, windowHeight);
      const responsiveHeight = calculateResponsiveHeight(
        deviceCategory,
        windowHeight,
      );
      const extraHeight = privateLabel?.mediaResources?.mobileBanner ? 0 : 40; // If PL has no mobile banner, add 40vh
      return `calc(${responsiveHeight} + ${extraHeight}vh)`;
    }

    return "140vh";
  };

  const getHeaderXsDisplay = () => {
    return privateLabel?.isNFTYDoor ? "flex" : "block";
  };

  const getHeaderXsMarginTop = () => {
    return "0";
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial="hidden"
        animate={isExiting ? "out" : "visible"}
        exit="out"
        variants={pageVariants}
        style={{
          width: "100%",
          position: "relative",
          margin: "0 auto",
          background: "#f5f5f5",
          ...(privateLabel?.isNFTYDoor ? { maxWidth: "1200px" } : {}),
        }}
      >
        <Box
          display={{ xs: getHeaderXsDisplay(), md: "flex" }}
          className={privateLabel?.isNFTYDoor ? "header" : ""}
          bgcolor="#f5f5f5"
          justifyContent="center"
          alignItems={{ xs: "center", md: "center" }}
          width="100%"
          marginTop={{ xs: getHeaderXsMarginTop(), md: "0" }}
          height={{ xs: getHeaderXsHeight(), md: "80vh" }}
          maxHeight={{ xs: "1100px", md: "1040px", xl: "1080px" }}
          {...(privateLabel?.isNFTYDoor ? { maxWidth: { xl: "1200px" } } : {})}
        >
          {privateLabel?.mediaResources?.borrowerPortalBanner && (
            <img
              className={
                privateLabel?.isNFTYDoor ? "header__principalimage" : ""
              }
              src={
                !privateLabel.isNFTYDoor &&
                isMobile &&
                privateLabel.mediaResources.mobileBanner
                  ? privateLabel.mediaResources.mobileBanner
                  : privateLabel.mediaResources.borrowerPortalBanner
              }
              alt="Hand holding phone with a holographic house on top of it."
              style={
                !privateLabel.isNFTYDoor
                  ? isMobile && privateLabel.mediaResources.mobileBanner
                    ? {
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }
                    : {
                        width: "100%",
                        height: "80vh",
                        objectFit: "cover",
                        objectPosition: "67%",
                      }
                  : undefined
              }
            />
          )}
          <Container
            maxWidth="lg"
            sx={{
              position: "absolute",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
            >
              <Box
                width={{ md: "fit-content", xs: "100%" }}
                data-acsb-main={true}
                role="main"
                className="home__titlecontainer"
              >
                {privateLabel?.isNFTYDoor && (
                  <motion.img
                    variants={itemVariants}
                    className="home__titlecontainer__img1"
                    src={fondoImagen}
                    alt="fondohome"
                  />
                )}
                {privateLabel?.isNFTYDoor && (
                  <motion.img
                    variants={itemVariants}
                    className="home__titlecontainer__img2"
                    src={fondoImagen2}
                    alt="fondohome"
                  />
                )}
                <motion.div variants={itemVariants}>
                  {isCornerstonePL && (
                    <Typography
                      fontWeight={700}
                      mb={2}
                      variant="h5"
                      className="home__title"
                      color={theme.palette.primary.dark}
                      mt={{ md: 0, xs: "1.5rem" }}
                      fontSize={{
                        lg: "1.8rem",
                        md: "1.5rem",
                        sm: "1.2rem",
                        xs: "1rem",
                      }}
                      role="h5"
                      aria-level={1}
                      sx={{
                        maxWidth: { sm: "600px", xs: "600px" },
                        whiteSpace: "pre-line",
                        "& br": {
                          display: { xs: "none", md: "inline" },
                        },
                      }}
                    >
                      {
                        privateLabel?.borrowerPortalConfiguration
                          ?.customHomePageContent?.productName
                      }
                    </Typography>
                  )}
                  <Typography
                    fontWeight={700}
                    mb={2}
                    variant="h1"
                    className="home__title"
                    color={theme.palette.primary.dark}
                    mt={{ md: 0, xs: "1.5rem" }}
                    fontSize={{
                      lg: "3.4rem",
                      md: "2.8rem",
                      sm: "2.4rem",
                      xs: "2rem",
                    }}
                    role="heading"
                    aria-level={1}
                    sx={{
                      maxWidth: { sm: "600px", xs: "600px" },
                      whiteSpace: "pre-line",
                      "& br": {
                        display: { xs: "none", md: "inline" },
                      },
                    }}
                  >
                    {mainTitle}
                  </Typography>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Typography
                    fontWeight={500}
                    mb={2}
                    variant="h3"
                    fontFamily="'Montserrat', sans-serif"
                    color={
                      isNeighborhoodloansPL
                        ? {
                            xs: theme.palette.primary.dark,
                            md: "#ffffff",
                          }
                        : theme.palette.primary.dark
                    }
                    mt={{ md: 0, xs: "1.5rem" }}
                    fontSize={{
                      lg: "1.3rem",
                      md: "1.2rem",
                      sm: "1.1rem",
                      xs: "1rem",
                    }}
                    role="heading"
                    aria-level={1}
                    sx={{
                      maxWidth: { sm: "500px", xs: "500px" },
                      whiteSpace: "pre-line",
                      "& br": {
                        display: { xs: "none", md: "inline" },
                      },
                    }}
                  >
                    {privateLabel?.lenderName} makes it easy to access the
                    equity in your home with a HELOC.{" "}
                    {privateLabel?.borrowerPortalConfiguration
                      ?.customHomePageContent?.customSubtitle ??
                      "Get the funds you need in just 1 minute"}
                  </Typography>
                </motion.div>
                {privateLabel?.hasOrganicFlow && (
                  <motion.div
                    style={{ maxWidth: "500px" }}
                    variants={itemVariants}
                  >
                    <CAddressAutocomplete
                      address={address}
                      noLabel
                      setAddress={setAddress}
                      placeholder={
                        privateLabel?.borrowerPortalConfiguration
                          ?.customHomePageContent?.checkMyRateInput ??
                        "Start typing your home address and please select from the dropdown."
                      }
                    />
                    <Box mt={2}>
                      <button
                        onClick={handleNavigate}
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          ...(isNeighborhoodloansPL && {
                            borderRadius: "0px",
                            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
                            boxShadow: "none",
                          }),
                        }}
                        className="buttongeneral home__title button third"
                      >
                        Check my rate
                      </button>
                    </Box>
                  </motion.div>
                )}
              </Box>
            </motion.div>
          </Container>
        </Box>
      </motion.div>
    </AnimatePresence>
  );
}
