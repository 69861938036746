import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import DrawRequestModal from "../DrawRequestModal";
import { FC, useEffect, useState } from "react";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import WarningIcon from "@mui/icons-material/Warning";
import dollarFormatter from "utils/FormatterDollar";
import { useIsHomebridge } from "utils/useIsHomebridge";

const PERCENTAGE = 100;

const Draw: FC<HELOCFormProps> = ({
  newValuesSlider,
  loading,
  loan,
  initialValuesSliderHELOC,
  marks,
  recalculateOffer,
  setDrawLessThan100,
  totalPayoffsAndPaydowns,
  fees,
}) => {
  const theme = useTheme();
  const { isHomeBridge } = useIsHomebridge();
  const [editDraw, setEditDraw] = useState(false);

  const [drawValues, setDrawValues] = useState({
    totalInitialDraw: 0,
    drawPercentaje: 0,
  });

  useEffect(() => {
    if (fees?.closingCosts) {
      const totalInitialDraw =
        newValuesSlider?.initialDrawAmount === loan?.finalOffer?.amount
          ? newValuesSlider?.initialDrawAmount ?? 0
          : (newValuesSlider?.initialDrawAmount ?? 0) +
            fees?.closingCosts +
            totalPayoffsAndPaydowns;

      let drawPercentaje = 100;
      if (
        newValuesSlider?.initialDrawAmount &&
        loan?.finalOffer?.initialDrawAmount
      ) {
        drawPercentaje =
          (totalInitialDraw / loan?.finalOffer?.amount) * PERCENTAGE;

        if (drawPercentaje < 100) {
          setDrawLessThan100(true);
        } else {
          setDrawLessThan100(false);
        }
      }

      setDrawValues({
        totalInitialDraw,
        drawPercentaje,
      });
    }
  }, [
    loan?.finalOffer?.amount,
    setDrawLessThan100,
    totalPayoffsAndPaydowns,
    fees?.closingCosts,
    newValuesSlider?.initialDrawAmount,
    loan?.finalOffer?.initialDrawAmount,
  ]);

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      alignItems="center"
      sx={{
        backgroundColor: isHomeBridge
          ? "#D3E1EA"
          : theme.palette.background.paper,
        p: 2,
      }}
    >
      <Typography
        sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
        maxWidth="220px"
        width="100%"
      >
        Draw:
      </Typography>
      <Box
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
        display="flex"
        alignItems="center"
        fontFamily={"Pro Display Bold"}
        gap={{ xs: 0, md: 1 }}
      >
        {loading ||
        !newValuesSlider?.initialDrawAmount ||
        !fees?.closingCosts ? (
          <Skeleton width={"75%"} height={"1.4rem"} />
        ) : (
          <>
            {Number(drawValues?.drawPercentaje?.toFixed(0)) === PERCENTAGE ? (
              <Typography
                fontFamily={"Pro Display Bold"}
                sx={{
                  fontSize: { xs: "1rem", md: "1.2rem" },
                }}
              >
                {dollarFormatter.format(drawValues?.totalInitialDraw)}{" "}
                {`(${drawValues?.drawPercentaje?.toFixed(0)}% draw)`}
              </Typography>
            ) : (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="12px"
              >
                <WarningIcon style={{ color: "#EAB308" }} />
                <Typography
                  fontFamily={"Pro Display Bold"}
                  sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
                  color="#EAB308"
                >
                  {dollarFormatter.format(drawValues?.totalInitialDraw)}{" "}
                  {`(${drawValues?.drawPercentaje?.toFixed(0)}% draw)`}
                </Typography>
              </Box>
            )}

            <Typography
              onClick={() => setEditDraw(true)}
              sx={{
                textDecoration: isHomeBridge ? "underline" : "none",
                cursor: "pointer",
                fontSize: { xs: "1rem", md: "1.2rem" },
              }}
              color="#6767AA"
              data-cy="editDraw"
            >
              Edit
            </Typography>
          </>
        )}
      </Box>

      {editDraw && (
        <DrawRequestModal
          closingCosts={fees?.closingCosts}
          showSlider={initialValuesSliderHELOC?.showSlider}
          openModal={editDraw}
          onClose={() => {
            setEditDraw(false);
          }}
          initialValuesSliderHELOC={initialValuesSliderHELOC}
          marks={marks}
          loading={loading}
          recalculateOffer={recalculateOffer}
          newValuesSlider={newValuesSlider}
          loan={loan}
          totalPayoffsAndPaydowns={totalPayoffsAndPaydowns}
        />
      )}
    </Box>
  );
};

export default Draw;
