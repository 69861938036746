import Typography from "@mui/material/Typography";
import API from "utils/API";
import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import useTracker from "components/CTracker/useTrackerContext";
import CoborrowerInvite from "components/CTracker/StepsDialogs/CoborrowerInvite";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";
import CTrackerTimelineItem from "components/CTimelineItem";
import CButton from "components/CButton";
import runCreditLambda from "./runCreditLambda";
import { mergeMissingDocsByStep } from "utils/missingDocuments";
import UploadEachDocument from "components/CTracker/StepsDialogs/UploadEachDocument";
import { DeniedLoanModal } from "./DeniedLoanModal";
import { errorMessages } from "utils/errorMessages";
import { ConfirmFirstLienMortgageModal } from "./ConfirmFirstLienMortgageModal";
import { ConfirmSSNDOB } from "./ConfirmSSNDOBModal";
import Loan from "typedef/Loan";
import { DeniedHardPullModal } from "./DeniedHardPull";
import CDialog from "components/CDialog";
import runHardCreditLambda from "utils/runHardCreditLambda";
import moment from "moment";

const CreditVerification: React.FC<{
  trackerStatusColor: TrackerStatusColor;
}> = ({ trackerStatusColor }) => {
  const {
    loan,
    refetchLoan,
    type,
    coborrowerInviteIsOpen,
    setCoborrowerInviteIsOpen,
  } = useTracker();
  const loanId = loan?.id;
  const isBorrower = type === "borrower";
  const [loading, setLoading] = useState(false);
  const step = isBorrower ? loan?.borrowerSteps : loan?.coborrowerSteps;
  const flag = isBorrower ? loan?.borrowerFlags : loan?.coborrowerFlags;
  const borrowerType = isBorrower ? "borrower" : "coborrower";
  const [openConfirmSSNDOB, setOpenConfirmSSNDOB] = useState(false);
  const [modalHardpullOpen, setModalHardpullOpen] = useState<boolean>(false);

  const prevStep =
    step?.idVerification.status === "success" ||
    (step?.idVerification.status === "analyzing" &&
      (step?.idVerification.needDocReview !== undefined ||
        step?.idVerification.deletedDocRequest ||
        loan?.borrowerSteps?.idVerification.needDocReview !== undefined ||
        loan?.borrowerSteps?.idVerification.deletedDocRequest));
  const status = prevStep
    ? step?.creditVerification?.status ?? "pending"
    : "pending";
  const messageHardCreditScore = `Completed! ${step?.hardpullVerification?.hardCreditScore} FICO Score!`;
  const message = step?.hardpullVerification?.hardCreditScore
    ? messageHardCreditScore
    : step?.creditVerification?.message;
  const errorCode = step?.creditVerification.errorCode;
  const statement = loan?.borrowerSteps?.creditVerification.statement;
  const borrowerHardpull = isBorrower
    ? loan?.borrowerSteps?.hardpullVerification
    : loan?.coborrowerSteps?.hardpullVerification;
  const differenceInDays =
    borrowerHardpull?.errorCode === "HARD_PULL_IN_LAST_90_DAYS" &&
    moment(borrowerHardpull?.expirationDate).diff(moment(), "days");
  const needDocsUploadB1 =
    loan?.borrowerSteps?.creditVerification?.needDocReview;
  const needDocsUploadB2 =
    loan?.coborrowerSteps?.creditVerification?.needDocReview;
  const stepName = "creditVerification";
  const processingStatus = step?.creditVerification?.processingStatus;

  const docsToUpload = useMemo(() => {
    if (!loan || !loan?.borrowerMissingDocs) return [];

    return mergeMissingDocsByStep(loan, stepName);
  }, [loan]);

  const handleButtonClick = async () => {
    setLoading(true);
    if (loanId && borrowerType) {
      await runCreditLambda(loanId, borrowerType);
      setLoading(false);
    }
  };

  const handleHardCreditPull = async (retryHardpull: boolean) => {
    setLoading(true);
    if (loanId && borrowerType) {
      await runHardCreditLambda(loanId, borrowerType, retryHardpull);
      setLoading(false);
      setModalHardpullOpen(false);
    }
  };

  useEffect(() => {
    if (borrowerHardpull?.errorCode === "HARD_PULL_IN_LAST_90_DAYS") {
      setModalHardpullOpen(true);
    }
  }, [isBorrower, loan, borrowerHardpull]);

  const hardpullFailedVerification =
    step?.hardpullVerification?.status &&
    step?.hardpullVerification?.status === "failed" &&
    step?.hardpullVerification?.hardCreditScore;
  return (
    <CTrackerTimelineItem
      processingStatus={processingStatus}
      status={status}
      title="Credit"
      tooltip="Our initial credit check only requires a soft pull. We use Experian to tell us your credit score and a summary of your credit profile. After Lender Disclosures are signed we do a hard pull of the credit."
      {...(isBorrower &&
        errorCode === "D001" &&
        !loan?.coborrowerInvite &&
        status !== "success" && {
          action: {
            message: "Add Co-Borrower",
            onClick: () => setCoborrowerInviteIsOpen(true),
          },
        })}
    >
      <Box>
        <Typography variant="h6" flex={1} color={[trackerStatusColor[status]]}>
          {status === "analyzing" &&
            (needDocsUploadB1 === false || needDocsUploadB2 === false ? (
              <UploadEachDocument docsToUpload={docsToUpload} loan={loan} />
            ) : (
              message ?? "Analyzing"
            ))}

          {status === "failed" && (message ?? "")}
          {status === "failed" && statement && <p>Statement: {statement}</p>}
          {status === "success" && (message ?? "")}
        </Typography>
        {flag?.creditVerification?.reasonCode === "N001" &&
          !message?.includes("Unfortunately your") &&
          !message?.includes("We are analyzing your credit information") &&
          prevStep &&
          step?.creditVerification?.status !== "success" && (
            <CButton
              variant="contained"
              onClick={() => setOpenConfirmSSNDOB(true)}
              disabled={
                step?.idVerification.status === "analyzing" ||
                step?.creditVerification.status === "analyzing" ||
                loading
              }
              sx={{
                textTransform: "inherit",
                fontSize: 18,
                fontWeight: 500,
                marginBottom: 3,
                marginTop: 3,
              }}
              name="confirm-ssn-dob"
            >
              Confirm SSN/DOB
            </CButton>
          )}
        {(flag?.creditVerification?.reasonCode === "B001" ||
          flag?.creditVerification?.reasonCode === "F001") &&
          !message?.includes("Unfortunately your") &&
          !message?.includes("We are unable to verify your credit") &&
          step?.creditVerification.status !== "success" &&
          prevStep && (
            <CButton
              variant="contained"
              onClick={handleButtonClick}
              disabled={loading}
              sx={{
                textTransform: "inherit",
                fontSize: 18,
                fontWeight: 500,
                marginBottom: 3,
                marginTop: 3,
              }}
              name="creditVerification-retryNow"
            >
              Retry Now
            </CButton>
          )}
      </Box>
      <CoborrowerInvite
        open={coborrowerInviteIsOpen}
        onClose={async () => {
          setCoborrowerInviteIsOpen(false);
          await API.post({
            url: `/save-to-loan/flags?entity=borrower&loanId=${loan?.id}`,
            data: {
              creditVerificationCoborrowerOffered: true,
            },
          });
        }}
      />

      <ConfirmSSNDOB
        loan={loan as Loan}
        refetchLoan={refetchLoan}
        handleOnClose={() => setOpenConfirmSSNDOB(false)}
        open={openConfirmSSNDOB}
      />

      <DeniedLoanModal
        open={
          step?.creditVerification?.adminError ===
          errorMessages.creditVerification.adminError
        }
        message={message ?? ""}
      />
      <DeniedHardPullModal
        open={hardpullFailedVerification as boolean}
        creditScore={step?.hardpullVerification?.hardCreditScore as number}
        errorMessage={
          step?.hardpullVerification?.hardpullMissingMessageBorrower
        }
      />
      <ConfirmFirstLienMortgageModal
        loan={loan}
        open={
          typeof step?.creditVerification?.messageToAdmin === "string"
            ? step.creditVerification.messageToAdmin?.includes(
                "Waiting for the borrower to confirm if it is a first lien mortgage.",
              )
            : (Array.isArray(step?.creditVerification?.messageToAdmin) &&
                step?.creditVerification?.messageToAdmin?.some(
                  (element) =>
                    element ===
                    "Waiting for the borrower to confirm if it is a first lien mortgage.",
                )) ??
              false
        }
        isBorrower={isBorrower}
        message={message ?? ""}
        docsToUpload={docsToUpload}
      />
      {modalHardpullOpen && (
        <CDialog
          open={modalHardpullOpen}
          className="denied-loan-modal"
          icon="warning"
        >
          <Box>
            <Typography variant="h3">Confirm credit information</Typography>

            <Typography
              sx={{ marginTop: "16px", fontSize: "1.2rem", fontWeight: 700 }}
            >
              {`You have a historical hard credit pull on file, it expires in ${differenceInDays} (days left for expiring) days. Do you wish to repull your credit?`}
            </Typography>
            <Box sx={{ padding: "1rem", textAlign: "start" }}>
              <Typography
                sx={{ marginTop: "16px", fontSize: "1.2rem", fontWeight: 700 }}
              >
                YES: This resets the file with a new credit score and tradelines
              </Typography>
              <Typography
                sx={{ marginTop: "16px", fontSize: "1.2rem", fontWeight: 600 }}
              >
                NO: We will use your previous credit report. If the loan does
                not close by {borrowerHardpull?.expirationDate} (date the
                hardpull expires) we will need to repull and your loan may be
                repriced.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "16px",
              }}
            >
              <CButton
                loading={loading}
                variant="contained"
                onClick={() => {
                  handleHardCreditPull(false);
                }}
                name={"no-confirm-hardpull"}
                aria-label={"no-confirm-hardpull"}
              >
                No
              </CButton>

              <CButton
                loading={loading}
                variant="contained"
                onClick={() => {
                  handleHardCreditPull(true);
                }}
                name={"yes-confirm-hardpull"}
                aria-label={"yes-confirm-hardpull"}
              >
                Yes
              </CButton>
            </Box>
          </Box>
        </CDialog>
      )}
    </CTrackerTimelineItem>
  );
};

export default CreditVerification;
