import { useState } from "react";
import useLoan from "components/CTracker/useLoan";
import PricingEngine from "typedef/PricingEngine";
import { handleOcuppancy } from "screens/BorrowerRegisterForm/useRegisterForm";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { useQuery } from "react-query";

export const useGetDtiByCreditScore = () => {
  const { userAdmin } = useUser();
  const { loan } = useLoan();
  const [dtiItems, setDtiItems] = useState<{
    dti: number | undefined;
    dtiHurdle: number;
    dtiAdjustor: number;
  }>({
    dti: undefined,
    dtiHurdle: 0,
    dtiAdjustor: 0,
  });

  const higherEarner =
    (loan?.borrowerSteps?.incomeVerification?.annualIncome ?? 0) >=
    (loan?.coborrowerSteps?.incomeVerification?.annualIncome ?? 0)
      ? "B1"
      : "B2";

  const creditScoreB1 =
    loan?.borrowerSteps?.hardpullVerification?.hardCreditScore &&
    loan?.borrowerSteps?.hardpullVerification?.hardCreditScore !== -1
      ? loan?.borrowerSteps?.hardpullVerification?.hardCreditScore
      : loan?.borrowerSteps?.creditVerification?.creditScore ??
        loan?.initialOffer?.creditScore;

  const creditScoreB2 =
    loan?.coborrowerSteps?.hardpullVerification?.hardCreditScore &&
    loan?.coborrowerSteps?.hardpullVerification?.hardCreditScore !== -1
      ? loan?.coborrowerSteps?.hardpullVerification?.hardCreditScore
      : loan?.coborrowerSteps?.creditVerification?.creditScore;

  const creditScore = higherEarner === "B1" ? creditScoreB1 : creditScoreB2;

  const queryType = userAdmin?.id ? "AdminHeloc" : "Heloc";
  const privateLabelId = loan?.privateLabelId;
  const helocType = handleOcuppancy(loan?.occupancy ?? "Primary Residence");
  const pricingEngineId = `${privateLabelId}#${helocType}`;
  const encodePricingEngineId = encodeURIComponent(pricingEngineId);

  const { isError } = useQuery<PricingEngine>({
    queryKey: [queryType, encodePricingEngineId, loan?.pricingEngineCreatedAt],
    enabled: !!loan,
    onSuccess: (data) => {
      const rangesCreditScore: PricingEngine["rangesCreditScore"] =
        data.rangesCreditScore;

      for (const range of rangesCreditScore) {
        if (
          creditScore &&
          creditScore >= range.min &&
          creditScore <= range.max
        ) {
          const dti = range.dti;
          const dtiHurdle = range.dtiHurdle;
          const dtiAdjustor = range.dtiAdjustor;
          setDtiItems({ dti, dtiHurdle, dtiAdjustor });
        }
      }
    },
  });

  if (isError) {
    throw new Error("DTI items could not be retrieved");
  }

  return dtiItems;
};
