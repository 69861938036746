import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "screens/BorrowerInviteRegisterForm/blueSageValidateLicense";
import useCalculateMaxLoan from "screens/BorrowerInviteRegisterForm/useCalculateMaxLoan";
import Loan from "typedef/Loan";
import PricingEngine from "typedef/PricingEngine";
import API from "utils/API";
import getEstimatedMonthlyPayment from "utils/getEstimatedMonthlyPayment";
import { getUseOfProceedsAdjuster } from "utils/getUseOfProceedsAdjuster";
import toPercentage from "utils/toPercentage";
import { useGetDtiByCreditScore } from "utils/useGetDtiByCreditScore";

type InterestRateResponse = {
  monthlyInterestCharge: number;
  interestRate: number;
  marginRate: number;
  primeRate: number;
  stateCap: number;
  monthlyPayment: number;
  error?: string;
};
const PERCENTAGE = 100;
const AMOUNT_OF_DECIMALS = 2;
const MINIMUM_LOAN_AMOUNT_AK = 25001;
const MINIMUM_LOAN_AMOUNT = 25000;
const AMOUNT_OF_MONTHS_IN_A_YEAR = 12;

export const useOffers = (loan: Loan) => {
  const [isLoading, setIsLoading] = useState(true);
  const [interestRate, setInterestRate] = useState<
    { error: string } | number | undefined
  >();
  const [stateCap, setStateCap] = useState<number>();
  const [monthlyPayment, setMonthlyPayment] = useState(
    loan?.initialOffer?.monthlyPayment,
  );

  const dtiItems = useGetDtiByCreditScore();
  const parsePercentageToNumber = (percentage: string) => {
    return Number(percentage?.replace("%", "")) / PERCENTAGE;
  };
  const { privateLabel } = usePrivateLabel();

  const lenderName = privateLabel?.lenderName;
  const creditScore =
    loan?.borrowerSteps?.creditVerification.creditScore ??
    loan?.initialOffer.creditScore;
  const currentLoanBalance =
    loan?.borrowerSteps?.creditVerification?.loanBalance ??
    loan?.initialOffer?.currentLoanBalance;
  const requestedLoanAmount =
    loan?.finalOffer?.amount ?? loan?.initialOffer?.amount;
  const homeValue =
    loan?.borrowerSteps?.appraisalVerification?.homeValue ??
    loan?.borrowerSteps?.homeValuationVerification?.estimatedCostData ??
    loan?.property?.homeValue;
  const useOfProceedsSelectedValue = loan?.initialOffer?.useProceeds;
  const state = loan?.property?.address?.components?.state_abbreviation;
  const zipcode = loan?.property?.address?.components?.zipcode;
  const { maxLoanAmount, pricingEngine: pricingEngineData } =
    useCalculateMaxLoan(undefined, loan);
  const getIndexCreditScore = (
    pricingEngine: PricingEngine | undefined,
    creditScoreNumber: number,
  ) => {
    return pricingEngine?.rangesCreditScore?.findIndex(
      (item) => creditScoreNumber >= item.min && creditScoreNumber <= item.max,
    );
  };
  const cltvValue = useMemo(() => {
    const cltv = (currentLoanBalance + requestedLoanAmount) / homeValue;
    return cltv;
  }, [currentLoanBalance, homeValue, requestedLoanAmount]);
  const getMarginRate = ({
    pricingEngine,
    cltv,
    creditScoreValue,
  }: {
    pricingEngine: PricingEngine;
    cltv: number;
    creditScoreValue: number;
  }) => {
    const indexCS = getIndexCreditScore(pricingEngine, creditScoreValue);
    const indexCltv = pricingEngine?.rangesCltv?.findIndex((item) => {
      if (item.min === 0) {
        return (
          Number(cltv.toFixed(AMOUNT_OF_DECIMALS)) >= item.min &&
          Number(cltv.toFixed(AMOUNT_OF_DECIMALS)) <= item.max
        );
      } else {
        return (
          Number(cltv.toFixed(AMOUNT_OF_DECIMALS)) > item.min &&
          Number(cltv.toFixed(AMOUNT_OF_DECIMALS)) <= item.max
        );
      }
    });
    let marginRate = null;
    const margin = pricingEngine?.margin;
    if (indexCS !== -1 && indexCltv !== -1) {
      marginRate = margin?.[indexCS ?? 0][indexCltv];
    }
    return marginRate;
  };
  const validateRequestAmount = (
    requestedAmount: number,
    loanBalance: number,
    currentState: string,
    pricingData: PricingEngine,
  ) => {
    if (!requestedAmount) return false;
    if (
      requestedAmount <
        (currentState === "AK"
          ? MINIMUM_LOAN_AMOUNT_AK
          : MINIMUM_LOAN_AMOUNT) ||
      requestedAmount > maxLoanAmount ||
      requestedAmount >
        (loanBalance === 0
          ? pricingData?.loanMaxFirstLien
          : pricingData?.loanMax)
    )
      return true;
  };
  const totalAnnualIncomeBorrower =
    loan?.initialOffer?.totalAnnualIncomeBorrower;
  const totalAnnualIncomeCoborrower =
    loan?.initialOffer?.totalAnnualIncomeCoborrower;
  const annualDebts = useMemo(() => {
    const result = monthlyPayment * AMOUNT_OF_MONTHS_IN_A_YEAR;
    return result;
  }, [monthlyPayment]);
  //** DTI calc */
  const totalHouseholdIncome = useMemo(() => {
    let result;
    if (totalAnnualIncomeCoborrower && totalAnnualIncomeCoborrower > 0)
      result =
        totalAnnualIncomeBorrower &&
        totalAnnualIncomeBorrower + totalAnnualIncomeCoborrower;
    else result = totalAnnualIncomeBorrower;
    return result;
  }, [totalAnnualIncomeBorrower, totalAnnualIncomeCoborrower]);
  const DEBOUNCE_TIMEOUT = 500;
  const debouncedAnnualDebts = useDebounce(annualDebts, DEBOUNCE_TIMEOUT);
  const debouncedTotalHouseholdIncome = useDebounce(
    totalHouseholdIncome,
    DEBOUNCE_TIMEOUT,
  );
  const dtiValue = useMemo(() => {
    if (totalHouseholdIncome === undefined) return 0;
    const dti = annualDebts / totalHouseholdIncome;
    if (!dti) return undefined;
    const result = toPercentage(dti);
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAnnualDebts, debouncedTotalHouseholdIncome]);
  const occupancyType = loan?.occupancy;
  useEffect(() => {
    if (!cltvValue) return;
    setIsLoading(true);
    const dti = dtiValue ? parsePercentageToNumber(dtiValue) : 0;
    const data = {
      initialDrawAmount: requestedLoanAmount,
      cltv: cltvValue,
      creditScore: creditScore,
      currentLoanBalance: currentLoanBalance,
      homeValue,
      occupancyType: occupancyType,
      state: state,
      useOfProceeds: useOfProceedsSelectedValue,
      dti,
      zipcode: zipcode,
    };
    const TIMEOUT_DELAY = 1500;
    if (pricingEngineData === undefined) return;
    const timeOut = setTimeout(() => {
      const validateAmount = validateRequestAmount(
        requestedLoanAmount,
        currentLoanBalance,
        state,
        pricingEngineData,
      );
      if (validateAmount === false) {
        setInterestRate({
          error:
            "We cannot provide an offer because FICO is too low. Please archive this application and reapply later",
        });
      } else {
        API.post<InterestRateResponse>({
          url: `/get/interestRate`,
          data,
        })
          .then((result) => {
            if ("error" in result) {
              setInterestRate(undefined);
            } else {
              setInterestRate(result?.data?.interestRate);
              setMonthlyPayment(result?.data?.monthlyPayment);
              setStateCap(result?.data?.stateCap);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }, TIMEOUT_DELAY);
    return () => {
      clearTimeout(timeOut);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    cltvValue,
    creditScore,
    occupancyType,
    state,
    useOfProceedsSelectedValue,
    dtiValue,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */
  const offertOptionsByFico = useMemo(() => {
    if (
      (!requestedLoanAmount ||
        !maxLoanAmount ||
        !creditScore ||
        !pricingEngineData) &&
      !isLoading
    )
      return [];
    const indexCreditScore = getIndexCreditScore(
      pricingEngineData,
      creditScore,
    );
    if (indexCreditScore === undefined) {
      return [];
    }
    const rowsNumber = pricingEngineData?.margin?.[indexCreditScore]?.filter(
      (el) => el !== null,
    ).length;
    if (rowsNumber === undefined) {
      return [];
    }
    const offers: { maxLoanAmount: number | undefined; rate: number }[] = [];
    for (let index = 0; index < rowsNumber; index++) {
      const currMaxCltv = pricingEngineData?.rangesCltv[index]?.max;
      const newRequestedLoanAmount =
        (currMaxCltv ?? 0) * homeValue - currentLoanBalance;
      if (!pricingEngineData || !currMaxCltv) {
        return [];
      }
      const marginRate =
        getMarginRate({
          pricingEngine: pricingEngineData,
          cltv: currMaxCltv,
          creditScoreValue: creditScore,
        }) ?? 0;
      const loanPurposeValue = getUseOfProceedsAdjuster(
        pricingEngineData?.marginAdjusters?.loanPurpose,
        useOfProceedsSelectedValue,
      );
      ///** DTI Adjuster */

      const dti = dtiValue ? parsePercentageToNumber(dtiValue) : 0;
      let dtiAdjustorValue = 0;
      if (dtiItems.dtiHurdle && dti >= dtiItems.dtiHurdle) {
        dtiAdjustorValue = dtiItems.dtiAdjustor;
      }
      let interestRateValue;
      if (currentLoanBalance === 0) {
        interestRateValue =
          marginRate +
          (pricingEngineData?.primeRate ?? 0) -
          (pricingEngineData?.firstLienDiscount ?? 0) +
          loanPurposeValue +
          dtiAdjustorValue;
      } else {
        interestRateValue =
          marginRate +
          (pricingEngineData?.primeRate ?? 0) +
          loanPurposeValue +
          dtiAdjustorValue;
      }
      if (newRequestedLoanAmount > requestedLoanAmount) {
        if (currentLoanBalance === 0) {
          if (newRequestedLoanAmount < pricingEngineData?.loanMaxFirstLien) {
            offers.push({
              maxLoanAmount: Math.floor(newRequestedLoanAmount),
              rate: interestRateValue,
            });
          } else if (
            !offers?.some(
              (offer) =>
                offer.maxLoanAmount === pricingEngineData?.loanMaxFirstLien,
            )
          ) {
            offers.push({
              maxLoanAmount: Math.floor(pricingEngineData?.loanMaxFirstLien),
              rate: interestRateValue,
            });
          }
        } else if (newRequestedLoanAmount < pricingEngineData?.loanMax) {
          offers.push({
            maxLoanAmount: Math.floor(newRequestedLoanAmount),
            rate: interestRateValue,
          });
        } else if (
          !offers?.some(
            (offer) => offer.maxLoanAmount === pricingEngineData?.loanMax,
          )
        ) {
          offers.push({
            maxLoanAmount: Math.floor(pricingEngineData?.loanMax),
            rate: interestRateValue,
          });
        }
      }
    }
    if (stateCap && stateCap !== 0) {
      const filteredOffers = offers.filter((item) => item.rate <= stateCap);
      return filteredOffers;
    } else {
      const offersWithMonthlyPayment = offers.map((offer) => ({
        ...offer,
        maxLoanAmount: Math.floor(offer.maxLoanAmount ?? 0),
        monthlyPayment: getEstimatedMonthlyPayment(
          Math.floor(offer.maxLoanAmount ?? 0),
          offer.rate,
        ),
      }));
      return offersWithMonthlyPayment;
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    creditScore,
    cltvValue,
    maxLoanAmount,
    stateCap,
    useOfProceedsSelectedValue,
    dtiValue,
  ]);

  /* eslint-enable react-hooks/exhaustive-deps */
  return {
    offertOptionsByFico,
    interestRate,
    isLoading,
    requestedLoanAmount,
    state,
    lenderName,
  };
};
