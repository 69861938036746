import { Box, Typography } from "@mui/material";
import { CHBButton } from "components/CHBButton";
import { HBSeparate } from "components/HBSeparate";
import Occupancy from "typedef/Occupancy";
import { CSSProperties, useState, useEffect, useRef } from "react";
import { FormData } from "../../useGetStarted";
import AddressType from "typedef/Address";
import { PrimaryResidence } from "components/icons/PrimaryResidence";
import { InvestmentProperty } from "components/icons/InvestmentProperty";
import { SecondHome } from "components/icons/SecondHome";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";
import { useUpdateLoanStatus } from "screens/GetStarted/useUpdateLoanStatus";
import addressToString from "components/CAddressAutocomplete/addressToString";
import { InputAddress } from "../Address/InputAddress";

export interface PropertyProps {
  onNext: () => void;
  onPrev: () => void;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

export const Property = ({
  onNext,
  onPrev,
  formData,
  setFormData,
}: PropertyProps) => {
  const { sizes } = useHomebridgeSizes();
  const [error, setError] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [currentActive, setCurrentActive] = useState(formData.occupancyType);
  const [showBorrowerAddress, setShowBorrowerAddress] = useState(false);
  const [hasSelectedAddress, setHasSelectedAddress] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [borrowerAddress, setBorrowerAddress] = useState<
    AddressType | undefined
  >({
    city: "",
    state: "",
    street_line: "",
    secondary: "",
    zipcode: "",
    entries: 0,
  });

  const propertyAddress = {
    city: formData.city || "",
    state: formData.state || "",
    street_line: formData.street_line || "",
    secondary: formData.secondary || "",
    zipcode: formData.zipcode || "",
    entries: formData.entries || 0,
  };

  const valueInputRef = useRef<HTMLInputElement>(null);

  const handleLoanStatusError = (error: string) => {
    setErrorMessage(error);
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
    }, 300);
  };

  useEffect(() => {
    if (
      currentActive === "Second Home" ||
      currentActive === "Investment Property"
    ) {
      setShowBorrowerAddress(true);
      setTimeout(() => {
        valueInputRef.current?.focus();
      }, 100);
    } else {
      setShowBorrowerAddress(false);
      setBorrowerAddress({
        city: "",
        state: "",
        street_line: "",
        secondary: "",
        zipcode: "",
        entries: 0,
      });
    }
  }, [currentActive]);

  const { puUpdateLoanStatus } = useUpdateLoanStatus(handleLoanStatusError);

  const handleNext = async () => {
    setErrorMessage("");
    const isBorrowerAddressUsed =
      formData.occupancyType === "Second Home" ||
      formData.occupancyType === "Investment Property";

    if (!formData.occupancyType) {
      setAnimation(true);
      setError(true);
      setTimeout(() => {
        setAnimation(false);
      }, 300);
    } else {
      if (
        isBorrowerAddressUsed &&
        !borrowerAddress?.street_line &&
        !hasSelectedAddress
      ) {
        setAnimation(true);
        setError(true);
        setErrorMessage("Please enter a property");
        valueInputRef.current?.focus();
        setTimeout(() => {
          setAnimation(false);
        }, 300);
      } else {
        const isSameAddress =
          addressToString(borrowerAddress) === addressToString(propertyAddress);

        if (isSameAddress) {
          setAnimation(true);
          setError(true);
          setErrorMessage(
            "Sub property address and primary home address cannot be the same",
          );
          valueInputRef.current?.focus();
          setTimeout(() => {
            setAnimation(false);
          }, 300);
        } else {
          setError(false);
          setLoading(true);
          setFormData({
            ...formData,
            borrowerAddress: borrowerAddress ? borrowerAddress : undefined,
          });
          const data = await puUpdateLoanStatus({
            body: {
              occupancyType: formData.occupancyType,
              borrowerAddress: borrowerAddress,
            },
          });

          if (data?.message === "Loan status updated.") {
            onNext();
          }
          setLoading(false);
        }
      }
    }
  };

  const handleSelect = (type: Occupancy) => {
    setErrorMessage("");
    setFormData({ ...formData, occupancyType: type });
    setCurrentActive(type);
    setError(false);
  };

  const descriptionStyles: CSSProperties = {
    textTransform: "capitalize",
    marginTop: "0.45rem",
  };
  return (
    <Box>
      <Box marginTop={"60px"}>
        <Box
          sx={{
            display: "grid",
            justifyItems: "center",
            gridTemplateColumns: {
              xs: "",
              md: "repeat(3, 1fr)",
            },
            justifyContent: {
              xs: "",
              md: "center",
            },
            gap: "20px",
          }}
        >
          <CHBButton
            onClick={() => handleSelect("Primary Residence")}
            classNameContainer="full-width"
            className={`animated-button ${
              currentActive === "Primary Residence"
                ? "animated-button--active"
                : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border:
                error && errorMessage === ""
                  ? "2px solid red"
                  : "2px solid #20A2D8",
              animation:
                animation && errorMessage === ""
                  ? "shake 0.3s ease-in-out"
                  : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <PrimaryResidence />
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              marginBottom={"10px"}
              className="custom-typography text-black"
              sx={descriptionStyles}
            >
              Full-Time
              <br /> Residence
            </Typography>
          </CHBButton>
          <CHBButton
            onClick={() => handleSelect("Second Home")}
            classNameContainer="full-width"
            className={`animated-button ${
              currentActive === "Second Home" ? "animated-button--active" : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border:
                error && errorMessage === ""
                  ? "2px solid red"
                  : "2px solid #20A2D8",
              animation:
                animation && errorMessage === ""
                  ? "shake 0.3s ease-in-out"
                  : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <SecondHome />
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              marginBottom={"10px"}
              className="custom-typography text-black"
              sx={descriptionStyles}
            >
              Second <br />
              Home
            </Typography>
          </CHBButton>
          <CHBButton
            onClick={() => handleSelect("Investment Property")}
            classNameContainer="full-width"
            className={`animated-button ${
              currentActive === "Investment Property"
                ? "animated-button--active"
                : ""
            }`}
            styleContainer={{
              height: "100%",
              width: "100%",
            }}
            style={{
              border:
                error && errorMessage === ""
                  ? "2px solid red"
                  : "2px solid #20A2D8",
              animation:
                animation && errorMessage === ""
                  ? "shake 0.3s ease-in-out"
                  : "none",
              height: "100%",
              padding: "20px",
            }}
          >
            <InvestmentProperty />
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              marginBottom={"10px"}
              className="custom-typography text-black"
              sx={descriptionStyles}
            >
              Investment <br />
              Property
            </Typography>
          </CHBButton>
        </Box>
        <p
          className="get-started__note"
          style={{ marginTop: "40px", marginBottom: "30px" }}
        >
          Knowing this will help us determine your available line of credit.
        </p>
        {showBorrowerAddress && (
          <Box
            sx={{
              fontFamily: "NunitoSans",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InputAddress
              inputLabel="Please enter the address of the home you currently live in."
              setCurrentAddress={setBorrowerAddress}
              valueInputRef={valueInputRef}
              error={error}
              setError={setError}
              animation={animation}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              addressToString={addressToString}
              setHasSelectedAddress={setHasSelectedAddress}
            />
          </Box>
        )}
        {/* <Typography
          className="get-started__note"
          variant="body2"
          fontFamily={"NunitoSans"}
          fontSize={"16px"}
          color={"error"}
          sx={{
            animation: animation ? "shake 0.3s ease-in-out" : "none",
          }}
        >
          {errorMessage !== "" ? errorMessage : ""}
        </Typography> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              sm: "row",
            },
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "40px",
          }}
        >
          <CHBButton onClick={onPrev} secondaryArrow={true} disabled={loading}>
            Go Back
          </CHBButton>
          <CHBButton
            onClick={handleNext}
            loading={loading}
            disabled={loading}
            ctaId="Step2PropertyTaken"
          >
            Next
          </CHBButton>
        </Box>
      </Box>
      <HBSeparate className="step-separate" type="vertical" />
    </Box>
  );
};
