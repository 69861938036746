import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { statesNames } from "typedef/stateMaping";
import { useIsHomebridge } from "utils/useIsHomebridge";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";

interface StyledTableCellProps {
  headColor?: string;
}

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "isHomebridge",
})<StyledTableCellProps>(({ theme, headColor }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: headColor ?? theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables() {
  const { isHomeBridge } = useIsHomebridge();
  const { primaryBackground, primaryText } = useHomeBridgeColors();
  const { privateLabel } = usePrivateLabel();

  return (
    <TableContainer component={Paper} elevation={0} sx={{ overflowX: "auto" }}>
      <Box>
        <Typography
          mt={4}
          mb={5}
          ml={5}
          variant="h2"
          lineHeight={1}
          letterSpacing={-0.8}
          pl={{ xs: 2, md: 0 }}
          fontSize={{ xs: "2rem", md: "4rem" }}
        >
          Licenses
        </Typography>
      </Box>
      <Box sx={{ width: "100%", maxWidth: "100vw", overflowX: "auto" }}>
        <Table
          sx={{
            minWidth: 600,
            width: "100%",
            borderCollapse: "collapse",
            border: isHomeBridge ? "1px solid white" : "1px solid black",
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {["State", "License Name", "License Number"].map(
                (text, index) => (
                  <StyledTableCell
                    headColor={isHomeBridge ? "black" : undefined}
                    key={index}
                    align="center"
                    sx={{
                      borderRight: isHomeBridge
                        ? "1px solid white"
                        : "1px solid #ddd",
                      backgroundColor: isHomeBridge
                        ? primaryBackground
                        : undefined,
                    }}
                  >
                    {text}
                  </StyledTableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {privateLabel?.statesLicensed.map((data) => (
              <StyledTableRow key={data.state_id}>
                <StyledTableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{
                    backgroundColor: isHomeBridge
                      ? primaryBackground
                      : undefined,
                    color: isHomeBridge ? primaryText : undefined,
                  }}
                  style={{
                    borderRight: isHomeBridge
                      ? "1px solid white"
                      : "1px solid black",
                  }}
                >
                  {
                    statesNames.find(
                      (state) => state.state_id === data.state_id,
                    )?.stateName
                  }
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    backgroundColor: isHomeBridge
                      ? primaryBackground
                      : undefined,
                    color: isHomeBridge ? primaryText : undefined,
                  }}
                  align="center"
                  style={{
                    borderRight: isHomeBridge
                      ? "1px solid white"
                      : "1px solid black",
                  }}
                >
                  {data.licenseName}
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    backgroundColor: isHomeBridge
                      ? primaryBackground
                      : undefined,
                    color: isHomeBridge ? primaryText : undefined,
                  }}
                  align="center"
                  style={{
                    borderRight: isHomeBridge
                      ? "1px solid white"
                      : "1px solid black",
                  }}
                >
                  {data.licenseNumber}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  );
}
