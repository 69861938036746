import {
  IconButton,
  InputAdornment,
  Typography,
  Box,
  useTheme,
  Checkbox,
} from "@mui/material";
import CDialog from "components/CDialog";
import { useConfirmSSNDOB } from "./useConfirmSSNDOB";
import { Close, RemoveRedEye, VisibilityOff } from "@mui/icons-material";
import CTextField from "components/CTextField";
import moment from "moment";
import CNumberField from "components/CNumberField";
import CButton from "components/CButton";
import Loan from "typedef/Loan";
import { useEffect, useState } from "react";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { QueryObserverResult } from "react-query";

interface ConfirmSSNDOBModalProps {
  loan: Loan;
  refetchLoan: () => Promise<QueryObserverResult<Loan, unknown>>;
  open: boolean;
  handleOnClose: () => void;
}

export const ConfirmSSNDOB = ({
  loan,
  refetchLoan,
  open,
  handleOnClose,
}: ConfirmSSNDOBModalProps) => {
  const theme = useTheme();
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    if (open) setConsent(false);
  }, [open]);

  const handleConsent = () => {
    setConsent((prev: boolean) => !prev);
  };
  const { privateLabel } = usePrivateLabel();
  const lenderName = privateLabel?.isNFTYDoor
    ? "Homebridge Financial Services, Inc."
    : privateLabel?.legalLenderName;

  const {
    styles,
    loading,
    error,
    errorsInputs,
    user,
    showSocialSecurityNumber,
    toggleShowSocialSecurityNumber,
    handleUserChange,
    handleSubmit,
    setErrorsInputs,
    resetForm,
  } = useConfirmSSNDOB(loan, refetchLoan, handleOnClose);

  return (
    <CDialog open={open} onClose={handleOnClose} maxWidth="xs">
      <Box display="flex" alignItems="center">
        <Typography
          mr={10}
          variant="h5"
          fontSize={30}
          textAlign="left"
          fontFamily={"Pro Display Semibold"}
        >
          Confirm SSN/DOB
        </Typography>
        <IconButton
          onClick={() => {
            setConsent(false);
            setErrorsInputs({});
            resetForm();
            handleOnClose();
          }}
        >
          <Close fontSize="large" htmlColor={theme.palette.text.disabled} />
        </IconButton>
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <CNumberField
          fullWidth
          id={"socialSecurityNumber"}
          name={"socialSecurityNumber"}
          label={"Social Security Number"}
          type={showSocialSecurityNumber ? "text" : "password"}
          sx={{ my: 4, width: "100%" }}
          format="###-##-####"
          placeholder="xxx-xx-xxxx"
          onChange={handleUserChange}
          error={errorsInputs.socialSecurityNumber}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowSocialSecurityNumber}>
                  {showSocialSecurityNumber ? (
                    <RemoveRedEye sx={styles.icon} />
                  ) : (
                    <VisibilityOff sx={styles.icon} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={user.socialSecurityNumber}
        />
        <CTextField
          fullWidth
          id="dateOfBirth"
          name="dateOfBirth"
          type="date"
          sx={{ width: "100%" }}
          onChange={handleUserChange}
          InputProps={{
            inputProps: {
              min: "1908-02-05",
              max: moment().subtract(18, "year").format("YYYY-MM-DD"),
            },
          }}
          format="YYYY-MM-DD"
          label="Date of Birth"
          value={user.dateOfBirth}
          error={errorsInputs.dateOfBirth}
        />
        <Box
          mt={3}
          display={"flex"}
          alignItems="flex-start"
          flexDirection="row"
          justifyContent={{ xs: "center", md: "left" }}
          maxWidth={360}
        >
          <Checkbox onClick={() => handleConsent()} />
          <Typography
            mt={1}
            variant="body2"
            color={theme.palette.text.primary}
            textAlign={"justify"}
          >
            By clicking <strong style={{ fontWeight: "500" }}>"Save"</strong>, I
            am providing ‘written instructions’ to{" "}
            <strong style={{ fontWeight: "500" }}>{lenderName}</strong> under
            the Fair Credit Reporting Act authorizing{" "}
            <strong style={{ fontWeight: "500" }}>{lenderName}</strong> to
            obtain information from my personal credit profile or other
            information related to my employment and income information.
          </Typography>
        </Box>
        <Box mt={2}>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
        <Box sx={styles.containerButton}>
          <CButton
            type="button"
            loading={loading}
            variant="contained"
            sx={styles.buttonStyles}
            onClick={() => {
              setConsent(false);
              resetForm();
              setErrorsInputs({});
              handleOnClose();
            }}
            name={"discard-confirm-ssn-dob"}
            aria-label={"discard-confirm-ssn-dob"}
          >
            Discard
          </CButton>
          <CButton
            type="submit"
            disabled={
              loading ||
              Boolean(Object.keys(errorsInputs).length > 0) ||
              !consent
            }
            loading={loading}
            variant="contained"
            sx={styles.buttonStyles}
            name={"send-confirm-ssn-dob"}
            aria-label={"send-confirm-ssn-dob"}
          >
            Confirm SSN/DOB
          </CButton>
        </Box>
      </Box>
    </CDialog>
  );
};
