import React, { useEffect, useState } from "react";
import { Box, Typography, Skeleton, Slider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CDialog from "components/CDialog";
import CButton from "components/CButton";
import { Controller, useForm } from "react-hook-form";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import {
  ValuesHELOC,
  ValuesSliderProps,
} from "screens/TypeOfCreditLine/useDefineLoanType";
import dollarFormatter from "utils/FormatterDollar";
import Loan from "typedef/Loan";
import SliderValueDisplay from "screens/TypeOfCreditLine/DrawRequestModal/SliderValueDisplay";

interface DrawRequestModalProps {
  closingCosts: number | undefined;
  showSlider: boolean | undefined;
  openModal: boolean;
  onClose: (event?: React.SyntheticEvent, reason?: string) => void;
  initialValuesSliderHELOC: ValuesSliderProps | undefined;
  marks: {
    value: number;
  }[];
  recalculateOffer: (value: number | number[], closingCost: number) => void;
  loading: boolean;
  newValuesSlider: ValuesHELOC | undefined;
  loan?: Loan;
  totalPayoffsAndPaydowns: number;
}

interface FormData {
  newLoanAmount: number;
}

type ExtendedFormData = Omit<FormData, "newLoanAmount"> & {
  newLoanAmount: number;
};

const DrawRequestModal: React.FC<DrawRequestModalProps> = ({
  closingCosts,
  showSlider,
  openModal,
  onClose,
  initialValuesSliderHELOC,
  marks,
  loading,
  recalculateOffer,
  newValuesSlider,
  loan,
  totalPayoffsAndPaydowns,
}) => {
  const theme = useTheme();
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    setValue,
  } = useForm<ExtendedFormData>();

  const [drawAmmountValue, setDrawAmmountValue] = useState<number | undefined>(
    initialValuesSliderHELOC?.maxDraw,
  );

  const isDrawAmountAtMax =
    loan?.finalOffer?.amount === newValuesSlider?.initialDrawAmount;

  const initialDrawAmount = newValuesSlider?.initialDrawAmount ?? 0;

  const closingCostsValue = closingCosts ?? 0;

  const additionalCosts = closingCostsValue + totalPayoffsAndPaydowns;

  const initalDraw = isDrawAmountAtMax
    ? initialDrawAmount
    : initialDrawAmount + additionalCosts;

  const [sliderValue, setSliderValue] = useState<number>(initalDraw);
  const [diffMaxDraw, setDiffMaxDraw] = useState<boolean>(false);

  const estNetProceeds = sliderValue - additionalCosts;

  useEffect(() => {
    if (
      initialValuesSliderHELOC?.maxDraw &&
      sliderValue < initialValuesSliderHELOC?.maxDraw
    )
      setDiffMaxDraw(true);
    else setDiffMaxDraw(false);
  }, [sliderValue, initialValuesSliderHELOC?.maxDraw]);

  const handlerChangeDraw = (value: number) => {
    const difference =
      initialValuesSliderHELOC?.maxDraw &&
      initialValuesSliderHELOC?.maxDraw - value;

    if (difference && difference > 0) setDiffMaxDraw(true);
    else setDiffMaxDraw(false);

    setDrawAmmountValue(value);
    setSliderValue(value);
    setValue("newLoanAmount", value);
  };

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number);
    // @ts-ignore
    handlerChangeDraw(newValue);
    setValue("newLoanAmount", newValue as number);
  };

  const handleSliderChangeCommitted = (
    _event: React.SyntheticEvent | Event,
    newValue: number | number[],
  ) => {
    if (typeof newValue === "number") {
      handlerChangeDraw(newValue);
    }
  };

  const onSubmit = async () => {
    try {
      const newLoanAmount = estNetProceeds
        ? Number(estNetProceeds)
        : Number(loan?.finalOffer?.initialDrawAmount);
      if (isNaN(newLoanAmount)) {
        throw new Error("Invalid loan amount");
      }

      handlerChangeDraw(estNetProceeds);
      recalculateOffer(estNetProceeds, closingCostsValue);

      if (onClose) {
        onClose();
      }
      // eslint-disable-next-line
    } catch (error) {}
  };

  useEffect(() => {
    if (drawAmmountValue === undefined) {
      setDrawAmmountValue(initialValuesSliderHELOC?.maxDraw);
      setSliderValue(initialValuesSliderHELOC?.maxDraw ?? 0);
    }
    // eslint-disable-next-line
  }, [initialValuesSliderHELOC?.maxDraw]);

  return (
    <CDialog
      style={{ margin: 0 }}
      open={openModal}
      onClose={() => onClose()}
      fullWidth
      maxWidth="xs"
    >
      <Box sx={{ margin: { xs: "0px" } }} display="flex" flexDirection="column">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{ margin: { xs: "0px" } }}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography
              fontSize="22px"
              fontWeight={600}
              maxWidth={"200px"}
              textAlign={"left"}
            >
              Draw Request
            </Typography>
            <CloseIcon
              fontSize="medium"
              onClick={() => onClose()}
              sx={{ cursor: "pointer" }}
            />
          </Box>
          <Typography mt="15px" textAlign="left" fontSize={18}>
            Available Draw:{" "}
            {loading ? (
              <span style={{ display: "inline-block" }}>
                <Skeleton
                  width="7rem"
                  sx={{
                    display: "inline-block",
                  }}
                />
              </span>
            ) : (
              <span style={{ fontSize: "18px" }}>
                {dollarFormatter.format(initialValuesSliderHELOC?.maxDraw ?? 0)}
              </span>
            )}
          </Typography>
          <Typography mt="15px" textAlign="left" fontSize={18}>
            Draw:{" "}
            {loading ? (
              <span style={{ display: "inline-block" }}>
                <Skeleton
                  width="7rem"
                  sx={{
                    display: "inline-block",
                  }}
                />
              </span>
            ) : (
              <span style={{ fontSize: "18px" }}>
                {dollarFormatter.format(sliderValue ?? 0)}
              </span>
            )}
          </Typography>
          <Typography mt="15px" textAlign="left" fontSize={18} color={"red"}>
            - Closing cost:{" "}
            <span style={{ fontSize: "18px" }}>
              {dollarFormatter.format(closingCostsValue)}
            </span>
          </Typography>
          <Typography mt="15px" textAlign="left" fontSize={18} color={"red"}>
            - Payoffs/Paydowns:{" "}
            <Typography style={{ fontSize: "18px", display: "inline" }}>
              {dollarFormatter.format(totalPayoffsAndPaydowns ?? 0)}
            </Typography>
          </Typography>
          <Typography mt="15px" textAlign="left" fontSize={18}>
            - Cash to Borrower:{" "}
            <Typography
              style={{ fontSize: "18px", display: "inline" }}
              color="green"
            >
              {dollarFormatter.format(estNetProceeds)}
            </Typography>
          </Typography>
          {showSlider && (
            <>
              {!initialValuesSliderHELOC?.maxDraw &&
              !initialValuesSliderHELOC?.minDraw ? (
                <Skeleton
                  width={"100%"}
                  height={"2.8rem"}
                  sx={{ marginTop: "30px" }}
                />
              ) : (
                initialValuesSliderHELOC?.minDraw !==
                  initialValuesSliderHELOC?.maxDraw && (
                  <Box
                    py={1}
                    sx={{
                      borderTop: { xs: "0.5px solid lightGrey", md: "none" },
                      borderBottom: {
                        xs: "0.5px solid lightGrey",
                        md: "none",
                      },
                      marginTop: "30px",
                    }}
                  >
                    <Controller
                      name="newLoanAmount"
                      control={control}
                      render={({ field: _field }) => (
                        <>
                          <SliderValueDisplay
                            valueSlider={sliderValue}
                            valueToDisplay={estNetProceeds}
                            min={initialValuesSliderHELOC?.minDraw ?? 0}
                            max={initialValuesSliderHELOC?.maxDraw ?? 0}
                          />

                          <Slider
                            aria-label="Price"
                            marks={marks}
                            disabled={loading}
                            onChange={handleSliderChange}
                            onChangeCommitted={handleSliderChangeCommitted}
                            getAriaValueText={(value) => `${value}`}
                            min={initialValuesSliderHELOC?.minDraw ?? 0}
                            max={initialValuesSliderHELOC?.maxDraw ?? 0}
                            value={sliderValue}
                            step={1000}
                            data-cy="sliderDraw"
                            sx={{
                              "& .MuiSlider-thumb": {
                                height: 25,
                                borderRadius: "4px",
                                clipPath:
                                  "polygon(0 0, 100% 0, 100% 50%, 50% 100%, 0 50%)",
                              },
                              "& .MuiSlider-rail": {
                                height: 15,
                                color: theme.palette.text.secondary,
                              },
                              "& .MuiSlider-mark": {
                                width: 6,
                                height: 6,
                                borderRadius: 5,
                                color: theme.palette.background.paper,
                              },
                              "& .MuiSlider-track": {
                                height: 15,
                                opacity: "0%",
                                color: theme.palette.text.secondary,
                              },
                              "& .MuiSlider-markLabel": {
                                color: theme.palette.text.secondary,
                                fontFamily: "Pro Display Regular",
                              },
                            }}
                          />
                        </>
                      )}
                    />
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        color={theme.palette.text.secondary}
                        variant="subtitle2"
                      >
                        {`75%`}
                      </Typography>
                      <Typography
                        color={theme.palette.text.secondary}
                        variant="subtitle2"
                      >
                        {`100%`}
                      </Typography>
                    </Box>
                  </Box>
                )
              )}
            </>
          )}
          {diffMaxDraw ? (
            <Box display="flex" flexDirection="row" gap="12px" marginTop="26px">
              <WarningIcon style={{ color: "#EAB308" }} />
              <Typography fontSize="16px">Draw is less than 100%</Typography>
            </Box>
          ) : null}

          <CButton
            type="submit"
            disabled={Boolean(errors.newLoanAmount)}
            loading={isSubmitting}
            variant="contained"
            fullWidth
            sx={{ marginTop: "40px", paddingY: "19px" }}
            data-cy="confirmDraw"
          >
            <Typography>Confirm Draw</Typography>
          </CButton>
          <CButton
            type="reset"
            variant="outlined"
            fullWidth
            sx={{ marginTop: "24px", paddingY: "19px" }}
            onClick={(event) => {
              onClose();
            }}
            data-cy="cancelDraw"
          >
            <Typography>Cancel</Typography>
          </CButton>
        </form>
      </Box>
    </CDialog>
  );
};

export default DrawRequestModal;
