import { Box, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { useIsHomebridge } from "utils/useIsHomebridge";

interface Prop {
  link: string;
  logo?: string;
  handleClick: () => void;
  isLogoClickable?: boolean;
}
export default function CCustomLogo({
  link,
  logo,
  handleClick,
  isLogoClickable = true,
}: Prop) {
  const { isHomeBridge } = useIsHomebridge();
  const target = link !== "/" ? "_parent" : "_self";
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();
  const invite = window.location.href.includes("invite");

  if (!isLogoClickable || invite) {
    return (
      <Box p={0}>
        <img
          src={logo}
          alt={`${
            privateLabel?.isNFTYDoor
              ? "NFTYDoor. A division of Homebridge"
              : privateLabel?.lenderName
          }`}
          style={{
            height: isHomeBridge ? undefined : 38,
            paddingTop: isHomeBridge ? undefined : 6,
          }}
          className="acsb-sr-only"
          data-acsb-force-visible={true}
          aria-hidden={false}
          data-acsb-hidden={false}
        />
      </Box>
    );
  }

  return (
    <Box p={0}>
      <Link
        onClick={handleClick}
        style={{
          cursor: "pointer",
          color: theme.palette.primary.main,
          textDecoration: "none",
        }}
        to={link}
        target={target}
        data-acsb-clickable={true}
        data-acsb-navigable={true}
        data-acsb-now-navigable={true}
      >
        <img
          src={logo}
          alt={`${
            privateLabel?.isNFTYDoor
              ? "NFTYDoor. A division of Homebridge"
              : privateLabel?.lenderName
          }`}
          style={{
            height: isHomeBridge ? undefined : 38,
            paddingTop: isHomeBridge ? undefined : 6,
            maxWidth: "200px",
          }}
          className="acsb-sr-only"
          data-acsb-force-visible={true}
          aria-hidden={false}
          data-acsb-hidden={false}
        />
        <span
          className="acsb-sr-only"
          data-acsb-sr-only={true}
          data-acsb-force-visible={true}
          aria-hidden={false}
          data-acsb-hidden={false}
        >
          Home
        </span>
      </Link>
    </Box>
  );
}
