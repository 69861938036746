//@ts-ignore
//@ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-expressions, no-debugger, no-console, prefer-const, prefer-rest-params,  no-sequences */
import useTracker from "../../useTrackerContext";
import Typography from "@mui/material/Typography";
import CTrackerTimelineItem from "components/CTimelineItem";
import API from "utils/API";
import moment from "moment";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import isHolliday from "utils/isHolliday";
import { Close } from "@mui/icons-material";
import CCalendar from "components/CCalendar";
import { Box, IconButton, useTheme } from "@mui/material";
import addWorkingDays from "utils/addWorkingDays";
import { useMemo, useState, useCallback, useEffect, Fragment } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CTextField from "components/CTextField";
import { renderTimeViewClock } from "@mui/x-date-pickers";
import ClosingNowDialog from "./ClosingNowDialog";
import useClosingRon from "./useClosingRon";

const ScheduleClosingVerification: React.FC<{
  trackerStatusColor: TrackerStatusColor;
}> = ({ trackerStatusColor }) => {
  const theme = useTheme();
  const { loan, type } = useTracker();
  const isBorrower = type === "borrower";
  const { user } = useUser();

  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>();
  const date = dayjs(selectedDate).format("YYYY-MM-DD");
  const time = dayjs(selectedTime).format("HH:mm");

  const [personaDialogIsOpen, setPersonaDialogIsOpen] = useState(false);
  const isUnsupportedCountyFips = loan?.property?.isUnsupportedCountyFips;

  const borrowerSteps = loan?.borrowerSteps;
  const finalOfferNotAccepted =
    !loan?.borrowerFlags?.finalOfferAccepted ||
    loan?.borrowerFlags?.reAcceptedFinalOffer === false;

  const prevStep = loan?.finalOffer;

  const status =
    loan?.borrowerSteps?.incomeVerification === "success"
      ? "pending"
      : prevStep
        ? borrowerSteps?.scheduleClosingVerification?.status ?? "pending"
        : "pending";

  const [signingAddress, setSigningAddress] = useState();
  const [showSigningAddressForm, setShowSigningAddressForm] = useState();
  const [customSigningAddress, setCustomSigningAddress] = useState({
    address: "",
    city: "",
    state: "",
    zip: "",
    county: "",
  });
  const [timePickerError, setTimePickerError] = useState(false);

  useEffect(() => {
    const occupancy = loan?.occupancy;
    const isSecondHomeOrInvestment = [
      "Second Home",
      "Investment Property",
    ].includes(occupancy);

    if (isSecondHomeOrInvestment) {
      const deliveryLine = user?.address?.delivery_line_1;
      const city = user?.address?.components?.city_name;
      const state = user?.address?.components?.state_abbreviation;
      const zipcode = user?.address?.components?.zipcode;
      const fullAddress = `${deliveryLine}, ${city}, ${state}, ${zipcode}`;
      setSigningAddress(fullAddress);
    } else {
      setSigningAddress(loan?.property?.fullAddress);
    }
  }, [loan?.property?.fullAddress, user, loan?.occupancy]);

  const [confirm, setConfirm] = useState(false);
  const [missDateModalIsOpen, setMissDateOpenModalIsOpen] = useState(false);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [missDate, setMissDate] = useState(false);

  const daysFrom = 0;

  const rangeFrom = useMemo(() => {
    if (prevStep) {
      const startDate = moment().add(1, "days");
      return isUnsupportedCountyFips
        ? addWorkingDays(startDate, daysFrom).toDate()
        : startDate.toDate();
    }
  }, [prevStep, daysFrom, isUnsupportedCountyFips]);

  const rangeTo = useMemo(() => {
    if (prevStep) {
      const startDate = moment().add(1, "days");
      return addWorkingDays(startDate, 6).toDate();
    }
  }, [prevStep]);

  const disableSubmit = useMemo(() => {
    if (
      selectedDate &&
      moment(selectedDate)?.isBetween(moment(rangeFrom), moment(rangeTo)) &&
      !isHolliday(selectedDate, true) &&
      isUnsupportedCountyFips &&
      selectedTime &&
      !timePickerError &&
      ((showSigningAddressForm &&
        customSigningAddress?.address &&
        customSigningAddress?.city &&
        customSigningAddress?.state &&
        customSigningAddress?.zip &&
        customSigningAddress?.county) ||
        (!showSigningAddressForm && signingAddress))
    ) {
      return false;
    }
    return true;
  }, [
    rangeFrom,
    rangeTo,
    selectedDate,
    selectedTime,
    customSigningAddress,
    signingAddress,
    showSigningAddressForm,
    isUnsupportedCountyFips,
    timePickerError,
  ]);

  const rescheduleDate = useCallback(async () => {
    await API.post<{ accessToken: string }>({
      url: "/invoke",
      data: {
        FunctionName: "exosNotary",
        InvocationType: "Event",
        Service: "nftydoor-app",
        Payload: {
          serviceName: "update-order",
          loanId: loan?.id,
          date: date,
          time: time,
        },
      },
    });
  }, [date, time, loan?.id]);

  const fetchClosingPackageUrl = async () => {
    const response = await API.get(
      `/get/get-closing-packages?loanId=${loan?.id}`,
    );
    if ("error" in response) {
      console.error(response.error);
    } else {
      if (response.data.url) {
        window.open(response.data.url, "_blank");
      }
    }
  };

  useEffect(() => {
    if (loan?.property?.borrowerFullAddress) {
      setSigningAddress(loan?.property?.borrowerFullAddress);
    }
  }, [
    loan?.finalOffer?.amount,
    loan?.borrowerFlags?.reAcceptedFinalOffer,
    loan?.borrowerSteps?.appraisalVerification?.needRecalculate,
    loan?.borrowerSteps?.appraisalVerification?.status,
    loan?.property?.borrowerFullAddress,
    loan?.borrowerSteps?.scheduleClosingVerification?.date,
    loan?.borrowerSteps?.scheduleClosingVerification?.exos,
  ]);

  const handleCloseModal = useCallback(() => {
    setCalendarIsOpen(false);
    setShowSigningAddressForm(false);
    setCustomSigningAddress({
      address: "",
      city: "",
      state: "",
      zip: "",
      county: "",
    });
  }, []);

  const tooltipMessage = useMemo(() => {
    if (isBorrower) {
      return "Please confirm if the closing date is convenient for you. You may delay closing for a certain amount of time, however, sometimes delays may cause the loan to require additional underwriting because all of the verifications are time-sensitive.";
    }

    if (!isBorrower) {
      return "You're all set! The Primary Borrower should now have the option to select a closing date.";
    }
  }, [isBorrower]);

  useEffect(() => {
    if (loan?.borrowerSteps?.scheduleClosingVerification?.exos?.appointment) {
      setConfirm(true);
    }
  }, [loan?.borrowerSteps?.scheduleClosingVerification?.exos?.appointment]);

  useEffect(() => {
    if (
      loan?.borrowerSteps?.scheduleClosingVerification?.exos?.appointment
        ?.appointmentDateTimeUtc
    ) {
      const appointmentDate = moment.parseZone(
        loan.borrowerSteps?.scheduleClosingVerification?.exos?.appointment
          .appointmentDateTimeUtc,
      );
      const signedAt =
        loan?.borrowerSteps?.finalDisclosuresVerification?.signedAt;
      const currentDateUtc = moment.utc();
      const diffInHours = currentDateUtc.diff(appointmentDate, "hours");
      if (diffInHours >= 2 && !signedAt) {
        setMissDate(true);
      } else {
        setMissDate(false);
      }
    }
  }, [
    loan?.borrowerSteps?.scheduleClosingVerification?.exos?.appointment
      ?.appointmentDateTimeUtc,
    loan?.borrowerSteps?.finalDisclosuresVerification?.signedAt,
  ]);

  const {
    handlSendFinalDisclosures,
    handleConfirmClosingNowModal,
    isCloseNowModalOpen,
    isLoadingFinalDisc,
  } = useClosingRon({ loan });

  const isRON = loan?.property?.isUnsupportedCountyFips === false;
  const titleStepSuccess =
    loan?.borrowerSteps?.titleVerification?.status === "success";
  const valuationDate =
    loan?.borrowerSteps?.homeValuationVerification?.valuationDate;
  const showValuationDateMissing =
    titleStepSuccess &&
    !valuationDate &&
    loan?.borrowerSteps?.appraisalVerification?.status !== "success";
  const valuationDateMissingMessage =
    "The loan cannot be Clear to Close because the Valuation Date is missing. We are working to solve this, please await.";
  const notaryTitle = missDate
    ? "Reschedule Closing"
    : confirm
      ? loan?.borrowerSteps?.scheduleClosingVerification?.exos?.status
      : isBorrower
        ? "Select Closing Date"
        : "Closing Date";

  const ronTitle = "Closing";

  const pendingDisclosures =
    loan?.borrowerSteps?.finalDisclosuresVerification?.status === "pending";

  const isTX = loan?.property?.address?.components?.state_abbreviation === "TX";

  const disabledForDisaster =
    !loan?.isInDisasterDeclarationArea ||
    (loan?.isInDisasterDeclarationArea &&
      (loan?.borrowerSteps?.homeValuationVerification?.PCRStatus ===
        "COMPLETED" ||
        loan?.borrowerSteps?.homeValuationVerification?.PCRDStatus ===
          "COMPLETED"));

  return (
    <CTrackerTimelineItem
      status={
        status === "success" && !loan?.loanFees?.cashDuetoBorrower
          ? "analyzing"
          : status
      }
      processingStatus={status === "analyzing"}
      title={isRON ? ronTitle : notaryTitle}
      tooltip={tooltipMessage}
      {...(prevStep &&
        disabledForDisaster &&
        !isRON &&
        isBorrower &&
        loan?.borrowerSteps?.finalDisclosuresVerification?.status !==
          "success" &&
        !isTX &&
        (status === "analyzing" || status === "success") &&
        !(!missDate && confirm) && {
          action: {
            message: missDate ? "Reschedule Closing" : "Schedule Notary Visit",
            onClick: async () => {
              if (missDate) {
                setMissDateOpenModalIsOpen(true);
              } else {
                if (
                  loan?.borrowerSteps?.titleVerification.status === "success"
                ) {
                  if (isUnsupportedCountyFips) {
                    setCalendarIsOpen(true);
                  } else {
                    tentativeDate();
                  }
                }
              }
            },
            disabled:
              loan?.borrowerSteps?.titleVerification?.status !== "success" ||
              loan.borrowerSteps?.scheduleClosingVerification?.exos?.orderId ===
                undefined ||
              finalOfferNotAccepted,
          },
        })}
    >
      {loan?.finalOffer && !isRON && (
        <Typography variant="h6" color={[trackerStatusColor[status]]}>
          {(loan?.borrowerSteps?.titleVerification.status !== "success" ||
            loan.borrowerSteps?.scheduleClosingVerification?.exos?.orderId ===
              undefined) &&
            loan?.borrowerSteps?.scheduleClosingVerification?.status !==
              "success" &&
            "You can schedule your closing after all steps are complete."}
        </Typography>
      )}
      {loan?.finalOffer && showValuationDateMissing && (
        <Typography variant="h6" color={[trackerStatusColor[status]]}>
          {valuationDateMissingMessage}
        </Typography>
      )}
      {confirm && !missDate && (
        <Typography variant="h6" color={[trackerStatusColor[status]]}>
          <Box component="span">
            {loan?.borrowerSteps?.scheduleClosingVerification?.message}
          </Box>
        </Typography>
      )}
      {isTX &&
        loan?.borrowerSteps?.scheduleClosingVerification?.exosOrderStatus &&
        loan?.borrowerSteps?.scheduleClosingVerification?.status ===
          "analyzing" && (
          <Typography variant="h6" color={[trackerStatusColor[status]]}>
            <Box component="span">
              {loan?.borrowerSteps?.scheduleClosingVerification?.message}
            </Box>
          </Typography>
        )}
      {!missDate &&
        confirm &&
        loan?.borrowerSteps?.finalDisclosuresVerification?.closingPackageKey &&
        loan?.borrowerSteps?.finalDisclosuresVerification?.status !==
          "failed" && (
          <Box
            component="span"
            sx={{
              cursor: "pointer",
            }}
            onClick={fetchClosingPackageUrl}
          >
            <Typography variant="h6" color={[trackerStatusColor[status]]}>
              Click Here to Review your Closing Documents
            </Typography>
          </Box>
        )}

      {loan?.coborrowerId &&
        !loan?.coborrowerSteps?.hardpullVerification?.hardCreditScore && (
          <Box>
            <Typography variant="h6" color={[trackerStatusColor["analyzing"]]}>
              Coborrower must register before proceeding with closing.
            </Typography>
          </Box>
        )}

      {pendingDisclosures &&
      loan?.borrowerSteps?.incomeVerification?.status === "success" &&
      isRON ? (
        <CButton
          data-cy="closeNow"
          disabled={
            !titleStepSuccess ||
            isLoadingFinalDisc ||
            !loan?.loanFees?.cashDuetoBorrower ||
            !loan?.borrowerSteps?.titleVerification?.taxPayment ||
            finalOfferNotAccepted ||
            !disabledForDisaster ||
            (loan?.coborrowerId &&
              !loan?.coborrowerSteps?.hardpullVerification?.hardCreditScore)
          }
          loading={isLoadingFinalDisc}
          type="button"
          sx={{ my: 1 }}
          variant="contained"
          onClick={handlSendFinalDisclosures}
        >
          Close Now
        </CButton>
      ) : null}

      <ClosingNowDialog
        open={isCloseNowModalOpen}
        loan={loan}
        onClose={handleConfirmClosingNowModal}
      />
      {isRON ? (
        <Typography variant="h6" color={[trackerStatusColor[status]]}>
          {status === "analyzing" &&
          (pendingDisclosures || !loan?.loanFees?.cashDuetoBorrower)
            ? "You may close after all steps are complete."
            : ""}
          {status === "success" &&
          loan?.borrowerSteps?.finalDisclosuresVerification?.status ===
            "analyzing"
            ? borrowerSteps?.scheduleClosingVerification?.message
            : ""}
        </Typography>
      ) : null}

      <CDialog maxWidth="xs" open={calendarIsOpen} onClose={handleCloseModal}>
        <Typography variant="h6">
          You will be redirected to our signing partner momentarily. When
          prompted please select a date, time and location that you{" "}
          {loan?.borrowerFirstName} {loan?.borrowerLastName}{" "}
          {loan?.coborrowerFullname ? ` and ${loan?.coborrowerFullname}` : ""}
          are physically available. This is an in person signing with a human
          Notary. Happy Closing!
        </Typography>
        <CButton
          fullWidth
          sx={{ marginTop: 3 }}
          variant="contained"
          onClick={() =>
            window.open(
              "https://nftydoor.exostechnology.com/app/consumer/#/login",
              "_blank",
            )
          }
        >
          Go to Signing Partner
        </CButton>
      </CDialog>

      <CDialog
        maxWidth="xs"
        description="It appears your closing date has passed. Please click below to schedule a new date and time."
        open={missDateModalIsOpen}
        onClose={() => {
          setMissDateOpenModalIsOpen(false);
        }}
      >
        <CCalendar
          date={selectedDate}
          excludeHollidays
          allowSundaySelection={true}
          rangeFrom={rangeFrom}
          rangeTo={rangeTo}
          onChange={(newDate) => setSelectedDate(newDate)}
          sx={{ width: 350, mx: "auto", mt: 3 }}
        />

        {isUnsupportedCountyFips && (
          <Fragment>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                defaultValue={dayjs(selectedTime)}
                sx={{ width: "90%", my: 1 }}
                minTime={dayjs()
                  .set("hour", 8)
                  .set("minute", 29)
                  .set("seconds", 59)}
                maxTime={dayjs()
                  .set("hour", 16)
                  .set("minute", 0)
                  .set("seconds", 59)}
                onChange={(newValue) => {
                  setSelectedTime(newValue);
                }}
                shouldDisableDate={(date) => {
                  return isTX && (date.day() === 0 || date.day() === 6);
                }}
                onError={(newError) =>
                  !newError
                    ? setTimePickerError(false)
                    : setTimePickerError(true)
                }
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                slotProps={{
                  textField: {
                    readOnly: true,
                  },
                }}
              />
            </LocalizationProvider>

            {timePickerError && (
              <Typography
                color="#ef6161"
                fontSize={15}
                fontFamily="Pro Text Light"
              >
                Please select a time between 8:30 am and 4 pm
              </Typography>
            )}

            {!showSigningAddressForm && (
              <Fragment>
                <CTextField
                  sx={{ width: "90%", mb: 1 }}
                  label="Signing address"
                  placeholder="Signing address"
                  value={signingAddress}
                  disabled={true}
                />
              </Fragment>
            )}
          </Fragment>
        )}
        <CButton
          fullWidth
          disabled={disableSubmit}
          variant="contained"
          sx={{ marginTop: 3 }}
          onClick={() => {
            setPersonaDialogIsOpen(true),
              setMissDate(false),
              setMissDateOpenModalIsOpen(false);
          }}
          name="scheduleClosing-confirm"
        >
          Confirm
        </CButton>
      </CDialog>

      <CDialog
        maxWidth="xs"
        open={personaDialogIsOpen}
        onClose={() => setPersonaDialogIsOpen(false)}
      >
        <Box
          mb={3}
          display="flex"
          justifyContent={"space-around"}
          alignItems={"center"}
        >
          <Typography
            sx={{ whiteSpace: "nowrap" }}
            width={280}
            variant="h5"
            fontSize={30}
            textAlign="center"
            fontFamily={"Pro Display Semibold"}
          >
            Confirm Closing Date
          </Typography>
          <Box>
            <IconButton onClick={() => setPersonaDialogIsOpen(false)}>
              <Close
                fontSize="large"
                htmlColor={theme.palette.text.secondary}
              />
            </IconButton>
          </Box>
        </Box>

        <Typography variant="h6" fontFamily="Pro Display Semibold">
          Provided that all your closing conditions are met, your tentative
          closing date is {moment(selectedDate).format("LL")}. Please confirm if
          this date is acceptable. If you need to reschedule, that may cause
          further delays because the underwriting is time-sensitive.
        </Typography>

        <CButton
          fullWidth
          type="submit"
          variant="contained"
          onClick={() => {
            rescheduleDate();
            setPersonaDialogIsOpen(false);
          }}
          sx={{ marginTop: 4 }}
          name="scheduleClosing-acceptDate"
        >
          Accept date
        </CButton>

        <CButton
          fullWidth
          variant="outlined"
          type="submit"
          onClick={() => {
            setPersonaDialogIsOpen(false);
            setMissDateOpenModalIsOpen(true);
          }}
          sx={{ marginTop: 4 }}
          name="scheduleClosing-changeClosingDate"
        >
          Change the closing date
        </CButton>
      </CDialog>
    </CTrackerTimelineItem>
  );
};

export default ScheduleClosingVerification;
