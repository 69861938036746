import { Box } from "@mui/material";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import { useNavigate } from "react-router-dom";
import { FormData } from "screens/GetStarted/useGetStarted";

interface ExistantUserModalProps {
  formData: FormData;
  openModal: boolean;
  errorMessage?: string;
}

export const ExistantUserModal = ({
  formData,
  openModal,
  errorMessage,
}: ExistantUserModalProps) => {
  const navigate = useNavigate();
  return (
    <CDialog
      fullWidth
      open={openModal}
      className="custom__dialog custom__dialog--existant-user"
      /*  PaperProps={{
        style: {
          maxWidth: "60vw",
          maxHeight: "60vh",
        },
      }} */
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        {/*   <Typography
          variant="h4"
          fontFamily={"NunitoSans"}
          fontSize={{
            xs: "22px",
            sm: "24px",
            md: "26px",
          }}
          textAlign={"center"}
          marginTop={"20px"}
          marginBottom={"10px"}
          fontWeight={900}
        >
          {formData.email}
        </Typography> */}

        <p
          style={{
            color: "white",
            textAlign: "center",
            opacity: errorMessage ? "1" : "0",
            margin: "0",
          }}
        >
          {errorMessage ?? "The was an error"}
        </p>

        <Box
          textAlign={"center"}
          display={"flex"}
          justifyContent={"center"}
          margin={"20px 0"}
          gap={"20px"}
        >
          <CButton
            variant="contained"
            onClick={() => {
              navigate(
                `/login?${new URLSearchParams({
                  email: formData.email,
                }).toString()}`,
              );
            }}
            sx={{
              textTransform: "uppercase",
              fontWeight: 900,
            }}
          >
            Go to Log in
          </CButton>
        </Box>
      </Box>
    </CDialog>
  );
};
