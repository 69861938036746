import { Box, Typography } from "@mui/material";
import styles from "../DeniedLoanModal/styles";
import CDialog from "components/CDialog";
import CButton from "components/CButton";
import { useNavigate } from "react-router-dom";

interface DeniedLoanModalProps {
  open: boolean;
  creditScore: number;
  errorMessage?: string;
}

export const DeniedHardPullModal = ({
  open,
  creditScore,
  errorMessage,
}: DeniedLoanModalProps) => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(`/loans`, {
      replace: true,
    });
  };

  return (
    <CDialog open={open} className="denied-loan-modal">
      <Box>
        <Typography variant="h3">Denied Loan</Typography>
        <Typography variant="body1" sx={styles.shortDescription}>
          {errorMessage ??
            `The system returned a credit score of ${creditScore}, which is lower than the minimum supported credit score of 640 for our platform. This loan will be archived`}{" "}
        </Typography>
        <Box sx={styles.containerButton}>
          <CButton
            variant="contained"
            sx={styles.buttonStyles}
            onClick={handleClose}
            name={"close-and-log-out-denied-loan-modal"}
            aria-label={"close-and-log-out-denied-loan-modal"}
          >
            Close
          </CButton>
        </Box>
      </Box>
    </CDialog>
  );
};
