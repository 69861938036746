import { BlogTemplate } from "components/BlogTemplate";
import { useNavigate } from "react-router-dom";
import HBHowToUseHomeEquity from "../../../assets/Images/HB_how_to_use_hero.png";

const HowToUseHomeEquity = () => {
  const navigate = useNavigate();

  return (
    <BlogTemplate
      title="How to use home equity"
      subtitle="Home equity is one of the most powerful financial tools available to homeowners. While it’s relatively simple to access, it has the ability to improve lives dramatically. Discover how to use your home equity to benefit both you and your family for years to come."
      image={HBHowToUseHomeEquity}
    >
      <h2>What is home equity?</h2>
      <p>
        Home equity is the dollar amount of your home that you own. You can
        calculate your equity by taking the home’s current value and subtracting
        the amount you have left to pay on your mortgage.
      </p>
      <p>
        Here’s an example: Suppose your house has a current value of $300,000,
        and you still owe $120,000. Subtracting $120,000 from $300,000 leaves
        $180,000, which is the amount you’ve paid so far. In other words, you
        have $180,000 of home equity.
      </p>

      <h2>Ways to use home equity</h2>
      <p>
        Homeowners have discovered countless ways to use home equity. While your
        circumstances and needs may differ, here are a few of the more common
        uses of home equity:
      </p>

      <ul>
        <li>Debt consolidation</li>
        <li>Home improvements</li>
        <li>Emergency expenses</li>
        <li>Education</li>
        <li>Business expenses</li>
        <li>Investment property</li>
      </ul>

      <p>Let’s take a look at each of these:</p>

      <h3>Debt consolidation</h3>
      <p>
        Consolidating your high-interest debt into one payment can have at least
        two benefits. It can lower your interest payments and it can reduce your
        debt-related stress.
      </p>
      <p>
        Home equity lines of credit (HELOC) typically have a lower interest rate
        than credit cards and personal loans. The average interest rate on
        popular credit cards is over 20%. Meanwhile, personal loans also average
        a double-digit interest rate of around 12%.<sup>*</sup>
      </p>
      <p>
        If you have several credit cards and personal loans, managing your
        payments can be time-consuming and confusing. For example, each may have
        a different due date each month.
      </p>
      <p>
        You can reduce both your interest payments and your mental stress by
        using a home equity line of credit to pay off your credit cards and
        personal loans. Then, instead of juggling multiple payments, you’ll only
        have the equity line of credit to repay.
      </p>
      <small>
        * “Paying off unsecured debt with a HELOC makes it secured.”
      </small>

      <h3>Home improvements</h3>

      <p>
        Your home is often your largest investment. It makes sense to keep it in
        the best shape possible — especially if you plan to sell your home one
        day. A home equity line of credit can give you the funds you need to
        repair and upgrade your home.
      </p>
      <p>
        Your home is often your largest investment. It makes sense to keep it in
        the best shape possible — especially if you plan to sell your home one
        day. A home equity line of credit can give you the funds you need to
        repair and upgrade your home. Kitchens and bathrooms are often the most
        expensive rooms to renovate. They’re also the rooms potential buyers are
        most interested in. A HELOC can help you make your home more comfortable
        for you and your family now, and make it more appealing to potential
        buyers in the future.
      </p>

      <h3>Emergency expenses</h3>

      <p>
        Life can present you with financial burdens when you least expect them.
        At those times, it’s helpful to have an emergency fund available.
      </p>
      <p>
        Experts often recommend that the fund contain at least enough to support
        your family for three to six months. Unfortunately, this is not
        practical for most families.
      </p>
      <p>
        If an emergency fund isn’t an option, your solution could be a home
        equity line of credit. Because a HELOC can be used for any purpose, it
        doesn’t matter what type of emergency expense you face. You don’t have
        to explain how you intend to use the money.
      </p>

      <h3>Education</h3>
      <p>
        Higher education costs can place a tremendous burden on a family’s
        finances. This is especially true if it seems the only option is a
        private student loan because the lower the borrower’s credit score, the
        higher the interest rate. Plus, it can take quite a long time to pay
        back this type of loan.
      </p>
      <p>
        A HELOC can provide funds for higher education without the huge interest
        rates. This line of credit can be even more important when there’s more
        than one student in a household. Instead of applying for and managing
        two or more student loans, you can simply open a single HELOC.
      </p>

      <h3>Business expenses</h3>
      <p>
        A large percentage of businesses fail due to a lack of capital. You may
        be able to prevent your business from becoming part of this statistic by
        using a HELOC.
      </p>
      <p>
        Traditionally, business owners in need of funds have turned to small
        business loans. However, the interest rate for a HELOC could be more
        favorable than that of a business loan.
      </p>
      <p>
        In addition, if your business is relatively new, you may not qualify for
        a business loan from some lenders. On the other hand, a HELOC is based
        on your home’s equity, not your business track record.
      </p>

      <h3>Investment property</h3>
      <p>
        If you’re thinking of investing in real estate, a HELOC could provide a
        larger loan amount than other funding options, like a personal loan.
      </p>
      <p>
        Investment properties might include acquiring rental property and acting
        as a landlord; buying, refurbishing, and selling (aka “flipping”)
        properties; or even investing in a real estate trust where you benefit
        from rental income and appreciation while someone else handles all the
        details.
      </p>

      <h2>How to get equity out of your home</h2>
      <p>
        Accessing your home’s equity isn’t complicated. You can simply{" "}
        <div id="container--GetEquityOfHome" style={{ all: "unset" }}>
          <button id="button--GetEquityOfHome" style={{ all: "unset" }}>
            <div
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => navigate("/get-started")}
              id="GetEquityOfHome"
            >
              apply for a HELOC
            </div>
          </button>
        </div>{" "}
        with a reputable lender, such as Homebridge.
      </p>
      <p>
        The lender will calculate how much of your home’s equity you can borrow.
        Then, just decide how much you want and how you want the funds
        distributed.
      </p>
      <p>
        For example, Homebridge can make payments directly to your credit card
        companies and other lenders if you’re trying to pay off your debt.
      </p>

      <h2>When you should NOT use home equity</h2>
      <p>
        You can certainly use your home equity however you please, but you may
        want to think twice for some things.{" "}
      </p>
      <p>
        For example, you shouldn't use a HELOC for everyday expenses. If you
        need a line of credit to cover basic needs like groceries, it may be
        time to reexamine your budget and lifestyle. You also don’t want to use
        a HELOC to purchase unnecessary luxury items, such as vacations.
      </p>
      <p>
        Overall, try to avoid using your HELOC for anything that won’t add
        long-term value to you and your family.{" "}
      </p>

      <h2>Should you use your home equity?</h2>
      <p>
        Before deciding whether to access your home equity, ask yourself some
        basic questions. Will you use these funds for a worthwhile purpose, such
        as debt consolidation or home improvement? If so, it may be time to{" "}
        <div id="container--ShouldUseHomeEquity" style={{ all: "unset" }}>
          <button id="button--ShouldUseHomeEquity" style={{ all: "unset" }}>
            <div
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => navigate("/get-started")}
              id="ShouldUseHomeEquity"
            >
              apply for a HELOC
            </div>
          </button>
        </div>
        {"."}
      </p>

      <br />
      <span style={{ fontWeight: "bold" }}>
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => navigate("/resources")}
        >
          Learn more
        </span>{" "}
        about a Homebridge HELOC
      </span>
    </BlogTemplate>
  );
};

export default HowToUseHomeEquity;
