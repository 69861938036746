import { Box, Collapse, Typography } from "@mui/material";
import { HBSeparate } from "components/HBSeparate";
import React, { useRef } from "react";
import NumberFormat from "react-number-format";
import { Errors } from "../BorrowerPersonalData/usePersonalDataValidation";
import { FormDataNumbers } from "screens/GetStarted/useGetStarted";

import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { CustomInput } from "./CustomInput";
import { useHomebridgeSizes } from "utils/useHomebridgeSizes";

interface LoanRequestInputsProps {
  formData: FormDataNumbers;
  error: boolean;
  errors: Errors[];
  animation: boolean;
  onValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOpenMoreBalance: (
    field: "helocBalance" | "judmentsAndLiensBalance",
  ) => void;
  helocBalanceInputRef: React.RefObject<HTMLInputElement>;
  judmentsAndLiensBalanceInputRef: React.RefObject<HTMLInputElement>;
  moreBalance: {
    helocBalance: boolean;
    judmentsAndLiensBalance: boolean;
  };
}

export const LoanRequestInputs = ({
  animation,
  error,
  errors,
  formData,
  onValueChange,
  handleOpenMoreBalance,
  helocBalanceInputRef,
  judmentsAndLiensBalanceInputRef,
  moreBalance,
}: LoanRequestInputsProps) => {
  const valueInputRef = useRef<HTMLInputElement>(null);
  const { sizes } = useHomebridgeSizes();
  const { fourthText } = useHomeBridgeColors();

  return (
    <Box>
      <Box>
        <Typography
          variant="body2"
          fontFamily={"NunitoSans"}
          fontSize={sizes.typography}
          fontWeight={"bold"}
          marginBottom={"10px"}
          className="custom-typography"
        >
          Estimated Property Value:
        </Typography>
        <NumberFormat
          min={1}
          id={"homeValue"}
          name={"homeValue"}
          className="no-spinner"
          thousandSeparator
          allowNegative={false}
          allowEmptyFormatting={false}
          prefix="$"
          value={formData.homeValue}
          autoFocus={true}
          onChange={onValueChange}
          style={{
            border:
              error && errors.find((err) => err.field === "homeValue")
                ? "2px solid red"
                : `2px solid ${fourthText}`,
            animation:
              animation && errors.find((err) => err.field === "homeValue")
                ? "shake 0.3s ease-in-out"
                : "none",
          }}
          customInput={CustomInput}
          refInput={valueInputRef}
        />

        <Typography
          variant="body2"
          fontFamily={"NunitoSans"}
          fontSize={"16px"}
          color={"error"}
          textAlign={"center"}
          marginTop={"10px"}
          sx={{
            animation:
              animation && errors.find((err) => err.field === "homeValue")
                ? "shake 0.3s ease-in-out"
                : "none",
          }}
        >
          {error && errors.find((err) => err.field === "homeValue")?.message}
        </Typography>
        <p
          className="get-started__note"
          style={{
            textAlign: "center",
          }}
        >
          <strong className="strong-700">Not sure?</strong> You may be able to
          find this by logging in to your account on your mortgage lender's site
        </p>

        <Typography
          variant="body2"
          fontFamily={"NunitoSans"}
          fontSize={sizes.typography}
          fontWeight={"bold"}
          marginBottom={"10px"}
          className="custom-typography"
        >
          Current Mortgage Balance:
        </Typography>
        <NumberFormat
          min={0}
          id={"loanBalance"}
          name={"loanBalance"}
          className="no-spinner"
          thousandSeparator
          allowNegative={false}
          allowEmptyFormatting={false}
          prefix="$"
          value={formData.loanBalance}
          onChange={onValueChange}
          style={{
            border:
              error && errors.find((err) => err.field === "loanBalance")
                ? "2px solid red"
                : `2px solid ${fourthText}`,
            animation:
              animation && errors.find((err) => err.field === "loanBalance")
                ? "shake 0.3s ease-in-out"
                : "none",
          }}
          customInput={CustomInput}
        />
        <Typography
          variant="body2"
          fontFamily={"NunitoSans"}
          fontSize={"16px"}
          color={"error"}
          textAlign={"center"}
          marginTop={"10px"}
          sx={{
            animation:
              animation && errors.find((err) => err.field === "homeValue")
                ? "shake 0.3s ease-in-out"
                : "none",
          }}
        >
          {errors.find((err) => err.field === "loanBalance")?.message}
        </Typography>
        <p
          className="get-started__note"
          style={{
            textAlign: "center",
          }}
        >
          <strong className="strong-700">Not sure?</strong> You can find this on
          your latest mortgage statement.
        </p>
      </Box>
      <HBSeparate
        className="step-separate step-separate--separate"
        type="vertical"
      />
      <Typography
        variant="body2"
        fontFamily={"NunitoSans"}
        fontSize={sizes.typography}
        fontWeight={"bold"}
        marginBottom={"10px"}
        className="custom-typography"
      >
        Do any of these apply to you?
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          alignItems: "start",
        }}
      >
        {/* Home Equity Loan Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <input
            type="checkbox"
            className="get-started__checkbox"
            id="home-equity-loan"
            name="home-equity-loan"
            onClick={() => {
              handleOpenMoreBalance("helocBalance");
            }}
          />
          <label
            htmlFor="home-equity-loan"
            style={{
              cursor: "pointer",
            }}
          >
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              className="custom-typography"
            >
              Home Equity Loan/HELOC Balance:
            </Typography>
          </label>
        </Box>
        <Collapse
          in={moreBalance.helocBalance}
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              paddingLeft: "27px",
              marginTop: "8px",
            }}
          >
            <NumberFormat
              refInput={helocBalanceInputRef}
              min={0}
              id={"helocBalance"}
              name={"helocBalance"}
              className="no-spinner"
              thousandSeparator
              allowNegative={false}
              allowEmptyFormatting={false}
              prefix="$"
              onChange={onValueChange}
              customInput={CustomInput}
              value={formData.helocBalance}
              style={{
                border:
                  error && errors.find((err) => err.field === "helocBalance")
                    ? "2px solid red"
                    : `2px solid ${fourthText}`,
                animation:
                  animation &&
                  errors.find((err) => err.field === "helocBalance")
                    ? "shake 0.3s ease-in-out"
                    : "none",
                width: "100%",
                padding: "8px",
                boxSizing: "border-box",
              }}
            />
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={"16px"}
              color={"error"}
              textAlign={"center"}
              marginTop={"10px"}
              sx={{
                animation:
                  animation && errors.find((err) => err.field === "homeValue")
                    ? "shake 0.3s ease-in-out"
                    : "none",
              }}
            >
              {errors.find((err) => err.field === "helocBalance")?.message}
            </Typography>
          </Box>
          <p
            className="get-started__note"
            style={{
              textAlign: "center",
            }}
          >
            Include the remaining loan balance or total line of credit
            available.
          </p>
        </Collapse>

        {/* Judgments/Liens Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <input
            type="checkbox"
            className="get-started__checkbox"
            id="home-judgments"
            name="home-judgments"
            onClick={() => {
              handleOpenMoreBalance("judmentsAndLiensBalance");
            }}
          />
          <label
            htmlFor="home-judgments"
            style={{
              cursor: "pointer",
            }}
          >
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={sizes.typography}
              fontWeight={"bold"}
              className="custom-typography"
            >
              Home Judgments/Liens:
            </Typography>
          </label>
        </Box>
        <Collapse
          in={moreBalance.judmentsAndLiensBalance}
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              paddingLeft: "27px",
              marginTop: "8px",
            }}
          >
            <NumberFormat
              refInput={judmentsAndLiensBalanceInputRef}
              min={0}
              id={"judmentsAndLiensBalance"}
              name={"judmentsAndLiensBalance"}
              className="no-spinner"
              thousandSeparator
              allowNegative={false}
              allowEmptyFormatting={false}
              prefix="$"
              onChange={onValueChange}
              customInput={CustomInput}
              value={formData.judmentsAndLiensBalance}
              style={{
                border:
                  error &&
                  errors.find((err) => err.field === "judmentsAndLiensBalance")
                    ? "2px solid red"
                    : `2px solid ${fourthText}`,
                animation:
                  animation &&
                  errors.find((err) => err.field === "judmentsAndLiensBalance")
                    ? "shake 0.3s ease-in-out"
                    : "none",
                width: "100%",
                padding: "8px",
                boxSizing: "border-box",
              }}
            />
            <Typography
              variant="body2"
              fontFamily={"NunitoSans"}
              fontSize={"16px"}
              color={"error"}
              textAlign={"center"}
              marginTop={"10px"}
              sx={{
                animation:
                  animation && errors.find((err) => err.field === "homeValue")
                    ? "shake 0.3s ease-in-out"
                    : "none",
              }}
            >
              {
                errors.find((err) => err.field === "judmentsAndLiensBalance")
                  ?.message
              }
            </Typography>
          </Box>

          <p
            className="get-started__note"
            style={{
              textAlign: "center",
            }}
          >
            <strong className="strong-700">These are not common.</strong>A
            judgment is a court’s decision in a legal case. A lien is a claim on
            your property to secure a debt.
          </p>
        </Collapse>
      </Box>
    </Box>
  );
};
