import Box from "@mui/system/Box";
import CButton from "components/CButton";
import CTimelineDot from "./CTimelineDot";
import {
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineOppositeContent,
} from "@mui/lab";
import HTMLtoolTip from "components/HTMLtoolTip";

export type CTrackerTimelineProps = {
  title: string;
  last?: boolean;
  children?: React.ReactNode;
  bottomChilden?: React.ReactNode;
  tooltip: string;
  status: "pending" | "analyzing" | "failed" | "success";
  action?: {
    message: string;
    onClick(): void;
    disabled?: boolean;
  };
  actions?: {
    message: string;
    onClick(): void;
    variant?: string;
    disabled?: boolean;
  }[];
  loading?: boolean;
  processingStatus?: boolean;
  badges?: React.ReactNode;
};
const CTrackerTimelineItem: React.FC<CTrackerTimelineProps> = ({
  title,
  last,
  status,
  action,
  tooltip,
  actions,
  children,
  loading,
  processingStatus,
  bottomChilden,
  badges,
}) => {
  const theme = useTheme();
  const buttonsFullWidth = useMediaQuery(theme.breakpoints.only("xs"));

  function transformMessageToCamelCase(message: string): string {
    const words = message.split(" ");
    const camelCaseMessage = words
      .map((word, index) => {
        if (index === 0) {
          return word.toLowerCase();
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
      })
      .join("");
    return camelCaseMessage;
  }

  return (
    <TimelineItem
      sx={{
        paddingLeft: 1,
        "&.MuiTimelineItem-root": {
          display: "flex",
        },
      }}
    >
      <TimelineOppositeContent sx={{ display: "none" }} />
      <TimelineSeparator>
        <TimelineDot
          variant="outlined"
          sx={{
            "&.MuiTimelineDot-root": {
              padding: "0",
              margin: "0 0",
              display: "flex",
              border: "solid",
              boxShadow: "none",
              borderRadius: "50%",
              borderColor: theme.palette.background.paper,
              alignSelf: "baseline",
              borderWidth: "0.1rem",
              backgroundColor: "transparent",
            },
          }}
        >
          <CTimelineDot status={status} processingStatus={processingStatus} />
        </TimelineDot>
        {!last && (
          <TimelineConnector
            sx={{
              margin: 0,
              background: `repeating-linear-gradient(transparent 0 4px, ${theme.palette.text.disabled} 4px 8px) 20%/2px 100% no-repeat`,
            }}
          />
        )}
      </TimelineSeparator>
      <TimelineContent
        sx={{
          overflowWrap: "break-word",
          marginBottom: 2,
          width: "70%",
          padding: "3px 8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            maxWidth: "100%",
            justifyContent: "space-between",
            alignItems: "start",
            flexDirection: "column",
            overflowWrap: "anywhere",
          }}
        >
          <Typography
            variant="h5"
            display={"flex"}
            alignItems={"center"}
            data-cy={title}
          >
            {title} <HTMLtoolTip children={<Button />} title={`${tooltip}`} />
            {badges && <Box sx={{ paddingLeft: "5px" }}>{badges}</Box>}
          </Typography>
          {children}

          <Box flexDirection={{ xs: "column", md: "row" }} display={"flex"}>
            {actions &&
              actions.flatMap((action, i) => (
                <CButton
                  type="button"
                  variant={action.variant ? "outlined" : "contained"}
                  fullWidth={buttonsFullWidth}
                  onClick={action.onClick}
                  sx={{
                    marginTop: 2,
                    whiteSpace: "pre",
                    ml: { md: i ? 2 : 0 },
                  }}
                  disabled={loading || action.disabled}
                  name={`${transformMessageToCamelCase(action.message)}`}
                  aria-label={`${transformMessageToCamelCase(action.message)}`}
                >
                  {loading ? (
                    <CircularProgress size={32} sx={{ color: "white" }} />
                  ) : (
                    action.message
                  )}
                </CButton>
              ))}
          </Box>
          {action && (
            <CButton
              type="button"
              fullWidth={buttonsFullWidth}
              variant="contained"
              onClick={action.onClick}
              sx={{ marginTop: 2, whiteSpace: "nowrap" }}
              disabled={loading || action.disabled}
              name={`${transformMessageToCamelCase(action.message)}`}
              aria-label={`${transformMessageToCamelCase(action.message)}`}
            >
              {loading ? (
                <CircularProgress size={32} sx={{ color: "white" }} />
              ) : (
                action.message
              )}
            </CButton>
          )}
          {bottomChilden}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

export default CTrackerTimelineItem;
