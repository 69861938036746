import API from "utils/API";
import moment from "moment";
import Loan from "typedef/Loan";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { DialogProps } from "@mui/material";
import useTracker from "components/CTracker/useTrackerContext";

type Inputs = {
  ssn: string;
  dob: string | undefined;
  coborrowerEmail: string;
  hasCoborrower: boolean;
  coborrowerFirstName: string;
  lastName?: string;
};

const usePersonaForm = (onClose: DialogProps["onClose"], consent: boolean) => {
  const form = useForm<Inputs>({
    defaultValues: { hasCoborrower: false, dob: "" },
  });

  const { loan, refetchLoan, type } = useTracker();
  const isBorrower = type === "borrower";
  const [error, setError] = useState<string | null>();

  const onSubmit = form.handleSubmit(async (data) => {
    if (isBorrower) {
      const response = await API.post<{ message: string }>({
        url: "/request-identity-verification",
        data: {
          loanId: loan?.id as string,
          birthdate: moment(data.dob).format("yyyy/MM/DD"),
          "identification-number": data.ssn.replace(/-/g, ""),
          boConsentedHardpull: consent,
          ...(data.hasCoborrower && {
            coborrowerName: data.coborrowerFirstName?.trim(),
            lastName: data.lastName?.trim(),
            coborrowerEmail: data.coborrowerEmail?.toLowerCase().trim(),
          }),
        },
      });

      if (data.hasCoborrower) {
        await API.post({
          url: `/save-to-loan/flags?entity=borrower&loanId=${loan?.id}`,
          data: {
            coborrowerInvited: true,
            offeredCoborrowerInvite: true,
          } as Loan["borrowerFlags"],
        });
      }

      if (loan?.borrowerSteps) {
        loan.borrowerSteps.idVerification.status = "analyzing";
      }
      await refetchLoan();
      if ("error" in response) {
        setError(response.error);
      } else {
        onClose?.({}, "backdropClick");
        setError(undefined);
      }
    }
  });

  return {
    ...form,
    onSubmit,
    error,
    setError,
  };
};

export default usePersonaForm;
