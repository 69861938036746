import { IconButton, Box, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import CDialog from "components/CDialog";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import CButton from "components/CButton";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { CustomInput } from "../LoanRequest/CustomInput";
import { FormData, HELOCOffer } from "../../useGetStarted";
import parseMoney from "utils/parseMoney";
import dollarFormatter from "utils/FormatterDollar";

interface EditAmountProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  item: HELOCOffer;
  requestedLoanAmount: FormData["requestedLoanAmount"];
  getOffer: (refresh?: boolean) => Promise<void>;
  loading?: boolean;
}

export const EditAmount = ({
  openModal,
  setOpenModal,
  formData,
  setFormData,
  item,
  requestedLoanAmount,
  loading,
  getOffer,
}: EditAmountProps) => {
  const { primaryText, secondaryText, tertiaryBackground, tertiaryText } =
    useHomeBridgeColors();

  const [amount, setAmount] = useState<number | undefined>(requestedLoanAmount);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validateAmount = (value: number, item: HELOCOffer) => {
    setErrorMessage(null);
    if (value < item.minAmount || value > item.maxAmount) {
      setErrorMessage(
        `You can enter any loan amount between ${dollarFormatter.format(
          item.minAmount,
        )} and ${dollarFormatter.format(item.maxAmount)}`,
      );
      return false;
    }
  };

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const isNumber = typeof value === "number";
    const newValue = isNumber ? value : parseMoney(value);

    validateAmount(newValue, item);
    setAmount(newValue);
    setFormData({
      ...formData,
      requestedLoanAmount: newValue as unknown as number,
      initialDrawAmount: newValue as unknown as number,
    });
  };

  const validateFields =
    loading || errorMessage !== null || !amount || amount === 0;
  return (
    <CDialog
      fullWidth
      open={openModal}
      onClose={() => {
        if (validateFields) {
          return;
        }
        setOpenModal(false);
      }}
      className="custom__dialog custom__dialog--edit-amount"
      PaperProps={{
        style: {
          maxWidth: "400px",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={() => setOpenModal(false)}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
        disabled={validateFields}
      >
        <Close />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Typography
          variant="h4"
          fontFamily={"NunitoSans"}
          fontSize={{
            xs: "22px",
            sm: "24px",
            md: "26px",
          }}
          textAlign={"center"}
          marginTop={"20px"}
          marginBottom={"10px"}
          fontWeight={900}
        >
          HELOC Amount
        </Typography>

        <NumberFormat
          id={"requestedLoanAmount"}
          name={"requestedLoanAmount"}
          data-cy={"requestedLoanAmount"}
          className="no-spinner"
          thousandSeparator
          allowNegative={false}
          allowEmptyFormatting={false}
          prefix="$"
          onChange={onValueChange}
          customInput={CustomInput}
          value={amount}
          style={{
            width: "180px",
            padding: "8px",
            boxSizing: "border-box",
            textAlign: "center",
          }}
        />

        <p
          style={{
            color: "#ff0000",
            textAlign: "center",
            opacity: errorMessage ? "1" : "0",
            margin: "0",
          }}
        >
          {errorMessage ?? "The was an error"}
        </p>

        <Box
          textAlign={"center"}
          display={"flex"}
          justifyContent={"center"}
          margin={"20px 0"}
          gap={"20px"}
        >
          <CButton
            variant="contained"
            disabled={validateFields}
            onClick={() => {
              setOpenModal(false);
            }}
            sx={{
              backgroundColor: secondaryText,
              color: primaryText,
              border: `1px solid ${tertiaryText}`,
              "&:hover": {
                backgroundColor: tertiaryBackground,
                color: secondaryText,
              },
            }}
          >
            Cancel
          </CButton>
          <CButton
            variant="contained"
            disabled={validateFields}
            loading={loading}
            onClick={() => {
              getOffer(true);
            }}
            sx={{
              backgroundColor: tertiaryText,
              color: secondaryText,
              border: `1px solid ${tertiaryText}`,
              "&:hover": {
                backgroundColor: primaryText,
              },
            }}
          >
            Confirm
          </CButton>
        </Box>
      </Box>
    </CDialog>
  );
};
