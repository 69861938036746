import {
  Box,
  Typography,
  Divider,
  useTheme,
  Container,
  useMediaQuery,
  Theme,
} from "@mui/material";
import HBLogo from "../../assets/Icons/homebridge_logo.svg";
import HELLAFAST from "../../assets/Icons/hella_fast.svg";
import { useHomeBridgeColors } from "utils/useHomeBridgeColors";
import { CHBButton } from "components/CHBButton";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import HBHouseIcon from "../../assets/Icons/HBHouseLender.svg";
import { CHBLink } from "components/CHBLink";

export const HBFastEasyPay = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const [searchParams] = useSearchParams();
  const { primaryText, tertiaryText, fourthText } = useHomeBridgeColors();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const navigate = useNavigate();

  const handleGetStarted = useCallback(() => {
    const queryString = searchParams.toString();
    navigate(`/get-started?${queryString}`);
  }, [navigate, searchParams]);

  const currentYear = new Date().getFullYear();
  return (
    <Container
      className={`homebridge-layout ${
        pathName !== "/" ? pathName.replace("/", "homebridge-layout__") : ""
      }`}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5vh",
        }}
      >
        <img src={HBLogo} alt="Homebridge LOGO" />
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        marginTop={"3rem"}
        marginBottom={"3rem"}
        flexDirection={"column"}
        minHeight={"55vh"}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
            maxWidth: { xs: "auto", md: "40vw" },
            margin: "0 auto",
            gap: "1rem",
          }}
        >
          <Box>
            <img src={HELLAFAST} alt="Homebridge LOGO" />
          </Box>
          <Typography
            fontFamily={"NunitoSans"}
            variant="h2"
            fontSize={{
              xs: "32px",
              sm: "42px",
              md: "48px",
              lg: "48px",
            }}
            textAlign={"center"}
            color={tertiaryText}
            fontWeight={700}
          >
            Need cash now?
          </Typography>
          <Typography
            variant="h3"
            fontSize={{
              xs: "22px",
              sm: "30px",
              md: "34px",
              lg: "40px",
            }}
            fontWeight={500}
            color={primaryText}
          >
            Here's the fast and easy way <br /> to access your home's equity!
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            gap: "2rem",
            margin: "2.5rem 0",
            textWrap: "balance",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
              maxWidth: "200px",
              textAlign: "center",
              gap: "1rem",
            }}
          >
            <Typography
              variant="h3"
              fontSize="1.5rem"
              fontWeight={500}
              color={primaryText}
            >
              Get approved in minutes!
            </Typography>
            <Typography
              variant="h4"
              fontSize="1.2rem"
              fontWeight={400}
              color={fourthText}
            >
              Complete our simple app right now.
            </Typography>
          </Box>
          <svg
            width="21"
            height="19"
            viewBox="0 0 21 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 11.2973L10.5 19L-1.23396e-06 11.2973L-2.46316e-07 7.14702e-08L21 1.90735e-06L21 11.2973Z"
              fill="#20A2D8"
            />
          </svg>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
              maxWidth: "200px",
              textAlign: "center",
              gap: "1rem",
            }}
          >
            <Typography
              variant="h3"
              fontSize="1.5rem"
              fontWeight={500}
              color={primaryText}
            >
              Get your cash in days!
            </Typography>
            <Typography
              variant="h4"
              fontSize="1.2rem"
              fontWeight={400}
              color={fourthText}
            >
              Not weeks or longer like other lenders.
            </Typography>
          </Box>
          <svg
            width="21"
            height="19"
            viewBox="0 0 21 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 11.2973L10.5 19L-1.23396e-06 11.2973L-2.46316e-07 7.14702e-08L21 1.90735e-06L21 11.2973Z"
              fill="#20A2D8"
            />
          </svg>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
              maxWidth: "200px",
              textAlign: "center",
              gap: "1rem",
            }}
          >
            <Typography
              variant="h3"
              fontSize="1.5rem"
              fontWeight={500}
              color={primaryText}
              // style={{textWrap:'balance'}}
            >
              Get started risk-free!
            </Typography>
            <Typography
              variant="h4"
              fontSize="1.2rem"
              fontWeight={400}
              color={fourthText}
            >
              There’s no impact on your credit score.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            margin: "2.5rem 0",
          }}
        >
          <CHBButton
            onClick={handleGetStarted}
            style={{
              padding: "10px 30px",
            }}
          >
            GET STARTED
          </CHBButton>
        </Box>
      </Box>
      <Divider
        orientation="horizontal"
        sx={{
          border: `1px solid ${theme.palette.text.primary}`,
          width: { xs: "85%", md: "60%", lg: "50%", xl: "100%" },
          margin: "0 auto",
          mb: 5,
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "40px",
          textAlign: "center",
          margin: "0 auto",
          padding: "5rem 0",
          width: "90%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "row" },
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",

            gap: {
              xs: "10px",
              md: "0",
            },
          }}
        >
          <CHBLink
            to="https://www.homebridge.com/who-we-are/"
            target="_blank"
            separate={!isMobile}
            isFooter
            style={{
              color: theme.palette.common.white,
              textUnderlineOffset: "2px",
              textDecorationColor: theme.palette.background.default,
              whiteSpace: "nowrap",
              margin: "0 10px",
            }}
          >
            About Us
          </CHBLink>

          <CHBLink
            to="https://homebridgeheloc.com/terms/"
            target="_blank"
            separate={!isMobile}
            isFooter
            style={{
              color: theme.palette.common.white,
              textUnderlineOffset: "2px",
              textDecorationColor: theme.palette.background.default,
              whiteSpace: "nowrap",
              margin: "0 10px",
            }}
          >
            Terms of Use
          </CHBLink>
          <CHBLink
            to="https://www.homebridge.com/privacy-policy/"
            target="_blank"
            separate={!isMobile}
            isFooter
            style={{
              color: theme.palette.common.white,
              textUnderlineOffset: "2px",
              textDecorationColor: theme.palette.background.default,
              whiteSpace: "nowrap",
              margin: "0 10px",
            }}
          >
            Privacy Policy
          </CHBLink>
          <CHBLink
            to="/licenses"
            target="_blank"
            separate={!isMobile}
            isFooter
            style={{
              color: theme.palette.common.white,
              textUnderlineOffset: "2px",
              textDecorationColor: theme.palette.background.default,
              whiteSpace: "nowrap",
              margin: "0 10px",
            }}
          >
            Licenses
          </CHBLink>
          <CHBLink
            to="https://www.homebridge.com/contact-us/"
            target="_blank"
            isFooter
            style={{
              color: theme.palette.common.white,
              textUnderlineOffset: "2px",
              textDecorationColor: theme.palette.background.default,
              whiteSpace: "nowrap",
              margin: "0 10px",
            }}
          >
            Contact Us
          </CHBLink>
        </Box>
        <Typography color={primaryText} width={"90%"}>
          Eligibility is subject to completion of an application and
          verification of homeownership, occupancy, title, income, employment,
          credit, home value, collateral, and underwriting requirements. Loan
          program terms and conditions will depend on underwriting and consumer
          credit characteristics. This is not a commitment to lend or offer of
          credit.
        </Typography>
        <Typography color={primaryText}>
          © {currentYear} Homebridge, a division of Homebridge Financial
          Services, Inc. | NMLS ID #6521 | nmlsconsumeraccess.org
        </Typography>
        <img src={HBHouseIcon} alt="HBHouseIcon" />
      </Box>
    </Container>
  );
};
