import { Box, Typography } from "@mui/material";
import dollarFormatter from "utils/FormatterDollar";

interface SliderValueDisplayProps {
  min: number;
  max: number;
  valueToDisplay: number;
  valueSlider: number;
}

const PERCENTAGE_BASE = 100;

const SliderValueDisplay: React.FC<SliderValueDisplayProps> = ({
  valueToDisplay,
  valueSlider,
  min,
  max,
}) => {
  const percentage = ((valueSlider - min) / (max - min)) * PERCENTAGE_BASE;
  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        position: "relative",
        left: `calc(${percentage}% - 0px)`,
        transform: `translateX(-50%)`,
      }}
    >
      <Typography sx={{ fontSize: 12 }}>
        {dollarFormatter.format(valueToDisplay)}
      </Typography>
    </Box>
  );
};

export default SliderValueDisplay;
