import { Theme, useTheme } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Loan from "typedef/Loan";
import API from "utils/API";
import { StepName, getAnualIncomeByTypeSelection } from "./getIncomeValues";
import BorrowerSteps from "typedef/BorrowerSteps";
import CoborrowerSteps from "typedef/CoborrowerSteps";
import useUser from "context/UserCustomer/useUserCustomerContext";
import checkTenMonthsMaturity from "./checkTenMonthsMaturity";
import calculateMonthlyAmount from "./calculateMonthlyAmount";
import moment from "moment";
import { UpdateFields } from "./ChangeDebtsInformationModal";
import { DirectCheck } from "typedef/ExperianCreditReport";
import { useGetDtiByCreditScore } from "utils/useGetDtiByCreditScore";
import { useCallback } from "react";

type LiabilitiesDebtsResponse = {
  liabilitiesHardPullAll: LiabilitiesHardPull[];
  liabilitiesHardPullBorrower: LiabilitiesHardPull[];
  liabilitiesHardPullCoborrower: LiabilitiesHardPull[];
  sk: string;
};

interface CheckedItemProps {
  checked: boolean;
  index: number;
}

export interface LiabilitiesHardPull {
  id: string;
  accountNumber: string;
  accountStatus: string;
  accountType: string;
  accountTypeOriginal: string;
  amount1: string;
  creditorName: string;
  ecoa: string;
  enhancedAccountType: string;
  enhancedPaymentStatus: string;
  lastReported: string;
  limit?: string;
  openDate: string;
  paymentHistory: { [year: string]: { [key: string]: boolean } };
  paymentStatus: string;
  remarks?: string;
  responsibility: string;
  revolvingOrInstallment: string;
  source: string;
  terms: string;
  times306090DaysLate: string;
  acceptedBefore?: boolean;
  dateOfLastPayment?: string;
  balance?: string;
  monthlyPayment: string;
  paydownStatus?: string;
  payoffStatus?: string;
  ignored?: boolean;
  proposedAdmin?: boolean;
  debtAddedByAdmin?: boolean;
  address: DirectCheck;
  isSubjectProperty?: boolean;
}

type ApiResponse = {
  status: number;
  data: {
    body: string;
    statusCode: number;
  };
};

export interface UseDebtsReturnProps {
  dtiColor: string;
  isDtiTooHigh: boolean;
  isProposedPaydown: boolean;
  theme: Theme;
  tradelines?: LiabilitiesHardPull[];
  isLoading: boolean;
  handleSelectTradeLine: (
    e: React.ChangeEvent<HTMLInputElement>,
    row: LiabilitiesHardPull,
    index: number,
  ) => void;
  handleConfirmDebts: () => Promise<void>;
  setIsLastTradeline: (isLast: boolean) => void;
  dti: number;
  needsHelp?: boolean;
  openHelpDialog: boolean;
  setOpenHelpDialog: (open: boolean) => void;
  setOpenModalB2toNPE: (open: boolean) => void;
  openModalB2toNPE: boolean;
  totalBalanceSelected: number;
  netFunding: number;
  checkedItems: CheckedItemProps[];
  showDTI: boolean;
  handleCloseModalB2ToNPE: () => void;
  errorDataMessage?: string;
  setErrorDataMessage: Dispatch<SetStateAction<undefined>>;
  requestedAdmin?: boolean;
  defaultDTI?: number;
  handleRefreshData: (
    updateField: UpdateFields,
    index: number,
    counter?: {
      current: number;
      total: number;
    },
  ) => void;
  checkedDebts?: LiabilitiesHardPull[];
  openSlider?: boolean;
  setOpenSlider: (open: boolean) => void;
  currentId?: number;
  handleNext: (index: number, total: number) => void;
  TOTAL_MONTHS_IN_YEAR: number;
  isLastTradeline: boolean;
  skTradelines?: string;
}

//**Function to check if the MDL was calculated with old or new logic after deploy 07-08-2024*/
const isMDLCalculatedWithOldLogic = (
  calculatedMDL: number,
  isBorrower?: boolean,
  loan?: Loan,
) => {
  const step = isBorrower ? loan?.borrowerSteps : loan?.coborrowerSteps;

  const deployDate = moment("07-08-2024");

  const isBeforeDeploy_08_07_2024 = moment(
    step?.hardpullVerification?.updatedAt,
  ).isBefore(deployDate);

  const isMDLOldLogic =
    calculatedMDL !== step?.creditVerification?.monthlyDebtLoad &&
    isBeforeDeploy_08_07_2024;

  return isMDLOldLogic;
};

const useDebts = ({
  loan,
  step,
  isBorrower,
}: {
  loan?: Loan;
  step?: BorrowerSteps | CoborrowerSteps;
  isBorrower?: boolean;
}): UseDebtsReturnProps => {
  const [tradelines, setTradelines] = useState<LiabilitiesHardPull[]>();

  const [defaultTradelines, setDefaultTradelines] = useState<
    LiabilitiesHardPull[]
  >([]);

  const loadedAnnualIncome = useRef({
    b1: false,
    b2: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isProposedPaydown, setIsProposedPaydown] = useState(false);
  const [openModalB2toNPE, setOpenModalB2toNPE] = useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [errorDataMessage, setErrorDataMessage] = useState();
  const { user, userAdmin } = useUser();
  const [totalBalanceSelected, setTotalBalanceSelected] = useState<number>(0);
  const [openSlider, setOpenSlider] = useState(false);
  const [currentId, setCurrentId] = useState<number | undefined>(1);
  const [requestedAdmin, setRequestedAdmin] = useState(false);
  const [b1AnnualIncome, setB1AnnualIncome] = useState<number | undefined>(
    undefined,
  );
  const [b2AnnualIncome, setB2AnnualIncome] = useState<number | undefined>(
    undefined,
  );
  const [isLastTradeline, setIsLastTradeline] = useState(false);
  const truvIncomeSourceB1 =
    loan?.borrowerSteps?.incomeVerification?.truvIncome?.incomeSourceSelected ??
    undefined;
  const truvIncomeSourceB2 =
    loan?.coborrowerSteps?.incomeVerification?.truvIncome
      ?.incomeSourceSelected ?? undefined;

  const [skTradelines, setSkTradelines] = useState("");

  const isFunded =
    loan?.borrowerSteps?.fundingVerification?.status === "success";

  const calculateTradelinesBalance = (
    isChecked: boolean,
    rowBalance: number,
  ) => {
    const newTotalBalanceSelected = isChecked
      ? totalBalanceSelected + rowBalance
      : totalBalanceSelected - rowBalance;

    setTotalBalanceSelected(newTotalBalanceSelected);
  };

  useEffect(() => {
    const message = step?.incomeVerification?.message || "";
    const regex = /A\s*new\s*paydown\s*has\s*been\s*proposed/i;
    if (regex.test(message)) {
      setRequestedAdmin(true);
    } else {
      setRequestedAdmin(false);
    }
  }, [step]);

  const formatCheckedItems = useMemo(() => {
    return tradelines && tradelines.length > 0
      ? (tradelines
          .map((row, index) => {
            if (
              (row?.responsibility === "Individual" ||
                row?.responsibility === "Joint Account") &&
              row?.accountStatus === "Open"
            ) {
              const isChecked =
                Boolean(row?.paydownStatus) ||
                Boolean(row?.payoffStatus) ||
                Boolean(row?.acceptedBefore);
              return {
                checked: isChecked,
                index,
              };
            }
            return null;
          })
          .filter((item) => item !== null) as CheckedItemProps[])
      : [];
  }, [tradelines]);

  const [checkedItems, setCheckedItems] =
    useState<CheckedItemProps[]>(formatCheckedItems);

  const theme = useTheme();

  useEffect(() => {
    const isTradelinesEdited = loan?.borrowerFlags?.tradelinesEdited;
    const invokeUrl = userAdmin?.id ? "/admin-impersonate/invoke" : "/invoke";
    if (
      loan?.id &&
      (loan?.borrowerSteps?.hardpullVerification?.creditProfile?.tradeline
        ?.length ||
        loan?.coborrowerSteps?.hardpullVerification?.creditProfile?.tradeline
          ?.length)
    ) {
      (async () => {
        if (isTradelinesEdited) {
          const result = await API.post<LiabilitiesDebtsResponse>({
            url: invokeUrl,
            data: {
              FunctionName: "getService",
              InvocationType: "RequestResponse",
              Service: "nftydoor-admin",
              Payload: {
                loanId: loan.id,
                pathParameters: {
                  service: `tradeline-liabilities`,
                  id: loan?.id,
                },
                ...(userAdmin?.id && {
                  adminId: userAdmin?.id,
                  ignoreGenerateNote: true,
                }),
              },
            },
          });

          const errorRes = "error" in result;

          if (result && !errorRes) {
            const liabilitiesHardPullBorrower = Array.isArray(
              result.data.liabilitiesHardPullBorrower,
            )
              ? result.data.liabilitiesHardPullBorrower
              : [];
            const liabilitiesHardPullCoborrower = Array.isArray(
              result.data.liabilitiesHardPullCoborrower,
            )
              ? result.data.liabilitiesHardPullCoborrower
              : [];

            const allLiabilitiesHardPull = [
              ...liabilitiesHardPullBorrower,
              ...liabilitiesHardPullCoborrower,
            ];

            let hasProposedStatus = false;

            const newSortedArray = allLiabilitiesHardPull
              .sort((a, b) => {
                if (
                  a?.paydownStatus === "proposed" &&
                  b?.paydownStatus !== "proposed"
                ) {
                  hasProposedStatus = true;
                  return 1;
                } else if (
                  a?.paydownStatus !== "proposed" &&
                  b?.paydownStatus === "proposed"
                ) {
                  hasProposedStatus = true;
                  return -1;
                } else {
                  return 0;
                }
              })
              .map((tradeline) => {
                if (
                  tradeline?.paydownStatus === "accepted" ||
                  tradeline?.payoffStatus === "accepted"
                ) {
                  tradeline["acceptedBefore"] = true;
                } else {
                  tradeline["acceptedBefore"] = false;
                }
                return tradeline;
              });

            if (hasProposedStatus) {
              setIsProposedPaydown(true);
            }
            setSkTradelines(result?.data?.sk ?? "");
            if (loan?.borrowerFlags?.tradelinesAcceptedAreHigherThanOffer) {
              const updatedTradelines =
                updateTradelinesIfChangeOffer(newSortedArray);
              setTradelines(updatedTradelines);
              setDefaultTradelines(updatedTradelines);
            } else {
              setTradelines(newSortedArray);
              setDefaultTradelines(newSortedArray);
            }
          }
        }

        if (!isTradelinesEdited) {
          const result = await API.post<LiabilitiesDebtsResponse>({
            url: invokeUrl,
            data: {
              FunctionName: "getCreditLiabilities",
              InvocationType: "RequestResponse",
              Service: "nftydoor-admin",
              Payload: {
                loanId: loan.id,
                pathParameters: {
                  service: `liabilities`,
                  id: loan?.id,
                },
                ...(userAdmin?.id && {
                  adminId: userAdmin?.id,
                  ignoreGenerateNote: true,
                }),
              },
            },
          });

          const errorRes = "error" in result;

          if (result && !errorRes) {
            const b1 =
              result?.data?.liabilitiesHardPullBorrower?.map((row) => {
                return { ...row, source: "B1" };
              }) ?? [];
            const b2 =
              result?.data?.liabilitiesHardPullCoborrower?.map((row) => {
                return { ...row, source: "B2" };
              }) ?? [];
            const b1andB2 = [...b1, ...b2];

            if (loan?.borrowerFlags?.tradelinesAcceptedAreHigherThanOffer) {
              const updatedTradelines = updateTradelinesIfChangeOffer(b1andB2);
              setTradelines(updatedTradelines);
              setDefaultTradelines(updatedTradelines);
            } else {
              setTradelines([...b1, ...b2]);
              setDefaultTradelines([...b1, ...b2]);
            }
          }
        }
      })();
    }
  }, [
    loan?.borrowerFlags?.tradelinesEdited,
    loan?.id,
    loan?.borrowerSteps?.hardpullVerification?.creditProfile?.tradeline?.length,
    loan?.coborrowerSteps?.hardpullVerification?.creditProfile?.tradeline
      ?.length,
    loan?.borrowerSteps?.hardpullVerification?.paydownInfo?.length,
    loan?.coborrowerSteps?.hardpullVerification?.paydownInfo?.length,
    loan?.payoff?.length,
    loan?.borrowerFlags?.tradelinesAcceptedAreHigherThanOffer,
    userAdmin?.id,
    loan?.borrowerFlags?.debtsCofirmed,
    loan?.coborrowerFlags?.debtsCofirmed,
  ]);

  const netFunding = Number(
    loan?.finalOffer?.netFundingWiredToBank ??
      loan?.initialOffer?.netFundingWiredToBank.toFixed(2),
  );

  const updateTradelinesIfChangeOffer = (tradelines: LiabilitiesHardPull[]) => {
    const updatedTradelines = tradelines?.map((tradeline) => {
      if (tradeline.paydownStatus !== undefined) {
        return {
          ...tradeline,
          paydownStatus: undefined,
        };
      } else if (tradeline.payoffStatus !== undefined) {
        return {
          ...tradeline,
          payoffStatus: undefined,
        };
      }
      return tradeline;
    });
    return updatedTradelines;
  };

  const handleSelectTradeLine = (
    e: React.ChangeEvent<HTMLInputElement>,
    row: LiabilitiesHardPull,
    index: number,
  ) => {
    const isChecked = e?.target?.checked;

    setCheckedItems((prev) => {
      const newFields = [...prev];
      const startIndex = newFields?.findIndex(
        (field) => field?.index === index,
      );
      if (newFields[startIndex]?.checked !== undefined) {
        newFields[startIndex].checked = isChecked;
      }
      return newFields;
    });
    const rowBalance = Number(row.balance);
    calculateTradelinesBalance(isChecked, rowBalance);

    let newTradelines = [...(tradelines ?? [])];
    const newStatus = isChecked ? "accepted" : undefined;

    if (row?.revolvingOrInstallment === "R") {
      newTradelines[index].paydownStatus = newStatus;
    } else if (row?.revolvingOrInstallment === "I") {
      newTradelines[index].paydownStatus = newStatus;
      newTradelines[index].payoffStatus = newStatus;
    }

    if (row?.responsibility === "Joint Account") {
      //** Add ignored to the other borrower tradeline */
      newTradelines = newTradelines?.map((rowJointAccount) => {
        if (
          rowJointAccount.accountNumber === row?.accountNumber &&
          rowJointAccount?.source !== row?.source
        ) {
          return { ...rowJointAccount };
        } else return rowJointAccount;
      });
    }
    setTradelines(newTradelines);
  };

  //** DTI calculations */
  const totalDebts = useMemo(() => {
    let totalTemp = 0;
    tradelines?.forEach((liability) => {
      const isOpen = liability.accountStatus !== "Close";

      const isTenMonthsMaturity = checkTenMonthsMaturity({
        isInstallment: liability?.revolvingOrInstallment === "I",
        isOpen: isOpen,
        terms: liability.terms,
        openDate: liability?.openDate,
        isSubjectProperty: liability?.isSubjectProperty ?? false,
        sk: skTradelines,
      });

      const isDebtAddedByAdmin = liability?.debtAddedByAdmin;
      const isValidECOA =
        liability?.ecoa === "1" ||
        liability?.ecoa === "2" ||
        liability?.ecoa === "4";

      const isLiabilityEligible =
        liability?.monthlyPayment &&
        liability?.monthlyPayment !== "UNKNOWN" &&
        !liability?.ignored &&
        !liability?.paydownStatus &&
        !liability?.payoffStatus &&
        isOpen &&
        ((isValidECOA && !isTenMonthsMaturity) || isDebtAddedByAdmin);

      if (isLiabilityEligible) {
        const monthlyAmount = calculateMonthlyAmount({
          revolvingOrInstallment: liability.revolvingOrInstallment,
          accountType: liability.accountTypeOriginal,
          monthlyPaymentAmount: liability.monthlyPayment,
          amount1: parseFloat(liability.amount1),
        });
        totalTemp += monthlyAmount;
      }
    });

    const isWithOldMDL = isMDLCalculatedWithOldLogic(
      totalTemp,
      isBorrower,
      loan,
    );
    let total = 0;
    tradelines?.forEach((liability) => {
      const isOpen = liability.accountStatus !== "Close";
      const isTenMonthsMaturity = checkTenMonthsMaturity({
        isInstallment: liability?.revolvingOrInstallment === "I",
        isOpen: isOpen,
        terms: liability.terms,
        openDate: liability?.openDate,
        isSubjectProperty: liability?.isSubjectProperty ?? false,
        sk: skTradelines,
      });

      const commonValidations =
        liability?.monthlyPayment &&
        liability?.monthlyPayment !== "UNKNOWN" &&
        !liability?.ignored &&
        !liability?.paydownStatus &&
        !liability?.payoffStatus &&
        isOpen;

      const isDebtAddedByAdmin = liability?.debtAddedByAdmin;
      const isValidECOA =
        liability?.ecoa === "1" ||
        liability?.ecoa === "2" ||
        liability?.ecoa === "4";

      if (isWithOldMDL && commonValidations) {
        total += parseInt(liability?.monthlyPayment ?? 0);
      } else if (
        !isWithOldMDL &&
        commonValidations &&
        ((isValidECOA && !isTenMonthsMaturity) || isDebtAddedByAdmin)
      ) {
        const monthlyAmount = calculateMonthlyAmount({
          revolvingOrInstallment: liability.revolvingOrInstallment,
          accountType: liability.accountTypeOriginal,
          monthlyPaymentAmount: liability.monthlyPayment,
          amount1: parseFloat(liability.amount1),
        });
        total += monthlyAmount;
      }
    });

    return total;
  }, [tradelines, loan, isBorrower, skTradelines]);

  const totalDefaultDebts = useMemo(() => {
    let total = 0;
    defaultTradelines?.forEach((liability) => {
      if (
        liability?.monthlyPayment &&
        liability?.monthlyPayment !== "UNKNOWN" &&
        !liability?.ignored &&
        !liability?.paydownStatus &&
        !liability?.payoffStatus &&
        liability?.accountStatus !== "Close"
      )
        total += Number(liability?.monthlyPayment);
    });
    return total;
  }, [defaultTradelines]);

  const TOTAL_MONTHS_IN_YEAR = 12;

  const loanMonthlyPayment =
    loan?.finalOffer?.monthlyPayment ?? loan?.initialOffer?.monthlyPayment ?? 0;

  useEffect(() => {
    const fetchB1Income = async () => {
      const defaultAmount =
        loan?.borrowerSteps?.incomeVerification?.annualIncome;
      const incomeSelection =
        loan?.borrowerSteps?.incomeVerification?.incomeSelection;
      if (incomeSelection) {
        if (isFunded) {
          setB1AnnualIncome(defaultAmount ?? undefined);
        } else {
          try {
            const amount = await getAnualIncomeByTypeSelection({
              loan,
              selection: incomeSelection,
              stepName: StepName.borrowerSteps,
              truvIncomeSource: truvIncomeSourceB1,
            });
            if ("data" in amount) {
              setB1AnnualIncome(amount.data as number);
            } else {
              setB1AnnualIncome(0);
            }
          } catch (error) {
            setB1AnnualIncome(0);
          }
        }
      }
    };
    if (loan && !loadedAnnualIncome.current.b1) {
      fetchB1Income();
      loadedAnnualIncome.current.b1 = true;
    }
  }, [loan, truvIncomeSourceB1, isFunded, loadedAnnualIncome.current.b1]);

  useEffect(() => {
    const fetchB2Income = async () => {
      const defaultAmount =
        loan?.coborrowerSteps?.incomeVerification?.annualIncome;
      const incomeSelection =
        loan?.coborrowerSteps?.incomeVerification?.incomeSelection;
      if (incomeSelection) {
        if (isFunded) {
          setB2AnnualIncome(defaultAmount ?? undefined);
        } else {
          try {
            const amount = await getAnualIncomeByTypeSelection({
              loan,
              selection: incomeSelection,
              stepName: StepName.coborrowerSteps,
              truvIncomeSource: truvIncomeSourceB2,
            });
            if ("data" in amount) {
              setB2AnnualIncome(amount.data as number);
            } else {
              setB2AnnualIncome(0);
            }
          } catch (error) {
            setB2AnnualIncome(0);
          }
        }
      }
    };
    if (loan && !loadedAnnualIncome.current.b2) {
      fetchB2Income();
      loadedAnnualIncome.current.b2 = true;
    }
  }, [loan, truvIncomeSourceB2, isFunded, loadedAnnualIncome.current.b2]);

  const b1MonthlyIncome =
    b1AnnualIncome !== undefined
      ? b1AnnualIncome / TOTAL_MONTHS_IN_YEAR
      : undefined;

  const b2MonthlyIncome =
    b2AnnualIncome !== undefined
      ? b2AnnualIncome / TOTAL_MONTHS_IN_YEAR
      : undefined;

  const totalMonthlyIncome =
    (b1MonthlyIncome !== undefined ? b1MonthlyIncome : 0) +
    (b2MonthlyIncome !== undefined ? b2MonthlyIncome : 0);

  const statedMonthlyIncome =
    (loan?.initialOffer?.annualIncome ?? 0) / TOTAL_MONTHS_IN_YEAR;

  const dti =
    b1MonthlyIncome !== undefined
      ? (totalDebts + loanMonthlyPayment) /
        (totalMonthlyIncome ?? statedMonthlyIncome)
      : 0;

  const defaultDTI =
    b1MonthlyIncome !== undefined
      ? (totalDefaultDebts + loanMonthlyPayment) /
        (totalMonthlyIncome ?? statedMonthlyIncome)
      : undefined;

  const handleConfirmDebts = useCallback(async () => {
    setIsLoading(true);

    let isJointAccount = false;

    if (tradelines) {
      for (const tradeline of tradelines) {
        if (
          tradeline?.responsibility === "Joint Account" &&
          (tradeline?.paydownStatus === "accepted" ||
            tradeline?.payoffStatus === "accepted")
        ) {
          isJointAccount = true;
        }
      }

      if (
        loan?.coborrowerId?.includes("temp") &&
        loan.borrowerSteps?.incomeVerification.isJointAccount
          ?.isJointAccount === undefined &&
        !isJointAccount
      ) {
        setOpenModalB2toNPE(true);
      }
    }
    /** When Borrower click the button set all the tradelines checked with status 'accepted' */
    const mappedTradelines = tradelines?.map((tradeline) => {
      const tradelineBorrowerSource = isBorrower ? "B1" : "B2";
      if (tradeline?.source === tradelineBorrowerSource) {
        const isPaydown = tradeline?.revolvingOrInstallment === "R";
        const isPayoff = tradeline?.revolvingOrInstallment === "I";

        const paydownStatus =
          isPaydown && tradeline?.paydownStatus === "proposed"
            ? "accepted"
            : tradeline?.paydownStatus;

        return {
          ...tradeline,
          paydownStatus: isPayoff ? undefined : paydownStatus,
          payoffStatus:
            isPayoff &&
            (tradeline?.paydownStatus === "proposed" ||
              tradeline?.payoffStatus === "accepted")
              ? "accepted"
              : undefined,
        };
      } else return tradeline;
    });

    if (isLastTradeline) {
      const createOfferResponse = (await API.post<LiabilitiesDebtsResponse>({
        url: "/invoke",
        data: {
          FunctionName: "manageDebts",
          InvocationType: "RequestResponse",
          Service: "nftydoor-admin",
          Payload: {
            loanId: loan?.id,
            pathParameters: {
              task: "confirm-debts",
              id: loan?.id,
            },
            body: {
              userId: user?.id,
              loanId: loan?.id,
              tradelines: mappedTradelines,
            },
          },
        },
      })) as unknown as ApiResponse;
      if (createOfferResponse?.data?.statusCode !== 200) {
        const errorData = JSON.parse(createOfferResponse?.data?.body);
        setErrorDataMessage(errorData?.message);
        setIsLoading(false);
      }
      setIsLastTradeline(false);
    }
    setIsLoading(false);
  }, [
    tradelines,
    isLastTradeline,
    isBorrower,
    loan?.borrowerSteps?.incomeVerification.isJointAccount?.isJointAccount,
    loan?.coborrowerId,
    loan?.id,
    user?.id,
  ]);

  useEffect(() => {
    if (isLastTradeline) {
      handleConfirmDebts();
    }
  }, [isLastTradeline, handleConfirmDebts]);

  const dtiItems = useGetDtiByCreditScore();

  const maxdti = dtiItems?.dti;

  const isDtiTooHigh = maxdti !== undefined && dti > maxdti;

  const dtiColor = isDtiTooHigh ? "#FF7A7A" : "#34C155";

  const needsHelp = step?.incomeVerification?.needsHelp;

  useEffect(() => {
    const initialTotalBalance = formatCheckedItems.reduce((total, item) => {
      if (item.checked) {
        const row = tradelines && tradelines[item.index];
        return total + Number(row?.balance ?? 0);
      }
      return total;
    }, 0);
    setCheckedItems(formatCheckedItems);
    setTotalBalanceSelected(initialTotalBalance);
  }, [formatCheckedItems, tradelines]);

  let showDTI = false;

  if (
    loan?.initialOffer?.useProceeds === "Debt Consolidation" ||
    (maxdti !== undefined && defaultDTI !== undefined && defaultDTI > maxdti) ||
    step?.hardpullVerification?.paydownInfo?.some(
      (paydown) => !paydown?.counterpartyId,
    )
  ) {
    showDTI = true;
  }

  const handleCloseModalB2ToNPE = () => {
    setOpenModalB2toNPE(false);
  };

  const handleRefreshData = (
    updateField: UpdateFields,
    index: number,
    counter?: {
      current: number;
      total: number;
    },
  ) => {
    const newTradelines = [...(tradelines ?? [])];
    newTradelines[index] = {
      ...newTradelines[index],
      ...updateField,
    };
    setTradelines(newTradelines);

    if (counter && counter?.current === counter?.total) {
      setIsLastTradeline(true);
    }
  };

  const checkedDebts = tradelines?.filter(
    (item) =>
      (Boolean(item?.paydownStatus) || Boolean(item?.payoffStatus)) &&
      (isBorrower ? item?.source === "B1" : item?.source === "B2"),
  );

  const handleNext = (index: number, total: number) => {
    if (index === total + 1) {
      setIsLastTradeline(true);
      handleConfirmDebts();
      setOpenSlider(false);
      setCurrentId(index - 1);
    } else {
      setCurrentId(index);
    }
  };

  return {
    dtiColor,
    isDtiTooHigh,
    isProposedPaydown,
    theme,
    tradelines,
    isLoading,
    handleSelectTradeLine,
    handleConfirmDebts,
    setIsLastTradeline,
    dti,
    needsHelp,
    openHelpDialog,
    setOpenHelpDialog,
    setOpenModalB2toNPE,
    openModalB2toNPE,
    totalBalanceSelected,
    netFunding,
    checkedItems,
    showDTI,
    handleCloseModalB2ToNPE,
    errorDataMessage,
    setErrorDataMessage,
    requestedAdmin,
    defaultDTI,
    handleRefreshData,
    checkedDebts,
    openSlider,
    setOpenSlider,
    currentId,
    handleNext,
    TOTAL_MONTHS_IN_YEAR,
    isLastTradeline,
    skTradelines,
  };
};

export default useDebts;
